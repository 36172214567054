import React from "react";
import autoBind from 'react-autobind';
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from "moment";

import 'moment/locale/en-ca';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import '@/stylesheets/CommonCalendar.less';

import CustomComponent from '@/ui-components/CustomComponent';
import '@/stylesheets/AdminDashboardView.less';
import Globals from "@/config/Globals";
import CommonCalendarEvent from "./CommonCalendarEvent";
import { Row } from "antd";

const DragAndDropCalendar = withDragAndDrop(Calendar);

export default class CommonWeeklyCalendar extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      events: [],
    };

    this.localizer = momentLocalizer(moment);
    this.startTime = Globals.CalendarViewStartTime;
    this.endTime = Globals.CalendarViewEndTime;
    this.hasWeekend = Globals.CalendarViewHasWeekends;
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.events) !== JSON.stringify(this.props.events)) {
      const events = this.props.events.map(event => ({
        id: `${event.startTime}${event.endTime}${event.sessionID || Date.now()}`,
        start: moment(Number(event.startTime)).toDate(),
        end: moment(Number(event.endTime)).toDate(),
        metadata: {
          event,
          app: this.props.app,
          handleDelete: () => this.props.onDeleteEvent(event.startTime),
          handleEdit: () => this.props.onEditEvent(event), 
          handleEditAttendance: () => this.props.onEditAttendance(event.session),
        },
      }));
  
      this.setState({ events });
    }
  }

  render() {
    return (
      <Row type="flex" justify="start" className="weeklySessionContainer">
        <DragAndDropCalendar
          localizer={this.localizer}
          events={this.state.events || []}
          draggableAccessor="dragEnabledNo"
          selectable={false}
          resizable={false}
          defaultView={(this.hasWeekend ? Views.WEEK : Views.WORK_WEEK)}
          views={[(this.hasWeekend ? Views.WEEK : Views.WORK_WEEK)]}
          toolbar={false}
          onNavigate={() => { }}
          step={1}
          timeslots={60}
          popup
          formats={{
            timeGutterFormat: 'hh:mm A',
            eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
              return localizer.format(start, 'hh:mm A') + ' - ' + localizer.format(end, 'hh:mm A');
            }
          }}
          min={this.props.from.clone().hour(this.startTime.split(':')[0]).minute(this.startTime.split(':')[1]).toDate()}
          max={this.props.from.clone().hour(this.endTime.split(':')[0]).minute(this.endTime.split(':')[1]).toDate()}
          date={this.props.from.toDate()}
          components={{ eventWrapper: CommonCalendarEvent }}
        />
      </Row>
    )
  }
}
