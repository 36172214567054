import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col, Form, Input, Switch, InputNumber } from 'antd';
//
import Utils from '@/components/helpers/Utils';
//
export default class CommonVaultForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      formData: this.props.data || {}
    };
  }

  // Form methods
  async validateFields() {
    return await this.form.validateFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ formData: this.props.data }, () => {
        this.form.setFieldsValue(this.state.formData);
      });
    }
  }

  // UI
  render() {
    const { isEdit } = this.props;
    const { formData } = this.state;

    return (
      <Layout.Content justify="right-align">
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please, type the name!' }]}
              >
                <Input value={formData.name || ''} onChange={e => this.setState({ formData: { ...formData, name: e.target.value } })} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="alias" label="Alias">
                <Input value={formData.alias || ''} onChange={e => this.setState({ formData: { ...formData, alias: e.target.value } })} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                name="autoPurgeEmptyDockets"
                label="Auto purge empty dockets"
                valuePropName="checked"
              >
                <Switch checked={formData.autoPurgeEmptyDockets || false} onChange={checked => this.setState({ formData: { ...formData, autoPurgeEmptyDockets: checked } })} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="autoPurgeThreshold"
                label="Auto purge empty dockets after (days)"
                rules={[{ type: 'number', min: 0, message: 'Must be a number greater than or equal to 0' }]}
              >
                <InputNumber value={formData.autoPurgeThreshold || 0} onChange={value => this.setState({ formData: { ...formData, autoPurgeThreshold: value } })} min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                name="maxDocSize"
                label="Maximum document size (MB)"
                rules={[{ required: true, type: 'number', min: 1, message: 'Must be greater than 0' }]}
              >
                <InputNumber value={formData.maxDocSize || 0} onChange={value => this.setState({ formData: { ...formData, maxDocSize: value } })} min={1} style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="hardDeleteAfter"
                label="Hard delete objects after"
                rules={[{ type: 'number', min: 1, max: 60, message: 'Must be between 1 and 60' }]}
              >
                <InputNumber value={formData.hardDeleteAfter || 0} onChange={value => this.setState({ formData: { ...formData, hardDeleteAfter: value } })} min={1} max={60} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          {isEdit && (
            <>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item name="vaultNumDockets" label="Number of dockets in vault">
                    <Input disabled value={formData.vaultNumDockets || ''} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="vaultNumDocs" label="Number of documents in vault">
                    <Input disabled value={formData.vaultNumDocs || ''} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item name="vaultSize" label="Total size (MB)">
                    <Input disabled value={formData.vaultSize || ''} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="deleted" label="Vault deleted">
                    <Switch disabled checked={formData.deleted || false} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item name="deletionDate" label="Deletion date">
                    <Input disabled value={formData.deletionDate || ''} />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Layout.Content>
    );
  }
}
