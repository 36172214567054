import React from 'react';
import autoBind from 'react-autobind';
import { Button, Table, Tooltip } from 'antd';
//icons
import { EditOutlined } from '@ant-design/icons';
//
import Globals from '@/config/Globals'
//props are: app, isLoading, managers, onManagerEdit
export default class CommonOrganizationManagersTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: {} };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      { title: 'Application', key: 'application', width: '30%', sorter: true,
        sorter: (a, b) => ((a.tenants.map(tenant => this.props.tenants.find(t => t.id === tenant).name).join(', ') || '').toLowerCase().localeCompare((b.tenants.map(tenant => this.props.tenants.find(t => t.id === tenant).name).join(', ') || '').toLowerCase())),
        sortOrder: sortedInfo.columnKey === 'application' && sortedInfo.order,
        render: props => props.tenants.map(tenant => this.props.tenants.find(t => t.id === tenant).name).join(', ') || 'N/A' },
      { title: 'Name', key: 'name', width: '30%', sorter: true,
        sorter: (a, b) => (`${a.firstName} ${a.lastName}`).toLowerCase().localeCompare((`${b.firstName} ${b.lastName}`).toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: props => props.firstName+' '+props.lastName || 'N/A', },
      { title: 'Email', key: 'email', width: '10%', sorter: true,
        sorter: (a, b) => (a.email || '').toLowerCase().localeCompare((b.email || '').toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        render: props => props.email || 'N/A', },
      { title: 'Actions', width: '10%', key: 'Actions', align: 'center',
          render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title="Edit">
              <Button
                variant="none"
                icon={<EditOutlined />}
                shape="circle"
                onClick={this.props.onManagerEdit.bind(this, props)}
              />
            </Tooltip>
          </span>
      ),
      }
    ];
    const props = { rowKey: 'id', loading: this.props.isLoading, onChange: this.handleFilterChange,
                    locale: {emptyText: 'No managers found!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (<Table className="managersTable" columns={columns} dataSource={this.props.managers} {...props}/>);
  }
}
