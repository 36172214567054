import React from "react";
import autoBind from 'react-autobind';
import { Modal, Button } from 'antd';
//
import CommonEmailMessageDetailsForm from "../Forms/CommonEmailMessageDetailsForm";

export default class CommonEmailMessageModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false };
  }

  show() { 
    this.setState({ isVisible: true }); 
  }

  handleClose = () => {
    this.setState({ isVisible: false });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  // UI
  render() {
    return (
      <Modal 
        open={this.props.isVisible}
        title={'Email Details'}
        placement="right" 
        onCancel={this.handleClose}
        footer={[
          <Button type="primary" onClick={this.handleClose}
            loading={this.state.isLoading} > Ok </Button>,
          ]} 
        width={800}
      >
        <CommonEmailMessageDetailsForm details={this.props.details} /> 
      </Modal>
    );
  }
}


