import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import autoBind from 'react-autobind';
import { Divider } from 'antd';
//icons
import { FaUsers, FaRegAddressCard, FaUserShield } from 'react-icons/fa';
import { GrMapLocation, GrCertificate} from 'react-icons/gr';
//
import config from '@/config/config';
//
import '@/stylesheets/org-app/AdminOrganizationOptions.less';
//
export const AdminOrganizationOptionTypes = {
  ORGANIZATION: -1,
  EMPLOYEES: 0,
  CONTACTS: 1,
  MANAGERS: 2,
  LOCATIONS: 3,
  PROGRAMS: 4,
};
//
export default class AdminOrganizationOptions {
  constructor(selectedOption, orgID, app, orgName) {
    autoBind(this);
    this.selectedOption = selectedOption;
    this.orgObj = { id: orgID, name: orgName || '' };
    this.app = app;
  }
  parseUrl(path) { return this.app.urlManager.parseUrl(path, { n: encodeURIComponent(this.orgObj.name) }, this.orgObj.id); }
  getOptions() {
    const options = [];
    //Org page
    if (this.selectedOption != AdminOrganizationOptionTypes.ORGANIZATION) options.push(
      <Fragment key="-1">
        <Link to={this.parseUrl(config.ApplicationRoutes.org_organization)} className='adminOrgLink'>
          <GrCertificate className='adminOrgLinkIcon' /><strong>Company</strong>
        </Link>
        <Divider type="vertical" className="adminOrgDivider" />
      </Fragment>
    )
    //Employees
    if (this.selectedOption != AdminOrganizationOptionTypes.EMPLOYEES) options.push(
      <Fragment key="2">
        <Link to={this.parseUrl(config.ApplicationRoutes.org_employees)} className='adminOrgLink'>
          <FaUsers className='adminOrgLinkIcon' /><strong>Employees</strong>
        </Link>
        <Divider type="vertical" className="adminOrgDivider"/>
      </Fragment>
    )
    //Contacts
    if (this.selectedOption != AdminOrganizationOptionTypes.CONTACTS) options.push(
      <Fragment key="3">
        <Link to={this.parseUrl(config.ApplicationRoutes.org_contacts)} className='adminOrgLink'>
          <FaRegAddressCard className='adminOrgLinkIcon' /><strong>Contacts</strong>
        </Link>
        <Divider type="vertical" className="adminOrgDivider" />
      </Fragment>
    )
    //Managers
    if (this.selectedOption != AdminOrganizationOptionTypes.MANAGERS) options.push(
      <Fragment key="4">
        <Link to={this.parseUrl(config.ApplicationRoutes.org_managers)} className='adminOrgLink'>
          <FaUserShield className='adminOrgLinkIcon' /><strong>Managers</strong>
        </Link>
        <Divider type="vertical" className="adminOrgDivider" />
      </Fragment>
    )
    //Locations
    if (this.selectedOption != AdminOrganizationOptionTypes.LOCATIONS) options.push(
      <Fragment key="6">
        <Link to={this.parseUrl(config.ApplicationRoutes.org_locations)} className='adminOrgLink'>
          <GrMapLocation className='adminOrgLinkIcon' /><strong>Locations</strong>
        </Link>
        <Divider type="vertical" className="adminOrgDivider" />
      </Fragment>
    )
    //Programs
    if (this.selectedOption != AdminOrganizationOptionTypes.PROGRAMS) options.push(
      <Fragment key="5">
        <Link to={this.parseUrl(config.ApplicationRoutes.org_programs)} className='adminOrgLink'>
          <GrCertificate className='adminOrgLinkIcon' /><strong>Programs</strong>
        </Link>
        <Divider type="vertical" className="adminOrgDivider" />
      </Fragment>
    )
    return options;
  }
}
