import React from 'react';
import autoBind from 'react-autobind';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import moment from "moment";
import { DeleteOutlined } from '@ant-design/icons';
//
import Globals from '@/config/Globals'

export default class CommonEmailSuppressionTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  //Table support
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Email', dataIndex: 'email', key: 'email',
        sortOrder: sortedInfo.columnKey === 'timestamp' && sortedInfo.order,
        sorter: (a, b) => a.timestamp - b.timestamp,
      },
      {
        title: 'Suppression Reason', dataIndex: 'reason', key: 'reason',
        sortOrder: sortedInfo.columnKey === 'reason' && sortedInfo.order,
        sorter: (a, b) => a.eventType.localeCompare(b.eventType)
      },
      {
        title: 'Date Added', dataIndex: 'startDate', key: 'timestamp',
        sortOrder: sortedInfo.columnKey === 'timestamp' && sortedInfo.order,
        sorter: (a, b) => a.timestamp - b.timestamp,
        render: timestamp => moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: 'Actions', width: '10%', key: 'Actions', align: 'center',
        render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Remove Suppression'>
              <Popconfirm title={`Do you really want to remove suppression for '${props.email}'?`} placement="bottomRight" onConfirm={this.props.onDelete.bind(this, props.email)} okText="Yes" cancelText="No">
                <Button variant="none" disabled={this.props.disabled} icon={<DeleteOutlined />} shape="circle" style={{ marginLeft: 10 }} />
              </Popconfirm>
            </Tooltip>
          </span>
        ),
      }
    ];
    const props = {
      rowKey: 'messageId', loading: this.props.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: 'No email logs found!' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },
    };
    return (<Table columns={columns} dataSource={this.props.suppressions} {...props} />);
  }
}
