module.exports = {
  "IDMClientOptions": {
    "externalAuth": false,
    "disableImpersonatingApp": true,
    "cookiesEndpoint": "TBD",
    "apiEndpoint": "https://api-auth.bccsa-services.ca",
    "roles": {
      "USER": "MY-APP-USER-ROLE",
      "ADMIN": "MY-APP-ADMIN-ROLE",
      "ORG_ADMIN": "MY-APP-ORGADMIN-ROLE",
      "ORG_SUBADMIN": "MY-APP-ORGSUBADMIN-ROLE",
      "ROOT": "59a72bbd-9a43-427e-9c92-afd74ab19433",
      "IDM_USER": "59a72bbd-9a43-427e-9c92-afd74ab19431",
      "INSTRUCTOR": "679ad96f-714_BC___Students_Portal"
    },
    "partitions": {
      "PERSONAL": "ca.bccsa.personal",
      "PROFESSIONAL": "ca.bccsa.professional"
    }
  },
  "IncludesStageOnName": false,
  "Stage": "prod",
  "ApplicationRecaptchaKey": "6LfO17sUAAAAADbEcJD9brTVgjw5uPW5h495bHwt",
  "ApplicationAPIEndpoint": "https://api-sitereadybc.bccsa-services.ca",
  "ApplicationConfigAPIEndpoint": "https://api-config.bccsa-services.ca",
  "LogRocketOptions": {
    "applicationId": "ue6tsn/idm"
  },
  "SMClassroomEndpoint": "https://api-classroom.bccsa-services.ca",
  "IKFileServiceEndpoint": "https://api-ikfs.bccsa-services.ca",
  "SMEmailLogEndpoint": "https://api-bounce-reporter.bccsa-services.ca",
  "SMEmailSuppressionEndpoint": "https://fapwkqwxy7.execute-api.ca-central-1.amazonaws.com/dev",
  "ApplicationRoutes": {
    "login": "/",
    "homepage": "/",
    "register": "/register",
    "registrationConfirmation": "/registrationConfirmation/:id/:id2",
    "registrationConfirmationMail": "/registrationConfirmation/:id",
    "registrationConfirmationReset": "/registrationConfirmation/reset/:id",
    "resetPassword": "/resetPass/:id",
    "resetPasswordRaw": "/resetPass/",
    "resetPasswordConfirmation": "/resetPass/confirm/:id",
    "setupPassword": "/setupPass/:id/:id2",
    "authError": "/authError",
    "smsMFA": "/smfa",
    "profile": "/user",
    "contactAdmin": "mailto:support@ikonintegration.com",
    "user": "/app/idm/user/:id",
    "users": "/app/idm/users",
    "applications": "/app/idm/apps",
    "application": "/app/idm/app/:id",
    "role": "/app/idm/app/:id/role/:id2",
    "accessToken": "/app/idm/app/:id/accessToken/:id2",
    "partitionACLs": "/app/idm/acls",
    "partitionACL": "/app/idm/acl/:id",
    "idmApp_homepage": "app/idm",
    "config_customer": "/app/config/customer",
    "config_tenants": "/app/config/tenants",
    "config_tenant": "/app/config/tenant/:id",
    "config_emails": "/app/config/emailLogs",
    "config_vaults": "/app/config/vaults",
    "config_vault": "/app/config/vault/:id",
    "config_email_suppression": "/app/config/emailSuppression",
    "org_orgs_search": "/app/org/companies",
    "org_fileImports": "/app/org/imports",
    "org_orgs_contacts": "/app/org/contacts",
    "org_orgs_students": "/app/org/students",
    "org_orgs_cus": "/app/org/classificationUnits",
    "org_fileImport_job": "/app/org/import/:id",
    "org_cu_cu": "/app/org/classificationUnit/:id",
    "org_contact": "/app/org/contact/:id",
    "org_organization": "/app/org/:id",
    "org_employees": "/app/org/:id/employees",
    "org_contacts": "/app/org/:id/contacts",
    "org_managers": "/app/org/:id/managers",
    "org_locations": "/app/org/:id/locations",
    "org_programs": "/app/org/:id/programs",
    "instructor_calendar": "/app/calendar",
    "instructor_students": "/app/students",
    "instructor_edit_session_attendance": "/app/session/:id/attendance/edit",
    "custom_attributes": "/app/vaults/customAttributes",
    "dockets": "/app/vaults/dockets/:id",
    "docketDoc": "/app/vaults/dockets/:id/docket/:id2"
  },
  "ThemeDomainsMapping": {
    "*.bccsa-services.ca": {
      "themeKey": "bccsa",
      "customerID": "BCCSA-ST-Prod",
      "userRole": "9d6d3cf4-04a_BC___Students_Portal",
      "adminRole": "d2ca5b72-aa2_BC___Students_Portal",
      "instructorRole": "b27694d3-0e9_BC___Students_Portal",
      "orgAdminRole": "f6c1a1b6-bb9_BC___Students_Portal",
      "orgSubAdminRole": "e25cf521-c75_BC___Students_Portal",
      "idmSecret": "88a02978-686c-418a-a1d0-eba4f2970afe",
      "idmKey": "db6a4089-2f69-4758-bb38-a7c0df66854a",
      "idmExternalAuthDomain": "portal.bccsa-services.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org.bccsa-services.ca"
    },
    "*.constructionsafety.ca": {
      "themeKey": "csam",
      "customerID": "CSAM-Prod",
      "userRole": "5c002926-c5f_MB___Students_Portal",
      "adminRole": "d3c473c1-194_MB___Students_Portal",
      "instructorRole": "197ff9b0-207_MB___Students_Portal",
      "orgAdminRole": "f47290e0-33a_MB___Students_Portal",
      "orgSubAdminRole": "640e271a-8cd_MB___Students_Portal",
      "idmSecret": "d30f364f-47f9-4e36-ac3a-fff0962ec0f5",
      "idmKey": "bc7bf400-f2ca-4b77-822c-bf61d96bbc92",
      "idmExternalAuthDomain": "portal.constructionsafety.ca",
      "idmExternalCookiesDomain": "https://portal.bccsa-services.ca",
      "orgModEndpoint": "https://api-org-csam.bccsa-services.ca"
    },
    "*.ihsa.ca": {
      "themeKey": "ihsa",
      "customerID": "IHSA",
      "userRole": "ed369b9b-b3d_ON___Students_Portal",
      "adminRole": "4898c104-24f_ON___Students_Portal",
      "instructorRole": "6c1c12d0-4eb_ON___Students_Portal",
      "orgAdminRole": "8944fa0e-836_ON___Students_Portal",
      "orgSubAdminRole": "ff1ec77a-422_ON___Students_Portal",
      "idmSecret": "8a2e4cda-3855-4330-8955-f03c35a4e1be",
      "idmKey": "adab1712-9c4a-4e7e-b709-ff0f219a7927",
      "idmExternalAuthDomain": "portal-ncso.ihsa.ca",
      "idmExternalCookiesDomain": "https://portal-ncso.ihsa.ca",
      "orgModEndpoint": "https://api-org-ihsa.bccsa-services.ca"
    }
  }
}