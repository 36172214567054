import React from "react";
import autoBind from 'react-autobind';
import * as ExcelJS from 'exceljs';
import { Layout, Descriptions, Popconfirm, Button, Table, Select, Modal, Upload, message, Spin, Input, InputNumber, Tooltip, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
//
import CustomComponent from '@/ui-components/CustomComponent';
import '@/stylesheets/InstructorEditSessionAttendanceView.less';
//
import WhiteBox from "../commonComponents/WhiteBox";
import { FaDownload, FaEye, FaFile, FaFileCsv, FaFileExcel, FaFileImage, FaFilePdf, FaFileWord, FaTrash, FaUpload } from "react-icons/fa";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import Globals from "@/config/Globals";
import moment from "moment";
import Utils from "@/components/helpers/Utils";
import config from "@/config/config";
//
export default class InstructorEditSessionAttendanceView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.sessionID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);

    this.state = {
      isLoading: true,
      session: null,
      students: null,
      studentIDs: [],
      changes: {},
      isUploading: false,
    };

    this.attendanceColumns = [
      {
        title: 'Student',
        key: 'student',
        render: ({ firstName, lastName }) => `${firstName || ''} ${lastName || ''}`,
      },
      {
        title: 'Email',
        key: 'email',
        render: ({ email }) => `${email || ''}`,
      },
      {
        title: 'Employer',
        key: 'employer',
        render: ({ employerName }) => `${employerName || ''}`,
      },
      {
        title: 'Attendance',
        key: 'attendance',
        width: 180,
        render: ({ userID, attendance }) => (
          <Select
            style={{ width: '100%' }}
            placeholder="Select..."
            onChange={this.handleChangeStudentInfo.bind(this, userID, 'attendance')}
            defaultValue={attendance?.attendanceStatus}
          >
            <Select.Option value={Globals.Attendance_Status.ATTENDED} key={Globals.Attendance_Status.ATTENDED}>Attended</Select.Option>
            <Select.Option value={Globals.Attendance_Status.NO_SHOW} key={Globals.Attendance_Status.NO_SHOW}>No Show</Select.Option>
            <Select.Option value={Globals.Attendance_Status.INCOMPLETE} key={Globals.Attendance_Status.INCOMPLETE}>Incomplete</Select.Option>
          </Select>
        ),
      },
      {
        title: 'Result',
        key: 'result',
        width: 150,
        render: ({ userID, result }) => (
          <Select
            style={{ width: '100%' }}
            placeholder="Select..."
            onChange={this.handleChangeStudentInfo.bind(this, userID, 'result')}
            defaultValue={result?.resultStatus}
          >
            <Select.Option value={Globals.Result_Status.PASS} key={Globals.Result_Status.PASS}>Pass</Select.Option>
            <Select.Option value={Globals.Result_Status.FAIL} key={Globals.Result_Status.FAIL}>Fail</Select.Option>
            <Select.Option value={Globals.Result_Status.INCOMPLETE} key={Globals.Result_Status.INCOMPLETE}>Incomplete</Select.Option>
          </Select>
        ),
      },
      {
        title: 'Grade',
        key: 'grade',
        render: ({ result, userID }) => (
          <InputNumber
            defaultValue={result?.resultGrade}
            onChange={this.handleChangeStudentInfo.bind(this, userID, 'grade')}
          />
        ),
      },
      {
        title: 'Comment',
        key: 'gradeComment',
        render: ({ result, userID }) => (
          <Input
            defaultValue={result?.resultNotes}
            onChange={e => this.handleChangeStudentInfo(userID, 'resultNotes', e.target.value)}
          />
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = `${this.props.app.themeManager.theme.applicationName} - Close session`;

    this._fetchSession(true);
  }

  async generateReportXLSX() {
    if (this.state.students < 1) {
      return;
    }

    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');

    let columns = [
      'Student',
      'Email', 
      'Employer',
      'Attendance',
      'Result',
      'Grade', 
      'Comment', 
    ];

    // Generate XLSX header
    ws.addRow(columns);
    
    // Generate XLSX rows
    this.state.students.forEach((student) => {
      let fullName = (student.firstName || '') + ' ' + (student.lastName || '');
      let row = [
       fullName,
       student.email,
       student.employerName,
       student.attendance?.attendanceStatus,
       student.result?.resultNotes,
       student.result?.resultGrade,
       student.result?.resultNotes,
      ];
    
      ws.addRow(row);
    });

    const buffer = await wb.xlsx.writeBuffer();

    Utils.downloadArrayBuffer(buffer, `Attendance`, 'xlsx');
  }

  handleChangeStudentInfo(userID, field, newValue) {
    this.setState(prevState => {
      const student = prevState.students.find(student => student.userID == userID);
      let apiValue = null;
      const changes = { ...prevState.changes };

      if (field == 'attendance') {
        apiValue = student.attendance?.attendanceStatus;
      }

      if (field == 'additionalNotes') {
        apiValue = student.attendance?.additionalNotes || '';
      }
      
      if (field == 'result') {
        apiValue = student.result?.resultStatus;
      }
      
      if (field == 'grade') {
        apiValue = student.result?.resultGrade;
      }
      
      if (field == 'resultNotes') {
        apiValue = student.result?.resultNotes || '';
      }

      const hasChanged = apiValue !== newValue;

      if (hasChanged) {
        changes[`${userID}.${field}`] = newValue;
      } else {
        delete changes[`${userID}.${field}`];
      }

      return { ...prevState, changes };
    });
  }

  handleFileChange({ file }) {
    const allowedTypes = ['image/', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv'];
    if (!allowedTypes.find(fileType => file.type?.includes(fileType))) {
      message.error('This type of file is not accepted.');
      return;
    }

    if (file.status == 'removed') {
      return;
    }

    this._uploadFile(file);
  }

  handleDeleteFile(fileID) {
    this._deleteFile(fileID);  
  }
  
  handleDownloadFile(file) {
    this._downloadFile(file);  
  }
  
  handlePreview(file) {
    this._previewFile(file);
  }

  handleSave() {
    Modal.confirm({
      content: 'This will write all changes made on this page and update student attendance information. Do you want to proceed?',
      okText: 'Proceed',
      onOk: this._saveAttendance,
    });
  }

  handleCloseSession() {
    Modal.confirm({
      content: 'Once you close the session, you will not be able to make any more changes to it. Confirm session closing?',
      okText: 'Proceed',
      onOk: this._closeSession,
    });
  }

  render() {
    const { session, students, changes } = this.state;
    
    const hasChanges = Object.keys(changes).length > 0;

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} isFixed/>

        {session && (
          <>
            <Layout.Content style={{ paddingBottom: 112 }}>
              <h1 style={{ fontSize: 24 }}>Session details</h1>
              <WhiteBox isSecondary style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ maxWidth: '100%' }}>
                  <Descriptions size="small" column={2} style={{ marginTop: 24 }}>
                    <Descriptions.Item label="Session" span={1}>
                      <strong>{session.name}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Location" span={1}>
                      <strong>{session.location}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Type" span={1}>
                      <strong>{Globals.getTemplateTypeIcon(session.type, true)}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Dates" span={1}>
                      <strong>
                        {(session.startDate || []).map((s, i) => (
                          <React.Fragment key={i}>
                            {moment(new Date(s)).format(`${Globals.DefaultDateFormat} hh:mm A`)} - {moment(new Date(session.endDate?.[i])).format('hh:mm A')}
                            <br />
                          </React.Fragment>
                        ))}
                      </strong>
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions size="small" column={1} >
                    <Descriptions.Item label="Launch URL">
                      <strong>{session.accessLink}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Notes">
                      <Typography.Paragraph
                        strong style={{ color: '#333', maxWidth: '100%' }}
                        ellipsis={{ rows: 4, expandable: true, symbol: 'See more' }}
                      >
                        {session.instructorNotes}
                      </Typography.Paragraph>
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </WhiteBox>

              <h1 style={{ fontSize: 24, marginTop: 48 }}>Students</h1>
              <Table
                rowKey="id"
                dataSource={students}
                columns={this.attendanceColumns}
                pagination={false}
              />

              <h1 style={{ fontSize: 24, marginTop: 48 }}>Session Documents</h1>
              <div className="uploads-container">
                {(session.documents || []).map(file => (
                  <div className="upload-item" key={file.fileID}>
                    {this._getFileIcon(file.fileName)}
                    <span>{file.fileName}</span>

                    <div className="actions">
                      {this._conditionalTooltipWrapper(
                        !this._hasPreview(file.fileName),
                        'Preview is not available for this file',
                      )(
                        <button
                          type="button"
                          onClick={this.handlePreview.bind(this, file)}
                          disabled={!this._hasPreview(file.fileName)}
                        >
                          <FaEye />
                        </button>
                      )}
                      <button type="button" onClick={this.handleDownloadFile.bind(this, file)}>
                        <FaDownload />
                      </button>
                      <Popconfirm
                        title={`Are you sure you want to delete file ${file.fileName}?`}
                        onConfirm={this.handleDeleteFile.bind(this, file.fileID)}
                      >
                        <button type="button"><FaTrash /></button>
                      </Popconfirm>
                    </div>
                  </div>
                ))}
                
                <Upload
                  style={{
                    width: '100%',
                    minHeight: '220px',
                    opacity: this.state.isUploading ? 0.5 : 1,
                  }}
                  multiple={false}
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={this.handleFileChange}
                  disabled={this.state.isUploading}
                  className="upload-item new-upload"
                >
                  <button className="upload-button">
                    {!this.state.isUploading && <><FaUpload /> New document</>}
                    {this.state.isUploading && <><Spin /> Uploading...</>}
                  </button>
                </Upload>
              </div>
            </Layout.Content>

            <div className="bottom-actions">
              <Button
                type="primary"
                onClick={this.handleSave}
                disabled={this.attendanceColumns === 0 || !hasChanges}
              >
                Save attendances
              </Button>
              <Button
                type="dashed"
                style={{ marginLeft: 4 }}
                icon={<DownloadOutlined />}
                onClick={this.generateReportXLSX}
                disabled={this.state.students < 1}
              >
                Export to xlsx
              </Button>
              <Button
                type="primary"  
                disabled={hasChanges || !this._isFullfiled()}
                onClick={this.handleCloseSession}
              >
                Close session
              </Button>
            </div>
          </>
        )}
      </Layout.Content>
    )
  }

  // Private methods
  _isFullfiled() {
    let isFullfiled = true;

    for (const student of this.state.students) {
      if (
        (!student.attendance && !this.state.changes[`${student.userID}.attendance`])
        || (!student.result && !this.state.changes[`${student.userID}.result`])
        || ((student.result?.resultGrade === undefined) && !this.state.changes[`${student.userID}.grade`])
      ) {
        isFullfiled = false;
        break;
      }
    }

    return isFullfiled;
  }

  _getFileIcon(fileName) {
    const { extension } = Utils.splitFilenameAndExtension(fileName);

    const excel = ['xlsx', 'xls'];
    const word = ['doc', 'docx'];
    const image = ['png', 'jpg', 'jpeg', 'gif'];
    const csv = ['csv'];
    const pdf = ['csv'];

    const iconSize = 32;

    if (excel.includes(extension)) {
      return <FaFileExcel size={iconSize} />;
    }

    if (word.includes(extension)) {
      return <FaFileWord size={iconSize} />;
    }

    if (image.includes(extension)) {
      return <FaFileImage size={iconSize} />;
    }

    if (csv.includes(extension)) {
      return <FaFileCsv size={iconSize} />;
    }

    if (pdf.includes(extension)) {
      return <FaFilePdf size={iconSize} />;
    }

    return <FaFile size={iconSize} />;
  }

  _hasPreview(fileName) {
    const { extension } = Utils.splitFilenameAndExtension(fileName);
    return ['png', 'jpeg', 'jpg', 'gif', 'pdf'].includes(extension?.toLowerCase());
  }

  _conditionalTooltipWrapper(condition, title) {
    return (component) => {
      if (condition) {
        return (
          <Tooltip title={title}>{component}</Tooltip>
        );
      } else {
        return component;
      }
    };
  }

  // API Calls
  async _fetchSession(loadLocations) {
    this.setState({ isLoading: true });

    const promises = [this.props.app.classroom.session.getSession(this.sessionID, true)];

    if (loadLocations) {
      promises.push(this.props.app.sharedCache().getVenues());
      promises.push(this.props.app.sharedCache().getCities());
    }

    const [resp] = await Promise.all(promises);

    if (resp.statusCode == 200) {
      const session = resp.body;
      const venue = this.props.app.sharedCache().getVenueByID(session?.venueID);
      const city = this.props.app.sharedCache().getCityByID(venue?.cityID);
  
      const students = session.enrolments.map(enrolment => ({
        ...enrolment,
        attendance: session.attendances.find(attendance => attendance.userID == enrolment.userID),
        result: session.results.find(result => result.userID == enrolment.userID),
      }));
  
      const studentIDs = students.map(student => student.userID);
  
      this.setState({
        session: {
          ...session,
          location: `${venue?.address?.street1}${city ? `, ${city.name}` : ''}`,
        },
        students: students,
        studentIDs: studentIDs, 
      });
  
      await this._getEmployerInformation();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isLoading: false });
  }

  async _saveAttendance() {
    this.setState({ isLoading: true });

    const students = [];

    Object.entries(this.state.changes).forEach(([key, value]) => {
      const [userID, field] = key.split('.');

      const student = this.state.students.find(student => student.userID == userID);
      let currentStudentIndex = students.findIndex(student => student.userID == userID);

      if (currentStudentIndex < 0) {
        currentStudentIndex = students.push({
          userID,
          externalID: student.externalID,
        }) - 1;
      }

      if (field === 'attendance') {
        students[currentStudentIndex] = {
          ...students[currentStudentIndex],
          attendance: {
            attendanceStatus: value,
            additionalNotes: students[currentStudentIndex]?.attendance?.additionalNotes ?? student.attendance?.additionalNotes,
          },
        };
      }

      if (field === 'additionalNotes') {
        students[currentStudentIndex] = {
          ...students[currentStudentIndex],
          attendance: {
            attendanceStatus: students[currentStudentIndex]?.attendance?.attendanceStatus ?? student.attendance?.attendanceStatus,
            additionalNotes: value,
          },
        };
      }

      if (field === 'result') {
        students[currentStudentIndex] = {
          ...students[currentStudentIndex],
          result: {
            resultStatus: value,
            resultNotes: students[currentStudentIndex]?.result?.resultNotes ?? student.result?.resultNotes,
            resultGrade: students[currentStudentIndex]?.result?.resultGrade ?? student.result?.resultGrade,
          },
        };
      }

      if (field === 'grade') {
        students[currentStudentIndex] = {
          ...students[currentStudentIndex],
          result: {
            resultStatus: students[currentStudentIndex]?.result?.resultStatus ?? student.result?.resultStatus,
            resultNotes: students[currentStudentIndex]?.result?.resultNotes ?? student.result?.resultNotes,
            resultGrade: value,
          },
        };
      }

      if (field === 'resultNotes') {
        students[currentStudentIndex] = {
          ...students[currentStudentIndex],
          result: {
            resultStatus: students[currentStudentIndex]?.result?.resultStatus ?? student.result?.resultStatus,
            resultNotes: value,
            resultGrade: students[currentStudentIndex]?.result?.resultGrade ?? student.result?.resultGrade,
          },
        };
      }
    });

    const resp = await this.props.app.classroom.session.batchUpdateSessionAttendanceAndResults(this.sessionID, { students });

    if (resp.statusCode == 200) {
      const results = resp.body;
      const errors = results.filter(result => !result.isSuccess);

      await this._fetchSession(false);

      if (errors.length > 0) {
        const students = errors.map(({ userID }) => {
          const student = this.state.students.find(student => student.userID == userID);
          
          if (!student) return null;

          return `${student.firstName || ''} ${student.lastName || ''}`
        }).filter(Boolean);

        Modal.error({
          title: 'Oops!',
          content: (
            <>
              These students cannot be updated:

              <ul>
                {students.map(student => (
                  <li key={student}>{student}</li>
                ))}
              </ul>
            </>
          )
        });
      } else {
        message.success('Attendances successfully saved!');
      }

      this.setState({ changes: {} })
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.setState({ isLoading: false });
  }

  async _closeSession() {
    this.startLoading();

    const resp = await this.props.app.classroom.session.transitionSessionState(this.sessionID);

    if (resp.statusCode == 200) {
      message.success('The session was successfully closed!');
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.instructor_calendar);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }

  async _getEmployerInformation() {
    const { studentIDs } = this.state;
  
    if (studentIDs.length === 0) {
      return;
    }
  
    const resp = await this.props.app.organization.employee.searchOrganizationsEmployeesByIDs(studentIDs);
  
    if (resp.statusCode === 200 && resp.body) {
      const employees = resp.body.employees;
  
      const updatedStudents = this.state.students.map(student => {
        const employee = employees.find(emp => emp.user.userID === student.userID);
  
        const employerName = employee && employee.org ? employee.org.name : '';
  
        return {
          ...student,
          employerName: employerName
        };
      });
  
      this.setState({ students: updatedStudents });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
  
  // Upload api calls
  async _uploadFile(file) {
    this.setState({ isUploading: true });

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onloadend = async (readerResp) => {
      if (!this._isMounted) return;

      file.file = readerResp.currentTarget.result;

      const resp = await this.props.app.classroom.sessionFile.uploadAndCreateDocument(
        this.sessionID,
        file,
      );

      if (resp && resp.statusCode == 200) {
        await this._fetchSession(false);
        message.success('File successfully uploaded!');
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
      }

      this.setState({ isUploading: false });
    };
  }

  async _previewFile(file) {
    this.startLoading();

    const resp = await this.props.app.classroom.sessionFile.getSignURL(this.sessionID, file.fileID);

    if (!this._isMounted) return;

    if (resp.statusCode == 200) {
      this.stopLoading();
      Utils.openFileInNewTab(resp.body.fileURL, file.fileName);
    } else {
      this.stopLoading();
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }
  }

  async _downloadFile(file) {
    this.startLoading();

    const resp = await this.props.app.classroom.sessionFile.download(this.sessionID, file.fileID);

    if (!this._isMounted) return;

    if (resp.statusCode == 200) {
      const { filename, extension } = Utils.splitFilenameAndExtension(file.fileName);
      Utils.downloadBlob(resp.body, filename || file.fileName, extension || Utils.getFileExtensionFromMimeType(resp.body.type));
    } else {
      this.props.app.alertController.showAPIErrorAlert('Error while downloading file!', resp);
    }

    this.stopLoading();
  }

  async _deleteFile(fileID) {
    this.startLoading();

    const resp = await this.props.app.classroom.sessionFile.delete(this.sessionID, fileID);

    if (resp.statusCode == 200) {
      await this._fetchSession(false);
      message.success('File successfully deleted!');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }
  
}
