import React from 'react';
import autoBind from 'react-autobind';
//
import { Menu, Dropdown, Tag } from 'antd';
import { MailOutlined, CaretDownOutlined, UserOutlined, ExportOutlined } from '@ant-design/icons';
//props are: app, openProfile, changePassword, logoutHandler
export default class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Actions
  handleSelect(i) {
    if (i.key == 'changepassword') this.props.changePassword();
    else if (i.key == 'logout') this.props.logoutHandler();
    else if (i.key == 'profile') this.props.openProfile();
  }
  //UI
  render() {
    return (
      <Dropdown overlay={this._renderDropdownMenu()} className="userMenu">
        <Tag className="idmBadgeContainer">
          My Account <CaretDownOutlined />
        </Tag>
      </Dropdown>
    );
  }
  _renderDropdownMenu() {
    return (
      <Menu className="usersMenuList" onClick={this.handleSelect} items={[
        { label: <>
          <UserOutlined style={{marginRight: 10}}/>
          {this.props.user?.firstName + ' ' + this.props.user?.lastName}<br/>
          <MailOutlined style={{marginRight: 10}}/>
          {this.props.user?.email}
        </>, key: 'name', disabled: true },
        { type: 'divider', key: 'divider' },
        { label: 'Profile', key: 'profile' },
        { label: 'Change Password', key: 'changepassword' },
        { label: 'Logout', key: 'logout', icon: <ExportOutlined/> }
      ]}/>
    );
  }
}
