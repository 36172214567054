import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col, Form, Input, Divider } from 'antd';
//

export default class CommonEmailMessageDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      eventType: props.details.eventType 
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.details !== this.props.details) {
      this.setState({
        eventType: this.props.details.eventType,
      });
    }
  }
  
  renderAdditionalFields(eventType) {
    switch(eventType) {
      case 'Delivery':
        return (
          <>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <Form.Item name="delivery.smtpResponse" label="Server response"> 
                  <span>{this.props.details.delivery.smtpResponse}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <Form.Item name="delivery.processingTimeMillis" label="Processing time (ms)"> 
                  <span>{this.props.details.delivery.processingTimeMillis}</span>
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      case 'Bounce':
        return (
          <>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <Form.Item name="bounce.bounceType" label="Bounce Type"> 
                  <span>{this.props.details.bounce.bounceType}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <Form.Item  label="Bounced Recipient:"> 
                  <div style={{ marginBottom: '16px', border: '1px solid #ccc', borderRadius: '5px', paddingLeft: '20px' }}>
                    {this.props.details.bounce.bouncedRecipients.map((recipient, index) => (
                      <div key={index} style={{  marginTop: '16px' }}>
                        <p><strong>Email Address:</strong> {recipient.emailAddress}</p>
                        <p><strong>Action:</strong> {recipient.action}</p>
                        <p><strong>Status:</strong> {recipient.status}</p>
                        <p><strong>Diagnostic Code:</strong> {recipient.diagnosticCode}</p>
                      </div>
                    ))}
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <Form.Item name="bounce.feedbackId" label="Feedback ID"> 
                  <span>{this.props.details.bounce.feedbackId}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <Form.Item name="bounce.reportingMTA" label="Reporting MTA"> 
                  <span>{this.props.details.bounce.reportingMTA}</span>
                </Form.Item>
              </Col>
            </Row>
          </>
        );
           
      case 'Complaint':
        return (
          <>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <div>
                  <p><strong>Complained Recipients:</strong></p>
                  {this.props.details.complaint.complainedRecipients.map((recipient, index) => (
                    <div key={index}>
                      <p>{JSON.stringify(recipient)}</p>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <Form.Item name="complaint.feedbackId" label="Feedback ID"> 
                  <span>{this.props.details.complaint.feedbackId}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <Form.Item name="complaint.complaintFeedbackType" label="Feedback type"> 
                  <span>{this.props.details.complaint.complaintFeedbackType}</span>
                </Form.Item>
              </Col>
            </Row>
            <Row type='flex'>
              <Col span={22} offset={1}>
                <Form.Item name="complaint.arrivalDate" label="Arrival date"> 
                  <span>{this.props.details.complaint.arrivalDate}</span>
                </Form.Item>
              </Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  }
  

  //UI
  render() {
    const { details } = this.props;

    return (
      <Layout.Content>
        <div>
          <div>
            <Row gutter={16}>
              <Col span={12}>
                <p><strong>Subject:</strong> {details.mail?.commonHeaders.subject}</p>
              </Col>
              <Col span={12}>
                <p><strong>Event:</strong> {details.eventType}</p>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <p><strong>From:</strong> {details.mail?.commonHeaders.from}</p>
              </Col>
              <Col span={12}>
                <p><strong>To:</strong> {details.mail?.commonHeaders.to}</p>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <p><strong>Date:</strong> {details.mail?.commonHeaders.date}</p>
              </Col>
              <Col span={12}>
                <p><strong>Message Id:</strong> {details.mail?.commonHeaders.messageId}</p>
              </Col>
            </Row>
            <Divider/>
            {this.renderAdditionalFields(details.eventType)}
          </div>
        </div>
      </Layout.Content>
    );
  }
}

  
