import React from "react"
import { Layout, Row, Col, Typography, Divider, Button } from 'antd';
//
import UserMenu from './UserMenu';
import BackToTenantButton from './BackToTenantButton';
//
import "@/stylesheets/AppUserHeader.less"
//props are: app, openProfile, changePassword, logoutHandler
export default class AppUserHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    //User object from IDM
    const userObject = await this.props.app.idm.session.data.getUserObject();
    this.setState({ userObject });
  }
  /* UI */
  render() {
    const user = this.state.userObject;
    const tenant = this.props.app.configManager.launchConfig?.tenant;
    return (
    <Layout.Header className='appUserHeader'>
      <Row type="flex" justify='space-between' align='middle'>
        <Col> 
          <Row>
            {tenant && <>
              <BackToTenantButton app={this.props.app}/>
              <Divider type="vertical" className={'appUserHeaderDivider'}/>
            </>}
          <Typography.Title className="welcomeTitle" level={5}>{`Welcome, ${user?.firstName + ' ' + user?.lastName}`}</Typography.Title>
          </Row>
        </Col>
        <Col>
          {this.props.app.idm.isLogged() && (
            <UserMenu user={user} openProfile={this.props.openProfile}
              changePassword={this.props.changePassword} logoutHandler={this.props.logoutHandler} />
          )}
        </Col>
      </Row>
    </Layout.Header>);
  }
}