import React from "react";
import { Layout, Tag, Button, Row, Col, Form, Input, Typography } from 'antd';
import autoBind from 'react-autobind';
import { IDMGlobals } from "@ikonintegration/idmclient";
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import Utils from '@/components/helpers/Utils';
import CommonBoxLinks from '../commonComponents/CommonBoxLinks';
import { InputBox } from "react-individual-input-box";
//
import config from "@/config/config";
import Globals from "@/config/Globals";
import { LockOutlined, MobileOutlined } from '@ant-design/icons';
//
import { decode as base64_decode } from 'base-64';
//
import "@/stylesheets/CommonReset.less";
//
export default class CommonSMSMFARequestView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      data: {
        username: this.props.app.urlManager.getQueryParam('username'),
        challengeContext: this.props.app.urlManager.getQueryParam('challenge'),
        code: null,
        extra: (this.props.app.urlManager.getQueryParam('extra') ? JSON.parse(base64_decode(this.props.app.urlManager.getQueryParam('extra'))) : {})
      }, isLoading: false
    };
    console.log(this.state)
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = `Multifactor Authenticator - ${this.props.app.themeManager.theme.applicationName}`;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //Actions
  async handleForgotPassword() {
    const formData = await this.form.validateFields();
    if (this.state.data.code && this.state.data.code.length == 6) this._loginWithMFA(this.state.data.code);
  }
  handleCodeInput(code) {
    this.setState({ data: { ...this.state.data, code } }, () => {
      //auto submit
      if (code && code.length == 6) this._loginWithMFA(code);
    });
  }

  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    const editMode = true;
    return (
      <Layout.Content>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Layout.Content className='resetContainer'>
          <Row type="flex" justify="center" align="middle">
            <Col className='resetBoxContainer' align='middle' justify="center">
              <div className='resetBox'>
                <Row type="flex" justify="center" align="middle">
                  <Col> <Logo app={this.props.app}/> </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h5 className="resetBoxTitle">MFA Authentication is required to <br></br>login to your {this.props.app.themeManager.theme.applicationName} account</h5>
                  </Col>
                </Row>
                <Form layout="vertical" {...Utils.propagateRef(this, 'form')} className='resetForm'>

                  <Row type="flex" justify="center" align="middle" style={{ marginBottom: '20px' }}>
                    <Col><LockOutlined /></Col>
                    <Col offset={1} align='start'>
                      <Typography.Text>We've sent a notification to your mobile device.<br></br>Please, add the code your received below.</Typography.Text>
                    </Col>
                  </Row>

                  <Row type="flex" justify="center" align="middle">
                    <Col span={24}>
                      <Form.Item name="code" rules={[{ required: true, validator: (rule, value, cb) => (value && value.length == 6 ? cb() : cb('Please, add the code your received on the specified number below.')) }]} className='mfaInput'>
                        <InputBox size={6} onChange={this.handleCodeInput} onlyNumbers />
                      </Form.Item>
                    </Col>
                  </Row>
                  {this.state?.data?.extra?.number && <Row type="flex" justify="center" align="middle" style={{ marginBottom: '30px', marginTop: '20px' }}>
                    <Typography.Text strong>Phone Number:</Typography.Text>  <Tag style={{ marginLeft: '10px' }} icon={<MobileOutlined />}>{this.state?.data?.extra?.number}</Tag>
                  </Row>}
                  <Button block onClick={this.handleForgotPassword} className='confirmButton' type="primary" htmlType="submit"> Confirm </Button>
                </Form>
              </div>
            </Col>
          </Row>
          <CommonBoxLinks width={500} app={this.props.app}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  async _loginWithMFA(mfaCode) {
    this.startLoading();
    //
    let obj = {
      context: this.state.data.challengeContext, code: mfaCode,
      username: this.state.data.username, srpID: this.state?.data?.extra?.srpID
    }
    //
    const resetResp = await this.props.app.idm.authenticator.respondToMFAAndLogin(obj.username, obj.srpID, obj.context, obj.code);
    if (this._isMounted) this.stopLoading();
  }
}