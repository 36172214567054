import React from 'react';
import autoBind from 'react-autobind';
import { Spin, Typography, Select, AutoComplete, Row, Col, Input, Button, Form, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import config from '@/config/config';
import Globals from '@/config/Globals'
import Utils from '@/components/helpers/Utils'
//
import '@/stylesheets/org-app/CommonSearchBar.less';
//props are: app, disabled, isLoading, placeholder, onSearch
export default class CommonSimpleSearchBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { searchValue: null };
  }
  //Actions
  focus() { setTimeout(() => { if (this.searchInput) this.searchInput.focus(); }, 100); }
    //Search bar
  handleSearch(newValue) {
    let searchValue = newValue;
    //Fetch safe search value
    if (typeof newValue === 'string') searchValue = newValue;
    else if (this.searchInput) searchValue = this.searchInput.input.value;
    //forward
    this.props.onSearch(searchValue);
  }
  //UI
  render() { return ( <div className="commonSearchBar"> {this._renderSimpleSearch()} </div> ); }

  /* sub renders */
  _renderSimpleSearch() {
    return (
      <Row type="flex" align="middle" justify="space-between" className="searchBarRow">
        {/* Search bar */}
        <Col span={2}> <SearchOutlined className="searchBarIcon" /> </Col>
        <Col className="searchInputColumn">
          <Form.Item /*validateStatus={error ? 'error' : 'success'} help={error ? `Minimum number of characters is ${Globals.Search_MinimumCharacters}!` : ''}*/>
            <Input.Search className="searchBarInput" onSearch={this.handleSearch} onChange={this.handleSearch} placeholder={this.props.placeholder || "Search for ..."} 
                {...Utils.propagateRef(this, 'searchInput')} disabled={this.state.isLoading || this.props.disabled}/>
          </Form.Item>
        </Col>
        {/* Search button */}
        <Col className="buttonColumn">
          <Button type="primary" className="searchButton" onClick={this.handleSearch} loading={this.props.isLoading}
            disabled={this.props.isLoading || this.props.disabled}> Search </Button>
        </Col>
      </Row>
    );
  }
}
