import React from 'react';
import autoBind from 'react-autobind';
import { Button, Row, Col, Typography, Table, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import Globals from '@/config/Globals';

//props are: app, isLoading, onDelete, onAdd, values
export default class CommonRegistrationListFieldValuesTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: {} };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      { title: 'Label', key: 'label', sorter: true,
        sortOrder: sortedInfo.columnKey === 'label' && sortedInfo.order, 
        sorter: (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
        render: props => props.label
      },
      { title: 'Value', key: 'value', sorter: true,
        sortOrder: sortedInfo.columnKey === 'value' && sortedInfo.order, 
        sorter: (a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()),
        render: props => props.value
      },
      { title: 'Actions', width: 100, key: 'Actions', align: 'center',
         render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Delete Value'>
              <Popconfirm title={`Do you really want to delete value '${props.label}/${props.value}'?`} placement="bottomRight" onConfirm={this.props.onDelete.bind(this, props)} okText="Yes" cancelText="No">
                <Button variant="none" icon={<DeleteOutlined />} shape="circle" style={{marginLeft: 10}}/>
              </Popconfirm>
            </Tooltip>
          </span>
        ),
      }
    ];
    const props = { rowKey: (p) => `${p.value}::${p.label}`, loading: this.props.isLoading, onChange: this.handleFilterChange, size: 'small',
                    locale: {emptyText: 'No values found for this field!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (
      <>        
        <Row type='flex' style={{marginBottom: 20}}>
          <Col span={24}>
            <Row type='flex' justify="middle" align="center" style={{marginTop: 10, marginBottom: 10}}>
              <Col span={20} align="center"> <Typography.Title level={5}>List Values</Typography.Title> </Col>
              <Col offset={1} span={3} > <Button type='primary' onClick={this.props.onAdd.bind(this)}>Add value</Button> </Col>
            </Row>
            <Table className="regFieldValuesTable" columns={columns} dataSource={this.props.values} {...props}/>
          </Col>
        </Row>
      </>
    );

  }
}