import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Row, Col, Button } from 'antd';
import scrollIntoView from 'scroll-into-view';
import { DownloadOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonSimpleSearchBar from '../commonComponents/CommonSimpleSearchBar';
import CommonOrganizationContactsTable from '../commonComponents/CommonOrganizationContactsTable';
import AdminOrganizationOptions, { AdminOrganizationOptionTypes } from '../commonComponents/AdminOrganizationOptions';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminOrganizationContactsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      orgID: this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this),
      orgName: this.props.app.urlManager.getQueryParam('n', this) || 'Company',
      contacts: []
    };
    //
    this.viewOptions = new AdminOrganizationOptions(AdminOrganizationOptionTypes.CONTACTS, this.state.orgID, this.props.app, this.state.orgName);
  }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    //set navigation items details
    this.props.app.appsManager?.navigation?.appendValue('orgID', this.state.orgID);
    this.props.app.appsManager?.navigation?.appendValue('orgName', this.state.orgName);
    //load data
    this._fetchData();
  }
  //Search
  handleSearch(searchTerm) { this.setState({ searchTerm }); }
  handleExportXLSX() { this._exportContacts(); }
  //Contact actions
  handleContactEdit(contactObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_contact, null, contactObj.contactID); }
  
  //UI
  render() {
    const { searchTerm } = this.state;
    //filter contacts on runtime
    const contacts = (this.state.contacts || []).filter((c) => {
      return searchTerm && searchTerm.length > 0 ? 
          (c.tenants.map(t => t.name).join(', ') || '').toLowerCase().includes(searchTerm) || 
          (`${c.firstName} ${c.lastName}` || '').toLowerCase().includes(searchTerm) ||
          (c.email || '').toLowerCase().includes(searchTerm) :
        true;
    });
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className='pageHeader' title={`${this.state.orgName} - Contacts`} onBack={() => window.history.back()} 
            extra={this.viewOptions.getOptions()}/>
          <Layout.Content className="pageSubContent">
            <CommonLoadingView isLoading={this.state.isLoading} />
              <Layout.Content>
                <Row type='flex' justify='space-between'>
                  <Col span={20}>
                    <CommonSimpleSearchBar isLoading={this.state.isLoading} onSearch={this.handleSearch} 
                      {...Utils.propagateRef(this, 'searchBar')} app={this.props.app} placeholder="Search by contact app, name or email"/>
                  </Col>
                  <Col>
                    <Button key="1" type="primary" icon={<DownloadOutlined />} onClick={this.handleExportXLSX}
                      disabled={this.state.contacts?.length < 1} style={{ marginLeft: 20, marginRight: -4 }}> Export to XLSX </Button>
                  </Col>
                </Row>
                <CommonOrganizationContactsTable app={this.props.app} isLoading={this.state.isLoading}
                    contacts={contacts} onContactEdit={this.handleContactEdit}/>
              </Layout.Content>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* remote data */
  async _fetchData() {
    if (this.state.isLoading) return;
    this.startLoading();
    //request
    const resp = await this.props.app.organization.organization.getOrganizationContacts(this.state.orgID);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.contacts) {
      const contacts = resp.body.contacts.map(c => {
        c.tenants = c.tenants.map(ct => resp.body.tenants.find(t => t.id === ct.id));
        return c;
      } );
      this.setState({ contacts, isLoading: false });
      scrollIntoView(document.getElementById('root'), { time: 500, align: { top: 0 } });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _exportContacts() {
    this.startLoading();
    //request
    const resp = await this.props.app.organization.organization.exportOrganizationContacts(this.state.orgID);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body) Utils.downloadBlob(resp.body, 'org-contacts', 'xlsx');
    else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.stopLoading();
  }
}
