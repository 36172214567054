import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Select, Switch, Col, Form, Input, InputNumber } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";
//
import CommonRegistrationListFieldValuesTable from '../CommonRegistrationListFieldValuesTable';
import CommonRegistrationListFieldValueModal from '../Modals/CommonRegistrationListFieldValueModal';

//props are: field, isNew
export default class CommonRegistrationFieldForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { type: 'text', values: [] };
  }
  //Form methods
  async validateFields() { 
    const d = await this.form.validateFields();
    if (!d) return;
    const { pattern_regex, extra, pattern_message, ...ds } = d;
    return {
      ...d,
      customProps: {
        extra: extra,
        ...(pattern_regex?.length > 0 && this.state.type == 'text' ? {
          pattern: { regex: pattern_regex, message: pattern_message }
        } : {}),
        ...(this.state.type == 'list' ? { values: this.state.values || [] } : {})
      }
    }
  }
  setFieldsValue(data) { 
    this.setState({ type: data?.type || 'text', values: data?.customProps?.values || [] }, () => {
      this.form.setFieldsValue({
        ...data || {},
        pattern_regex: data?.customProps?.pattern?.regex,
        pattern_message: data?.customProps?.pattern?.message,
        extra: data?.customProps?.extra,
      }); 
    });
  }
  //Actions
  handleTypeChange(type) { this.setState({ type }); }
  handleAddListValue() { this.regFieldListValueModal.show(); }
  handleDeleteListValue(value) { 
    const values = this.state.values || [];
    values.splice(values?.indexOf(value), 1);
    this.setState({ values });
  }
  handleListValueCreated(value) { this.setState({ values: (this.state.values || []).concat([value]) }); }
  
  //Life cycle
  componentDidMount() { 
    if (this.props.field) this.setFieldsValue(this.props.field);
  }
  componentDidUpdate(prevProps) {
    const data = this.props.field;
    if (prevProps.field != data && data) this.setFieldsValue(data);
  }
  //UI
  render() {
    const isNotEmploymentSelection = this.state.type != Globals.RegOrgEmploymentSelectionType;
    return(
      <Layout.Content justify="right-align">
        <CommonRegistrationListFieldValueModal onCreate={this.handleListValueCreated} {...Utils.propagateRef(this, 'regFieldListValueModal')}/>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="id" label="Field ID" rules={[
                  { required: true, message: 'Please, type the field id!' },
                  { min: 4, message: 'Field ID must be between 4 and 255 characters' },
                  { max: 255, message: 'Field ID must be between 4 and 255 characters' },
                ]}> 
                  <Input disabled={!this.props.isNew}/> 
                </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item name="label" label="Label" rules={[
                  { required: true, message: 'Please, type the label name!' },
                  { min: 4, message: 'Label Name must be between 4 and 255 characters' },
                  { max: 255, message: 'Label Name must be between 4 and 255 characters' },
                ]}> 
                  <Input/> 
                </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="type" label="Field Type" rules={[ { required: true, message: 'Field type is required!' }]}> 
                <Select onChange={this.handleTypeChange}> {Globals.Tenant_RegistrationFieldTypes.map((opt) => <Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>)} </Select>
              </Form.Item>
            </Col>
            {isNotEmploymentSelection && <Col span={11} offset={1}>
              <Form.Item name="placeholder" label="Placeholder" rules={[
                  { min: 4, message: 'Placeholder must be between 4 and 255 characters' },
                  { max: 255, message: 'Placeholder must be between 4 and 255 characters' },
                ]}> 
                  <Input/> 
                </Form.Item>
            </Col>}
          </Row>
          <Row type='flex'>
            <Col span={6} offset={1}>
              <Form.Item name="widthPercentage" label="Row width percentage">
                <InputNumber min={1} max={100} precision={0} step={1} style={{width: 100}}/>
              </Form.Item>
            </Col>
            <Col span={15} offset={1}>
              <Form.Item name="extra" label="Extra message" rules={[
                  { min: 4, message: 'Extra message must be between 4 and 255 characters' },
                  { max: 255, message: 'Extra message must be between 4 and 255 characters' },
                ]}> 
                  <Input.TextArea size={3} showCount/> 
                </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={5} offset={1}>
              <Form.Item name="row" label="Row" rules={[ { required: true, message: 'Field row is required!' }]}>
                <InputNumber min={0} max={99} precision={0} step={1} style={{width: 100}}/>
              </Form.Item>
            </Col>
            <Col span={5} offset={1}>
              <Form.Item name="orderInRow" label="Order in row" rules={[ { required: true, message: 'Order in row is required!' }]}>
                <InputNumber min={0} max={99} precision={0} step={1} style={{width: 100}}/>
              </Form.Item>
            </Col>
            <Col span={5} offset={1}>
              <Form.Item name="required" valuePropName="checked" label="Is Required?"> 
                <Switch/>
              </Form.Item>
            </Col>
            {isNotEmploymentSelection && <Col span={5} offset={1}>
              <Form.Item name="saveOnUser" valuePropName="checked" label="Save on LMS User?"> 
                <Switch/>
              </Form.Item>
            </Col>}
          </Row>
          <Col span={10} offset={1}>
            <Form.Item name="defaultValue" label="Default Value"> 
              <Input/>
            </Form.Item>
          </Col>
          {isNotEmploymentSelection && <Row type='flex'>
            <Col span={22} offset={1}>
              <Form.Item name="partitionID" label="PartitionID" rules={[
                  { min: 4, message: 'Placeholder must be between 4 and 255 characters' },
                  { max: 255, message: 'Placeholder must be between 4 and 255 characters' },
                ]} extra={"Blank means not saving on any partition."}> 
                  <Input/> 
                </Form.Item>
            </Col>
          </Row>}
          {this.state.type == 'date' && <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="allowFutureValue" valuePropName="checked" label="Allow dates on the future?"> 
                <Switch/>
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item name="format" label="Date Format" rules={[
                  { min: 4, message: 'Date format must be between 4 and 255 characters' },
                  { max: 255, message: 'Date format must be between 4 and 255 characters' },
                ]} extra={"Blank means it will use default format. ex.: YYYY-MM-DD"}> 
                  <Input/> 
                </Form.Item>
            </Col>
          </Row>}
          <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="pattern_regex" label="Validation pattern regex" rules={[
                  { min: 4, message: 'Validation pattern regex must be between 4 and 255 characters' },
                  { max: 255, message: 'Validation pattern regex must be between 4 and 255 characters' },
                ]} extra={"Blank means no validation."}> 
                  <Input/> 
                </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item name="pattern_message" label="Validation pattern error message" rules={[
                  { min: 4, message: 'Validation pattern error message must be between 4 and 255 characters' },
                  { max: 255, message: 'Validation pattern error message must be between 4 and 255 characters' },
                ]} extra={"Blank when regex is configured means default error message."}> 
                  <Input/> 
                </Form.Item>
            </Col>
          </Row>
          {this.state.type == 'list' && <Row type='flex'>
            <Col span={22} offset={1}>
              <CommonRegistrationListFieldValuesTable onDelete={this.handleDeleteListValue} onAdd={this.handleAddListValue} 
                values={this.state.values}/>
            </Col>
          </Row>}
        </Form>
      </Layout.Content>
    );
  }
}
