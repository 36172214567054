import Utils from '@/components/helpers/Utils';
import AdminNavigationView from '@/views/adminSubviews/AdminNavigationView';
//Apps
import IDMMiniApp from '@/views/apps/idm/App';
import OrganizationMiniApp from '@/views/apps/organization/App';
import ConfigMiniApp from '@/views/apps/config/App';
//
export default class AppsManager {
  constructor(auth) {
    this.auth = auth;
    this.apps = [
      new ConfigMiniApp(this.auth),
      new IDMMiniApp(this.auth),
      new OrganizationMiniApp(this.auth)
    ];
  }
  /* public */
  getNavigationOptionFromEnablesApps(path, values) {
    return this.enabledApps.map((app) => app.getNavigationOption(path, values)).filter(e => !!e)[0];
  }
  renderNavigationComponent() {
    return (<AdminNavigationView app={this.auth} {...Utils.propagateRef(this, 'navigation')}/>);
  }
  renderExpandedMiniAppsRoutes() {
    return this.enabledApps.reduce((acc, a) => acc.concat(a.routes || []), []);
  }
  get enabledApps() {
    return this.apps.filter((a) => a.isEnabled);
  }
}
