import React from "react";
import autoBind from 'react-autobind';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { AppIDv2 } from '@ikonintegration/idmclient';
import moment from 'moment';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import CommonRegistrationForm from "./CommonRegistrationForm";
import CommonBoxLinks from '../commonComponents/CommonBoxLinks';
//
import "@/stylesheets/CommonRegistration.less";
//
import { Layout, Row, Col } from 'antd';
import Globals from "@/config/Globals";
//
class CommonRegistrationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.additionalInputs = this.props.app.getRegistrationObject();
    this.state = {
      data: {
        //event if someone change this 'linkingRoles', API will prevent to register on roles that dont allow self-registering :p
        linkingRoles: [ config.IDMClientOptions.roles.IDM_USER, props.app.themeManager.theme.userRole ],
      }, isLoading: false};
      console.log(this.state)
  }
  //life cycle
  componentDidMount() {
    document.title = `Create your ${this.props.app.themeManager.theme.applicationName} account`;
    super.componentDidMount();
    Utils.showGoogleRecaptchaBagde();
    /// ### Bugfix for reload, back buttons (browser) after registration successfull or login/logout happended
    //Reload from current URL (this helps reload, back buttons (after registration/login), to make the registration page to work again)
    this.props.app.idm.impersonate.reloadImpersonateFromCurrentURL();
    //Check if is a redirect and contains roles
    if (this.props.app.configManager.launchConfig.tenant) {
      this.state.data.linkingRoles.push(this.props.app.configManager.launchConfig.tenant.idmUserRoleID);
      console.debug('Appending role from tenant - ', this.props.app.configManager.launchConfig.tenant.idmUserRoleID);
    } else console.debug('No appending role detected');
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    Utils.hideGoogleRecaptchaBagde();
  }
  //Actions handlers
  handleLogin() { this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.homepage); }
  async handleSignup(data) { this._singup(data); }

  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    return (
      <Layout.Content>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Layout.Content className='registerContainer'>
          <Row type="flex" justify="center" align="middle">
            <Col span={18} className='registerBoxContainer' align='middle' justify="center">
              <div className='registerBox'>
                <Row>
                  <Col align="center" className='registerMainLogo'> <Logo noLink app={this.props.app}/></Col>
                </Row>
                <Row>
                  <Col align="center">
                    <h5 className="registerBoxTitle">Create your {this.props.app.themeManager.theme.applicationName} account</h5>
                  </Col>
                </Row>
                <CommonRegistrationForm app={this.props.app} additionalInputs={this.additionalInputs} onSignup={this.handleSignup} onLogin={this.handleLogin} {...Utils.propagateRef(this, 'form')} enableEmailConfirmation />
              </div>
            </Col>
          </Row>
          <CommonBoxLinks width={'75%'} app={this.props.app}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private API */
  async _singup(data) {
    this.startLoading();
    const obj = await this._getIDMRegistrationObject(data);
    //Check for tenant impersonation at registration time only
    const tenant = this.props.app.configManager?.launchConfig?.tenant;
    let appID = null;
    if (tenant?.id) {
      //Get pk
      const pk = await this.props.app.idm.session.getPublicToken();
      if (pk) appID = (new AppIDv2(null, tenant.idmKey, tenant.idmSecret, pk)).getHash();
    }
    //Send register request
    const registrationResp = await this.props.app.idm.registration.register(obj, null, null, null, appID);
    if (registrationResp.statusCode == 200 && registrationResp.body.regToken) {
      this.props.app.alertController.showSuccessAlert("You have registered with success!", "Please, check your inbox for the confirmation code.");
      //Set employeement if field is specified
      const userID = registrationResp.body.userID;
      if (userID && Object.values(this.additionalInputs.fields).find((f) => f.type == Globals.RegOrgEmploymentSelectionType)) {
        let employmentResp = (await this.props.app.organization.employee.upsertEmployee(data?.selectedOrg?.id || '', userID, {
          email: obj.email, firstName: obj.firstName, lastName: obj.lastName, hashedWorksafeID: data?.selectedOrg?.hashedWorksafeID || ''
        }));
        //warn and let user deal with this later!
        if (employmentResp.statusCode != 200 && employmentResp.statusCode != 204) {
          this.props.app.alertController.showAPIErrorAlert('Error while setting your employer!', employmentResp);
        }
      }
      //redirect
      this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.registrationConfirmation, null, registrationResp.body.regToken, this.state.data.email);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, registrationResp);
      this.stopLoading();
    }
  }
  /* data helpers */
  async _getIDMRegistrationObject(data) {
    /* ------------- Partition data --------------- */
    //Get all unique partitions in parallel
    const { fields } = this.additionalInputs;
    const uniqueValidParts = Object.keys(fields).filter((f) => fields[f].partitionID && fields[f].partitionID.length > 0).reduce((acc, curr) => {
      if (acc.indexOf(fields[curr].partitionID) == -1) acc.push(fields[curr].partitionID);
      return acc;
    }, []);
    const partsPool = await Promise.all(uniqueValidParts.map((p) => this.props.app.idm.session.getPartitionByID(p, true)));
    //Buildup part data
    const partData = {};
    //For each unique part
    for (let partitionID of uniqueValidParts) {
      //Get old value
      let partition = partsPool.find((p) => p && p.partID == partitionID);
      if (!partition || !partition.partID) partition = { partID: partitionID };
      //Get fields that needs to be saved on this partition
      const fieldsData = {};
      for (let fieldKey of Object.keys(fields)) {
        const field = fields[fieldKey];
        if (field.partitionID && field.partitionID.length > 0 && field.partitionID == partition.partID) {
          if (data[field.id] && field.type == 'date') {
            Utils.setNestedObject(fieldsData, field.id, moment(data[field.id]).format(field.format));
          } else {
            Utils.setNestedObject(fieldsData, field.id, data[field.id]);
          }
        }
      }
      //Build part from old values + overrides
      partData[partition.partID] = { value: { ...partition.value || {}, ...fieldsData } };
    }
    
    /* --------------- IDM user -------------------- */
    const userObj = {};
    //If has impersonate callback url, set to registration object
    if (this.props.app.idm.impersonate.getCallbackURL()) userObj.redirectURL = this.props.app.idm.impersonate.getCallbackURL();
    //Try to get captcha
    let token = null;
    try { token = await this.props.googleReCaptchaProps.executeRecaptcha('registration'); }
    catch (e) { console.error(e); }
    if (!token) {
      this.props.app.alertController.showErrorAlert('Error!', 'Invalid captcha authentication. This might be a problem with your page, try reloading it first. If this issue persists, please, contact the support!');
      this.stopLoading();
      return;
    } userObj.recaptchaToken = token;
    //Default fields
    userObj.email = data.email;
    userObj.firstName = data.firstName;
    userObj.lastName = data.lastName;
    // userObj.phoneNumber = null;//data.mobilePhone;
    userObj.linkingRoles = this.state.data.linkingRoles;
    userObj.password = data.password;
    userObj.parts = partData;
    //
    console.log(userObj)
    return userObj;
  }
}
const CommonRegistrationViewRecaptcha = withGoogleReCaptcha(CommonRegistrationView);
export default class CommonRegistrationViewHOC extends React.Component {
    render() {
      return (<CommonRegistrationViewRecaptcha {...this.props}/>);
    }
}
