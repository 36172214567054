import React from 'react';
import autoBind from 'react-autobind';
import { Table } from 'antd';
//
import Globals from '@/config/Globals'
//props are: app, isLoading, programs, tenants
export default class CommonOrganizationProgramsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: {} };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      { title: 'Program ID', key: 'tenantID', sorter: true,
        sorter: (a, b) => this._getTenantNameByID(a.tenantID).toLowerCase().localeCompare(this._getTenantNameByID(b.tenantID).toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'tenantID' && sortedInfo.order,
        render: props => this._getTenantNameByID(props.tenantID)
      },
      { title: 'Name', key: 'name', sorter: true,
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order, 
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        render: props => props.name && props.name.length > 0 ? props.name : 'N/A' 
      },
      { title: '# of Members', key: 'numMembers', sorter: true,
        sortOrder: sortedInfo.columnKey === 'numMembers' && sortedInfo.order, 
        sorter: (a, b) => (a.numMembers || 0) - (b.numMembers || 0),
        render: props => props.numMembers || 0 
      },
      { title: '# of Managers', key: 'numManagers', sorter: true,
        sortOrder: sortedInfo.columnKey === 'numManagers' && sortedInfo.order, 
        sorter: (a, b) => (a.managers || []).length - (b.managers || []).length,
        render: props => (props.managers || []).length || 0
      },
      { title: 'Is Affiliate?', key: 'isAffiliate', sorter: true,
        sortOrder: sortedInfo.columnKey === 'isAffiliate' && sortedInfo.order, 
        sorter: (a, b) => (a.isAffiliate ? 1 : 0) - (b.isAffiliate ? 1 : 0),
        render: props => (props.isAffiliate ? 'Yes' : 'No')
      }
    ];
    const props = { rowKey: 'tenantID', loading: this.props.isLoading, onChange: this.handleFilterChange, size: 'small',
                    locale: {emptyText: 'No programs attached to this company yet!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (<Table className="orgProgramsTable" columns={columns} dataSource={this.props.programs} {...props}/>);
  }
  _getTenantNameByID(tenantID) { return (this.props.tenants || []).find((t) => t.id == tenantID)?.name || tenantID; }
}