import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Alert, Col, Select, Form, Input, Divider, Switch } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
import { AdminCancellationPoliciesForm } from "./AdminCancellationPoliciesForm";

//props are: app, customer, courseProviders, isNew
export default class AdminCustomerForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Form methods
  async validateFields() {
    const d = await this.form.validateFields();
    if (!d) return;
    const { theme, cookiesHubDomain, acceptConnectionsFrom, preventUserFromUpdatingNames, domain, ...ds } = d;
    const cancellationPolicies = this.cancellationPoliciesForm.getPolicies();
    return {
      ...ds, allowedForeignReqs: [],/* unimplemented */
      cancelationPolicies: cancellationPolicies,
      preventUserFromUpdatingNames: d.preventUserFromUpdatingNames,
      portalConfig: {
        theme: d.theme, domain: d.domain,
        cookiesHubDomain: d.cookiesHubDomain,
        acceptConnectionsFrom: d.acceptConnectionsFrom,
      }
    }
  }
  setFieldsValue(data) {
    this.form.setFieldsValue({ ...data, ...data.portalConfig });
    this.cancellationPoliciesForm.setPolicies(data.cancelationPolicies);
  }
  //Life cycle
  componentDidUpdate() {
    const data = this.props.customer;
    if (data) this.setFieldsValue(data);
  }

  // delay to change, fe rebuild
  //UI
  render() {
    const criticalChangeInfo = <>Attention: <strong>Change on these values might take a while to propagate throughout the system. Additionally, rebuild of the Web FrontEnd is required!</strong></>;
    return (
      <Layout.Content>
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="id" label="ID (Unique Identifier)" rules={[
                { required: true, message: 'Please, type the customer ID!' },
                { min: 4, message: 'Customer ID must be between 4 and 255 characters' },
                { max: 255, message: 'Customer ID must be between 4 and 255 characters' },
              ]}>
                <Input disabled={!this.props.isNew} />
              </Form.Item>
            </Col>
          </Row>
          {/* Alert */}
          <Row type='flex' style={{ marginBottom: 10 }}>
            <Col span={21} offset={1}> <Alert message={criticalChangeInfo} type="warning" showIcon /> </Col>
          </Row>
          {/* IDM credentials */}
          {this._renderIDMCredentialsSubsection()}
          {/* IDM roles */}
          {this._renderIDMRolesSubsection()}
          {/* Submodule credentials */}
          {this._renderSubmodulesCredentialsSubsection()}
          {/* Portal config */}
          {this._renderPortalConfigsSubsection()}
          {/* Cancellation Policies */}
          {this._renderCancellationPoliciesSubsection()}
          {/* Misc. */}
          {this._renderMiscellaneousSubsection()}
        </Form>
      </Layout.Content>
    );
  }
  /* subsections */
  _renderIDMCredentialsSubsection() {
    return (
      <>
        <Divider orientation="left">IDM Credentials</Divider>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="idmKey" label="IDM Portal Key" rules={[{ required: true, message: 'Portal Key is required!' }]} extra='Key used to sign all calls made to IDM within the Portal Web FE.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="idmToken" label="IDM Portal Token" rules={[{ required: true, message: 'Portal Secret is required!' }]} extra='Token used to sign all calls made to IDM within the Portal Web FE.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="idmAPIUser" label="IDM API User" rules={[{ required: true, message: 'IDM API User is required!' }]} extra='IDM API User used to make private calls to IDM within the LMS API and submodules.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="idmAPIPassword" label="IDM API Password" rules={[{ required: true, message: 'IDM API Password is required!' }]} extra='IDM API Password used to make private calls to IDM within the LMS API and submodules.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderIDMRolesSubsection() {
    return (
      <>
        <Divider orientation="left">IDM Roles</Divider>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="userRole" label="User's role" rules={[{ required: true, message: 'User role is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="adminRole" label="Admin's role" rules={[{ required: true, message: 'Admin role is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={6} offset={1}>
            <Form.Item name="instructorRole" label="Instructor's role" rules={[{ required: true, message: 'Instructor role is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name="orgAdminRole" label="Organization Admin's role" rules={[{ required: true, message: 'Organization admin role is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item name="orgSubAdminRole" label="Organization SubAdmin's role" rules={[{ required: true, message: 'Organization subadmin role is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderSubmodulesCredentialsSubsection() {
    return (
      <>
        <Divider orientation="left">Submodules Credentials and Configs</Divider>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="sharedModuleLicenseAPIKey" label="License Module API Key" rules={[{ required: true, message: 'License module API Key is required!' }]} extra='License module API key utilized to access licenses on the customer level (across tenants). Currently, not utilized but accepted by the licenses service for future utilization.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="sharedModuleClassroomAPIKey" label="Classroom Module API Key" rules={[{ required: true, message: 'Classroom module API Key is is required!' }]} extra='Classroom module API key utilized to access sessions on the customer level (across tenants). Currently, not utilized but accepted by the classroom service for future utilization.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="sharedModuleAuditingAPIKey" label="Auditing Module API Key" rules={[{ required: true, message: 'Auditing API Key is required!' }]} extra='Auditing module API key utilized to send auditing events on the customer scope.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="sharedModuleOrganizationAPIKey" label="Organization Module API Key" rules={[{ required: true, message: 'Organization API Key is required!' }]} extra='Organization module API key utilized to access sessions on the customer level (across tenants). Currently, not utilized but accepted by the organization service for future utilization.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="orgModNamespace" label="Organization Module Namespace" rules={[{ required: true, message: 'Organization module namespace is required!' }]} extra='This will allow APIs to determine which organization module to use based on the namespace published by each customer.'>
              <Select> {Globals.Customer_OrgModuleNamespaces.map((opt) => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
  _renderPortalConfigsSubsection() {
    return (
      <>
        <Divider orientation="left">Web Portal Configuration</Divider>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="domain" label="Portal Domain" rules={[{ required: true, message: 'Portal domain is required!' }]} extra='Portal domain that will be matched with this customer and configs on it. eg.: "auth-dev.mycompany.com".'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="cookiesHubDomain" label="Portal Cookies hub domain" rules={[{ required: true, message: 'Portal Cookies hub domain is required!' }]} extra='Portal Cookies hub domain will be used as the browser based authentication microservice for this customer. Preferably it should be the same as the domain if primary customer, otherwise it should use the primary customer hub domain to maintain single signin experience. Example value.: "auth-dev.mycompany.com"'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="acceptConnectionsFrom" label="Portal Cookies hub domain policy" rules={[{ required: true, message: 'Portal Cookies hub domain policy is required!' }]} extra='Portal Cookies hub domain policy will be used as the CORS for the hub service, allowing only connections from the specified domain(s) to use this hub authenticator. Example value.: "*.mycompany.com"'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="theme" label="Portal UI Theme" rules={[{ required: true, message: 'Portal UI theme is required!' }]}>
              <Select> {Globals.Customer_PortalThemes.map((opt) => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="preventUserFromUpdatingNames" valuePropName="checked" label="Prevent User from updating name">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="salesTaxLabel" label="Sales Tax Label">
              <Input placeholder="GST" />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="timezone" label="Timezone" extra='This timezone is used on server side operations to determine expiration, validation and user facing dates on emails.'>
              <Select> {Intl.supportedValuesOf('timeZone').map((opt) => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
  _renderCancellationPoliciesSubsection() {
    return (
      <AdminCancellationPoliciesForm app={this.props.app} {...Utils.propagateRef(this, 'cancellationPoliciesForm')} />
    );
  }
  _renderMiscellaneousSubsection() {
    return (
      <>
        <Divider orientation="left">Miscellaneous</Divider>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="mailerSender" label="Default email sender" rules={[{ required: true, message: 'Customer default email sender is required!' }]} extra='Default email sender used by API actions that send emails at customer scope.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="allowedOnlineCourseProviders" label="Allowed Online Course Providers" rules={[{ required: true, message: 'One or more course providers are required!' }]}>
              <Select mode='multiple' style={{ minWidth: 300 }} showSearch filterOption={(input, option) => {
                const provider = (this.props.courseProviders || []).find((c) => option.value == c.id);
                return `${provider.id} (${provider.type})`.toLowerCase().includes(input.toLowerCase());
              }}>
                {(this.props.courseProviders || []).map((c) => <Select.Option value={c.id} key={c.id}>{c.id} ({c.type})</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="worksafeTerm" label="Worksafe Term" extra='Word used to represent WorksafeBC, WCB or any other org used to provide company data.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
}
