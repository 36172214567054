import React from "react";
import autoBind from 'react-autobind';
import { Modal, Row, Col, Button } from 'antd';
//
import CommonDynamicForm from "@/views/commonComponents/CommonDynamicForm";

//props are: app, customRegistration
export default class CommonRegistrationFormPreviewModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false };
  }
  //Public
  show() { this.setState({ isVisible: true }); }
  //Private actions
  handleClose() { this.setState({ isVisible: false }); }

  //UI
  render() {
    const customRegistration = this.props.customRegistration || {};
    return (
      <Modal open={this.state.isVisible} title={'Dynamic form preview'}
              placement="right" onCancel={this.handleClose} footer={null} width={700}>
        <CommonDynamicForm app={this.props.app} additionalInputs={customRegistration}/>
      </Modal>
    );
  }
}
