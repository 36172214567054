import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, Row, Col } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import scrollIntoView from 'scroll-into-view';
//
import Utils from '@/components/helpers/Utils';
import PersistentSearch from '@/components/helpers/PersistentSearch';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonEmployeesSearchBar from '../commonComponents/CommonEmployeesSearchBar';
import CommonOrganizationEmployeesTable from '../commonComponents/Table/CommonOrganizationEmployeesTable';
import AdminOrganizationOptions, { AdminOrganizationOptionTypes } from '../commonComponents/AdminOrganizationOptions';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminOrganizationEmployeesView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.persistentSearch = new PersistentSearch();
    this.state = {
      isLoading: false,
      orgID: this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this),
      orgName: this.props.app.urlManager.getQueryParam('n', this) || 'Company',
      employees: [], employeesTotal: 0,
      ...this._getInitialState(),
    };
    //
    this.viewOptions = new AdminOrganizationOptions(AdminOrganizationOptionTypes.EMPLOYEES, this.state.orgID, this.props.app, this.state.orgName);
  }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    //set navigation items details
    this.props.app.appsManager?.navigation?.appendValue('orgID', this.state.orgID);
    this.props.app.appsManager?.navigation?.appendValue('orgName', this.state.orgName);
    //load data
    this._fetchData();
  }

  //Actions
  handleSearch(term) { this.setState({ searchTerm: term, currentPage: 1, sortedInfo: null }, this._fetchData); }
  handleSearchChange(value) {
    if (!value) this.setState({ searchTerm: null, currentPage: 1, sortedInfo: null }, this._fetchData);
  }
  handleExportXLSX() { this._exportSearch(); }
  //Employee actions
  handleEmployeeEdit(employeeObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.user, null, employeeObj.id); }
  //Table internal actions
  handleTablePageChange(currentPage) { this.setState({ currentPage }, this._fetchData); }
  handleTableChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }, this._fetchData); }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className='pageHeader' title={`${this.state.orgName} - Employees`} onBack={() => window.history.back()} extra={ this.viewOptions.getOptions() }/>
          <Layout.Content className="pageSubContent">
            <CommonLoadingView isLoading={this.state.isLoading} />
              <Layout.Content>
                {this._renderExportButton()}
                <CommonEmployeesSearchBar persistentSearch={this.persistentSearch} isLoading={this.state.isLoading} orgID={this.state.orgID}
                    onSearch={this.handleSearch} {...Utils.propagateRef(this, 'searchBar')} app={this.props.app}/>
                <CommonOrganizationEmployeesTable app={this.props.app} isLoading={this.state.isLoading} total={this.state.employeesTotal}
                    currentPage={this.state.currentPage} employees={this.state.employees} sortedInfo={this.state.sortedInfo}
                    onPageChange={this.handleTablePageChange} onFilterChange={this.handleTableChange}
                    onEmployeeEdit={this.handleEmployeeEdit}/>
              </Layout.Content>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderExportButton() {
    return (
      <Row type='flex' align='end'>
        <Col>
          <Button key="1" type="primary" icon={<DownloadOutlined />} onClick={this.handleExportXLSX}
                  disabled={this.state.employees.length < 1} style={{ marginLeft: 20, marginRight: -4 }}> Export to XLSX </Button>
        </Col>
      </Row>
    );
  }
  /* remote data */
  async _fetchData() {
    if (this.state.isLoading) return;
    this.startLoading();
    //request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const resp = await this.props.app.organization.employee.searchOrganizationsEmployeesByTerm(searchTerm, this._getSearchFilter());
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.employees) {
      const employees = resp.body.employees;
      this.setState({ employees, employeesTotal: resp.body.total, isLoading: false });
      scrollIntoView(document.getElementById('root'), { time: 500, align: { top: 0 } });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
    this._reloadURLParams();
  }
  /* private */
  _getInitialState() {
    const currentPage = parseInt(this.persistentSearch.getParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.persistentSearch.getParam(Globals.URLQueryParam_SortField) || 'firstName.keyword';
    const order = this.persistentSearch.getParam(Globals.URLQueryParam_SortOrder) || 'ascend';
    return { currentPage, sortedInfo: { columnKey, order } };
  }
  _reloadURLParams() {
    this.persistentSearch.setParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.persistentSearch.setParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : null));
    this.persistentSearch.setParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }
  _getSearchFilter() {
    const orgID = this.state.orgID ? this.state.orgID : null;
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    const searchBarObjs = this.searchBar ? this.searchBar.getSearchFilters() : {};
    return { orgID, from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'), ...searchBarObjs };
  }
  async _exportSearch() {
    this.startLoading();
    //request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const resp = await this.props.app.organization.employee.exportEmployeesSearch(searchTerm, this._getSearchFilter());
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body) Utils.downloadBlob(resp.body, 'employees', 'xlsx');
    else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.stopLoading();
  }
}
