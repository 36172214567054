import React from "react";
import autoBind from 'react-autobind';
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import CommonLoginForm from './Forms/CommonLoginForm';
import CommonBoxLinks from '../commonComponents/CommonBoxLinks';
//resources
import "@/stylesheets/CommonLogin.less";
//
import config from "@/config/config";
import Utils from "@/components/helpers/Utils";
//
import { Layout, Row, Col, Button, Divider } from 'antd';
//
export default class CommonLoginView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
    this.props.app.sessionDidFailLoadHandler = this.sessionDidFailLoadHandler;
    this.props.app.idm.sessionExternalProviderLoadedHandler = this.sessionExternalProviderLoadedHandler;
  }
  componentDidMount() {
    super.componentDidMount();
    document.title = `Sign in - ${this.props.app.themeManager.theme.applicationName}`;
  }
  sessionDidFailLoadHandler(err) {
    if (!err) err = 'Could not load application information. Please, check your internet connection.';
    this.props.app.alertController.showErrorAlert('Error!', err);
    if (this._isMounted) this.stopLoading();
  }
  sessionExternalProviderLoadedHandler() { if (this._isMounted) this.forceUpdate(); }
  //Actions
  handleSignup(event) { this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.register); }
  handleForgotPassword(event) {
    let param = '';
    if (this.form && this.form.form.getFieldValue('username')) {
      param = this.form.form.getFieldValue('username').trim();
    }
    this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.resetPassword, null, param);
  }
  handleChange(event) {
    if (event.target.type == "checkbox") this.setState({ [event.target.id]: event.target.checked });
    else this.setState({ [event.target.id]: event.target.value });
  }
  async handleLogin(data) {
    this.startLoading();
    await this.props.app.idm.authenticator.login(data.username.trim(), data.password, null);
    if (this._isMounted) this.setState({ isLoading: false });
  }
  async handleLoginWithProvider(provider) {
    this.startLoading();
    await this.props.app.idm.externalProviders[`loginWith${provider}`]();
  }

  //UI
  render() {
    let isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    return (
      <Layout.Content>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Layout.Content className='authorizationContainer'>
          <Row type="flex" justify="center" align="middle" >
            <Col span={6} className='authorizationBoxContainer'>
              <div className='authorizationBox'>
                <Row><Col align="center"> <Logo app={this.props.app}/> </Col></Row>
                <Row>
                  <Col align="center">
                    <h5 className="authorizationBoxTitle">Sign in</h5>
                    <h6 className="authorizationBoxDescription">Use your {this.props.app.themeManager.theme.applicationName} Account</h6>
                  </Col>
                </Row>
                <CommonLoginForm app={this.props.app} onLogin={this.handleLogin} onSingup={this.handleSignup}
                  onForgot={this.handleForgotPassword} {...Utils.propagateRef(this, 'form')} />
              </div>
              {this._renderLoginButtons()}
            </Col>
          </Row>
          <CommonBoxLinks width={340} app={this.props.app}/>
        </Layout.Content>
      </Layout.Content>
    );
  }
  _renderLoginButtons() {
    if (this.props.app.idm.externalProviders.isLoading) return (<></>);
    else if (this.props.app.idm.externalProviders.isExternalProvidersEnabled()) {
      return (
        <>
          <Divider>Or</Divider>
          {this.props.app.idm.externalProviders.isGoogleAvailable() && <Row type='flex' justify='center' style={{ marginBottom: '15px' }}><Button onClick={this.handleLoginWithProvider.bind(this, 'Google')}>
            <img width="17px" style={{ marginBottom: '3px', marginRight: '5px' }} alt="Google login" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
              Sign in with Google
            </Button></Row>}
          {this.props.app.idm.externalProviders.isAppleAvailable() && <Row type='flex' justify='center' style={{ marginBottom: '15px' }}><Button onClick={this.handleLoginWithProvider.bind(this, 'Apple')}>
            <img width="17px" style={{ marginBottom: '3px', marginRight: '5px' }} alt="Apple login" src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" />
            Sign in with Apple
            </Button></Row>}
          {this.props.app.idm.externalProviders.isFacebookAvailable() && <Row type='flex' justify='center' style={{ marginBottom: '15px' }}><Button onClick={this.handleLoginWithProvider.bind(this, 'Facebook')}>
            <img width="17px" style={{ marginBottom: '3px', marginRight: '5px' }} alt="Apple login" src="https://upload.wikimedia.org/wikipedia/commons/1/1b/Facebook_icon.svg" />
            Continue with Facebook
            </Button></Row>}
        </>
      );
    } else return (<></>);
  }
}
