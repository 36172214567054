import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col, Form, Input, InputNumber } from 'antd';
//
import Utils from '@/components/helpers/Utils';

//props are: section, isNew
export default class CommonRegistrationSectionForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Form methods
  async validateFields() { return await this.form.validateFields(); }
  setFieldsValue(data) { this.form.setFieldsValue(data); }
  //Life cycle
  componentDidMount() { 
    if (this.props.field) this.setFieldsValue(this.props.field);
  }
  componentDidUpdate(prevProps) {
    const data = this.props.section;
    if (prevProps.section != data && data) this.setFieldsValue(data);
  }
  //UI
  render() {
    return(
      <Layout.Content justify="right-align">
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={22} offset={1}>
              <Form.Item name="name" label="Section Name" rules={[
                  { required: true, message: 'Please, type the section name!' },
                  { min: 4, message: 'Section Name must be between 4 and 255 characters' },
                  { max: 255, message: 'Section Name must be between 4 and 255 characters' },
                ]}> 
                  <Input/> 
                </Form.Item>
            </Col>
          </Row><Row type='flex'>
            <Col span={22} offset={1}>
              <Form.Item name="beforeRow" label="Place before row">
                <InputNumber min={0} max={99} precision={0} step={1} style={{width: 200}}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    );
  }
}
