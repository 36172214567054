import React from 'react';
import autoBind from 'react-autobind';
import { Button, Row, Col, Typography, Table, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import Globals from '@/config/Globals';

//props are: app, disabled, isLoading, onDelete, onEdit, onAdd, sections
export default class CommonRegistrationSectionTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: {} };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      { title: 'Name', key: 'name', sorter: true,
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order, 
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        render: props => props.name && props.name.length > 0 ? props.name : 'N/A' 
      },
      { title: 'Before row', key: 'beforeRow', sorter: true,
        sortOrder: sortedInfo.columnKey === 'beforeRow' && sortedInfo.order, 
        sorter: (a, b) => (a.beforeRow || 0) - (b.beforeRow || 0),
        render: props => props.beforeRow
      },
      { title: 'Actions', width: 100, key: 'Actions', align: 'center',
         render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit Section'>
              <Button variant="none" disabled={this.props.disabled} icon={<EditOutlined />} shape="circle" onClick={this.props.onEdit.bind(this, props)}/>
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Delete Section'>
              <Popconfirm title={`Do you really want to delete section '${props.name}'?`} placement="bottomRight" onConfirm={this.props.onDelete.bind(this, props)} okText="Yes" cancelText="No">
                <Button variant="none" disabled={this.props.disabled} icon={<DeleteOutlined />} shape="circle" style={{marginLeft: 10}}/>
              </Popconfirm>
            </Tooltip>
          </span>
        ),
      }
    ];
    const props = { rowKey: (p) => `${p.name}:${p.beforeRow}`, loading: this.props.isLoading, onChange: this.handleFilterChange, size: 'small',
                    locale: {emptyText: 'No sections found for this schema!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (
      <Row type='flex' style={{marginBottom: 20}}>
        <Col span={24}>
          <Row type='flex' justify="middle" align="center" style={{marginTop: 10, marginBottom: 10}}>
            <Col span={17} align="center"> <Typography.Title level={5}>Sections</Typography.Title> </Col>
            <Col offset={1} span={3} > <Button disabled={this.props.disabled} type='primary' onClick={this.props.onAdd.bind(this)}>Add section</Button> </Col>
          </Row>
          <Table className="regSectionsTable" columns={columns} dataSource={this.props.sections} {...props}/>
        </Col>
      </Row>
    );

  }
}