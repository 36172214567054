import React from "react";
import autoBind from 'react-autobind';
import { Layout, Alert, Menu } from 'antd';
import { CalendarOutlined, ScheduleOutlined, UserOutlined } from '@ant-design/icons';
//
import { isIE } from 'react-device-detect';
//
import CommonLoadingView from './views/commonComponents/CommonLoadingView';
import CommonChangePasswordModal from "./views/commonComponents/CommonChangePasswordModal";
import AppRoutes from "./AppRoutes";
import CustomComponent from './ui-components/CustomComponent';
import AppHeader from "./views/commonComponents/Header/AppHeader";
import AppUserHeader from "./views/commonComponents/Header/AppUserHeader";
//
import config from "./config/config";
import Globals from "./config/Globals";
//
import "./stylesheets/AppViewController.less";
import { Link } from "react-router-dom";
//
export default class AuthorizedViewController extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.props.app.props.history.location.state = undefined; //reset history state if restarting app
    this.handleLogout = this.handleLogout.bind(this);
    this.state = { isLoading: false };
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    //App title
    document.title = this.props.app.themeManager.theme.applicationName;
    //Support foreign apps asking change pass
    if (this.props.app.onGoingBehaviour.changingPassword) {
      this.props.app.onGoingBehaviour.changingPassword = false;
      if (this.changePassModal) this.changePassModal.handleShow(true);
    }
  }
  //Actions
  async handleLogout() {
    this.startLoading();
    await this.props.app.idm.authenticator.logout();
    this.props.app.urlManager.pushPage("/");
  }
  handleChangePassword() { this.changePassModal.handleShow(); }
  handleOpenProfile() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.profile) }
  //UI
  render() { return this.renderPage(); }
  renderPage() {
    const themeAlert = this.props.app?.themeManager?.theme?.branding?.alert;
    return (
      <Layout className="container-layout">
        {/* Alerts */}
        {themeAlert && <Alert style={{ zIndex: 9999 }} message={themeAlert?.content} type={themeAlert?.type || 'info'} banner />}
        {isIE && <Alert message={Globals.CompabilityMessage} banner/>}
        {/* Header */}
        <AppHeader app={this.props.app}/>
        <AppUserHeader app={this.props.app} openProfile={this.handleOpenProfile} changePassword={this.handleChangePassword} 
          logoutHandler={this.handleLogout}/>
        {/* Content */}
        <Layout>
          {this._renderInstructorSidebar()}

          <Layout>
            <Layout.Content className="main-content">
              {/* Loading */}
              <CommonLoadingView isLoading={this.state.isLoading} />
              {/* Navigation Component */}
              {this.props.app.appsManager.renderNavigationComponent()}
              {/* Routes */}
              <AppRoutes appRef={this} />
            </Layout.Content>
            {/* Modals */}
            {this._renderChangePasswordModal()}
          </Layout>
        </Layout>
      </Layout>
    );
  }
  //Extra UI
  _renderChangePasswordModal() { return (<CommonChangePasswordModal ref={f => (this.changePassModal = f)} app={this.props.app} />); }

  _renderInstructorSidebar() {
    if (!this.props.app.isInstructor()) {
      return null;
    }

    const { pathname } = this.props.app.props.history.location;

    return (
      <Layout.Sider theme="light">
        <Menu
          theme="light"
          className="sideNavigation"
          style={{ height: '100%' }}
          selectedKeys={[pathname]}
          mode="vertical"
          items={[
            {
              key: config.ApplicationRoutes.instructor_calendar,
              label: (
                <Link to={config.ApplicationRoutes.instructor_calendar}>
                  <CalendarOutlined />My Calendar
                </Link>
              ),
            },
            // code removed based on request from BCCSA, we will keep it around in case they want it back
            // {
            //   key: config.ApplicationRoutes.instructor_students,
            //   label: (
            //     <Link to={config.ApplicationRoutes.instructor_students}>
            //       <UserOutlined /> Students
            //     </Link>
            //   ),
            // },
          ]}
        />
      </Layout.Sider>
    )
  }
}
