import Utils from '../helpers/Utils';
import LMSAPI from '@ikonintegration/lms-api-client';
import config from '../../config/config';
//
class Cache {
		constructor(auth) {
			this.auth = auth;
			this.isLoading = false;
			//idm
			this.roles = [];
			this.apps = [];
			//org
			this.orgCities = [];
			this.orgCUs = [];
			//config
			this.configCourseProviders = [];
			this.configCustomerWorksafeTerm = null;
			//
			this._onDemandCache = {};
		}
		async loadCache() {
			console.log('Loading cache')
			//avoid double cache loading
			if (this.isLoading) return false;
			this.isLoading = true;
			//Parallel requests
			const respAll = await Utils.execRequests([
				/* IDM Mini-app */
				(this.auth.isRoot() ? this.auth.idm.api.application.listAll() : null),
				(this.auth.isRoot() ? this.auth.idm.api.role.listAll() : null),
				/* Organization Mini-app */
				// ((this.auth.isRoot() || this.auth.isOrgAdmin()) ? this.auth.organization.organization.getSearchOrganizationCitiesValues() : null), -- loaded on demand
			], null, ((resp, idx) => {
				if (idx == 0 && resp && resp.body && resp.statusCode == 200) this.apps = resp.body.apps;
				else if (idx == 1 && resp && resp.body && resp.statusCode == 200) this.roles = resp.body.roles;
				// else if (idx == 2 && resp && resp.body && resp.statusCode == 200) this.orgCities = resp.body.cities.map((c) => c.value);
			}), true);
			//
			this.isLoading = false;
			return respAll;
		}
		clearCache() {
			this.isLoading = false;
			//idm
			this.roles = [];
			this.apps = [];
			//org
			this.orgCities = [];
			this.orgCUs = [];
			//config
			this.configCourseProviders = [];
			this.configCustomerWorksafeTerm = null;
		}
		/* IDM mini-app Caching */
		getRoles() { return this.roles; }
		setRoles(roles) { this.roles = roles; }
		getApps() { return this.apps; }
		setApps(apps) { this.apps = apps; }
		findAppByID(appID) { return this.apps.find(appObj => { if (appObj.id == appID) return appObj;} ); }
		findRoleByID(roleID) { return this.roles.find(roleObj => { if (roleObj.id == roleID) return roleObj;} ); }
		findRoleByCognitoName(roleID) { return this.roles.find(roleObj => { if (roleObj.cognitoName == roleID) return roleObj;} ); }
		//UI Helpers
		getDropdownRoles() {
			let roles = [];
			for (let role of this.roles) {
				roles.push({ pk: role.id, description: `${role.name} - ${this.findAppByID(role.appID).name}` });
			} return roles;
		}
		/* Organization mini-app Caching */
		getOrganizationCities() { return this.orgCities.map(Utils.camelizeString); }
		async loadOrganizationCities(force) { 
			if (this.orgCities.length > 0 && !force) return this.orgCities;
			return await Utils.execRequests([this.auth.organization.organization.getSearchOrganizationCitiesValues()], null, ((resp, idx) => {
				if (idx == 0 && resp && resp.body && resp.statusCode == 200) this.orgCities = resp.body.cities.map((c) => c.value);
			}), true);
		}
		getOrganizationCUs() { return this.orgCUs; }
		async loadOrganizationCUs(force) {
			if (this.orgCUs.length > 0 && !force) return this.orgCUs;
			return await Utils.execRequests([this.auth.organization.classificationUnit.getAllClassificationUnits()], null, ((resp, idx) => {
				if (idx == 0 && resp && resp.body && resp.statusCode == 200) this.orgCUs = resp.body.classificationUnits;
			}), true);
		}
		/* Config mini-app Caching */
		getConfigCourseProviders() { return this.configCourseProviders; }
		async loadConfigCourseProviders(force) { 
			if (this.configCourseProviders.length > 0 && !force) return this.configCourseProviders;
			return await Utils.execRequests([this.auth.config.courseProvider.getAllProviders()], null, ((resp, idx) => {
				if (idx == 0 && resp && resp.body && resp.statusCode == 200 && resp.body.courseProviders) this.configCourseProviders = resp.body.courseProviders;
			}), true);
		}
		getConfigCustomerWorksafeTerm() { return this.configCustomerWorksafeTerm }
		async loadConfigCustomerWorksafeTerm(force) {
			console.log('*-*-* loadConfigCustomerWorksafeTerm');
			if (this.configCustomerWorksafeTerm && !force) {
				console.log('*-*-* did not fetch will return', this.configCustomerWorksafeTerm);
				return this.configCustomerWorksafeTerm;
			}
			const customer = await this.auth.config.customer.getCustomer(this.auth.customerID);
			if (customer && customer.body && customer.statusCode == 200) {
				this.configCustomerWorksafeTerm = customer.body.worksafeTerm;
				console.log('*-*-* fetched will return', this.configCustomerWorksafeTerm);
				return true
			}
			console.log('*-*-* failed fetch will return false');
			return false;
		}
		getCourseByID(courseID, certID = null, tenantID = null) {
			let tenantConfig = this.auth.configManager.launchConfig.tenant;
			if (tenantID) {
			  tenantConfig = this._onDemandCache[`tenant_${tenantID}`]	
			}
			if (!tenantConfig || ! tenantConfig.certifications) return null;
			//For all certifications
			for (let certificationObj of tenantConfig.certifications) {
				if (certID && certificationObj.id != certID) { continue; }
				//For all exams of tenant config
				for (let course of certificationObj.requirements) {
					if (Array.isArray(course)) {
						for (let subCourse of course) if (subCourse.id == courseID) return subCourse;
					} else if (course.id == courseID) return course;
				}
			} return null;
		}

		/* On Demand Cache */
		getVenueByID(venueID) {
			if (this._onDemandCache['venues']) {
				return this._onDemandCache['venues'].find((v) => v.id == venueID);
			} return null;
		}
		async getVenues() {
			if (!this._onDemandCache['venues']) {
				const resp = await this.auth.classroom.venue.getAllVenues();
				if (resp.statusCode == 200 && resp.body.venues) this._onDemandCache['venues'] = resp.body.venues;
			} return this._onDemandCache['venues'];
		}
		async getCities() {
			if (!this._onDemandCache['cities']) {
				const resp = await this.auth.classroom.city.getAllCities();
				if (resp.statusCode == 200 && resp.body.cities) this._onDemandCache['cities'] = resp.body.cities;
			} return this._onDemandCache['cities'];
		}
		getCityByID(cityID) {
			return this._onDemandCache['cities']?.find(city => city.id == cityID);
		}
		async getTenantByID(tenantID) {
			const cacheKey = `tenant_${tenantID}`
			if (!this._onDemandCache[cacheKey]) {
				const api = new LMSAPI({ endpoint: config.ApplicationAPIEndpoint, statsEndpoint: null, authorizationToken: this.auth._getIDMToken.bind(this.auth), tenantID });
				const resp = await api.tenant.getConfiguration();
				if (resp.statusCode == 200 && resp.body && resp.body.id) this._onDemandCache[cacheKey] = resp.body;
			} return this._onDemandCache[cacheKey];
		}
};

export default Cache;
