/* eslint-disable no-nested-ternary */
import React from 'react';
import { Breadcrumb, Row } from 'antd';
import autoBind from 'react-autobind';
import { Link } from "react-router-dom";
import { matchPath } from "react-router";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
//
import config from '../../config/config';
//props are: app
export default class AdminNavigationView extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.values = {};
  }
  appendValue(key, value) { 
    this.values[key] = value; 
    this.forceUpdate();
  }
  parseUrl(a1,a2,a3,a4) { return this.props.app.urlManager.parseUrl(a1,a2,a3,a4); }
  openLink(a1, a2, a3, a4) { this.props.app.urlManager.pushPage(a1,a2,a3,a4); }
  //UI
  render() {
    const path = this.props.app?.props?.history?.location?.pathname;
    let appLevel = this.props.app.appsManager.getNavigationOptionFromEnablesApps(path, this.values);
    if (!appLevel) appLevel = this._getNavigationOption(path, this.values);
    if (!appLevel) return <></>;
    return (
      <Row type='flex' className="adminNavigationView">
        <Breadcrumb style={{marginBottom: '20px', fontSize: '18px'}}>
          <Breadcrumb.Item onClick={this.openLink.bind(this, config.ApplicationRoutes.homepage, null)}>
            <Link to={this.parseUrl(config.ApplicationRoutes.homepage)}>
              <HomeOutlined style={{ fontSize: '20px', marginRight: '10px' }} />
            </Link>
          </Breadcrumb.Item>
          {appLevel}
        </Breadcrumb>
      </Row>
    );
  }
  /* private - Main App */
  _getNavigationOption(path, values) {
    //Homepage -- hide for now
    // if (matchPath(path, config.ApplicationRoutes.homepage)) return [];
    //
    if (matchPath(path, config.ApplicationRoutes.profile)) {
      return [<Breadcrumb.Item key='profileBC' onClick={this.openLink.bind(this, config.ApplicationRoutes.profile)}>
                <Link to={this.parseUrl(config.ApplicationRoutes.profile)}> 
                  <UserOutlined style={{ fontSize: '20px', marginRight: '4px' }} /> Profile
                </Link>
              </Breadcrumb.Item>];
    } return null;
  }
}