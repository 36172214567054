import React from 'react';
import autoBind from 'react-autobind';
import { Spin, Typography, Select, AutoComplete, Row, Col, Input, Button, Form, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//
import config from '@/config/config';
import Globals from '@/config/Globals'
import Utils from '@/components/helpers/Utils'
//
import '@/stylesheets/org-app/CommonSearchBar.less';
//props are: app, persistentSearch, disabled, isLoading, onSearch
export default class CommonOrganizationsSearchBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.debounce = null; //search debouncer
    this.autocompleteDebounce = null; //autocomplete search debounce
    this.state = {
      searchValue: this.props.persistentSearch.getParam(Globals.URLQueryParam_SearchTerm) || '',
      cities: this.props.persistentSearch.getParam(Globals.URLQueryParam_Cities) || [],
      cus: this.props.persistentSearch.getParam(Globals.URLQueryParam_CUs) || [],
      autocompleteItems: [], loadingAutocomplete: false
    };
  }
  //Lifecycle
  componentDidMount() {
    if (this.state.searchValue) this.handleSearch(this.state.searchValue);
  }
  //Public
  getSearchTerm() { return this.state.searchValue; }
  getSearchFilters() { return { cities: this.state.cities, cus: this.state.cus }; }
  //Actions
  focus() { setTimeout(() => { if (this.searchInput) this.searchInput.focus(); }, 100); }
    //Search bar
  handleSearch(newValue) {
    let searchValue = newValue;
    //Fetch safe search value
    if (typeof newValue === 'string') searchValue = newValue;
    else if (this.searchInput) searchValue = this.searchInput.input.value;
    this._cleanupAutocompletion();
    this._scheduleSearch(searchValue);
  }
  handleSearchInputChange(newValue) {
    const searchValue = newValue ;
    const isEmpty = !searchValue || searchValue.length == 0;
    if (isEmpty) this._scheduleSearch(null);
    this.setState({ searchValue: searchValue, autocompleteItems: []});
    //has text, generate auto complete, else, cleanup above
    if (!isEmpty) this._scheduleAutocompletion(newValue);
  }
  handleSearchAutocompleteSelect(v) {
    let item = this.state.autocompleteItems.find((i) => i.id == v);
    if (!item) item = { name: this.state.searchValue }; //auto selection of the search value
    this.setState({ searchValue: item.name });
    this.handleSearch(item.name);
  }
  //Agnostic filter handler
  handleSearchFilterChange(type, value) { this.setState({ [type]: value }, this.handleSearch); }

  //UI
  render() { 
    return ( 
      <div className="commonSearchBar"> 
        {this._renderSimpleSearch()} 
        {this._renderAdditionalFilters()}
      </div>
    ); 
  }

  /* sub renders */
  _renderSimpleSearch() {
    const { searchValue } = this.state;
    const autocompleteItems = (this.state.loadingAutocomplete ?
      [ this._renderAutocompleteOption(null) ] : //loading state
      ((!searchValue || searchValue.length == 0) ? [] : [{ id: Date.now() + '', name: searchValue, search: true }]) //append to valid state the current value (when valid)
        .concat(this.state.autocompleteItems).map(this._renderAutocompleteOption)); //append server side autocomplete items + map to UI option
    return (
      <Row type="flex" align="middle" justify="space-between" className="searchBarRow">
        {/* Search bar */}
        <Col span={2}> <SearchOutlined className="searchBarIcon" /> </Col>
        <Col className="searchInputColumn">
          <Form.Item /*validateStatus={error ? 'error' : 'success'} help={error ? `Minimum number of characters is ${Globals.Search_MinimumCharacters}!` : ''}*/>
            <AutoComplete value={this.state.searchValue} onChange={this.handleSearchInputChange} onSelect={this.handleSearchAutocompleteSelect}
                          style={{ width: '100%' }} dataSource={autocompleteItems}>
              <Input.Search className="searchBarInput" onSearch={this.handleSearch} placeholder="Search for companies name, worksafe # or trade name" 
                {...Utils.propagateRef(this, 'searchInput')} disabled={this.state.isLoading || this.props.disabled}/>
            </AutoComplete>
          </Form.Item>
        </Col>
        {/* Search button */}
        <Col className="buttonColumn">
          <Button type="primary" className="searchButton" onClick={this.handleSearch} loading={this.props.isLoading}
            disabled={this.props.isLoading || this.props.disabled}> Search </Button>
        </Col>
      </Row>
    );
  }
  _renderAdditionalFilters() {
    return (
      <Row type="flex" align="middle" justify="start" className="searchFilterRow">
        {/* Cities */}
        <Col className="buttonColumn">
          <Typography.Text strong>City:</Typography.Text>
          <Select value={this.state.cities} onChange={this.handleSearchFilterChange.bind(this, 'cities')} className="searchCitiesSelect"
                  disabled={this.state.isLoading || this.props.disabled} mode='multiple' style={{minWidth: 300}} placeholder="Filter by one or more cities">
            {this.props.app.sharedCache().getOrganizationCities().map((val, index) => (<Select.Option key={index} value={val}> {val} </Select.Option>))}
          </Select>
        </Col>
        {/* CUs */}
        {/* <Col className="buttonColumn">
          <Typography.Text strong>CU:</Typography.Text>
          <Select value={this.state.cus} onChange={this.handleSearchFilterChange.bind(this, 'cus')} className="searchCUsSelect"
                  disabled={this.state.isLoading || this.props.disabled} mode='multiple' style={{minWidth: 300}}>
            {[].map((val, index) => (<Select.Option key={index} value={val}> {val} </Select.Option>))}
          </Select>
        </Col> */}
      </Row>
    );
  }
  _renderAutocompleteOption(item) {
    if (!item) return ( <AutoComplete.Option disabled key='loading' value='averystrangeconstantkey12345@d1d2'> <Spin/> </AutoComplete.Option> );
    return (
      <AutoComplete.Option key={item.id} value={item.id}>
        <Typography.Text style={{ marginRight: 15 }}>
          <SearchOutlined style={{marginRight: 5}}/>
          {item.name} {item.worksafeID ? ` - (wsid: ${item.worksafeID})` : ''}
        </Typography.Text>
      </AutoComplete.Option>
    );
  }

  /* debouncer */
  _scheduleSearch(term) {
    if (this.debounce) clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      //persist changes on url
      this.props.persistentSearch.setSearchTerm(term, { 
        [Globals.URLQueryParam_Cities]: this.state.cities || [],
        [Globals.URLQueryParam_CUs]: this.state.cus || [],
      });
      //
      if (this.props.onSearch) this.props.onSearch(term);
      this.debounce = null;
    }, 100);
  }
  _scheduleAutocompletion(term) {
    if (this.autocompleteDebounce) clearTimeout(this.autocompleteDebounce);
    this.autocompleteDebounce = setTimeout(async () => {
      this.setState({ loadingAutocomplete: true });
      const items = await this._getAutocompletionItems(term);
      if (!this.autocompleteDebounce) return; //cancelled during request?
      this.setState({ loadingAutocomplete: false, autocompleteItems: items || [] })
      this.autocompleteDebounce = null;
    }, 300);
  }
  _cleanupAutocompletion() {
    if (this.autocompleteDebounce) clearTimeout(this.autocompleteDebounce);
    this.setState({ loadingAutocomplete: false });
    if (this.searchInputAutocomplete) this.searchInputAutocomplete.blur();
    this.autocompleteDebounce = null;
  }

  /* private API */
  async _getAutocompletionItems(term) {
    const searchResp = await this.props.app.organization.organization.searchOrganizationsAutocomplete(term);
    if (searchResp.statusCode == 200 && searchResp.body.results) return searchResp.body.results;
    return false;
  }
}
