import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/helpers/Utils';
//
import { Layout, Row, Col, Form, Input } from 'antd';
//props are: onChange, editMode, isNew, data
export default class RootACLForm extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
    }

    validateFields() {
      return this.form.validateFields();
    }

    componentDidUpdate() {
      const data = this.props.data;
      if (data) this.form.setFieldsValue(data);
    }

    render() {
      const editMode = this.props.editMode;
      return(
        <Layout.Content justify="right-align">
          <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
            <Row gutter={12}>
              <Col span={6}>
                <Form.Item name="id" label="Partition Identifier"
                  rules={[
                    { required: true, message: 'Please, type partition identifier!' },
                    { min: 1, max: 255, message: 'Partition identifier must be between 1 and 255 characters' },
                  ]}>
                  <Input disabled={!(editMode && this.props.isNew)} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Layout.Content>
      );
    }
}
