import moment from 'moment';
import React from 'react';
import autoBind from 'react-autobind';
import EventWrapper from 'react-big-calendar/lib/addons/dragAndDrop/EventWrapper';
import { DeleteOutlined, LockOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Popconfirm, Popover, Row, Tag, Typography } from 'antd';
import Globals from '@/config/Globals';
import Utils from '@/components/helpers/Utils';

export default class CommonCalendarEvent extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  
  render() {
    const { metadata: { app, event, handleDelete, handleEdit } } = this.props.event;
    
    const isCancelled = event?.session?.state === Globals.Session_State.CANCELLED;
    const isLocked = event?.session?.state == Globals.Session_State.LOCKED;
    const isPendingReview = event?.session?.state == Globals.Session_State.PENDING_INSTRUCTOR;
    const course = (event?.session?.courseID) ? app.sharedCache().getCourseByID(event?.session.courseID, null, event?.session.tenantID) : null;

    const Wrapper = event.isAvailabilityEvent ? React.Fragment : Popover;
    const wrapperProps = event.isAvailabilityEvent
      ? {}
      : {
        title: this._getPopoverTitle(),
        placement: 'bottom',
        trigger: 'click',
        content: this._getPopoverContent(),
      };

    return (
      <EventWrapper {...this.props}>
        <Wrapper {...wrapperProps}>
          <div className="rbc-event cleanEventContainer" style={this._getStyle()}>
            <div
              {...(event.isAvailabilityEvent ? { onClick: handleEdit } : {})}
              className="customSessionEvent"
              style={{
                height: '100%',
                backgroundColor: `${event.isAvailabilityEvent ? '#c5303c' : (isCancelled ? '#B4B4B4' : (isLocked ? '#000' : event?.session?.calendarColor))}`,
                }}
              >
              <Row type='flex' className='secondRow'>
                <Typography.Text className='sessionName'>
                  {event.isAvailabilityEvent ? `Unavailable - ${event.description}` : course?.description}
                </Typography.Text>
              </Row>  
              <Row type="flex" justify="space-between">
                <Col>
                  {isLocked && <LockOutlined style={{ marginRight: 4, fontSize: 11 }} />}
                  {isPendingReview && <WarningOutlined style={{ marginRight: 4, fontSize: 11 }} />}
                  <Typography.Text
                    ellipsis={true}
                    className="dateLabel">
                    {moment(this.props.event.start, 'hh:mm A').format('hh:mm A')}
                    {' - '}
                    {moment(this.props.event.end, 'hh:mm A').format('hh:mm A')}
                  </Typography.Text>
                </Col>

                {event.isAvailabilityEvent && (
                  <div onClick={e => e.stopPropagation()}>
                    <Popconfirm
                      title="Remove entry"
                      description="Do you want to remove this entry?"
                      onConfirm={handleDelete}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button type="button" className="deleteButton">
                        <DeleteOutlined />
                      </button>
                    </Popconfirm>
                  </div>
                )}
              </Row>
            </div>
          </div>
        </Wrapper>
      </EventWrapper>
    );
  }

  // Private methods

  _getStyle() {
    if (!this.props.style) {
      return {};
    }

    return {
      top: `${this.props.style.top}%`,
      left: `${this.props.style.xOffset}%`,
      width: `${this.props.style.width}%`,
      height: `${this.props.style.height}%`,
    };
  }

  _getPopoverTitle() {
    const { metadata: { handleEditAttendance } } = this.props.event;

    return (
      <Row
        type="flex"
        justify="space-between"
        align="middle"
      >
        <Col>Session Information</Col>
        <Col>
          <Button
            size="small"
            type="secondary"
            style={{ lineHeight: '.6', padding: '8px', height: '30px' }}
            onClick={handleEditAttendance}
          >
            <UserOutlined /> Edit Attendance
          </Button>
        </Col>
      </Row>
    );
  }

  _getPopoverContent() {
    const { metadata: { app, event: { session } } } = this.props.event;
    
    if (!session) return null;

    const isLocked = session.state == Globals.Session_State.LOCKED;
    const course = app.sharedCache().getCourseByID(session.courseID, null, session?.tenantID);
    const venue = app.sharedCache().getVenueByID(session?.venueID);
    const city = app.sharedCache().getCityByID(venue?.cityID);

    return (
      <Descriptions size="small" column={2} style={{ width: '440px' }}>
        {isLocked && (
          <Descriptions.Item span={2} label="State">
            <strong>{Globals.Session_State.LOCKED}</strong>
          </Descriptions.Item>
        )}

        {isLocked && session.lockReason && (
          <Descriptions.Item span={2} label="Lock Reason">
            {session.lockReason}
          </Descriptions.Item>
        )}

        <Descriptions.Item span={2} label="Internal Name">{session.internalName}</Descriptions.Item>
        <Descriptions.Item span={2} label="Display Name">{session.name}</Descriptions.Item>
        <Descriptions.Item span={2} label="Course">{course?.description}</Descriptions.Item>
        <Descriptions.Item span={2} label="Type">{Globals.getTemplateTypeIcon(session.type, true)}</Descriptions.Item>
        <Descriptions.Item span={2} label="State">
          <Tag color={Globals.getColorBySessionState(session.state)}>
            {Globals.getTextBySessionState(session.state)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Location">{venue?.address?.street1}{city ? `, ${city.name}` : ''}</Descriptions.Item>
        <Descriptions.Item span={2} label="Capacity">{session.capacity < 0 ? 'Unlimited' : session.capacity}</Descriptions.Item>
        <Descriptions.Item span={2} label="Published">{session.isEventPublished ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item span={2} label="Dates">
          {(session.startDate || []).map((s, i) => (
            <React.Fragment key={i}>
              {moment(new Date(s)).format(`${Globals.DefaultDateFormat} hh:mm A`)} - {moment(new Date(session.endDate?.[i])).format('hh:mm A')}
              <br />
            </React.Fragment>
          ))}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Created On">
          {Utils.getDateAndTimeOnUIFormatByTimestamp(session.createdOn)}
        </Descriptions.Item>
      </Descriptions>
    );
  }
}
