import React from "react";
import { Breadcrumb, Menu } from "antd";
import { Link } from "react-router-dom";
import { matchPath } from "react-router";
import autoBind from "react-autobind";
//Icons
import { IoIosSettings, IoIosBusiness, IoIosMail } from "react-icons/io";
import { LuContainer } from "react-icons/lu";
//Components
import CustomRoute from "@/ui-components/CustomRoute";
import BaseApp from "@/components/subcomponents/AppsManager/BaseApp";
import Globals from "@/config/Globals";
//App Routes
import AdminCustomerView from "./adminSubviews/AdminCustomerView";
import AdminTenantsView from "./adminSubviews/AdminTenantsView";
import AdminTenantView from "./adminSubviews/AdminTenantView";
import AdminEmailLogView from "./adminSubviews/AdminEmailLogView";
import AdminVaultsView from "./adminSubviews/AdminVaultsView";
import AdminVaultView from "./adminSubviews/AdminVaultView";
//Config
import config from "@/config/config";
import AdminEmailSuppresionView from "./adminSubviews/AdminEmailSuppressionView";
//
export default class ConfigApp extends BaseApp {
  constructor(app) {
    super(Globals.MiniApps.CONFIG, app);
    autoBind(this);
    console.log("ConfigApp", this);
  }
  /* Overrides */
  get isEnabled() {
    console.log("Roles are");
    console.log(this.app.isRoot(), "isRoot");
    console.log(this.app.isAdmin(), "isAdmin");

    return this.app.isRoot() || this.app.isAdmin();
  }
  get name() {
    return "Platform Configuration Management";
  }
  get description() {
    return "Customer, tenants, certifications and other type of configuration for the LMS platform.";
  }
  get routes() {
    if (this.app.isRoot() || this.app.isAdmin()) {
      return [
        <CustomRoute key={config.ApplicationRoutes.config_tenants} path={config.ApplicationRoutes.config_tenants} exact component={AdminTenantsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.config_tenant} path={config.ApplicationRoutes.config_tenant} exact component={AdminTenantView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.config_customer} path={config.ApplicationRoutes.config_customer} exact component={AdminCustomerView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.config_emails} path={config.ApplicationRoutes.config_emails} exact component={AdminEmailLogView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.config_vaults} path={config.ApplicationRoutes.config_vaults} exact component={AdminVaultsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.config_vault} path={config.ApplicationRoutes.config_vault} exact component={AdminVaultView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.config_email_suppression} path={config.ApplicationRoutes.config_email_suppression} exact component={AdminEmailSuppresionView} appRef={this.app} />,
      ];
    }
    return [];
  }

  get dashboardOptions() {
    if (this.app.isRoot() || this.app.isAdmin()) {
      return [
        {
          key: "config_1",
          name: "Customer",
          Icon: IoIosSettings,
          description: "Current customer configurations",
          route: config.ApplicationRoutes.config_customer,
          hasPermission: true /* TBD */,
        },
        {
          key: "config_2",
          name: "Tenants",
          Icon: IoIosBusiness,
          description: "View and manager tenants from this customer",
          route: config.ApplicationRoutes.config_tenants,
          hasPermission: true /* TBD */,
        },
        {
          key: "config_3",
          name: "Email log",
          Icon: IoIosMail,
          description: "Search emails sent from the platform",
          route: config.ApplicationRoutes.config_emails,
          hasPermission: false /* TBD */,
        },
        {
          key: "config_4",
          name: "Email suppression",
          Icon: IoIosMail,
          description: "Manages the list of email addresses with delivery supressed",
          route: config.ApplicationRoutes.config_email_suppression,
          hasPermission: false /* TBD */,
        },
        {
          key: "config_5",
          name: "Vaults",
          Icon: LuContainer,
          description: "Manage document storage vaults",
          route: config.ApplicationRoutes.config_vaults,
          hasPermission: this.app.isRoot() && !process.env.REACT_APP_OFFLINE || process.env.NODE_ENV == "development",
        },
      ];
    }
    return null;
  }
  //navigation items
  getNavigationOption(path, values) {
    //Top level
    if (matchPath(path, config.ApplicationRoutes.config_customer)) return [this._renderNavigationMainLevel(0)];
    else if (matchPath(path, config.ApplicationRoutes.config_tenants)) return [this._renderNavigationMainLevel(1)];
    else if (matchPath(path, config.ApplicationRoutes.config_emails)) return [this._renderNavigationMainLevel(2)];
    else if (matchPath(path, config.ApplicationRoutes.config_email_suppression)) return [this._renderNavigationMainLevel(3)];
    else if (matchPath(path, config.ApplicationRoutes.config_vaults)) return [this._renderNavigationMainLevel(4)];
    //Tenant detail
    else if (matchPath(path, config.ApplicationRoutes.config_tenant)) {
      return [
        this._renderNavigationMainLevel(1),
        <Breadcrumb.Item key="config_tenantBC" onClick={this._openLink.bind(this, config.ApplicationRoutes.config_tenant, null, values.tenantID)}>
          <Link to={this._parseUrl(config.ApplicationRoutes.config_tenant, null, values.tenantID)}> {values.tenantName || "Tenant"} </Link>
        </Breadcrumb.Item>,
      ];
    }
    //Vault detail
    else if (matchPath(path, config.ApplicationRoutes.config_vault)) {
      return [
        this._renderNavigationMainLevel(4),
        <Breadcrumb.Item key="config_vaultBC" onClick={this._openLink.bind(this, config.ApplicationRoutes.config_vault, null, values.vaultID)}>
          <Link to={this._parseUrl(config.ApplicationRoutes.config_vault, null, values.vaultID)}> {"Vault"} </Link>
        </Breadcrumb.Item>,
      ];
    }
    return null;
  }

  //Private UI
  _renderNavigationMainLevel(type) {
    //Check for selected third level option
    let option = null;
    if (type == 0 /* customer */) {
      option = {
        displayName: "Customer",
        link: this._parseUrl(config.ApplicationRoutes.config_customer),
        raw: config.ApplicationRoutes.config_customer,
        icon: <IoIosSettings style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    } else if (type == 1 /* tenant */) {
      option = {
        displayName: "Tenants",
        link: this._parseUrl(config.ApplicationRoutes.config_tenants),
        raw: config.ApplicationRoutes.config_tenants,
        icon: <IoIosBusiness style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    } else if (type == 2 /* email log */) {
      option = {
        displayName: "Email Log",
        link: this._parseUrl(config.ApplicationRoutes.config_tenants),
        raw: config.ApplicationRoutes.config_emails,
        icon: <IoIosMail style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    } else if (type == 3 /* email suppression */) {
      option = {
        displayName: "Email Suppression",
        link: this._parseUrl(config.ApplicationRoutes.config_tenants),
        raw: config.ApplicationRoutes.config_email_suppression,
        icon: <IoIosMail style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    } else if (type == 4 /* vaults */) {
      option = {
        displayName: "Vaults",
        link: this._parseUrl(config.ApplicationRoutes.config_vaults),
        raw: config.ApplicationRoutes.config_vaults,
        icon: <LuContainer style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    }
    //should be handled above, but it's here to support new options without showing wrong information
    if (!option) return <></>;
    //Render
    const items = [];
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.config_customer, null, null)}> Customer </Link>, key: config.ApplicationRoutes.config_customer, icon: <IoIosSettings /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.config_tenants, null, null)}> Tenants </Link>, key: config.ApplicationRoutes.config_tenants, icon: <IoIosBusiness /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.config_emails, null, null)}> Email Log </Link>, key: config.ApplicationRoutes.config_tenants, icon: <IoIosMail /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.config_vaults, null, null)}> Vaults </Link>, key: config.ApplicationRoutes.config_vaults, icon: <LuContainer /> });
    items.push({
      label: <Link to={this._parseUrl(config.ApplicationRoutes.config_email_suppression, null, null)}> Email Suppression </Link>,
      key: config.ApplicationRoutes.config_tenants,
      icon: <IoIosMail />,
    });
    const menu = <Menu items={items} />;
    return (
      <Breadcrumb.Item key="config_mainBC" overlay={menu} onClick={this._openLink.bind(this, option.raw, null, null)}>
        {" "}
        <Link to={option.link}>
          {option.icon}
          {option.displayName}
        </Link>{" "}
      </Breadcrumb.Item>
    );
  }
}
