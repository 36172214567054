import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Row, Col, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import scrollIntoView from 'scroll-into-view';
//
import Utils from '@/components/helpers/Utils';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonSimpleSearchBar from '../commonComponents/CommonSimpleSearchBar';
import CommonOrganizationManagersTable from '../commonComponents/CommonOrganizationManagersTable';
import AdminOrganizationOptions, { AdminOrganizationOptionTypes } from '../commonComponents/AdminOrganizationOptions';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminOrganizationManagersView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      orgID: this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this),
      orgName: this.props.app.urlManager.getQueryParam('n', this) || 'Company',
      managers: [], tenants: []
    };
    //
    this.viewOptions = new AdminOrganizationOptions(AdminOrganizationOptionTypes.MANAGERS, this.state.orgID, this.props.app, this.state.orgName);
  }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    //set navigation items details
    this.props.app.appsManager?.navigation?.appendValue('orgID', this.state.orgID);
    this.props.app.appsManager?.navigation?.appendValue('orgName', this.state.orgName);
    //load data
    this._fetchData();
  }
  //Search
  handleSearch(searchTerm) { this.setState({ searchTerm }); }
  //Manager actions
  handleManagerEdit(managerObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_manager, null, managerObj.managerID); }
  handleExportXLSX() { this._exportManagers(); }

  //UI
  render() {
    const { searchTerm } = this.state;
    //filter managers on runtime
    const managers = (this.state.managers || []).filter((c) => {
      return searchTerm && searchTerm.length > 0 ? 
          (c.tenants.map(tenant => this.state.tenants.find(t => t.id === tenant).organizationName).join(', ') || '').toLowerCase().includes(searchTerm) || 
          (`${c.firstName} ${c.lastName}` || '').toLowerCase().includes(searchTerm) ||
          (c.email || '').toLowerCase().includes(searchTerm) :
        true;
    });
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className='pageHeader' title={`${this.state.orgName} - Managers`} onBack={() => window.history.back()} extra={this.viewOptions.getOptions()}/>
          <Layout.Content className="pageSubContent">
            <CommonLoadingView isLoading={this.state.isLoading} />
              <Layout.Content>
                <Row type='flex' justify='space-between'>
                  <Col span={20}>
                    <CommonSimpleSearchBar isLoading={this.state.isLoading} onSearch={this.handleSearch} 
                      {...Utils.propagateRef(this, 'searchBar')} app={this.props.app} placeholder="Search by manager app, name or email"/>
                  </Col>
                  <Col>
                    <Button key="1" type="primary" icon={<DownloadOutlined />} onClick={this.handleExportXLSX}
                      disabled={managers?.length < 1} style={{ marginLeft: 20, marginRight: -4 }}> Export to XLSX </Button>
                  </Col>
                </Row>
                <CommonOrganizationManagersTable app={this.props.app} isLoading={this.state.isLoading}
                    tenants={this.state.tenants} managers={managers} onManagerEdit={this.handleManagerEdit}/>
              </Layout.Content>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* remote data */
  async _fetchData() {
    if (this.state.isLoading) return;
    this.startLoading();
    //request
    const resp = await this.props.app.organization.organizationApp.getOrganizationAppsByOrgID(this.state.orgID);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.orgs && resp.body.tenants) {
      let managers = [];
      let tenants = resp.body.tenants;
      for (const oa of resp.body.orgs) {
        if (Array.isArray(oa.managers) && oa.managers.length > 0) {
          for (const m of oa.managers) {
            const i = managers.findIndex(manager => manager.id === m.id);
            if (i !== -1) managers[i].tenants.push(oa.tenantID);
            else managers.push({ ...m, tenants: [oa.tenantID] });
          }
        }
      }
      this.setState({ managers, tenants, isLoading: false });
      scrollIntoView(document.getElementById('root'), { time: 500, align: { top: 0 } });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _exportManagers() {
    this.startLoading();
    //request
    const resp = await this.props.app.organization.organizationApp.exportOrganizationManagersByOrgID(this.state.orgID);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body) Utils.downloadBlob(resp.body, 'org-managers', 'xlsx');
    else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.stopLoading();
  }
}
