import React from 'react';
import autoBind from 'react-autobind';
import { Button, Table, Tooltip, Popconfirm } from 'antd';
//icons
import { EditOutlined, ToolOutlined, SolutionOutlined } from '@ant-design/icons';
//
import Globals from '@/config/Globals'
import Utils from '@/components/helpers/Utils'
//
import CommonSetPasswordModal from '@/views/commonComponents/CommonSetPasswordModal';
import CommonOrganizationSelectionModal from './CommonOrganizationSelectionModal';
//props are: app, isLoading, total, currentPage, sortedInfo, employees, onPageChange, onFilterChange, onEmployeeEdit, onEmployerSet
export default class CommonEmployeesTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { user: {} };
  }
    //StudentActions
  handleStudentPasswordSet(userObj) { this.changePassModal.handleShow(userObj.id); }
  handleEmployerSelection(userObj) {
    if (this.selectionModal) {
      this.setState({ user: userObj });
      this.selectionModal.show(); 
    }
  }
  handleStudentEmployerSet(orgObj) { this.props.onEmployerSet({ org: orgObj, user: this.state.user }); }
    //
  render() {
    let { sortedInfo, hideActions } = this.props;
    sortedInfo = sortedInfo || {};
    
    const width = (w) => hideActions ? {} : { width: w };
   
    const columns = [
      { title: 'Name', key: 'name', ...(width('30%')), sorter: true,
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: props => props.firstName+' '+props.lastName || 'N/A', },
      { title: 'Email', key: 'email.keyword', ...(width('25%')), sorter: true,
        sortOrder: sortedInfo.columnKey === 'email.keyword' && sortedInfo.order,
        render: props => props.email || 'N/A', },
      { title: 'Employer', key: 'orgID', ...(width('25%')), sorter: true,
        sorter: (a, b) => {
          if (!this.props.companies) return (a.orgID || '').localeCompare((b.orgID || ''));
          const orgA = (this.props.companies || []).find((o) => o.id == a.orgID);
          const orgB = (this.props.companies || []).find((o) => o.id == b.orgID);
          return (orgA?.name || '').toLowerCase().localeCompare((orgB?.name || '').toLowerCase());
        },
        sortOrder: sortedInfo.columnKey === 'orgID' && sortedInfo.order,
        render: props => {
          if (!props.orgID) return '-';
          if (!this.props.companies) return 'Loading...';
          const org = (this.props.companies || []).find((o) => o.id == props.orgID);
          return org?.name || '-';
        }
      },
      (hideActions ? {} : { title: 'Actions', width: '10%', key: 'Actions', align: 'center',
        render: props => (
        <span className='tableButtonContainer'>
          <Tooltip placement="bottomLeft" title='Set employer'>
            <Popconfirm title={`Do you really want to set the student '${props.firstName + ' ' + props.lastName}' a new employer?`} placement="bottomRight" onConfirm={this.handleEmployerSelection.bind(this, props)} okText="Yes" cancelText="No">
              <Button variant="none" icon={<SolutionOutlined />} shape="circle" />
            </Popconfirm>
          </Tooltip>{' '}
          <Tooltip placement="bottomLeft" title='Set password'>
            <Popconfirm title={`Do you really want to set the student '${props.firstName + ' ' + props.lastName}' a new password?`} placement="bottomRight" onConfirm={this.handleStudentPasswordSet.bind(this, props)} okText="Yes" cancelText="No">
              <Button variant="none" icon={<ToolOutlined />} shape="circle" />
            </Popconfirm>
          </Tooltip>{' '}
          <Tooltip placement="bottomLeft" title="Edit">
            <Button
              variant="none"
              icon={<EditOutlined />}
              shape="circle"
              onClick={this.props.onEmployeeEdit.bind(this,props)}
            />
          </Tooltip>
        </span>
      ),
      })
    ];
    const props = { rowKey: 'id', loading: this.props.isLoading, onChange: this.props.onFilterChange,
                    locale: {emptyText: 'No employees found!'}, sortDirections: ['ascend', 'descend', 'ascend'],
                    pagination: {
                      pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ],
                      total: this.props.total, onChange: this.props.onPageChange, current: this.props.currentPage,
                      showSizeChanger: false, showTotal: (total, range) => <div style={{marginRight: 20}}>{`${range[0]}-${range[1]} of ${total} students`}</div>
                    }};
    return (
      <>
        <CommonSetPasswordModal ref={f => (this.changePassModal = f)} app={this.props.app} hideAdvancedOptions/>
        <CommonOrganizationSelectionModal app={this.props.app} requiresConfirmation
          allowOrgsListing={this.props.isAdmin} {...Utils.propagateRef(this, 'selectionModal')} onSelection={this.handleStudentEmployerSet}/>
        <Table className="employeesTable" columns={columns} dataSource={this.props.employees} {...props}/>
      </>
    );
  }
}