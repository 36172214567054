import React from "react";
import autoBind from 'react-autobind';
import { Layout, Button, PageHeader } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
//
import AdminClassificationUnitForm from "./Forms/AdminClassificationUnitForm";
//
export default class AdminClassificationUnitView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: {}, isLoading: false };
    this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) == Globals.URL_NewIndentifier);;
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Company Classification units`;
    //Check for id
    if (!this.isNew) this._fetchData();
  }

  //Actions handler
  async handleSubmit() {
    //validate and retrieve form data
    const formData = await this.form.validateFields();
    if (!formData) return;
    //make operation
    if (this.isNew) this._addCU(formData);
    else this._saveCU(formData);
  }
  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <PageHeader className='pageHeader' onBack={() => window.history.back()} title={this.isNew ? 'New Classification Unit' : `Classification Unit - ${(this.state.data?.id || 'Loading...')}`}
          extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create Classification Unit' : 'Save Classification Unit'}</Button>]}/>
        <Layout.Content>
          <AdminClassificationUnitForm isNew={this.isNew} classificationUnit={this.state.data} {...Utils.propagateRef(this, 'form')}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /*Private methods*/
  async _addCU(data) {
    this.startLoading();
    const addResp = await this.props.app.organization.classificationUnit.createClassificationUnit(data.id, data.description);
    if (!this._isMounted) return;
    if (addResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Classification Unit created with success!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_cu_cu, null, encodeURIComponent(data.id));
      this.props.app.appsManager?.navigation?.appendValue('cuID', data.id);
      this._fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, addResp);
      this.stopLoading();
    }
  }
  async _saveCU(data) {
    this.startLoading();
    const updateResp = await this.props.app.organization.classificationUnit.updateClassificationUnit(data.id, data.description);
    if (!this._isMounted) return;
    if (updateResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Classification updated with success!");
      this._fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
  async _fetchData() {
    this.startLoading();
    const cuID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const cuResp = await this.props.app.organization.classificationUnit.getClassificationUnit(cuID);
    if (!this._isMounted) return;
    if (cuResp.statusCode == 200 && cuResp.body.id) { //valid data state
      const data = cuResp.body;
      this.setState({ data, isLoading: false });
      //set navigation items details
      this.props.app.appsManager?.navigation?.appendValue('cuID', cuID);
    }else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, cuResp);
      this.setState({ data: {}, isLoading: false });
    }
  }
}
