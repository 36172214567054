import React from "react";
import Cache from "./subcomponents/Cache";
import IDM from "@ikonintegration/idmclient";
import LMSAPI from "@ikonintegration/lms-api-client";
import OrgAPI from "@ikonintegration/mod-organization-client";
import SMConfig from "@ikonintegration/mod-config-client";
import SMClassroom from "@ikonintegration/mod-classroom-client";
import { Api as SMEmailLog } from "@ikonintegration/ses-bounce-reporter";
import { Api as IKFileService } from "@ikonintegration/ik-fileservice";
import LogRocket from "logrocket";
//
import Utils from "./helpers/Utils";
import AlertController from "./subcomponents/AlertController";
import URLManager from "./subcomponents/URLManager";
import ConfigManager from "./subcomponents/ConfigManager";
import AppsManager from "./subcomponents/AppsManager";
//
import config from "./../config/config";
import packageJSON from "./../../../package.json";
import Globals from "./../config/Globals";
import ThemeManager from "./subcomponents/ThemeManager";
//
class Authenticator extends React.Component {
  constructor(props) {
    super(props);
    console.debug(
      `BCCSA LMS Auth ui - version: ${packageJSON.version}@${process.env.COMMIT_HASH}`
    );
    //
    this._sharedCache = null;
    this.alertController = null; //ref
    //comps
    this.urlManager = new URLManager(this);
    this.themeManager = new ThemeManager(this);
    this.configManager = new ConfigManager(
      this,
      this.configManagerDidBecomeAvailable.bind(this),
      this.configManagerFailedToBecomeAvailable.bind(this)
    );
    this.appsManager = new AppsManager(this);
    //IDM
    const {
      idmKey,
      idmSecret,
      orgAdminRole,
      orgSubAdminRole,
      userRole,
      adminRole,
      idmExternalCookiesDomain,
      idmExternalAuthDomain,
      orgModEndpoint,
      instructorRole,
    } = this.themeManager.theme;
    this.idm = new IDM(idmKey, idmSecret, {
      ...config.IDMClientOptions,
      roles: {
        ...config.IDMClientOptions.roles,
        USER: userRole,
        ADMIN: adminRole,
        ORG_ADMIN: orgAdminRole,
        ORG_SUBADMIN: orgSubAdminRole,
        INSTRUCTOR: instructorRole,
      },
      externalAuthDomain: idmExternalAuthDomain,
      cookiesEndpoint: idmExternalCookiesDomain,
      autoEnrollRole: [config.IDMClientOptions.roles.IDM_USER, userRole],
    });
    this.idm.sessionLoadedHandler = this.sessionLoadedHandler.bind(this);
    //retrieve customer/tenant infos
    this.customerID = this.themeManager.theme.customerID;
    this.tenantID =
      this.idm?.impersonate?.getImpersonatingExtraContext()?.tenantID ||
      this.themeManager.theme.forceTenantID;
    //more comps
    this.api = new LMSAPI({
      endpoint: config.ApplicationAPIEndpoint,
      statsEndpoint: null,
      authorizationToken: this._getIDMToken.bind(this),
      tenantID: this.tenantID,
    });
    this.organization = new OrgAPI({
      endpoint: orgModEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      customerID: this.customerID,
    });
    this.config = new SMConfig({
      endpoint: config.ApplicationConfigAPIEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      customerID: this.customerID,
    });
    this.classroom = new SMClassroom({
      endpoint: config.SMClassroomEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      customerID: this.customerID,
    });
    this.emailLog = new SMEmailLog({
      baseURL: config.SMEmailLogEndpoint,
    });
    //
    this.ikFileService = new IKFileService({
      baseURL: config.IKFileServiceEndpoint,
    });
    const getToken = this._getIDMToken.bind(this);
    const clientID = this.customerID;
    this.ikFileService.instance.interceptors.request.use(
      async function (options) {
        const token = await getToken();
        if (token) options.headers["Authorization"] = token;
        return options;
      },
      function (error) {
        console.log("request error: ", error);
        return Promise.reject(error);
      }
    );
    this.emailLog.instance.interceptors.request.use(
      async function (options) {
        const token = await getToken();
        if (clientID) options.headers["clientID"] = clientID;
        if (token) options.headers["Authorization"] = token;
        return options;
      },
      function (error) {
        console.log("request error: ", error);
        return Promise.reject(error);
      }
    );
    //Behaviour detection
    this.onGoingBehaviour = {
      isAuthenticating: false,
      changingPassword: this.urlManager.getQueryParam(
        Globals.URLQueryParam_ChangePassword
      ),
      confirmingAccount: this.urlManager.getQueryParam(
        Globals.URLQueryParam_RegConfirmationCode
      ),
    };
  }
  //Lifecycle
  async componentDidMount() {
    this.startLoading(true);
    this.onGoingBehaviour.redirectMode =
      !!this.idm.impersonate.isImpersonatingCBURL();
    await this.configManager.detectConfig();
  }

  /* public */
  //Shortcuts on application levels
  isRoot() {
    return this.idm.session.authorization
      ? this.idm.session.authorization.hasClaim("ROOT")
      : false;
  }
  isAdmin() {
    return this.idm.session.authorization
      ? this.idm.session.authorization.hasClaim("ADMIN")
      : false;
  }
  isUser() {
    return this.idm.session.authorization
      ? this.idm.session.authorization.hasClaim("USER")
      : false;
  }
  isOrgAdmin() {
    return this.idm.session.authorization
      ? this.idm.session.authorization.hasClaim("ORG_ADMIN")
      : false;
  }
  isOrgSubAdmin() {
    return this.idm.session.authorization
      ? this.idm.session.authorization.hasClaim("ORG_SUBADMIN")
      : false;
  }
  isInstructor() {
    return this.idm.session.authorization
      ? this.idm.session.authorization.hasClaim("INSTRUCTOR")
      : false;
  }
  getAuthorizedUserID() {
    return this.idm?.session?.authorization?.getUserID();
  }
  //
  getRegistrationObject() {
    const regObject =
      this.configManager.launchConfig?.tenant?.customRegistration ||
      this.themeManager.theme.registration;
    return regObject;
  }
  //Loading
  startLoading(reload) {
    this.onGoingBehaviour.isAuthenticating = true;
    if (reload) this.forceUpdate();
  }
  endLoading(reload) {
    this.onGoingBehaviour.isAuthenticating = false;
    if (reload) this.forceUpdate();
  }
  //Singleton
  sharedCache() {
    if (this._sharedCache == null) {
      this._sharedCache = new Cache(this);
    }
    return this._sharedCache;
  }

  /* Delegate/callbacks */
  // IDM delegate calls
  async sessionLoadedHandler() {
    //session will be loaded by this.idm.load or by login call, both should be
    //loading while this is called, so we dont update state when starting loading for session load
    //reloading state will cause authorized area to be called while we haven't fully loaded the session
    this.startLoading(false);
    this._identifyUser();
    if (this.onGoingBehaviour.redirectMode)
      console.log(
        "Not loading application cache due redirect nature of the authentication."
      );
    else await this._loadCache();
    // this.urlManager.pushPage(config.ApplicationRoutes.homepage);
    this.endLoading(true);
  }
  // ConfigManager delegate calls
  async configManagerDidBecomeAvailable() {
    this.startLoading(true); //should be already on loading state, ask rerender if this is the case
    if (!this.idm.isLogged()) await this.idm.load(); // ask for revalidation if have session - will redirect
    if (!this.idm.isLogged()) this.endLoading(true); //if we kept logged out, stop loading
  }
  configManagerFailedToBecomeAvailable() {
    /* not handling this due render logic that already handle equally all unavailability cases */
    this.startLoading(true); //should be already on loading state, ask rerender if this is the case
  }

  //UI
  render() {
    const fullyAuthorized =
      this.idm.isLogged() &&
      !this.onGoingBehaviour.isAuthenticating &&
      !this.onGoingBehaviour.redirectMode;
    return (
      <>
        <AlertController {...Utils.propagateRef(this, "alertController")} />
        {this.configManager.isAvailable()
          ? // Launch config available
          fullyAuthorized
            ? this.renderAuthorizedView()
            : this.renderUnauthorizedView()
          : // Launch config not available (due error or still loading)
          this.renderLaunchConfigUnavailableView()}
      </>
    );
  }

  /* private */
  async _identifyUser() {
    if (process.env.REACT_APP_OFFLINE) return;
    //Identify
    const userObj = await this.idm.session.data.getUserObject();
    const userID = this.getAuthorizedUserID();
    if (!userID) return;

    // Identify the user with LogRocket
    LogRocket.identify(userObj.id, {
      userType: this.isAdmin() ? "Admin" : "User",
      firstName: userObj.firstName,
      lastName: userObj.lastName,
      email: userObj.email,
      application: "BCCSA Portal",
    });
  }
  async _loadCache() {
    //Check if could load cache properly, if not we can't proceed :/
    //Since the user has already logged it worth trying 2/3 times so
    //we dont loose user retetion :p
    let retries = 0;
    const maxAttempts = 3;

    while (retries < maxAttempts) {
      if (await this.sharedCache().loadCache()) break;
      await Utils.shortRandSleep();
      retries++;
    }

    //Check if reach max attempts
    if (maxAttempts == retries) {
      await this.idm.session.clearSession(); //light logout
      this.sharedCache().clearCache();
      if (this.sessionDidFailLoadHandler) this.sessionDidFailLoadHandler();
    } else {
      if (this.sessionDidLoadHandler) this.sessionDidLoadHandler();
    }
  }

  /* helpers */
  async _getIDMToken() {
    if (this.idm.session.authorization) {
      const token = await this.idm.session.getToken(true);
      return `Bearer ${token}`;
    }
    return null;
  }
}

export default Authenticator;
