import React from "react";
import autoBind from 'react-autobind';
import { Modal, Row, Col, Button } from 'antd';
//
import Utils from '@/components/helpers/Utils';
//
import CommonRegistrationListFieldValueForm from '../Forms/CommonRegistrationListFieldValueForm';

//props are: onCreate
export default class CommonRegistrationListFieldValueModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false };
  }
  //Public
  show() { this.setState({ isVisible: true }); }
  //Private actions
  handleClose() {
    if (this.form) this.form?.form?.resetFields();
    this.setState({ isVisible: false });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    this.props.onCreate(formData);
    this.handleClose();
  }

  //UI
  render() {
    return (
      <Modal open={this.state.isVisible} title="Create List Field Value"
              placement="right" onCancel={this.handleClose} footer={null} width={700}>
        <Row type='flex' justify='end'>
          <Col> <Button type="primary" onClick={this.handleSubmit}>{'Create'}</Button> </Col>
        </Row>
        <CommonRegistrationListFieldValueForm {...Utils.propagateRef(this, 'form')}/>
      </Modal>
    );
  }
}
