import React from "react";
import autoBind from 'react-autobind';
import { Button, Divider, Layout, message, Row, Table, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import CustomComponent from '@/ui-components/CustomComponent';

import CommonLoadingView from "../commonComponents/CommonLoadingView";
import CommonSessionsDateRangeSelector from "../commonComponents/CommonSessionsDateRangeSelector";
import TabViewController from "../commonComponents/TabViewController";
import CommonWeeklyCalendar from "../commonComponents/calendar/CommonWeeklyCalendar";
import CommonMonthlyCalendar from "../commonComponents/calendar/CommonMonthlyCalendar";
import moment from "moment";
import Utils from "@/components/helpers/Utils";
import Globals from "@/config/Globals";
import WhiteBox from "../commonComponents/WhiteBox";
import config from "@/config/config";

export default class InstructorCalendarView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isTimeEntryModalOpen: false,
      isLoading: false,
      from: null,
      to: null,
      dateType: 1,
      events: [],
      sortedInfo: {
        columnKey: 'courseName',
        order: 'ascend',
      },
    };

    this.tabs = [
      {
        component: () => (
          <CommonWeeklyCalendar
            from={this.state.from ? moment(this.state.from) : moment()}
            to={this.state.to ? moment(this.state.to) : moment()}
            events={this.state.events}
            onDeleteEvent={this.handleDeleteEvent}
            onEditEvent={this.handleEditEvent}
            app={this.props.app}
            onEditAttendance={this.handleEditAttendance}
          />
        ),
      },
      {
        component: () => (
          <CommonMonthlyCalendar
            from={this.state.from ? moment(this.state.from) : moment()}
            to={this.state.to ? moment(this.state.to) : moment()}
            events={this.state.events}
            onDeleteEvent={this.handleDeleteEvent}
            onEditEvent={this.handleEditEvent}
            app={this.props.app}
            onEditAttendance={this.handleEditAttendance}
          />
        ),
      },
    ];
  }

  componentDidMount() {
    document.title = `${this.props.app.themeManager.theme.applicationName} - My Calendar`;
  }

  handleDateChange(from, to, dateType) {
    this.setState({ from, to, dateType }, this._fetchData);
  }

  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  async handleDeleteEvent(startTime) {
    this.setState({ isLoading: true });

    const instructorID = this.props.app.getAuthorizedUserID();
    const resp = await this.props.app.classroom.instructorCalendar.deleteAvailabilityEvent(instructorID, startTime);

    if (resp.statusCode == 200) {
      message.success('Time entry successfully deleted!');
      this._fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }

  handleEditEvent(event) {
    this.sessionsDateRangeSelector.openEditModal(event);
  }

  handleEditAttendance({ sessionID }) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.instructor_edit_session_attendance, null, sessionID)
  }

  render() {
    return (
      <Layout.Content className="pageContent" style={{ paddingBottom: 24 }}>
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        
        <CommonSessionsDateRangeSelector
          anyRangeMode={false}
          app={this.props.app}
          onDateChange={this.handleDateChange.bind(this)}
          onTimeEntryAdded={this._fetchData}
          onTimeEntryEdited={this._fetchData}
          {...Utils.propagateRef(this, 'sessionsDateRangeSelector')}
        />

        <Layout.Content>
          <WhiteBox isSecondary>
            <TabViewController
              selectedIndex={(this.state.dateType || 1) - 1}
              config={this.tabs}
              parent={this}
              app={this.props.app}
            />
          </WhiteBox>
        </Layout.Content>

        <Divider />

        <Layout.Content>
          <Row type="flex" justify="start">
            {this._renderTableView()}
          </Row>
        </Layout.Content>
      </Layout.Content>
    )
  }

  _renderTableView() {
    const { sortedInfo } = this.state;

    const columns = [
      {
        title: 'Course',
        key: 'courseName',
        sorter: (a, b) => (a.courseName || String(!!a.isAvailabilityEvent))?.localeCompare((b.courseName || String(!!b.isAvailabilityEvent))),
        sortOrder: sortedInfo.columnKey === 'courseName' && sortedInfo.order,
        render: ({ isAvailabilityEvent, session }) => {
          if (isAvailabilityEvent) {
            return <Tag color="red">Unavailable</Tag>;
          } else {
            const course = (session?.courseID) ? this.props.app.sharedCache().getCourseByID(session?.courseID, null, session?.tenantID) : null;
            return course?.description || '-';
          }
        },
      },
      {
        title: 'Start time',
        key: 'startTime',
        render: ({ startTime }) => {
          if (startTime){
            return Utils.getDateAndTimeOnUIFormatByTimestamp(Number(startTime));
          } else {
            return '-';
          }
        },
        sorter: (a, b) => (a.startTime || 0) - (b.startTime || 0),
        sortOrder: sortedInfo.columnKey === 'startTime' && sortedInfo.order,
      },
      {
        title: 'End time',
        key: 'endTime',
        render: ({ endTime }) => {
          if (endTime){
            return Utils.getDateAndTimeOnUIFormatByTimestamp(Number(endTime));
          } else {
            return '-';
          }
        },
        sorter: (a, b) => (a.endTime || 0) - (b.endTime || 0),
        sortOrder: sortedInfo.columnKey === 'endTime' && sortedInfo.order,
      },
      {
        title: 'State',
        key: 'state',
        render: ({ session }) => {
          if (!session?.state) return '-';
          return <Tag color={Globals.getColorBySessionState(session.state)}>{Globals.getTextBySessionState(session.state)}</Tag>;
        },
        sorter: (a, b) => (a.endTime || 0) - (b.endTime || 0),
        sortOrder: sortedInfo.columnKey === 'endTime' && sortedInfo.order,
      },
      {
        title: '',
        key: 'actions',
        render: (session) => {
          if (session.isAvailabilityEvent || (session.session.state != Globals.Session_State.AVAILABLE && session.session.state != Globals.Session_State.PENDING_INSTRUCTOR)) {
            return null;
          }
          return (
            <Button type="primary" onClick={this.handleEditAttendance.bind(this, session)}>
              <UserOutlined /> Edit Attendance
            </Button>
          );
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={this.state.events}
        rowKey="startTime"
        loading={this.state.isLoading}
        onChange={this.handleFilterChange}
        locale={{ emptyText: 'No events found!' }}
        style={{ width: '100%', marginBottom: 40 }}
        pagination={{
          pageSize: Globals.Table_PagingItemsPerPage,
          hideOnSinglePage: true,
          showSizeChanger: false,
          position: ['bottomCenter'],
        }}
      />
    );
  }

  async _fetchData() {
    this.startLoading();
    const userID = this.props.app.getAuthorizedUserID();

    const [resp] = await Promise.all([
      this.props.app.classroom.instructor.getInstructorEvents(userID, {
        startTime: this.state.from,
        endTime: this.state.to,
        expandSessions: true,
      }),
      this.props.app.sharedCache().getVenues(),
      this.props.app.sharedCache().getCities(),
    ]);

    if (resp.statusCode == 200) {
      const events = resp.body.instructorCalendarEvents.map(event => ({
        ...event,
        isAvailabilityEvent: !event.sessionID,
        session: resp.body.sessions?.find(session => (
          session?.id === event.sessionID
        )),
      }));

      // Remove entries with null values from the "sessions" array
      if (resp.body.sessions && Array.isArray(resp.body.sessions)) {
        resp.body.sessions = resp.body.sessions.filter(session => session !== null);
      }
      const tenantIDs = [...new Set((resp.body.sessions ?? []).map(session => session.tenantID))]
       if (tenantIDs.length > 0) {
         await Promise.all(tenantIDs.map(tenantID => this.props.app.sharedCache().getTenantByID(tenantID) ))
       } 

      this.setState({ events });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }

    this.stopLoading();
  }
}
