import React from "react";
import autoBind from "react-autobind";
import { Table } from "antd";
//
import Globals from "@/config/Globals";
//props are: app, isLoading, locations, onLocationEdit
export default class CommonOrganizationLocationsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: {} };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      {
        title: "Application",
        key: "application",
        width: "15%",
        sorter: true,
        sorter: (a, b) => (a.tenant.name || "").toLowerCase().localeCompare((b.tenant.name || "").toLowerCase()),
        sortOrder: sortedInfo.columnKey === "application" && sortedInfo.order,
        render: (props) => props.tenant.name || "N/A",
      },
      {
        title: "Name",
        key: "name",
        width: "25%",
        sorter: true,
        sorter: (a, b) => (a.name || "").toLowerCase().localeCompare((b.name || "").toLowerCase()),
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
        render: (props) => props.name || "N/A",
      },
      {
        title: "Address",
        key: "address",
        width: "40%",
        sorter: true,
        sorter: (a, b) => (a.address.street.join(" - ") || "").toLowerCase().localeCompare((b.address.street.join(" - ") || "").toLowerCase()),
        sortOrder: sortedInfo.columnKey === "address" && sortedInfo.order,
        render: (props) => `${props.address.street.join(" - ")} - ${props.address.city} - ${props.address.province} - ${props.address.postalCode}` || "N/A",
      },
      {
        title: "Phone",
        key: "phone",
        width: "20%",
        sorter: true,
        sorter: (a, b) => (a.phone.label || "").toLowerCase().localeCompare((b.phone.label || "").toLowerCase()),
        sortOrder: sortedInfo.columnKey === "phone" && sortedInfo.order,
        render: (props) => props.phone.map((p) => `${p.label}: ${p.number}`).join(", ") || "N/A",
      },
    ];
    const props = {
      rowKey: "id",
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: "No locations found!" },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: ["bottomRight"] },
    };
    return <Table className="locationsTable" columns={columns} dataSource={this.props.locations} {...props} />;
  }
}
