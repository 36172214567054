import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col, Form, Input, Divider, Select, Button } from 'antd';
//

export default class CommonEmailSuppressionForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }  

  //UI
  render() {
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="email" label="Email Address">
              <Input placeholder="Enter email address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="reason" label="Reason">
              <Select placeholder="Select reason">
                <Select.Option value="BOUNCE">Bounce</Select.Option>
                <Select.Option value="COMPLAINT">Complaint</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

  
