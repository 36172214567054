import React from 'react';
import autoBind from 'react-autobind';
import { Typography, Select, DatePicker, Input, Space } from 'antd';
import moment from "moment";
//
import '@/stylesheets/org-app/CommonSearchBar.less';
import Globals from '@/config/Globals';

export default class CommonEmailLogSearchBar extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { eventType: ["Delivery"], dateFrom: 0, dateTo: 0, email: "" };
  }

  //UI
  render() { return (<div className="commonSearchBar"> {this._renderAdditionalFilters()} </div>); }

  /* sub renders */
  _renderAdditionalFilters() {
    const eventOptions = Object.keys(Globals.EventTypes).map(key => ({
      value: Globals.EventTypes[key],
      label: Globals.EventTypes[key]
    }));

    return (
      <div className="searchFilterRow">
        <Space>
          <Typography.Text strong>Event Type:</Typography.Text>
          <Select mode="multiple" style={{ minWidth: 300 }} placeholder="Filter by event type" defaultValue={['Delivery']} options={eventOptions} onChange={this._handleEventTypeChange} allowClear />
        </Space>
        <Space>
          <Typography.Text strong style={{ marginLeft: 16 }}>Date:</Typography.Text>
          <DatePicker.RangePicker style={{ minWidth: 300 }} onChange={this._handleDateChange} />
        </Space>
        <Space>
          <Typography.Text strong style={{ marginLeft: 16 }}>Email Address:</Typography.Text>
          <Input placeholder="Search by email" style={{ minWidth: 300 }} onChange={this._handleEmailAddressChange} />
        </Space>
      </div>
    );
  }

  /* Event handlers */
  _handleEventTypeChange(value) {
    this.setState({ eventType: value }, () => {
      this.props.onUpdateSearchObj(this.state);
    });
  }

  _handleDateChange(dates) {
    if (Array.isArray(dates) && dates.length === 2) {
      const [dateFrom, dateTo] = dates.map(date => date ? moment(date).valueOf() : null);
      if (dateFrom !== null && dateTo !== null) {
        this.setState({ dateFrom, dateTo }, () => {
          this.props.onUpdateSearchObj(this.state);
        });
      } else {
        console.error("Invalid dates provided:", dates);
      }
    } else {
      console.error("Invalid dates:", dates);
    }
  }

  _handleEmailAddressChange(event) {
    const { value } = event.target;
    this.setState({ email: value }, () => {
      this.props.onUpdateSearchObj(this.state);
    });
  }

  _handleSearch() {
    const { eventType, dateFrom, dateTo, email } = this.state;
    const searchObj = {
      eventType: eventType,
      dateFrom: dateFrom,
      dateTo: dateTo,
      email: email
    };
    this.props.onSearch(searchObj);
  }
}
