import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button } from 'antd';
import moment from 'moment';
//
import Utils from '@/components/helpers/Utils';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonSimpleSearchBar from '../commonComponents/CommonSimpleSearchBar';
import CommonOrgImportsTable from '../commonComponents/Table/CommonOrgImportsTable';
import CommonCreateImportJobModal from '../commonComponents/CommonCreateImportJobModal';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminOrgImportsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, searchTerm: '' };
  }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    this._fetchJobs();
  }
    //Search
  handleSearch(searchTerm) { this.setState({ searchTerm }); }
    //Org actions
  handleJobCreate() { this.uploadModal.show(); }
  handleJobView(obj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_fileImport_job, null, obj.id); }
  handleJobDelete(obj) { this._deleteJob(obj.id); }
  handleFileDownload(obj) { this._downloadFile(obj); }
  handleLogDownload(obj) { this._downloadLogs(obj, false); }
  handleWandesDownload(obj) { this._downloadLogs(obj, true); }
  //UI
  render() {
    const { searchTerm } = this.state;
    //filter data on runtime
    const filteredData = (this.state.data || []).filter((c) => {
      return searchTerm && searchTerm.length > 0 ? 
        c.id.toLowerCase().includes(searchTerm) || (c.description || '').toLowerCase().includes(searchTerm) :
        true;
    });
    //
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader className='pageHeader' title="File Imports"
            extra={[<Button key="1" onClick={this.handleJobCreate} type='primary'>Create new import</Button>]}/>
        <CommonCreateImportJobModal app={this.props.app} onCompleted={this._fetchJobs} {...Utils.propagateRef(this, 'uploadModal')}/>
        <Layout.Content>
          <CommonSimpleSearchBar isLoading={this.state.isLoading} onSearch={this.handleSearch} 
              {...Utils.propagateRef(this, 'searchBar')} app={this.props.app} placeholder="Search by description or ID"/>
          <CommonOrgImportsTable app={this.props.app} isLoading={this.state.isLoading}
              onView={this.handleJobView} onDelete={this.handleJobDelete} jobs={filteredData}
              onFileDownload={this.handleFileDownload} onLogDownload={this.handleLogDownload}
              onWandesDownload={this.handleWandesDownload}/>
        </Layout.Content>
      </Layout.Content>
    );
  }
  /* Private API operations */
  async _fetchJobs() {
    this.startLoading();
    const resp = await this.props.app.organization.organizationsImportJob.getJobs();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body.jobs) { //valid data state
      const data = resp.body.jobs;
      this.setState({ data, isLoading: false });
    } else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ data: [], isLoading: false });
    }
  }
  async _deleteJob(jobID) {
    this.startLoading();
    const deleteResp = await this.props.app.organization.organizationsImportJob.deleteJob(jobID);
    if (!this._isMounted) return;
    if (deleteResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "File import delete with success!");
      this._fetchJobs();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, deleteResp);
      this.stopLoading();
    }
  }
  async _downloadFile(jobObj) {
    this.startLoading();
    //request
    const resp = await this.props.app.organization.organizationsImportJobFile.download(jobObj.importFileID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) Utils.downloadBlob(resp.body, jobObj.importFileName);
    else this.props.app.alertController.showAPIErrorAlert('Error while downloading import file!', resp);
    this.stopLoading(true);
  }

  async _downloadLogs(jobObj, onlyWande) {
    this.startLoading();
    //request
    const resp = await this.props.app.organization.organizationsImportJob.getLogs(jobObj.id, (onlyWande ? 'WANDE' : 'ALL'));
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `${jobObj.importFileName}-${moment(jobObj.createdOn).format(Globals.DefaultDateFormat)}-${onlyWande ? 'logs' : 'debug-logs'}`, 'txt');
    } else {
      this.props.app.alertController.showAPIErrorAlert(`Error while downloading import logs file!`, resp);
    } this.stopLoading(true);
  }
}
