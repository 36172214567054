import React from "react";
import autoBind from 'react-autobind';
import { Divider, Row, Col, Button, Table, Tooltip, Popconfirm, Modal, Select, Form, message, Input } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";

const { Option } = Select;

//props: isLoading, data, handleEdit, handleDelete
export default class CommonVaultPermissionsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sortedInfo: null,
      isModalVisible: false,
      editingPermission: null,
      form: null
    };
  }

  // Table support
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  // Handle Modal
  showEditModal(permission) {
    this.setState({ isModalVisible: true, editingPermission: permission });
  }

  async handleModalOk() {
    const values = await this.state.form.validateFields();
    const { role, accessLevel } = values;

    if (this.state.editingPermission) {
      await this.props.handleUpdatePermission(this.state.editingPermission.id, accessLevel);
    } else {
      await this.props.handleAddPermission(role, accessLevel);
    }

    this.setState({ isModalVisible: false, editingPermission: null });
  }

  handleModalCancel() {
    this.setState({ isModalVisible: false, editingPermission: null });
  }

  // UI
  render() {
    let { sortedInfo, isModalVisible, editingPermission } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      size: 'small',
      onChange: this.handleFilterChange,
      rowKey: 'id',
      loading: this.props.isLoading,
      locale: { emptyText: 'No permissions found in this vault.' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: ['bottomRight'] }
    };

    const columns = [
      {
        title: 'Role',
        dataIndex: 'id',
        key: 'id',
        width: 150,
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
      },
      {
        title: 'Access Level',
        width: 150,
        key: 'accessLevel',
        dataIndex: 'accessLevel',
        sorter: (a, b) => (a === b ? 0 : a ? -1 : 1),
        sortOrder: sortedInfo.columnKey === 'accessLevel' && sortedInfo.order
      },
      {
        title: 'Actions',
        width: 80,
        key: 'Actions',
        render: (record) => {
          return (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title='Delete Permission'>
                <Popconfirm
                  title={'Are you sure you want to remove this permission?'}
                  placement="bottomRight"
                  onConfirm={() => this.props.handleDelete(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button variant="none" icon={<DeleteOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>{' '}
              <Tooltip placement="bottomLeft" title='Edit Permission'>
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={() => this.showEditModal(record)}
                />
              </Tooltip>
            </span>
          );
        }
      }
    ];

    return (
      <>
        <Divider orientation="left">Vault Permissions</Divider>
        <Row justify="end" style={{ marginBottom: 16 }}>
          <Col>
            <Button
              type="primary"
              onClick={() => this.showEditModal(null)}
            >
              Create Permission
            </Button>
          </Col>
        </Row>
        <Row type='flex'>
          <Col offset={1} span={22}>
            <Table dataSource={this.props.data} columns={columns} {...props} />
          </Col>
        </Row >
        <Modal
          title={editingPermission ? "Edit Access Level" : "Create Permission"}
          visible={isModalVisible}
          onOk={() => this.handleModalOk()}
          onCancel={() => this.handleModalCancel()}
          destroyOnClose
        >
          <Form
            ref={(form) => (this.state.form = form)}
            initialValues={editingPermission || { accessLevel: '', role: '' }}
          >
            {!editingPermission && (
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  { required: true, message: 'Please enter a role' },
                ]}
              >
                <Input placeholder="Enter a role" />
              </Form.Item>
            )}
            <Form.Item
              name="accessLevel"
              label="Access Level"
              rules={[{ required: true, message: 'Please select an access level' }]}
            >
              <Select>
                <Option value="ADMIN">Admin</Option>
                <Option value="WRITE">Write</Option>
                <Option value="READ">Read</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
