import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/helpers/Utils';
import Globals from '@/config/Globals'
//
import CommonContactApplicationsTable from '@/views/apps/organization/commonComponents/Table/CommonContactApplicationsTable';
//
import { Space, Button, Select, Layout, Row, Col, Form, Input, DatePicker, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PhoneInput from "@/ui-components/PhoneInput";
//props are: contact, isNew
export default class AdminContactForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Form methods
  async validateFields() {
    let contact = await this.form.validateFields();
    contact.phone = contact.phone || [];
    contact.otherEmail = contact.otherEmail || [];
    return contact; 
  }
  setFieldsValue(data) {
    this.form.setFieldsValue({...data, phone: data.phone || [], otherEmail: data.otherEmail || []});
  };
  //Life cycle
  componentDidUpdate() {
    const data = this.props.contact;
    if (data) this.setFieldsValue(data);
  }
  //UI
  render() {
    const phoneTypeList = Globals.PhoneTypeList;
    const emailTypeList = Globals.EmailTypeList;
    return(
      <Layout.Content justify="right-align">
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={7} offset={1}>
              <Form.Item name="firstName" label="First Name" rules={[
                  { required: true, message: 'Please, type the contact first name!' },
                  { max: 255, message: 'First name must have less than 255 characters' },
                ]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item name="middleName" label="Middle Name" rules={[
                  { max: 255, message: 'Middle name must have less than 255 characters' },
                ]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name="lastName" label="Last Name" rules={[
                  { required: true, message: 'Please, type the contact last name!' },
                  { max: 255, message: 'Last name must have less than 255 characters' },
                ]}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={11} offset={1}>
              <Form.Item name="title" label="Title" rules={[
                  { max: 255, message: 'Title must have less than 255 characters' },
                ]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item name="email" label="Email" rules={[
                  { required: true, message: 'Please, type the contact email!' },
                  //TODO: add email validation
                ]}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Address</Divider>
          <Row type='flex'>
            <Col span={11} offset={1}>
              <Form.Item name="address1" label="Address 1">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item name="address2" label="Address 2">
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={6} offset={1}>
              <Form.Item name="postalCode" label="Postal Code">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name="city" label="City">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name="province" label="Province">
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Contact Information</Divider>
          <Row type='flex'>
            <Col span={7} offset={1}>
              <Form.List name="phone">
                {(fields, { add, remove }) => (
                  <Form.Item label='Phone(s)'>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            style={{ width: 100 }}
                            {...restField}
                            name={[name, 'label']}
                          >
                            <Select>
                              {phoneTypeList.map(option => (
                                <Select.Option key={option.key} value={option.key}>{option.label}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            style={{ width: 350 }}
                            {...restField}
                            name={[name, 'number']}
                            rules={[
                              { required: true, message: 'Please, type the phone or remove the empty line!' },
                            ]}
                          >
                            <PhoneInput />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add phone
                      </Button>
                    </Form.Item>
                  </Form.Item>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={7} offset={1}>
              <Form.List name="otherEmail">
                {(fields, { add, remove }) => (
                  <Form.Item label='Other Email(s)'>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            style={{ width: 100 }}
                            {...restField}
                            name={[name, 'label']}
                          >
                            <Select>
                              {emailTypeList.map(option => (
                                <Select.Option key={option.key} value={option.key}>{option.label}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            style={{ width: 350 }}
                            {...restField}
                            name={[name, 'emailAddress']}
                            rules={[
                              { required: true, message: 'Please, type the email or remove the empty line!' },
                            ]}
                          >
                            <Input/>
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add email
                      </Button>
                    </Form.Item>
                  </Form.Item>
                )}
              </Form.List>
            </Col>
          </Row>
          { !this.props.isNew &&
            <Divider orientation="left">Applications</Divider> &&
            <Row type='flex'>
              <Col span={22} offset={1}>
                <CommonContactApplicationsTable app={this.props.app} isLoading={this.props.isLoading} applications={this.props.contact.applications | {}} tenants={this.props.tenants}/>
              </Col>
            </Row>
          }
          <Divider/>
        </Form>
      </Layout.Content>
    );
  }
}
