import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import RootAppForm from "./RootAppForm";
import CommonRolesTable from '../commonComponents/CommonRolesTable';
import CommonAccessTokensTable from '../commonComponents/CommonAccessTokensTable';
//resources
import "@/stylesheets/CommonRegistration.less";
//
import { Layout, Button, PageHeader } from 'antd';
//
export default class RootAppView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: [], firstLoad: true, isLoading: false};
    this.isNew = false;
  }
  loadResponse(appResp) {
    console.log(appResp);
    if (!this._isMounted) return;
    if (appResp.statusCode == 200) { //valid data state
      this.state.data = {
        ...appResp.body,
        customEmailSenderEnabled: !!appResp.body.customEmailSender,
        customEmailTemplateEnabled: !!appResp.body.customEmailTemplate,
        customDomainEnabled: !!appResp.body.customDomain,
      };
      //set navigation items details
      this.props.app.appsManager?.navigation?.appendValue('applicationID', this.state.data.id);
      this.props.app.appsManager?.navigation?.appendValue('applicationName', this.state.data.name);
    }else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, appResp);
      this.state.data = [];
    }
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Application details`;
    if (this.state.firstLoad) {
      //Check for id
      this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) == Globals.URL_NewIndentifier);
      if (!this.isNew) this.fetchData();
      else this.setState({firstLoad: false});
    }
  }
  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const app = (await this.props.app.idm.api.application.readDetailed(appID));
    this.loadResponse(app);
  }
  //Actions handler
  handleChange(event) { Utils.defaultFormChangeHandler(event, this); }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      if (this.isNew) this._addApplication();
      else this._saveApplication();
    }
  }
  //Role actions
  handleRoleDelete(roleObj) {
    const resp = this.props.app.alertController.showPromptAlert('Attention!', `Are you really sure you want to delete role ${roleObj.name}? If you are sure, please type the role name.`);
    if (resp == roleObj.name) this._deleteRole(roleObj);
    else this.props.app.alertController.showWarningAlert('Aborted!', 'Delete operation aborted!');
  }
  handleRoleEdit(roleObj) {
    const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.role, null, appID, roleObj.id);
    this.props.app.appsManager?.navigation?.appendValue('roleID', roleObj.id);
    this.props.app.appsManager?.navigation?.appendValue('roleName', roleObj.name);
  }
  handleRoleCreate() {
    const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.role, null, appID, Globals.URL_NewIndentifier);
    this.props.app.appsManager?.navigation?.appendValue('roleID', Globals.URL_NewIndentifier);
    this.props.app.appsManager?.navigation?.appendValue('roleName', 'New Role');
  }
  //Access token actions
  handleAccessTokenDelete(tokenObj) {
    if (!this.props.app.alertController.showQuestionAlert("Attention!", `Do you really want to delete AccessToken '${tokenObj.description}'?`)) return;
    this._deleteAccessToken(tokenObj);
  }
  handleAccessTokenEdit(tokenObj) {
    const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.accessToken, null, appID, tokenObj.id);
    this.props.app.appsManager?.navigation?.appendValue('accessTokenID', tokenObj.id);
    this.props.app.appsManager?.navigation?.appendValue('accessTokenName', tokenObj.name);
  }
  handleAccessTokenCreate() {
    const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.accessToken, null, appID, Globals.URL_NewIndentifier);
    this.props.app.appsManager?.navigation?.appendValue('accessTokenID', Globals.URL_NewIndentifier);
    this.props.app.appsManager?.navigation?.appendValue('accessTokenName', 'New Access Token');
  }
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <PageHeader className='pageHeader' onBack={() => window.history.back()} title={this.isNew ? 'New Application' : `Application - ${(this.state.data.name ? this.state.data.name : 'Loading...')}`}
          extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create application' : 'Save application'}</Button>]}/>
        <Layout.Content>
          <RootAppForm onChange={this.handleChange} editMode={true} isNew={this.isNew} data={this.state.data} {...Utils.propagateRef(this, 'form')}/>
          {!this.isNew && //Roles table
            <CommonRolesTable data={this.state.data.roles} isLoading={isLoading} handleCreate={this.handleRoleCreate}
                              handleEdit={this.handleRoleEdit} handleDelete={this.handleRoleDelete}/>
          }
          {!this.isNew && //Access Token table
            <CommonAccessTokensTable data={this.state.data.tokens} isLoading={isLoading} handleCreate={this.handleAccessTokenCreate}
                                     handleEdit={this.handleAccessTokenEdit} handleDelete={this.handleAccessTokenDelete}/>
          }
        </Layout.Content>
      </Layout.Content>
    );
  }


  /*Private methods*/
  async _addApplication() {
    this.startLoading();
    //Object manipulation
    let obj = Object.assign({}, this.state.data);
    if (!obj.customDomainEnabled) delete obj.customDomain;
    delete obj.customDomainEnabled;
    if (!obj.customEmailTemplateEnabled) delete obj.customEmailTemplate;
    delete obj.customEmailTemplateEnabled;
    if (!obj.customEmailSenderEnabled) delete obj.customEmailSender;
    delete obj.customEmailSenderEnabled;
    //
    const addResp = await this.props.app.idm.api.application.create(obj);
    if (addResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Application created with success!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.applications);
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, addResp);
      this.stopLoading();
    }
  }
  async _saveApplication() {
    this.startLoading();
    //Obj manipulation
    let obj = Object.assign({}, this.state.data);
    delete obj.roles;
    delete obj.tokens;
    if (!obj.customDomainEnabled) delete obj.customDomain;
    delete obj.customDomainEnabled;
    if (!obj.customEmailTemplateEnabled) delete obj.customEmailTemplate;
    delete obj.customEmailTemplateEnabled;
    if (!obj.customEmailSenderEnabled) delete obj.customEmailSender;
    delete obj.customEmailSenderEnabled;
    //
    const updateResp = await this.props.app.idm.api.application.update(obj);
    if (updateResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Application updated with succeed!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.applications);
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
  async _deleteRole(roleObj) {
    this.startLoading();
    const delResp = await this.props.app.idm.api.role.delete(roleObj.id, roleObj.appID);
    if (delResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Role deleted with succeed!");
      this.fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, delResp);
      this.stopLoading();
    }
  }
  async _deleteAccessToken(acObj) {
    this.startLoading();
    const delResp = await this.props.app.idm.api.accessToken.delete(acObj.id, acObj.appID);
    if (delResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Access token deleted with succeed!");
      this.fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, delResp);
      this.stopLoading();
    }
  }
}
