import React from 'react';
import autoBind from 'react-autobind';
import { Button, Table, Tooltip, Popconfirm, Popover, Tag, Descriptions } from 'antd';
import { DeleteOutlined, EyeOutlined, DownloadOutlined, CloseCircleOutlined, FileExclamationOutlined } from '@ant-design/icons';
//
import Globals from '@/config/Globals'
import Utils from '@/components/helpers/Utils'
//props are: app, isLoading, onDelete, onView, onFileDownload, onLogDownload, onWandesDownload, jobs
export default class CommonOrgImportsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: { order: 'descend', columnKey: 'createdOn' } };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      { title: 'ID', key: 'id', dataIndex: 'id', sorter: true,
        sorter: (a, b) => a.id.toLowerCase().localeCompare(b.id.toLowerCase()),
        render: id => id.substr(0, 10),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order },
      { title: 'File', key: 'fileName', sorter: true,
        sortOrder: sortedInfo.columnKey === 'importFileName' && sortedInfo.order, 
        sorter: (a, b) => a.importFileName.toLowerCase().localeCompare(b.importFileName.toLowerCase()),
        render: props => props.importFileName && props.importFileName.length > 0 ? props.importFileName : 'N/A' 
      },
      { title: 'Description', key: 'description', sorter: true, width: '20%',
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order, 
        sorter: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
        render: props => props.description && props.description.length > 0 ? props.description : 'N/A' 
      },
      { title: 'Status', key: 'status', sorter: true,
        sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order, 
        sorter: (a, b) => a.status.toLowerCase().localeCompare(b.status.toLowerCase()),
        render: (props) => {
          const Statuses = Globals.ImportJob_Statuses;
          switch (props.status) {
            case Statuses.AVAILABLE:
              return <Tag color="green">Ready to Import</Tag>;
            case Statuses.IMPORT_STARTING:
              return <Tag color="cyan">Preparing Import</Tag>;
            case Statuses.VALIDATION_STARTING:
              return <Tag color="cyan">Preparing Validation</Tag>;
            case Statuses.IMPORT_RUNNING:
              return <Tag color="blue">Import Running</Tag>;
            case Statuses.VALIDATION_RUNNING:
              return <Tag color="blue">Validation Running</Tag>;
            case Statuses.IMPORT_COMPLETED:
              return <Tag color="geekblue">Import Completed</Tag>;
            case Statuses.VALIDATION_COMPLETED:
              return <Tag color="geekblue">Validation Completed</Tag>;
            case Statuses.IMPORT_FAILED:
              return <Tag color="red">Import Failed</Tag>;
            case Statuses.VALIDATION_FAILED:
              return <Tag color="red">Validation Failed</Tag>;
            default:
              return <Tag color="grey">{props.status}</Tag>;
          }
        }
      },
      { title: 'Started on', key: 'startDate', sorter: true,
        sortOrder: sortedInfo.columnKey === 'startDate' && sortedInfo.order, 
        sorter: (a, b) => a.startDate - b.startDate,
        render: props => props.startDate ? Utils.getDateOnUIFormatByTimestamp(props.startDate) : '-',
      },
      { title: 'Ended on', key: 'endDate', sorter: true,
        sortOrder: sortedInfo.columnKey === 'endDate' && sortedInfo.order, 
        sorter: (a, b) => a.endDate - b.endDate,
        render: props => props.endDate ? Utils.getDateOnUIFormatByTimestamp(props.endDate) : '-',
      },
      { title: '# of rows', key: 'rowsCount', sorter: true,
        sortOrder: sortedInfo.columnKey === 'rowsCount' && sortedInfo.order, 
        sorter: (a, b) => (a.summary?.validated || 0) - (b.summary?.validated || 0),
        render: props => {
          if (!props.summary) return '-';
          else {
            return <Popover content={
              <div>
                <Descriptions title="Quick Summary:" bordered size='small' layout='vertical'>
                  {props?.summary?.validated && <Descriptions.Item label={'# of validated rows'}>{props?.summary?.validated}</Descriptions.Item>}
                  {props?.summary?.warnings && <Descriptions.Item label={'# of warnings'}>{props?.summary?.warnings}</Descriptions.Item>}
                  {props?.summary?.errors && <Descriptions.Item label={'# of errors'}>{props?.summary?.errors}</Descriptions.Item>}
                  {props?.summary?.processed && <Descriptions.Item label={'# of processed rows'}>{props?.summary?.processed}</Descriptions.Item>}
                  {props?.summary?.updated && <Descriptions.Item label={'# of updates on companies'}>{props?.summary?.updated}</Descriptions.Item>}
                  {props?.summary?.created && <Descriptions.Item label={'# of created companies'}>{props?.summary?.created}</Descriptions.Item>}
                  {props?.summary?.failed && <Descriptions.Item label={'# of failed processed rows'}>{props?.summary?.failed}</Descriptions.Item>}
                </Descriptions>
              </div>
            }>{props?.summary?.validated || 0}</Popover>
          }
        },
      },
      { title: 'Created on', key: 'createdOn', sorter: true,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order, 
        sorter: (a, b) => a.createdOn - b.createdOn,
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
      },
      { title: 'Actions', key: 'Actions', align: 'center',
         render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='View Import'>
              <Button variant="none" icon={<EyeOutlined />} shape="circle" onClick={this.props.onView.bind(this, props)}/>
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Download Import File'>
              <Button variant="none" icon={<DownloadOutlined />} shape="circle" onClick={this.props.onFileDownload.bind(this, props)} style={{marginLeft: 10}}/>
            </Tooltip>
            {(props?.executionLog || props?.validationLog) && <Tooltip placement="bottomLeft" title='Download Debug Logs'>
              <Button variant="none" icon={<FileExclamationOutlined />} shape="circle" onClick={this.props.onLogDownload.bind(this, props)} style={{marginLeft: 10}}/>
            </Tooltip>}
            {(props?.executionLog || props?.validationLog) && <Tooltip placement="bottomLeft" title='Download Warnings & Errors Logs'>
              <Button variant="none" icon={<CloseCircleOutlined style={{ color: 'red' }} />} shape="circle" onClick={this.props.onWandesDownload.bind(this, props)} style={{marginLeft: 10}}/>
            </Tooltip>}
            {props.status != Globals.ImportJob_Statuses.AVAILABLE && props.status != Globals.ImportJob_Statuses.VALIDATION_COMPLETED && props.status != Globals.ImportJob_Statuses.VALIDATION_FAILED &&
              <Tooltip placement="bottomLeft" title='Delete Import'>
                <Popconfirm title={'Do you really want to delete this file import?'} placement="bottomRight" onConfirm={this.props.onDelete.bind(this, props)} okText="Yes" cancelText="No">
                  <Button variant="none" icon={<DeleteOutlined />} shape="circle" style={{marginLeft: 10}}/>
                </Popconfirm>
              </Tooltip>}
          </span>
        ),
      }
    ];
    const props = { rowKey: 'id', loading: this.props.isLoading, onChange: this.handleFilterChange, size: 'small',
                    locale: {emptyText: 'No import jobs on the system!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (<Table className="importJobsTable" columns={columns} dataSource={this.props.jobs} {...props}/>);
  }
}