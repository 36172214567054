import LaunchConfig from './LaunchConfig';
//
export default class ConfigManager  {
		constructor(auth, didBecomeAvailableHandler, failedToBecomeAvailableHandler) {
			this.auth = auth;
      this.didBecomeAvailableHandler = didBecomeAvailableHandler;
      this.failedToBecomeAvailableHandler = failedToBecomeAvailableHandler;
      this.launchConfig = null;
		}
	/* Public */
    //Initial load
  async detectConfig() { await this._detectConfig(); }
    //Getters
  isAvailable() { return !!this.launchConfig; }
  /* Private */
  async _detectConfig() {
    //Detect customer
    const customerID = this.auth.customerID;
    //Detect optional tenant and load it
    const tenantID = this.auth.tenantID;
    let tenant = null;
    if (tenantID) {
      const resp = (await this.auth.api.tenant.getConfiguration());
      if (resp.statusCode == 200 && resp.body && resp.body.id) tenant = resp.body;
      else {
        console.log(`Error while loading specified tenant ${tenantID} - ${resp}`);
        if (this.failedToBecomeAvailableHandler) this.failedToBecomeAvailableHandler();
        return; //stop exec, dont load config
      }
    } else console.log(`No tenants identified, using customer ${customerID}`);
    //Load or notify of failure
    if (customerID) this._loadLaunchConfig({ customerID, tenantID, tenant });
    else if (this.failedToBecomeAvailableHandler) this.failedToBecomeAvailableHandler();
  }
  _loadLaunchConfig(optionalConfig) {
    this.launchConfig = new LaunchConfig(optionalConfig);
    if (this.didBecomeAvailableHandler) this.didBecomeAvailableHandler();
    console.debug('Launch config loaded', optionalConfig);
  }
}
