import { CalendarOutlined, ContactsOutlined } from "@ant-design/icons";
import { FaBuilding } from "react-icons/fa";
import { GiVideoConference } from "react-icons/gi";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { RiComputerLine } from "react-icons/ri";
//
export default class Globals {}
//Comp.
Globals.CompabilityMessage =
  "You are using Internet Explorer to connect to this application. Some functions may not work correctly using IE. In order to have a better experience please use Chrome, Firefox or Safari.";
//UI
Globals.Table_PagingItemsPerPage = 25;
Globals.DefaultDateFormat = "DD/MM/YYYY";
//Paths
Globals.URL_Path_ID_Placeholder = "id";
Globals.URL_Path_ID2_Placeholder = "id2";
Globals.URL_NewIndentifier = "new";
//Calendar View
Globals.CalendarViewStartTime = "06:00";
Globals.CalendarViewEndTime = "23:00";
Globals.CalendarViewHasWeekends = true;
//Query
Globals.URLQueryParam_RegConfirmationToken = "regToken";
Globals.URLQueryParam_RegConfirmationCode = "regCode";
Globals.URLQueryParam_ChangePassword = "changePassword";
Globals.URLQueryParam_SearchTerm = "search";
Globals.URLQueryParam_Page = "page";
Globals.URLQueryParam_SortField = "sort";
Globals.URLQueryParam_SortOrder = "order";
Globals.URLQueryParam_Cities = "cities";
Globals.URLQueryParam_CUs = "cus";
Globals.URLQueryParam_Type = "type";
Globals.URLQueryParam_From = "from";
Globals.URLQueryParam_To = "to";
//Registration
Globals.RegOrgEmploymentSelectionType = "employmentSelection";
//IDM Apps
Globals.ApplicationEmailThemes = [null, "orangepiggy", "application", "ncsoimport", "tcp"];
//UI
Globals.VALIDATION_RULES = {
  LOWER: 0,
  UPPER: 1,
  NUMBER: 2,
  MATCH_EMAIL: 3,
  MIN_CHARS: 4,
  PASS_MATCH: 5,
};
//Mini apps
Globals.MiniApps = {
  IDM: "IDM",
  ORG: "ORG",
  CONFIG: "CONFIG",
};
/* Org App */
// Enums
Globals.OrgStatusList = [
  { key: "Active", label: "Active" },
  { key: "Inactive", label: "Inactive" },
];
Globals.OrgCORTypeList = [
  { key: "Large", label: "Large" },
  { key: "Small", label: "Small" },
];
Globals.OrgCountryList = [
  { key: "Canada", label: "Canada" },
  { key: "US", label: "US" },
];
Globals.PhoneTypeList = [
  { key: "Mobile", label: "Mobile" },
  { key: "Work", label: "Work" },
  { key: "Home", label: "Home" },
  { key: "Other", label: "Other" },
  { key: "Fax", label: "Fax" },
];
Globals.EmailTypeList = [
  { key: "Work", label: "Work" },
  { key: "Home", label: "Home" },
  { key: "Other", label: "Other" },
];
Globals.EventTypes = {
  BOUNCE: "Bounce",
  COMPLAINT: "Complaint",
  DELIVERY: "Delivery",
  SEND: "Send",
  REJECT: "Reject",
  DELIVERY_DELAY: "Delivery Delay",
};
Globals.OrganizationApp_ProgramsMembersTypes = {
  MEMBER: "MEMBER",
  MANAGER: "MANAGER",
  INVITED: "INVITED",
};
Globals.ImportJob_Statuses = {
  AVAILABLE: "AVAILABLE",
  //Validation substatuses
  VALIDATION_STARTING: "VALIDATION_STARTING",
  VALIDATION_RUNNING: "VALIDATION_RUNNING",
  VALIDATION_COMPLETED: "VALIDATION_COMPLETED",
  VALIDATION_FAILED: "VALIDATION_FAILED",
  //Import substatuses
  IMPORT_STARTING: "IMPORT_STARTING",
  IMPORT_RUNNING: "IMPORT_RUNNING",
  IMPORT_COMPLETED: "IMPORT_COMPLETED",
  IMPORT_FAILED: "IMPORT_FAILED",
};
Globals.ImportJob_FilterTypes = {
  ALL: "ALL",
  WARN: "WARN",
  ERR: "ERR",
  WANDE: "WANDE",
};
Globals.ImportJob_ProcessLogTypes = {
  DEBUG: "DEBUG",
  ERROR: "ERROR", //can have an object that may be an error, may be an exception or may be a validation error
  WARNING: "WARNING", //will have a object that will be a validation error
  DATA: "DATA",
  SUMMARY: "SUMMARY",
};
Globals.ImportJob_SimpleV1Columns = {
  EmployerID: "Employer ID",
  EmployerLegalName: "Employer Legal Name",
  EmployerTradeName: "Employer Trade Name",
  IndustryCU: "Industry Classification Unit",
  EmployerCUFirstStartDate: "Employer Classification Unit First Start Date",
  EmployerCUStartDate: "Employer Classification Unit Start Date",
  HeadOfficeAddressLine1: "Head Office Address Line 1",
  HeadOfficeAddressLine2: "Head Office Address Line 2",
  HeadOfficeAddressLine3: "Head Office Address Line 3",
  HeadOfficeAddressLine4: "Head Office Address Line 4",
  HeadOfficeCity: "Head Office City",
  HeadOfficeProvince: "Head Office Province",
  HeadOfficePostalCode: "Head Office Postal Code",
  HeadOfficeCountryCode: "Head Office Country",
  HeadOfficePhone: "Head Office Phone",
  COROHS: "COR OHS Certificate Indicator",
  CORRTW: "COR RTW Certificate Indicator",
  CompanySize: "Size based on Person Years",
};
Globals.CompanySize = {
  None: "",
  Small: "Small",
  Medium: "Medium",
  Large: "Large",
};
/* Config App */
Globals.Customer_OrgModuleNamespaces = ["bccsa", "csam", "ihsa"];
Globals.Customer_PortalThemes = ["bccsa", "csam", "ihsa"];
Globals.Tenant_MailTemplates = {
  default: "default",
  srmb: "srmb",
  ncsobc: "ncsobc",
  ncsoon: "ncsoon",
  trainingbc: "trainingbc",
  asbestosbc: "asbestosbc",
  tcpbc: "tcpbc",
  extaudbc: "extaudbc",
};
Globals.Tenant_LogoPaths = ["/resources/bccsa.png", "/resources/csam.png", "/resources/ihsa.png"];
Globals.Tenant_DisablableEmails = [
  // List extracted from Program-API on Sep 18, 2024
  "certificationApplicationSubmitted_Admin",
  "certificationApplicationApproved",
  "certificationApplicationPreApprovalRequested",
  "certificationApplicationPreApprovalRequested_Admin",
  "certificationApplicationPreApprovalReviewed",
  "certificationApplicationRejected",
  "certificationApplicationRenewStarted",
  "certificationApplicationStarted",
  "certificationApplicationSubmitted",
  "certificationApplicationSubmitted_Admin",
  "certificationCompleted",
  "certificationCompletedManager",
  "certificationExpire1st",
  "certificationExpire1st_Admin",
  "certificationExpire2nd",
  "certificationExpire2nd_Admin",
  "certificationFailed",
  "certificationRenewStarted",
  "certificationRestarted",
  "certificationStalled",
  "certificationStarted",
  "contactForm",
  "courseAdminRevoked",
  "courseApprovalApproved",
  "courseApprovalRejected",
  "courseApprovalRequested",
  "courseApprovalRequested_Admin",
  "courseCooldownFinished",
  "courseCooldownOverwritten",
  "courseCooldownStarted",
  "courseExpirationWarning",
  "courseExpirationWarningManager",
  "courseExpired",
  "courseExpiredManager",
  "courseFailed",
  "courseFailedManager",
  "courseNoShow",
  "courseNoShowManager",
  "coursePassed",
  "coursePassedManager",
  "courseRedeemSucceeded",
  "courseRevoked",
  "importProcess",
  "sessionAutoEnrolmentFailed",
  "userRegistration",
  "userRegistrationInvalidReferrer",
  // List extracted from Classroom-API on Sep 18, 2024
  "onlineExpire_30d",
  "onlineExpire1st",
  "onlineExpire2nd",
  "onlineExpire3rd",
  "sessionAssignedToInstructor",
  "sessionBillingSummary",
  "sessionCancelled",
  "sessionEventReminder1d",
  "sessionEventReminder7d",
  "sessionFull",
  "sessionInvitation",
  "studentEnrolmentNotification",
  "studentEnrolmentNotification_Admin",
  "sessionNoLongerFull",
  "sessionPendingAdminReview",
  "sessionPendingInstructorReview",
  "sessionPendingInstructorReview_Admin",
  "sessionUnassignedFromInstructor",
  "sessionVenueChanged",
  "studentEnrolmentCanceled",
  "studentEnrolmentDeleted",
  "studentEnrolmentNotification",
  "studentEnrolmentNotification_Admin",
];
Globals.Tenant_UISidebarOptions = [
  { label: "Stats Dashboard", value: "showApplicationDashboard" },
  { label: "Pending Applications", value: "showPendingApplications" },
  { label: "Pending Order", value: "showPendingOrders" },
  { label: "Sessions", value: "showSessions" },
  { label: "Email Certificates", value: "showEmailCertificates" },
  { label: "Calendar (Students)", value: "showSessionsToUsers" },
  { label: "Account Merge enabled", value: "showMergeAction" },
];
Globals.Tenant_UISettingsOptions = [
  { label: "Locations", value: "showLocations" },
  { label: "Venues", value: "showVenues" },
  { label: "Instructors", value: "showInstructors" },
  { label: "Requirements", value: "showRequirements" },
  { label: "Equivalences", value: "showEquivalences" },
  { label: "Training Providers", value: "showTrainingProviders" },
  { label: "Sessions", value: "showSessions" },
  { label: "Products", value: "showProducts" },
  { label: "Vouchers", value: "showVouchers" },
  { label: "FAQ", value: "showFAQ" },
];
Globals.Tenant_EnabledUsersFilter = [
  { label: "Registered", value: "REGISTERED" },
  { label: "All", value: "ALL" },
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "UNACTIVE" },
  { label: "Certified", value: "CERTIFIED" },
  { label: "Online expired courses", value: "EXPIRED_ONLINE" },
  { label: "Any application approved", value: "APPLICATION_APPROVED" },
  { label: "Any in-progress application", value: "APPLICATION_IN_PROGRESS" },
  { label: "Any rejected application", value: "APPLICATION_REJECTED" },
  { label: "Enrolled Course", value: "ENROLLED_COURSE" },
  { label: "Recertified", value: "RECERTIFIED" },
];
Globals.Tenant_ReportTypes = [
  { label: "Affiliates Commision", value: "AFFILIATE_COMMISION" },
  { label: "Custom Users", value: "CUSTOM_USERS" },
  { label: "Certification by Period", value: "CERTIFICATION_PERIOD" },
  { label: "Revenue by Period", value: "REVENUE_PERIOD" },
  { label: "Applications", value: "APPLICATIONS_EXPORT" },
  { label: "Application Status", value: "APPLICATION_STATUS" },
  { label: "Expired Certifications", value: "EXPIRED_CERTIFICATIONS" },
  { label: "Certificate Expiration", value: "CERTIFICATE_EXPIRATION" },
  { label: "Exam Result Analysis", value: "EXAM_ANALYSIS" },
  { label: "Sessions List", value: "SESSIONS_LIST" },
  { label: "Sessions Results", value: "SESSIONS_RESULTS" },
  { label: "Session Summary", value: "SESSION_SUMMARY" },
];
Globals.Tenant_OptionalUserSearchFilters = [
  { label: "Certification", value: "CERTIFICATION" },
  { label: "Registration Date", value: "REGISTRATION_DATE" },
  { label: "Certification Expiration Date", value: "CERTIFICATION_EXP_DATE" },
];
Globals.Tenant_OptionalSearchColumns = [
  { label: "ITA Number", value: "ITA_NUMBER" },
  { label: "Certification Expiration Date", value: "CERT_EXP_DATE" },
  { label: "Referred by", value: "REFERRED_BY" },
  { label: "Certification Number", value: "CERT_NUMBER" },
  { label: "Certification Completion Date", value: "CERT_COMPLETION_DATE" },
  { label: "Status", value: "STATUS" },
  { label: "Send Gift", value: "SEND_GIFT" },
];
Globals.Tenant_Themes = [
  { label: "Asbestos", value: "asbestos" },
  { label: "SiteReady", value: "bccsa" },
  { label: "CSAM", value: "csam" },
  { label: "External Auditor", value: "extaud" },
  { label: "IHSA", value: "ihsa" },
  { label: "NCSO", value: "ncso" },
  { label: "RSA", value: "rsa" },
  { label: "Training", value: "training" },
  { label: "TCP", value: "tcp" },
];
Globals.Tenant_RegistrationFieldTypes = [
  { label: "Text Input", value: "text" },
  { label: "Date Picker", value: "date" },
  { label: "Number Input", value: "number" },
  { label: "Phone Number Input", value: "tel" },
  { label: "Checkbox", value: "boolean" },
  { label: "List", value: "list" },
  { label: "Switch", value: "switch" },
  {
    label: "Employment Selector",
    value: Globals.RegOrgEmploymentSelectionType,
  },
];
Globals.Tenant_Commission_Pay_Type = [
  { label: "Registration using affiliate URL", value: "AFFILIATE" },
  { label: "Referral", value: "REFERRAL" },
  { label: "No payments", value: "NONE" }, // Always keep the "NONE" as last item
];

// Default values
Globals.WorksafeTermDefault = "Account #";

// Sessions
Globals.Result_Status = {
  INCOMPLETE: "INCOMPLETE",
  FAIL: "FAIL",
  PASS: "PASS",
};
Globals.Attendance_Status = {
  INCOMPLETE: "INCOMPLETE",
  NO_SHOW: "NO_SHOW",
  ATTENDED: "ATTENDED",
};
Globals.Session_Type = {
  SCHEDULED_PRESENTIAL: "SCHEDULED_PRESENTIAL",
  SCHEDULED_ONLINE_EXT: "SCHEDULED_ONLINE_EXT",
  SCHEDULED_VILT: "SCHEDULED_VILT",
  SCHEDULED_WEBINAR: "SCHEDULED_WEBINAR",
  ONLINE_EXT: "ONLINE_EXT",
  ONLINE: "ONLINE",
};
Globals.Session_State = {
  DRAFT: "DRAFT",
  PENDING_INSTRUCTOR: "PENDING_INSTRUCTOR",
  AVAILABLE: "AVAILABLE",
  PENDING_ADMIN: "PENDING_ADMIN",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  LOCKED: "LOCKED",
};
Globals.getTemplateTypeIcon = function (type, includeLabel) {
  if (type == Globals.Session_Type.SCHEDULED_ONLINE_EXT)
    return (
      <>
        <CalendarOutlined style={{ marginRight: 7 }} />
        {includeLabel && "Online External (Scheduled)"}
      </>
    );
  else if (type == Globals.Session_Type.SCHEDULED_PRESENTIAL)
    return (
      <>
        <FaBuilding style={{ marginRight: 7 }} />
        {includeLabel && "In class (Scheduled)"}
      </>
    );
  else if (type == Globals.Session_Type.SCHEDULED_VILT)
    return (
      <>
        <ContactsOutlined style={{ marginRight: 7 }} />
        {includeLabel && "VILT"}
      </>
    );
  else if (type == Globals.Session_Type.SCHEDULED_WEBINAR)
    return (
      <>
        <GiVideoConference style={{ marginRight: 7 }} />
        {includeLabel && "Webinar"}
      </>
    );
  else if (type == Globals.Session_Type.ONLINE_EXT)
    return (
      <>
        <HiOutlineStatusOnline style={{ marginRight: 7 }} />
        {includeLabel && "Online External"}
      </>
    );
  else if (type == Globals.Session_Type.ONLINE)
    return (
      <>
        <RiComputerLine style={{ marginRight: 7 }} />
        {includeLabel && "Online"}
      </>
    );
  return "-";
};
Globals.getColorBySessionState = function (state) {
  if (state == Globals.Session_State.DRAFT) return "blue";
  if (state == Globals.Session_State.AVAILABLE) return "green";
  if (state == Globals.Session_State.PENDING_ADMIN) return "gold";
  if (state == Globals.Session_State.PENDING_INSTRUCTOR) return "orange";
  if (state == Globals.Session_State.COMPLETED) return "geekblue";
  if (state == Globals.Session_State.CANCELLED) return "red";
  if (state == Globals.Session_State.LOCKED) return "orange";
  return "default";
};
Globals.getTextBySessionState = function (state) {
  if (state == Globals.Session_State.DRAFT) return "Draft";
  if (state == Globals.Session_State.AVAILABLE) return "Available";
  if (state == Globals.Session_State.PENDING_ADMIN) return "Pending Admin Review";
  if (state == Globals.Session_State.PENDING_INSTRUCTOR) return "Pending Instructor Review";
  if (state == Globals.Session_State.COMPLETED) return "Completed";
  if (state == Globals.Session_State.CANCELLED) return "Cancelled";
  if (state == Globals.Session_State.LOCKED) return "Locked";
  return "-";
};
