import React from 'react';
import moment from 'moment';
import { Col, Modal, Input, Row, Form, Select, DatePicker, Button, Upload, message, Tooltip, Spin } from 'antd';
import { InboxOutlined, PaperClipOutlined, DeleteOutlined, RocketOutlined } from '@ant-design/icons';
import { GrDocumentPdf, GrDocumentExcel } from "react-icons/gr";
import autoBind from 'react-autobind';

import Utils from '@/components/helpers/Utils'
import Globals from '@/config/Globals'
import CustomComponent from '@/ui-components/CustomComponent';
//
import '@/stylesheets/org-app/CommonCreateImportJobModal.less';
//props are: app, onCompleted
export default class CommonCreateImportJobModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.initialState = { isLoading: false, isVisible: false, file: null, description: '' };
    this.state = { ...this.initialState };
  }

  /* Public method */
  show() { this.setState({ isLoading: false, isVisible: true, file: null, description: '' }); }
  close() { this.setState({ ...this.initialState }); }
  /* Actions */
  handleFileChange({ file }) {
    //check for type constraints
    const XLSX_types = ['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (!XLSX_types.find((type) => file.type?.includes(type))) {
      message.error('This type of file is not accepted.');
      return;
    }
    this.setState({ file });
  }
  handleRemoveFile(fileID) { this.setState({ file: null }); }
  async handleSubmit() {
    const data = await this.form.validateFields();
    if (!data) return;
    this._createImportJob(this.state.file, data.description);
  }

  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Modal closable={false} open={this.state.isVisible} title={'New Import'} className={`uploadModal`}
        footer={[
          <Button key="cancel" disabled={isLoading} onClick={this.close}> Cancel </Button>,
          <Button type="primary" key="done" onClick={this.handleSubmit}
            loading={this.state.isLoading} disabled={isLoading}> Create import </Button>,
        ]}>
        <Row gutter={32}>
          <Col span={24}>
            <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
              <Form.Item label="Description" name='description' rules={[{ required: true, message: 'Description is required!' }]}>
                <Input.TextArea size={3} style={{ width: '100%' }} disabled={isLoading}/>
              </Form.Item>
              <Form.Item label={`Import file:`} name={'file'} required rules={[{ required: true, validator: () => new Promise((re, rj) => {
                return (!this.state.file ? rj('Import file is required.') : re());
              })}]}>
                <Upload.Dragger multiple={false} maxCount={1} beforeUpload={() => false} showUploadList={false}
                  fileList={this.state.file ? [this.state.file] : []} onChange={this.handleFileChange} 
                  disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }}>
                  <p className="ant-upload-drag-icon">
                    <GrDocumentExcel size={40} color={'#106D37'}/>
                  </p>
                  {this.state.file && <p className="ant-upload-text">{this.state.file?.name}</p>}
                  {!this.state.file && <p className="ant-upload-text">Click or drag the spreadsheet to this area</p>}
                  <p className="ant-upload-hint">
                    {this.state.file ? 
                      <>Click or drag on this area to replace the selected file.<br/></> :
                      <>File must be on xlsx format and the standard required import columns.</>}
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }

  // API Requests
  async _createImportJob(file, description) {
    this.startLoading();
    //Start file reader
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = async (readerResp) => {
      if (!this._isMounted) return;
      //Upload and create job
      file.file = readerResp.currentTarget.result;
      const resp = await this.props.app.organization.organizationsImportJobFile.uploadAndCreateJob(file, description);
      if (!this._isMounted) return;
      //Process response
      if (resp && resp.statusCode == 200) {
        message.success('Import job created with success!');
        if (this.props.onCompleted) this.props.onCompleted();
        this.close();
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, resp);
        this.stopLoading();
      }
    };
  }
}
