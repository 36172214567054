import React from "react";
import autoBind from 'react-autobind';
//
import { Select, Divider, Button, Row, Col, Table, Tooltip, Form} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";

const ACCESSTYPES = [
  {id: 'READONLY', description: 'Read Only'},
  {id: 'READWRITE', description: 'Read Write'},
  {id: 'READWRITE_NOREPLACEMENT', description: 'Read Write (No update to root level keys)'}
];
//props are: app, accessList, onACEAdded, onACEDeleted, editMode, isLoading
export default class RootACEsTable extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
      this.state = { selectedRole: null, selectedAccessType: null, sortedInfo: null };
    }
    //Actions
    handleACECreate(e) {
      e.preventDefault();
      //Check if role is already in ACL
      for (let ACE of this.props.accessList) {
        if (ACE.roleID == this.state.selectedRole) {
          this.props.app.alertController.showErrorAlert("Error!", 'ACE with this group already exists');
          return;
        }
      }
      //Add into
      this.props.onACEAdded({ roleID: this.state.selectedRole, accessType: this.state.selectedAccessType });
    }
    handleACEDelete(aceObj) {
      let idx = -1;
      //Try to find ACE
      for (let ACE of this.props.accessList) { if (ACE.roleID == aceObj.roleID) idx = this.props.accessList.indexOf(ACE); }
      //Delete if found
      if (idx != -1) this.props.onACEDeleted(idx);
    }
    handleAccessTypeChanged(accessType) {  this.setState({ selectedAccessType: accessType }); }
    handleRoleChanged(role) {  this.setState({ selectedRole: role }); }
      //Filter support
    handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
    //UI
    render() {
      const editMode = this.props.editMode;
      const roles = this.props.app.sharedCache().getRoles();
      //
      let { sortedInfo } = this.state;
      sortedInfo = sortedInfo || {};
      //
      const props = { 
        size: 'middle', onChange: this.handleFilterChange, rowKey: 'roleID', loading: this.props.isLoading,
        locale: {emptyText: 'Partition does not have any ACE.'},
        pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }}
      ;
      const columns = [
        { title: 'Role Name', key: 'roleName', width: 150, dataIndex: 'roleID',
          sorter: (a, b) => {
            let roleA = this.props.app.sharedCache().findRoleByID(a.roleID);
            let roleB = this.props.app.sharedCache().findRoleByID(b.roleID);
            return (roleA ? roleA.name : 'UNKNOWN').localeCompare((roleB ? roleB.name : 'UNKNOWN'));
          },
          sortOrder: sortedInfo.columnKey === 'roleName' && sortedInfo.order,
          render: (d) => {
            let role = this.props.app.sharedCache().findRoleByID(d);
            return (role ? role.name : 'UNKNOWN');
          }
        },
        { title: 'Role App', key: 'appName', width: 150, dataIndex: 'roleID',
          sorter: (a, b) => {
            let roleA = this.props.app.sharedCache().findRoleByID(a.roleID);
            let appA = this.props.app.sharedCache().findAppByID((roleA ? roleA.appID : ''));
            let roleB = this.props.app.sharedCache().findRoleByID(b.roleID);
            let appB = this.props.app.sharedCache().findAppByID((roleB ? roleB.appID : ''));
            return (appA ? appA.name : 'UNKNOWN').localeCompare((appB ? appB.name : 'UNKNOWN'));
          },
          sortOrder: sortedInfo.columnKey === 'appName' && sortedInfo.order,
          render: (d) => {
            let role = this.props.app.sharedCache().findRoleByID(d);
            let app = this.props.app.sharedCache().findAppByID((role ? role.appID : ''));
            return (app ? app.name : 'UNKNOWN');
          }
        },
        { title: 'Access Type', key: 'accessType', width: 150, dataIndex: 'accessType',
          sorter: (a, b) => {
            let ACTypeA = ACCESSTYPES.find(i => { if (i.id == a.accessType) return i; });
            let ACTypeB = ACCESSTYPES.find(i => { if (i.id == b.accessType) return i; });
            return (ACTypeA ? ACTypeA.description : 'UNKNOWN').localeCompare((ACTypeB ? ACTypeB.description : 'UNKNOWN'));
          },
          sortOrder: sortedInfo.columnKey === 'accessType' && sortedInfo.order,
          render: (d) => {
            let ACType = ACCESSTYPES.find(i => { if (i.id == d) return i; });
            return (ACType ? ACType.description : 'UNKNOWN');
          }
        },
        { title: 'Actions', width: 180, key: 'Actions',
          render: props => {
            return (<span className='tableButtonContainer'>
                      <Tooltip placement="bottomLeft" title='Remove ACE'>
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle" onClick={this.handleACEDelete.bind(this, props)}/>
                      </Tooltip>
                    </span>);
          }
        }
      ];
      //render
      return (
        <>
          <Divider orientation="left">ACEs</Divider>
          <Form {...Utils.propagateRef(this, 'form')}>
            <Row type='flex' justify='end'>
              <Col span={6}>
                <Form.Item name="roleID" label="Role" style={{marginLeft: 10, width: 500}}>
                  <Select showSearch value={this.state.selectedRole} onChange={this.handleRoleChanged.bind(this)} disabled={!editMode}>
                    {roles.map((opt) => (<Select.Option key={opt.id} value={opt.id}>{`${opt.name} - ${this.props.app.sharedCache().findAppByID(opt.appID).name}`}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} offset={1}>
                <Form.Item name="accessType" label="AccessType" style={{marginLeft: 10, width: 500}}>
                  <Select showSearch value={this.state.selectedRole} onChange={this.handleAccessTypeChanged.bind(this)} disabled={!editMode}>
                    {ACCESSTYPES.map((opt) => (<Select.Option key={opt.id} value={opt.id}>{opt.description}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2} offset={1}>
                <Button key="1" onClick={this.handleACECreate} type='primary'>Add ACE</Button>
              </Col>
            </Row>
          </Form>
          <Row type='flex'>
            <Col offset={1} span={22}>
              <Table dataSource={this.props.accessList} columns={columns} {...props} />
            </Col>
          </Row>
        </>
     );
    }
}
