import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col, Form, Input } from 'antd';
//
import Utils from '@/components/helpers/Utils';
//
export default class CommonRegistrationListFieldValueForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Form methods
  async validateFields() { return await this.form.validateFields(); }
  //UI
  render() {
    return(
      <Layout.Content justify="right-align">
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={22} offset={1}>
              <Form.Item name="label" label="Label" rules={[
                  { required: true, message: 'Please, type the label!' },
                  { min: 4, message: 'Label must be between 4 and 255 characters' },
                  { max: 255, message: 'Label must be between 4 and 255 characters' },
                ]}> 
                  <Input/> 
                </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={22} offset={1}>
              <Form.Item name="value" label="Value" rules={[
                  { required: true, message: 'Please, type the value!' },
                  { min: 2, message: 'Value must be between 2 and 255 characters' },
                  { max: 255, message: 'Value must be between 2and 255 characters' },
                ]}> 
                  <Input/> 
                </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    );
  }
}
