import React from "react";
import autoBind from 'react-autobind';
import { Layout, Button, PageHeader, Row, Col } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
//
import AdminOrganizationForm from "./Forms/AdminOrganizationForm";
import AdminOrganizationOptions, { AdminOrganizationOptionTypes } from '../commonComponents/AdminOrganizationOptions';
//
export default class AdminOrganizationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      data: {}, cus: [],
      isLoading: false,
      orgID: this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this),
      orgName: this.props.app.urlManager.getQueryParam('n', this) || 'Company',
      worksafeTerm: this.props.app.sharedCache().getConfigCustomerWorksafeTerm() || Globals.WorksafeTermDefault
    };
    this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) == Globals.URL_NewIndentifier);
    //
    this.viewOptions = new AdminOrganizationOptions(AdminOrganizationOptionTypes.ORGANIZATION, this.state.orgID, this.props.app, this.state.orgName);
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Company`;
    //Check for id
    this._fetchData(!this.isNew);
  }

  //Actions handler
  async handleSubmit() {
    //validate and retrieve form data
    const formData = await this.form.validateFields();
    if (!formData) return;
    //make operation
    if (this.isNew) this._addOrganization(formData);
    else this._saveOrganization(formData);
  }
  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <PageHeader className='pageHeader' title={this.isNew ? 'New Company' : `${(this.state.data?.name || 'Loading...')} - Company Information`} onBack={() => window.history.back()} extra={(this.isNew ? '' : this.viewOptions.getOptions())}/>
        {this._renderSubmit()}
        <Layout.Content>
          <AdminOrganizationForm isNew={this.isNew} organization={this.state.data} cus={this.props.app.sharedCache().getOrganizationCUs()} worksafeTerm={this.state.worksafeTerm}  {...Utils.propagateRef(this, 'form')}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderSubmit() {
    return (
      <Row type='flex' align='end'>
        <Col>
          <Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create Company' : 'Save Company'}</Button>
        </Col>
      </Row>
    );
  }

  /*Private methods*/
  async _addOrganization(data) {
    this.startLoading();
    const addResp = await this.props.app.organization.organization.createOrganization(data);
    const orgID = addResp.body.id;
    if (!this._isMounted) return;
    if (addResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Company created with success!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_organization, null, encodeURIComponent(orgID));
      this.props.app.appsManager?.navigation?.appendValue('orgID', orgID);
      this._fetchData(true);
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, addResp);
      this.stopLoading();
    }
  }
  async _saveOrganization(data) {
    this.startLoading();
    const orgID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const updateResp = await this.props.app.organization.organization.updateOrganization(data, orgID);
    if (!this._isMounted) return;
    if (updateResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Company updated with success!");
      this._fetchData(true);
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
  async _fetchData(fetchOrg) {
    this.startLoading();
    await this.props.app.sharedCache().loadOrganizationCUs();
    await this.props.app.sharedCache().loadConfigCustomerWorksafeTerm();
    if (fetchOrg) await this._fetchOrganization();
    else this.stopLoading();
  }
  async _fetchOrganization() {
    const orgID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    let orgResp = await this.props.app.organization.organization.getOrganization(orgID);
    if (!this._isMounted) return;
    if (orgResp.statusCode == 200 && orgResp.body.id) { //valid data state
      const data = orgResp.body;
      this.setState({ data, worksafeTerm: this.props.app.sharedCache().getConfigCustomerWorksafeTerm() || Globals.WorksafeTermDefault, isLoading: false });
      //set navigation items details
      this.props.app.appsManager?.navigation?.appendValue('orgID', orgID);
    } else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, orgResp);
      this.setState({ data: {}, worksafeTerm: this.props.app.sharedCache().getConfigCustomerWorksafeTerm() || Globals.WorksafeTermDefault, isLoading: false });
    }
  }
}
