import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonSimpleSearchBar from '../commonComponents/CommonSimpleSearchBar';
import CommonClassificationUnitsTable from '../commonComponents/Table/CommonClassificationUnitsTable';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminClassificationUnitsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, searchTerm: '' };
  }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    //load once on the scree (reload if re-enter)
    this.startLoading();
    await this.props.app.sharedCache().loadOrganizationCUs();
    this.stopLoading();
  }
    //Search
  handleSearch(searchTerm) { this.setState({ searchTerm }); }
    //Org actions
  handleCUCreate() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_cu_cu, null, Globals.URL_NewIndentifier); }
  handleCUEdit(cuObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_cu_cu, null, cuObj.id); }
  handleCUDelete(cuObj) { this._deleteCU(cuObj.id); }
  //UI
  render() {
    const { searchTerm } = this.state;
    //filter cus on runtime
    const cus = (this.props.app.sharedCache().getOrganizationCUs() || []).filter((c) => {
      return searchTerm && searchTerm.length > 0 ? 
        c.id.toLowerCase().includes(searchTerm) || (c.description || '').toLowerCase().includes(searchTerm) :
        true;
    });
    //
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className='pageHeader' title="Classification Units"
              extra={[<Button key="1" onClick={this.handleCUCreate} type='primary'>Create Classification Unit</Button>]}/>
          <Layout.Content>
            <CommonSimpleSearchBar isLoading={this.state.isLoading} onSearch={this.handleSearch} 
                {...Utils.propagateRef(this, 'searchBar')} app={this.props.app} placeholder="Search by description or ID"/>
            <CommonClassificationUnitsTable app={this.props.app} isLoading={this.state.isLoading}
                onEdit={this.handleCUEdit} onDelete={this.handleCUDelete} cus={cus} />
          </Layout.Content>
      </Layout.Content>
    );
  }
  /* Private API operations */
  async _deleteCU(cuID) {
    this.startLoading();
    const deleteResp = await this.props.app.organization.classificationUnit.deleteClassificationUnit(cuID);
    if (!this._isMounted) return;
    if (deleteResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Classification delete with success!");
      await this.props.app.sharedCache().loadOrganizationCUs(true);
    } else this.props.app.alertController.showAPIErrorAlert(null, deleteResp);
    this.stopLoading();
  }
}
