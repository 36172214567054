import React from "react";
import autoBind from 'react-autobind';
import { Row, Col, Button, Table, Tooltip, message, Layout, PageHeader, Drawer, Form, Input, List } from 'antd';
import { EyeOutlined, FileSearchOutlined } from '@ant-design/icons';
//
import config from "@/config/config";
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";

export default class AdminVaultDockets extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sortedInfo: null,
      data: [],
      docket: {},
      attributes: [],
      isLoading: false,
    };
  }

  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    this._loadVaultDockets();
  }

  //Form methods
  async validateFields() { return await this.form.validateFields(); }
  setFieldsValue(data) { this.form.setFieldsValue(data); }

  // Table support
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  handleViewDocketDocument(tObj) {
    const id = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.docketDoc,
      null,
      id,
      tObj.id
    );
  }

  handleViewDocket(tObj) {
    this._loadDocket(tObj.id);
    this.setState({ drawerVisible: true });
  }

  onClose() {
    this.setState({ drawerVisible: false, docket: {} });
  }

  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <PageHeader className='pageHeader' title={'Dockets'} onBack={() => window.history.back()} />
        <Layout.Content>
          {this.renderDocketsTable()}
          {this.renderDocketDrawer()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  //private UI
  renderDocketsTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      size: 'small',
      onChange: this.handleFilterChange,
      rowKey: 'id',
      loading: this.props.isLoading,
      locale: { emptyText: 'No dockets founds in this vault.' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: ['bottomRight'] }
    };

    const columns = [
      {
        title: 'ID', dataIndex: 'id', key: 'id', width: 150,
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      },
      {
        title: 'Name', dataIndex: 'name', key: 'name', width: 150,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      {
        title: 'Size', dataIndex: 'docketSize', key: 'docketSize', width: 150,
        sorter: (a, b) => a.docketSize.localeCompare(b.docketSize),
        sortOrder: sortedInfo.columnKey === 'docketSize' && sortedInfo.order,
      },
      {
        title: 'Documents', dataIndex: 'docketNumDocs', key: 'docketNumDocs', width: 150,
        sorter: (a, b) => a.docketNumDocs.localeCompare(b.docketNumDocs),
        sortOrder: sortedInfo.columnKey === 'docketNumDocs' && sortedInfo.order,
      },
      {
        title: 'Tags', dataIndex: 'tags', key: 'tags', width: 150,
        sorter: (a, b) => a.tags.localeCompare(b.tags),
        sortOrder: sortedInfo.columnKey === 'tags' && sortedInfo.order,
        render: (tags) => tags ? tags.map((tag) => tag).join(',') : '-'
      },
      {
        title: 'Deleted', dataIndex: 'deleted', key: 'deleted', width: 150,
        sorter: (a, b) => a.deleted - b.deleted,
        sortOrder: sortedInfo.columnKey === 'deleted' && sortedInfo.order,
        render: deleted => deleted ? Utils.getDateOnUIFormatByTimestamp(deleted) : '-',
      },
      {
        title: 'Actions',
        width: 80,
        key: 'Actions',
        render: (record) => {
          return (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title='View Docket Details'>
                <Button
                  variant="none"
                  icon={<EyeOutlined />}
                  style={{ marginRight: '5px' }}
                  shape="circle"
                  onClick={() => this.handleViewDocket(record)}
                />
              </Tooltip>
              <Tooltip placement="bottomLeft" title='View Docket Document'>
                <Button
                  variant="none"
                  icon={<FileSearchOutlined />}
                  shape="circle"
                  onClick={() => this.handleViewDocketDocument(record)}
                />
              </Tooltip>
            </span>
          );
        },
      },
    ];

    return (
      <>
        <Row type="flex">
          <Col offset={1} span={22}>
            <Table dataSource={this.state.data} columns={columns} {...props} />
          </Col>
        </Row>
      </>
    );
  }

  renderDocketDrawer() {
    const { docket, drawerVisible, attributes } = this.state;
    if (!docket) { return null; }

    return (
      <Drawer
        title="Docket Details"
        placement="right"
        closable={true}
        onClose={this.onClose}
        visible={drawerVisible}
        width={720}
      >
        <Layout.Content>
          <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="ID" name="id" initialValue={docket.id}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Name" name="name" initialValue={docket.name}>
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Size" name="docketSize" initialValue={docket.docketSize}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Documents" name="docketNumDocs" initialValue={docket.docketNumDocs}>
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Tags" name="tags" initialValue={docket.tags}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Deleted" name="deletionDate" initialValue={docket.deletionDate}>
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Attributes">
                  <Table
                    dataSource={attributes}
                    rowKey="id"
                    pagination={false}
                    columns={[
                      {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                      },
                      {
                        title: 'Value',
                        dataIndex: 'value',
                        key: 'value',
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>

          </Form>

        </Layout.Content>
      </Drawer>
    );
  }

  /* Private API operations */
  async _loadVaultDockets() {
    this.startLoading();
    const id = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    try {
      const resp = await this.props.app.ikFileService.vault.getDockets(id);
      this.setState({ data: resp.dockets, });
    } catch (error) {
      console.error("Error occurred while loading:", error);
      message.error(`Error occurred while loading: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
    this.stopLoading();
  }

  async _loadDocket(docketId) {
    this.startLoading();
    const vaultId = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const expanded = true;
    if (!vaultId || !docketId) return;
    try {
      const resp = await this.props.app.ikFileService.vault.getDocket({ vaultId, docketId, expanded });
      this.setState({ docket: resp, attributes: resp.attributes }, () => {
        this.form.setFieldsValue(resp);
      });
    } catch (error) {
      console.error("Error occurred while loading:", error);
      message.error(`Error occurred while loading: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
    this.stopLoading();
  }
}
