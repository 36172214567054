import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button } from 'antd';
import scrollIntoView from 'scroll-into-view';
import { DownloadOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import PersistentSearch from '@/components/helpers/PersistentSearch';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonOrganizationsSearchBar from '../commonComponents/CommonOrganizationsSearchBar';
import CommonOrganizationsTable from '../commonComponents/Table/CommonOrganizationsTable';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminOrganizationsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.persistentSearch = new PersistentSearch();
    this.state = {
      isLoading: false,
      orgs: [], orgsTotal: 0,
      ...this._getInitialState(),
    };
  }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    await this.props.app.sharedCache().loadOrganizationCities(); //load once on the scree (reload if re-enter)
    await this.props.app.sharedCache().loadConfigCustomerWorksafeTerm();
    this.fetchData();
  }

  //Actions
  handleSearch(term) { this.setState({ searchTerm: term, currentPage: 1, sortedInfo: null }, this.fetchData); }
  handleSearchChange(value) {
    if (!value) this.setState({ searchTerm: null, currentPage: 1, sortedInfo: null }, this.fetchData);
  }
  handleExportXLSX() { this._exportSearch(); }
    //Org actions
  handleOrgCreate() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_organization, null, Globals.URL_NewIndentifier); }
  handleOrgEdit(orgObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_organization, null, orgObj.id); }
  handleOrgAction(orgObj, actionName) {
    //set navigation items details
    this.props.app.appsManager?.navigation?.appendValue('orgID', orgObj.id);
    this.props.app.appsManager?.navigation?.appendValue('orgName', orgObj.name);
    //navigate
    this.props.app.urlManager.pushPage(config.ApplicationRoutes[`org_${actionName}`], { n: orgObj.name }, orgObj.id);
  }
    //Table internal actions
  handleTablePageChange(currentPage) { this.setState({ currentPage }, this.fetchData); }
  handleTableChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }, this.fetchData); }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className='pageHeader' title="Companies"
              extra={[
                <Button key="1" onClick={this.handleOrgCreate} type='primary'>Create Company</Button>,
                <Button key="2" type="primary" icon={<DownloadOutlined />} onClick={this.handleExportXLSX}
                  disabled={this.state.orgs.length < 1} style={{ marginLeft: 20, marginRight: -4 }}> Export to XLSX </Button>
                ]}/>
          <Layout.Content>
            <CommonOrganizationsSearchBar persistentSearch={this.persistentSearch} isLoading={this.state.isLoading}
                onSearch={this.handleSearch} {...Utils.propagateRef(this, 'searchBar')} app={this.props.app}/>
            <CommonOrganizationsTable app={this.props.app} isLoading={this.state.isLoading} total={this.state.orgsTotal}
                currentPage={this.state.currentPage} orgs={this.state.orgs} sortedInfo={this.state.sortedInfo}
                onPageChange={this.handleTablePageChange} onFilterChange={this.handleTableChange}
                onOrgEdit={this.handleOrgEdit} onRowAction={this.handleOrgAction} worksafeTerm={this.state.worksafeTerm}/>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* private */
  _getInitialState() {
    const currentPage = parseInt(this.persistentSearch.getParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.persistentSearch.getParam(Globals.URLQueryParam_SortField) || 'name.keyword';
    const order = this.persistentSearch.getParam(Globals.URLQueryParam_SortOrder) || 'ascend';
    return { currentPage, sortedInfo: { columnKey, order } };
  }
  _reloadURLParams() {
    this.persistentSearch.setParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.persistentSearch.setParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : null));
    this.persistentSearch.setParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }
  _getSearchFilter() {
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    const searchBarObjs = this.searchBar ? this.searchBar.getSearchFilters() : {};
    return { noDefaultSorting: true, from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'), ...searchBarObjs };
  }
  
  /* remote data */
  async fetchData() {
    if (this.state.isLoading) return;
    this.startLoading();
    const worksafeTerm = this.props.app.sharedCache().getConfigCustomerWorksafeTerm() || Globals.WorksafeTermDefault;
    //request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const filter = this._getSearchFilter();
    if (!searchTerm || searchTerm.length == 0) filter.noDefaultSorting = false;
    const resp = await this.props.app.organization.organization.searchOrganizationsByTerm(searchTerm, filter);
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.orgs) {
      const orgs = resp.body.orgs;
      this.setState({ orgs, orgsTotal: resp.body.total, isLoading: false, worksafeTerm });
      scrollIntoView(document.getElementById('root'), { time: 500, align: { top: 0 } });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
    this._reloadURLParams();
  }
  async _exportSearch() {
    this.startLoading();
    //request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const resp = await this.props.app.organization.organization.exportOrganizationsSearch(searchTerm, this._getSearchFilter());
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body) Utils.downloadBlob(resp.body, 'companies', 'xlsx');
    else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.stopLoading();
  }
}
