import React, { Component } from "react";
import autoBind from 'react-autobind';
import { IDMGlobals } from "@ikonintegration/idmclient";
import { Layout, Alert } from 'antd';
import { isIE } from 'react-device-detect';
import { encode as base64_encode } from 'base-64';
//
import AppRoutes from "./AppRoutes";
import CommonLoadingView from './views/commonComponents/CommonLoadingView';
import AppHeader from "./views/commonComponents/Header/AppHeader";
//
import "./stylesheets/AppViewController.less";
//
import config from "./config/config";
import Globals from "./config/Globals";
//
export default class UnauthorizedViewController extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.props.app.props.history.location.state = undefined; //reset history state if restarting app
    this.props.app.idm.sessionLoadErrorHandler = this.sessionLoadErrorHandler.bind(this);
  }
  //UI
  render() {
    return this.renderPage();
  }
  renderPage() {
    const themeAlert = this.props.app?.themeManager?.theme?.branding?.alert;
    const launchConfigAvailable = this.props.app.configManager.isAvailable();
    return (
      <Layout className="container-layout">
        {themeAlert && <Alert style={{ zIndex: 9999 }} message={themeAlert?.content} type={themeAlert?.type || 'info'} banner />}
        {isIE && <Alert message={Globals.CompabilityMessage} banner />}
        <AppHeader app={this.props.app}/>
        {launchConfigAvailable ? 
          <Layout.Content className="main-content">
            <AppRoutes appRef={this}/>
          </Layout.Content> :
          <Layout.Content className="main-content">
            <CommonLoadingView isLoading/>
          </Layout.Content>}
      </Layout>
    );
  }

  //Handle login cases from User interaction and IDM clients redirect
  async sessionLoadErrorHandler(loginResp, username, isRenew) {
    if (loginResp.statusCode == 400) {
      this.props.app.alertController.showAPIErrorAlert(null, loginResp);
    } else if (loginResp.statusCode == 200 || loginResp.statusCode == 401) {
      const challengeState = (loginResp.body && loginResp.body.challengeState ? loginResp.body.challengeState :
                              (loginResp.body && loginResp.body.errCode ? loginResp.body.errCode : ''));
      //Success
      if (challengeState == IDMGlobals.AuthorizationChallengeState_Authorized) {
        const userObj = await this.props.app.idm.session.data.getUserObject();
        this.props.app.alertController.showSuccessAlert("", `Welcome ${userObj.firstName}!`); //welcome message
        await this.props.app.sharedCache().loadCache(); //load app cache manually
        this.props.app.urlManager.pushPage(config.ApplicationRoutes.homepage); //push to main page
      }
      //Reset pass required
      else if (challengeState == IDMGlobals.AuthorizationChallengeState_PasswordResetRequired) {
        this.props.app.alertController.showWarningAlert('Could not login!', loginResp.body.message);
        this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.resetPasswordConfirmation, null, username); //push to main page
      }
      //New pass required
      else if (challengeState == IDMGlobals.AuthorizationChallengeState_NewPasswordRequired) {
        this.props.app.alertController.showWarningAlert('Could not login!', loginResp.body.message);
        this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.setupPassword, null, username, loginResp.body.challengeContext); //push to main page
      }
      //Confirmation required
      else if (challengeState == IDMGlobals.AuthorizationChallengeState_ConfirmationRequired) {
        this.props.app.alertController.showWarningAlert('Could not login!', loginResp.body.message);
        this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.registrationConfirmation, null, loginResp.body.registrationToken, username); //push to main page
      }
      //SMS MFA required
      else if (challengeState == IDMGlobals.AuthorizationChallengeState_SMSMFARequired) {
        this.props.app.alertController.showWarningAlert('Could not login!', loginResp.body.message);
        this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.smsMFA, { extra: base64_encode(JSON.stringify(loginResp.body.extra)), username, challenge: loginResp.body.challengeContext }); //push to main page
      }
      //Invalid MFA
      else if (challengeState == IDMGlobals.AuthorizationErrorCode_InvalidMFACode) {
        this.props.app.alertController.showWarningAlert('Login error!', loginResp.body.message);
      }
      //Handling auth challenges errors
      else if (challengeState != IDMGlobals.AuthorizationChallengeState_Forbidden &&
               challengeState != IDMGlobals.AuthorizationChallengeState_NotFound) {
        this.props.app.alertController.showWarningAlert('Could not login!', loginResp.body.message);
        this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.authError, null, null, null, loginResp.body); //push to main page
      }
      //Unhandled error
      else if (!isRenew) {
        this.props.app.alertController.showAPIErrorAlert("Login error!", loginResp);
      }
    } else { //probably connection error or server is offline :/
      this.props.app.alertController.showAPIErrorAlert("Unknown error!", JSON.stringify(loginResp));
    }
  }
}
