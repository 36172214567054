export default {
    "sections": [
        {
            "name": "Address and Contact",
            "beforeRow": 2
        }
    ],
    "fields": {
        "employerName": {
            "row": 0,
            "orderInRow": 0,
            "widthPercentage": 55,
            "id": 'employer.name',
            "label": 'Employer name',
            "type": 'text',
            "partitionID": 'ca.bccsa.professional',
            "required": false,
            "customProps": { "maxLength": 50 }
        },
        "dateOfBirth": {
            "row": 1,
            "orderInRow": 0,
            "widthPercentage": 35,
            "id": "dateOfBirth",
            "label": "Date of Birth",
            "type": "date",
            "format": "YYYY-MM-DD",
            "partitionID": "ca.bccsa.personal",
            "saveOnUser": false,
            "required": true,
            "placeholder": "yyyy-mm-dd",
            "allowFutureValue": false
        },
        "address.unit": {
            "row": 2,
            "orderInRow": 0,
            "id": "address.unit",
            "label": "Apt/Unit",
            "type": "text",
            "partitionID": "ca.bccsa.personal",
            "saveOnUser": false,
            "required": false
        },
        "addressStreetZero": {
            "row": 2,
            "orderInRow": 1,
            "id": "address.street.0",
            "label": "Street Address",
            "type": "text",
            "partitionID": "ca.bccsa.personal",
            "saveOnUser": false,
            "required": true
        },
        "addressStreetOne": {
            "row": 2,
            "orderInRow": 2,
            "id": "address.street.1",
            "label": "Street Address 2",
            "type": "text",
            "partitionID": "ca.bccsa.personal",
            "saveOnUser": false,
            "required": false
        },
        "addressCity": {
            "row": 3,
            "orderInRow": 0,
            "id": "address.city",
            "label": "City",
            "type": "text",
            "partitionID": "ca.bccsa.personal",
            "saveOnUser": false,
            "required": true
        },
        "addressProvince": {
            "row": 3,
            "orderInRow": 1,
            "id": "address.province",
            "label": "Province",
            "type": "text",
            "partitionID": "ca.bccsa.personal",
            "required": true,
            "saveOnUser": false
        },
        "addressCountry": {
            "row": 3,
            "orderInRow": 2,
            "id": "address.country",
            "label": "Country",
            "type": "text",
            "partitionID": "ca.bccsa.personal",
            "saveOnUser": false,
            "required": true
        },
        "addressPostalCode": {
            "row": 4,
            "orderInRow": 0,
            "id": "address.postalCode",
            "label": "Postal Code",
            "type": "text",
            "partitionID": "ca.bccsa.personal",
            "saveOnUser": false,
            "required": true
        },
        "phoneNumber": {
            "row": 4,
            "orderInRow": 1,
            "id": "phoneNumber",
            "label": "Mobile Number",
            "customProps": {},
            "type": "tel",
            "partitionID": "ca.bccsa.personal",
            "required": true,
            "saveOnUser": true
        },
        "addressPhone": {
            "row": 4,
            "orderInRow": 2,
            "id": "address.phone",
            "label": "Other Number",
            "customProps": {},
            "type": "tel",
            "partitionID": "ca.bccsa.personal",
            "required": false,
            "saveOnUser": false
        }
    }
}