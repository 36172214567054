import React from 'react';
import autoBind from 'react-autobind';
import { Typography, Button, Row, Col, Table, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import Globals from '@/config/Globals';

//props are: app, isLoading, disabled, onDelete, onEdit, onAdd, fields
export default class CommonRegistrationFieldsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: { order: 'ascend', columnKey: 'row'} };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      { title: 'Label', key: 'label', sorter: true,
        sortOrder: sortedInfo.columnKey === 'label' && sortedInfo.order, 
        sorter: (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
        render: props => props.label && props.label.length > 0 ? props.label : 'N/A' 
      },
      { title: 'ID', key: 'id', sorter: true,
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order, 
        sorter: (a, b) => a.id.toLowerCase().localeCompare(b.id.toLowerCase()),
        render: props => props.id && props.id.length > 0 ? props.id : 'N/A' 
      },
      { title: 'Type', key: 'type', sorter: true,
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order, 
        sorter: (a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()),
        render: props => props.type && props.type.length > 0 ? props.type : 'N/A' 
      },
      { title: 'Required', key: 'required', sorter: true,
        sortOrder: sortedInfo.columnKey === 'required' && sortedInfo.order, 
        sorter: (a, b) => (a.required ? 1 : 0) - (b.required ? 1 : 0),
        render: props => props.required ? 'Yes' : 'No'
      },
      { title: 'Row', key: 'row', sorter: true,
        sortOrder: sortedInfo.columnKey === 'row' && sortedInfo.order, 
        sorter: (a, b) => (a.row || 0) - (b.row || 0),
        render: props => props.row
      },
      { title: 'Actions', width: 100, key: 'Actions', align: 'center',
         render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit Field'>
              <Button variant="none" disabled={this.props.disabled} icon={<EditOutlined />} shape="circle" onClick={this.props.onEdit.bind(this, props)}/>
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Delete Field'>
              <Popconfirm title={`Do you really want to delete field '${props.label}'?`} placement="bottomRight" onConfirm={this.props.onDelete.bind(this, props)} okText="Yes" cancelText="No">
                <Button variant="none" disabled={this.props.disabled} icon={<DeleteOutlined />} shape="circle" style={{marginLeft: 10}}/>
              </Popconfirm>
            </Tooltip>
          </span>
        ),
      }
    ];
    const props = { rowKey: (p) => `${p.id}`, loading: this.props.isLoading, onChange: this.handleFilterChange, size: 'small',
                    locale: {emptyText: 'No fields found for this schema!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (
      <Row type='flex' style={{marginBottom: 20}}>
        <Col span={24}>
          <Row type='flex' justify="middle" align="center" style={{marginTop: 10, marginBottom: 10}}>
            <Col span={20} align="center"> <Typography.Title level={5}>Fields</Typography.Title> </Col>
            <Col offset={1} span={3} > <Button type='primary' disabled={this.props.disabled} onClick={this.props.onAdd.bind(this)}>Add field</Button> </Col>
          </Row>
          <Table className="regFieldsTable" columns={columns} dataSource={Object.values(this.props.fields || {})} {...props}/>
        </Col>
      </Row>
    );
  }
}