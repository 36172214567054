import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/helpers/Utils';
import { Typography, Layout, Row, Col, Form, Input, Checkbox, Select } from 'antd';
import Globals from "@/config/Globals";

export default class RootAppForm extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
    }

    validateFields() { return this.form.validateFields(); }

    handleChangeSelect(id, value) {
      this.props.onChange({
        target: { id, value },
      });
    }

    componentDidUpdate() {
      const data = this.props.data;
      if (data) {
        this.form.setFieldsValue({
          customEmailSenderEnabled: !!data.customEmailSender,
          customEmailTemplateEnabled: !!data.customEmailTemplate,
          customDomainEnabled: !!data.customDomain,
          ...data
        });
      }
    }

    render() {
      const editMode = this.props.editMode;
      return(
        <Layout.Content justify="right-align">
          <Typography.Title level={3}>Details</Typography.Title>
          <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
            <Row gutter={12}>
              <Col span={6} offset={2}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: 'Please, type application name!' },
                    { min: 2, max: 255, message: 'App name must be between 2 and 255 characters' },
                  ]}
                >
                  <Input disabled={!editMode} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
              <Col span={6} offset={2}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    { required: true, message: 'Please, type application description!' },
                  ]}
                >
                  <Input.TextArea disabled={!editMode} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
            </Row>
            
            <Row gutter={12}>
              
              <Col span={6} offset={2}>
                <Form.Item
                  name="customDisplayName"
                  label="Custom Display Name"
                  rules={[
                    { required: false },
                    { min: 2, max: 255, message: 'Custom display name must be between 2 and 255 characters' },
                  ]}
                >
                  <Input disabled={!editMode} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
              <Col span={2} offset={2}>
                <Form.Item name="mfaRequired" label="MFA Required" valuePropName="checked">
                  <Checkbox disabled={!editMode} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
              <Col span={4} offset={0}>
                <Form.Item name="CORS" label="CORS" defaultValue={[]}>
                  <Select mode="tags" onChange={this.handleChangeSelect.bind(this, 'CORS')} disabled={!editMode} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={2} offset={2}>
                <Form.Item name="customEmailSenderEnabled" label="Enable email custom sender?" valuePropName="checked">
                  <Checkbox disabled={!editMode} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
              <Col span={4} offset={0}>
                <Form.Item name="customEmailSender" label="Sender" rules={[{
                  required: this.props.data.customEmailSenderEnabled, message: 'Custom email sender is enabled and but no sender is provided!'
                }]}>
                  <Input type='email' disabled={!editMode || !this.props.data.customEmailSenderEnabled} onChange={this.props.onChange} />
                </Form.Item>
              </Col>

              <Col span={2} offset={2}>
                <Form.Item name="customDomainEnabled" label="Enable email custom domain?" valuePropName="checked">
                  <Checkbox disabled={!editMode} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
              <Col span={4} offset={0}>
                <Form.Item name="customDomain" label="URL" rules={[{
                  required: this.props.data.customDomainEnabled, message: 'Custom email domain is enabled and but no domain is provided!'
                }]}>
                  <Input disabled={!editMode || !this.props.data.customDomainEnabled} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={2} offset={2}>
                <Form.Item name="customEmailTemplateEnabled" label="Enable email custom template?" valuePropName="checked">
                  <Checkbox disabled={!editMode} onChange={this.props.onChange} />
                </Form.Item>
              </Col>
              <Col span={4} offset={0}>
                <Form.Item name="customEmailTemplate" label="Template" rules={[{
                  required: this.props.customEmailTemplateEnabled, message: 'Custom email sender is enabled and but no template is provided or default is selected!'
                }]}>
                  <Select value={this.props.customEmailTemplate} onChange={this.handleChangeSelect.bind(this, 'customEmailTemplate')} disabled={!editMode || !this.props.data.customEmailTemplateEnabled}>
                    {Globals.ApplicationEmailThemes.map((opt) => {
                      return (<Select.Option key={opt || 'default'} value={opt || ''}>{opt || 'default'}</Select.Option>);
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6} offset={2}>
                <Form.Item name="createdOn" label="Created On">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col span={6} offset={2}>
                <Form.Item name="updatedBy" label="Updated By">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6} offset={2}>
                <Form.Item name="updatedOn" label="Updated On">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Layout.Content>
      );
    }
}
