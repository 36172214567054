import React from 'react';
import autoBind from 'react-autobind';
import { Divider, Layout, PageHeader, Alert, Row, Col, Typography, Select } from 'antd';
//
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonOrganizationProgramsTable from '../commonComponents/Table/CommonOrganizationProgramsTable';
import CommonOrganizationMembersTable from '../commonComponents/Table/CommonOrganizationMembersTable';
import AdminOrganizationOptions, { AdminOrganizationOptionTypes } from '../commonComponents/AdminOrganizationOptions';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminOrganizationProgramsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false, data: [],
      orgID: this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this),
      orgName: this.props.app.urlManager.getQueryParam('n', this) || 'Company',
      filterPrograms: []
    };
    //
    this.viewOptions = new AdminOrganizationOptions(AdminOrganizationOptionTypes.PROGRAMS, this.state.orgID, this.props.app, this.state.orgName);
  }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    //set navigation items details
    this.props.app.appsManager?.navigation?.appendValue('orgID', this.state.orgID);
    this.props.app.appsManager?.navigation?.appendValue('orgName', this.state.orgName);
    //load data
    this._fetchData();
  }

  //Actions
  handleProgramsFilterChange(filterValues) { this.setState({ filterPrograms: filterValues }); }

  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className='pageHeader' title={`${this.state.orgName} - Application Data`} onBack={() => window.history.back()} extra={this.viewOptions.getOptions()}/>
          <Layout.Content className="pageSubContent">
            {/* Incomplete results alert */}
            {this.state.incompleteResults && <Alert type='info' description={`Attention: This company has too many members and therefore this view might be showing incomplete data!`} />}
            {/* Filter */}
            {this._renderProgramsFilter()}
            {/* Data components */}
            <Divider orientation='left'>Programs</Divider>
              <CommonOrganizationProgramsTable app={this.props.app} isLoading={this.state.isLoading} programs={this._getFilteredOApps()} tenants={this.state.tenants}/>
            <Divider orientation='left'>Members</Divider>
              <CommonOrganizationMembersTable app={this.props.app} isLoading={this.state.isLoading} members={this._getAggregatedMembers()} tenants={this.state.tenants}/>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderProgramsFilter() {
    return (
      <Row type='flex' align='end'>
        <Col>
          <Typography.Text strong style={{marginRight: 10}}>Filter Programs:</Typography.Text>
          <Select value={this.state.filterPrograms} onChange={this.handleProgramsFilterChange.bind(this)} className="programsFilter"
                  disabled={this.state.isLoading} mode='multiple' style={{minWidth: 300}} placeholder="Filter by one or more programs">
            {(this.state.tenants || []).map((val, index) => (<Select.Option key={index} value={val.id}> {val.name} </Select.Option>))}
          </Select>
        </Col>
      </Row>
    );
  }
  /* data helper */
  _getFilteredOApps() {
    return this.state.data.filter((oapp) => {
      return !(this.state.filterPrograms.length > 0 && !this.state.filterPrograms.includes(oapp.tenantID));
    });
  }
  _getAggregatedMembers() {
    const members = {};
    for (const app of this.state.data) {
      //Check if needs to skip
      if (this.state.filterPrograms.length > 0 && !this.state.filterPrograms.includes(app.tenantID)) continue;
      //members
      for (const member of (app.members || [])) {
        const program = { tenantID: app.tenantID, role: Globals.OrganizationApp_ProgramsMembersTypes.MEMBER, confirmedOn: member.createdOn };
        const user = (this.state.additionalUsers || []).find((u) => u.id == member.userID);
        members[member.email] = { firstName: user?.firstName, lastName: user?.lastName, ...(members[member.email] || {}), email: member.email, id: member.userID, programs: (members?.[member.email]?.programs || []).concat([program])};
      }
      //unconfirmedMembers
      for (const member of (app.unconfirmedMembers || [])) {
        const program = { tenantID: app.tenantID, role: Globals.OrganizationApp_ProgramsMembersTypes.MEMBER, confirmedOn: null };
        const user = (this.state.additionalUsers || []).find((u) => u.id == member.userID);
        members[member.email] = { firstName: user?.firstName, lastName: user?.lastName, email: member.email, id: member.userID, ...(members[member.email] || {}), programs: (members?.[member.email]?.programs || []).concat([program])};
      }
      //invitations
      for (const member of (app.invitations || [])) {
        const program = { tenantID: app.tenantID, role: Globals.OrganizationApp_ProgramsMembersTypes.INVITED, confirmedOn: null };
        const user = (this.state.additionalUsers || []).find((u) => u.id == member.userID);
        members[member.email] = { firstName: user?.firstName, lastName: user?.lastName, email: member.email, id: member.userID, ...(members[member.email] || {}), programs: (members?.[member.email]?.programs || []).concat([program])};
      }
      //managers
      for (const member of (app.managers || [])) {
        const program = { tenantID: app.tenantID, role: Globals.OrganizationApp_ProgramsMembersTypes.MANAGER };
        members[member.email] = {...(members[member.email] || {}), email: member.email, firstName: member.firstName, lastName: member.lastName, id: member.id, programs: (members?.[member.email]?.programs || []).concat([program])};
      }
    } return Object.values(members);
  }
  /* remote data */
  async _fetchData() {
    if (this.state.isLoading) return;
    this.startLoading();
    //request
    const resp = await this.props.app.organization.organizationApp.describeOrganizationAppsByOrgID(this.state.orgID);
    if (!this._isMounted) return; //Important, check if is mounted
    //
    if (resp.statusCode == 200 && resp.body && resp.body.apps) {
      this.setState({ data: resp.body.apps, isLoading: false, tenants: resp.body.tenants, incompleteResults: !resp.body.completeResults }, () => {
        const missingUserIDs = this._getAggregatedMembers().filter((m) => !m.firstName && m.id).map((m) => m.id);;
        if (missingUserIDs.length > 0) this._fetchMissingUsers(missingUserIDs);
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _fetchMissingUsers(usersIDs) {
    console.log('Fetching users by IDs', usersIDs);
    this.setState({ loadingUsers: true });
    const resp = await this.props.app.idm.api.user.searchUsersByIDs(usersIDs);
    if (resp.statusCode == 200 && resp.body) {
      const users = (resp.body.users || []).map((u) => u._source);
      this.setState({ additionalUsers: users, loadingUsers: false });
    } else this.setState({ loadingUsers: false });
  }
}
