import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Row, Col, Typography, Radio, Button, DatePicker, Modal, Form, TimePicker, message, Input } from 'antd';
import { CaretLeftOutlined, SwapRightOutlined, CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { MdViewWeek } from 'react-icons/md';
import { CgViewMonth } from 'react-icons/cg';

//
import Globals from '../../config/Globals';
//
import WhiteBox from './WhiteBox';
import Utils from '@/components/helpers/Utils';

//props are: app, anyRangeMode, onDateChange
export default class CommonSessionsDateRangeSelector extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    //Defaults
    const dateType = parseInt(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Type) || ( this.props.anyRangeMode ? 3 : 2 ));
    const fromRawDate = (this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_From) ? moment(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_From), Globals.DefaultDateFormat) : moment());
    const fromDate = (fromRawDate.isValid() ? fromRawDate : moment()).clone().startOf((dateType == 1 ? 'week' : 'month')).startOf('day');
    const toRawDate = (this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_To) ? moment(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_To), Globals.DefaultDateFormat) : null);
    this.state = {
      from: fromDate, dateType,
      to: (this.props.anyRangeMode && toRawDate && toRawDate.isValid() ? toRawDate.clone() : (fromRawDate.isValid() ? fromRawDate : moment()).clone().endOf((dateType == 1 ? 'week' : 'month')).endOf('day')),
      isTimeEntryModalOpen: false, isLoading: false, isEditingEntry: false, editingEntry: null,
    };
  }
  //Life cycle
  componentDidMount() { this._updateDelegate(); }

  //actions
  openEditModal(event) {
    this.setState({ isTimeEntryModalOpen: true, isEditingEntry: true, editingEntry: event }, () => {
      // Set fields values inside setTimeout with 0 delay
      // to be executed in cb queue after the modal mounts
      setTimeout(() => {
        const startTime = moment(event.startTime);
        const endTime = moment(event.endTime);
        const description = event.description;

        this.addTimeEntryForm.setFieldsValue({
          startDate: startTime,
          startTime,
          endDate: endTime,
          endTime,
          description,
        });
      });

    });
  }

  handleDateTypeChange(newVal) {
    if (newVal.target.value == 1) {
      console.log(this.state.from)
      this.setState({
        dateType: newVal.target.value,
        from: moment().startOf('week').startOf('day'),
        to: moment().endOf('week').endOf('day')
      }, this._updateDelegate);
    } else this.setState({
      dateType: newVal.target.value,
      from: this.state.from.clone().startOf('month').startOf('day'),
      to: this.state.to.clone().endOf('month').endOf('day')
    }, this._updateDelegate);
  }
  handleDateStepChange(diff) {
    const from = moment(this.state.from, Globals.DefaultDateFormat)[(diff == '-1' ? 'subtract' : 'add')](1, (this.state.dateType == 2 ? 'months' : 'weeks'));
    if (this.state.dateType == 1) {
      this.setState({ from: from.startOf('week').startOf('day'), to: from.clone().endOf('week').endOf('day') }, this._updateDelegate);
    } else {
      this.setState({ from: from.startOf('month').startOf('day'), to: from.clone().endOf('month').endOf('day') }, this._updateDelegate);
    }
  }
  handleRangeDataChange(value) { 
    if (this.state.dateType == 1) {
      this.setState({ from: value.startOf('week').startOf('day'), to: value.clone().endOf('week').endOf('day') }, this._updateDelegate);
    } else if (this.state.dateType == 2) {
      this.setState({ from: value.startOf('month').startOf('day'), to: value.clone().endOf('month').endOf('day') }, this._updateDelegate);
    } else {
      this.setState({ from: value[0].startOf('day'), to: value[1].endOf('day') }, this._updateDelegate);
    }
  }
  handleOpenTimeEntryModal() {
    this.setState({ isTimeEntryModalOpen: true, isEditingEntry: false });
  }
  handleCloseTimeEntryModal() {
    this.setState({ isTimeEntryModalOpen: false, isEditingEntry: false, editingEntry: null })
  }

  async handleSubmitTimeEntryForm() {
    const formData = await this.addTimeEntryForm.validateFields();
    if (!formData) return;

    const startDate = formData.startDate.format('YYYY-MM-DD');
    const startTime = formData.startTime.format('HH:mm:ss');
    const endDate = formData.endDate.format('YYYY-MM-DD');
    const endTime = formData.endTime.format('HH:mm:ss');
    const fullStartTime = moment(`${startDate} ${startTime}`);
    const fullEndTime = moment(`${endDate} ${endTime}`);
    const description = formData.description;

    if (fullEndTime.isBefore(fullStartTime)) {
      message.error(`The end date should not be before the start date.`);
      return;
    }

    if (this.state.isEditingEntry && this.state.editingEntry) {
      this._editTimeEntry({ fullStartTime, fullEndTime, description });
    } else {
      this._addNewTimeEntry({ fullStartTime, fullEndTime, description });
    }
  }

  //
  render() {
    return (
      <WhiteBox isSecondary>
        {this._renderAddTimeEntryModal()}
        <Row type="flex" justify="center" align='middle'>
          {!this.props.anyRangeMode && <Col> <Button type='link' onClick={this.handleDateStepChange.bind(this, -1)}><CaretLeftOutlined style={{ color: 'grey', fontSize: '25px' }} /></Button> </Col>}
          <Col>
            {this.state.dateType == 1 && this._renderWeeklyFilter() }
            {this.state.dateType == 2 && this._renderMonthlyFilter() }
            {this.state.dateType == 3 && this._renderRangeFilter() }
          </Col>
          {!this.props.anyRangeMode && <Col> <Button type='link' onClick={this.handleDateStepChange.bind(this, 1)}><CaretRightOutlined style={{ color: 'grey', fontSize: '25px' }} /></Button> </Col>}
        </Row>
        {!this.props.anyRangeMode && <Row type="flex" justify="end" style={{ marginTop: '-31px' }}>
          <Col>
            <Radio.Group onChange={this.handleDateTypeChange} value={this.state.dateType}>
              <Radio.Button value={1}><MdViewWeek style={{ marginRight: '10px' }} /> Weekly</Radio.Button>
              <Radio.Button value={2}><CgViewMonth style={{ marginRight: '10px' }} />Monthly</Radio.Button>
            </Radio.Group>
          </Col>

          <Col style={{ marginLeft: 24 }}>
            <Button type="primary" onClick={this.handleOpenTimeEntryModal}>
              Add Time Entry
            </Button>
          </Col>
        </Row>}
      </WhiteBox>
    );
  }

  // API Calls
  async _addNewTimeEntry({ fullStartTime, fullEndTime, description }) {
    this.setState({ isLoading: true });

    const instructorID = this.props.app.getAuthorizedUserID();
    const resp = await this.props.app.classroom.instructorCalendar.createAvailabilityEvent(instructorID, {
      startTime: fullStartTime.toDate().getTime(),
      endTime: fullEndTime.toDate().getTime(),
      description,
    });

    if (resp.statusCode == 200) {
      this.setState({ isLoading: false });
      this.handleCloseTimeEntryModal();
      this.props.onTimeEntryAdded();

      message.success('Time entry successfully added.');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
  async _editTimeEntry({ fullStartTime, fullEndTime, description }) {
    this.setState({ isLoading: true });

    const instructorID = this.props.app.getAuthorizedUserID();
    const resp = await this.props.app.classroom.instructorCalendar.updateAvailabilityEvent(instructorID, {
      startTime: fullStartTime.toDate().getTime(),
      endTime: fullEndTime.toDate().getTime(),
      description
    });

    if (resp.statusCode == 200) {
      this.setState({ isLoading: false });
      this.handleCloseTimeEntryModal();
      this.props.onTimeEntryEdited();

      message.success('Time entry successfully updated.');
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ isLoading: false });
    }
  }

  /* private renders */
  _renderAddTimeEntryModal() {
    return (
      <Modal
        destroyOnClose
        open={this.state.isTimeEntryModalOpen}
        onCancel={this.handleCloseTimeEntryModal}
        title={this.state.isEditingEntry ? 'Edit Time Entry' : 'Add new Time Entry'}
        onOk={this.handleSubmitTimeEntryForm}
        footer={[
          <Button key="cancel" onClick={this.handleCloseTimeEntryModal}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={this.state.isLoading} onClick={this.handleSubmitTimeEntryForm}>
            OK
          </Button>,
        ]}

      >
        <Form layout="vertical" {...Utils.propagateRef(this, 'addTimeEntryForm')}>
          <Row gutter={32}>
            <Col span={14}>
              <Form.Item
                label="Start date"
                name="startDate"
                getValueProps={d => ({ value: moment(d) })}
                initialValue={this.state.from}
                rules={[{ required: true, message: 'Start date is required!' }]}
              >
                <DatePicker
                  picker="date"
                  allowClear={false}
                  format={`${Globals.DefaultDateFormat}`}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Start time"
                name="startTime"
                rules={[{ required: true, message: 'Start time is required!' }]}
                getValueProps={t => {
                  if (t === undefined) return { value: null };
                  return { value: moment(t) };
                }}
              >
                <TimePicker
                  use12Hours
                  format="HH:mm"
                  allowClear={false}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          
          <Row gutter={32}>
            <Col span={14}>
              <Form.Item
                label="End date"
                name="endDate"
                getValueProps={d => {
                  if (d === undefined) return { value: null };
                  return { value: moment(d) };
                }}
                rules={[{ required: true, message: 'End date is required!' }]}
              >
                <DatePicker
                  picker="date"
                  allowClear={false}
                  format={`${Globals.DefaultDateFormat}`}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="End time"
                name="endTime"
                rules={[{ required: true, message: 'End time is required!' }]}
                getValueProps={t => {
                  if (t === undefined) return { value: null };
                  return { value: moment(t) };
                }}
              >
                <TimePicker
                  use12Hours
                  format="HH:mm"
                  allowClear={false}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="description"
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }

  _renderWeeklyFilter() {
    return (
      <DatePicker picker='week' onChange={this.handleRangeDataChange} bordered={false} allowClear={false} format={`${Globals.DefaultDateFormat}`} value={this.state.from} suffixIcon={
        <div style={{ width: '110px' }}>
          <SwapRightOutlined style={{ color: 'black', display: 'inline', marginRight: '10px' }} />
          <Typography.Text>
            {moment(this.state.to).format(Globals.DefaultDateFormat)}
          </Typography.Text>
        </div>} />
    );
  }
  _renderMonthlyFilter() {
    return (
      <DatePicker picker='month' onChange={this.handleRangeDataChange} bordered={false} allowClear={false} format={`${Globals.DefaultDateFormat}`} value={this.state.from} suffixIcon={
        <div style={{ width: '110px' }}>
          <SwapRightOutlined style={{ color: 'black', display: 'inline', marginRight: '10px' }} />
          <Typography.Text>
            {moment(this.state.to).format(Globals.DefaultDateFormat)}
          </Typography.Text>
        </div>} />
    );
  }
  _renderRangeFilter() {
    return (
      <DatePicker.RangePicker onChange={this.handleRangeDataChange} bordered={false} allowClear={false} format={`${Globals.DefaultDateFormat}`} value={[this.state.from, this.state.to]}/>
    );
  }

  /* private URL */
  _reloadURLParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_From, moment(this.state?.from).format(Globals.DefaultDateFormat));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_To, moment(this.state?.to).format(Globals.DefaultDateFormat));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Type, this.state?.dateType + '');
  }
  _updateDelegate() {
    this._reloadURLParams();
    if (this.props.onDateChange) this.props.onDateChange(this.state.from.toDate().getTime(), this.state.to.toDate().getTime(), this.state.dateType);
  }
}
