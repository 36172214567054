import React from "react";
import autoBind from 'react-autobind';
import { Layout, Button, PageHeader, Row, Col } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
//
import AdminContactForm from "./Forms/AdminContactForm";
//
export default class AdminContactView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      data: {}, applications: [],
      isLoading: false,
      contactID: this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this),
      contactName: this.props.app.urlManager.getQueryParam('n', this) || 'Contact'
    };
    this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) == Globals.URL_NewIndentifier);
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Contact`;
    //Check for id
    if(!this.isNew) this._fetchContact();
  }

  //Actions handler
  async handleSubmit() {
    //validate and retrieve form data
    const formData = await this.form.validateFields();
    if (!formData) return;
    //make operation
    if (this.isNew) this._addContact(formData);
    else this._saveContact(formData);
  }
  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <PageHeader className='pageHeader' title={this.isNew ? 'New Contact' : `${(this.state.data?.firstName || 'Loading...')} - Contact Information`} onBack={() => window.history.back()}/>
        {this._renderSubmit()}
        <Layout.Content>
          <AdminContactForm isNew={this.isNew} contact={this.state.data} tenants={this.state.tenants} applications={this.state.applications} {...Utils.propagateRef(this, 'form')}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private UI */
  _renderSubmit() {
    return (
      <Row type='flex' align='end'>
        <Col>
          <Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create Contact' : 'Save Contact'}</Button>
        </Col>
      </Row>
    );
  }

  /*Private methods*/
  async _addContact(data) {
    this.startLoading();
    const addResp = await this.props.app.organization.contact.createContact(data);
    const contactID = addResp.body.id;
    if (!this._isMounted) return;
    if (addResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Contact created with success!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_contact, null, encodeURIComponent(contactID));
      this.props.app.appsManager?.navigation?.appendValue('contactID', contactID);
      this._fetchContact();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, addResp);
      this.stopLoading();
    }
  }
  async _saveContact(data) {
    this.startLoading();
    const contactID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const updateResp = await this.props.app.organization.contact.updateContact(data, contactID);
    if (!this._isMounted) return;
    if (updateResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Contact updated with success!");
      this._fetchContact();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
  async _fetchContact() {
    this.startLoading();
    const contactID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    let contactResp = {};
    let contactAppOrgsResp = {};
    //Parallel requests
    const respAll = await Utils.execRequests([
      this.props.app.organization.contact.getContact(contactID),
      this.props.app.organization.organizationAppContact.getAllContactOrganizationApps(contactID),
    ], null, ((resp, idx) => {
      if (idx == 0 && resp?.body && resp.statusCode == 200) contactResp = resp;
      else if (idx == 1 && resp?.body?.contactAppOrgs && resp.statusCode == 200) contactAppOrgsResp = resp;
    }), true);
    //
    if (!this._isMounted) return;
    if (respAll) { //valid data state
      //get contact app orgs details
      const orgIDs = contactAppOrgsResp.body.contactAppOrgs.map(o => o.orgID);
      let orgsResp = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs });
      if (orgsResp.statusCode == 200 && orgsResp.body?.orgs) {
        contactResp.body.applications = contactAppOrgsResp.body.contactAppOrgs.map(o => { return { orgID: o.orgID, name: orgsResp.body.orgs.find(org => org.id === o.orgID).name, tenantID: o.tenantID }; })
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, orgsResp);
        this.setState({ data: {}, isLoading: false });
        return;
      }
      //
      const data = contactResp.body;
      const tenants = contactAppOrgsResp.body.tenants;
      this.setState({ data, tenants, isLoading: false });
      //set navigation items details
      this.props.app.appsManager?.navigation?.appendValue('contactID', contactID);
    } else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, contactResp, contactAppOrgsResp);
      this.setState({ data: {}, isLoading: false });
    }
  }
}
