import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
//
import Utils from '@/components/helpers/Utils';
import Globals from '@/config/Globals'
//
import { Space, Button, Select, Layout, Row, Col, Form, Input, DatePicker, Divider, Switch } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PhoneInput from "@/ui-components/PhoneInput";
//props are: organization, cus, isNew, worksafeTerm
export default class AdminOrganizationForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      worksafeID: ''
    }
  }
  //Form methods
  async validateFields() {
    let org = await this.form.validateFields();
    org.address = { street: org.street, city: org.city, province: org.province, postalCode: org.postalCode, country: org.country };
    org.cor = {
      certificationStatus: org.certificationStatus, certificationDate: +moment(org.certificationDate),
      type: org.type, ohsCertified: org.ohsCertified, rtwCertified: org.rtwCertified
    };
    org.worksafeID = this.state.worksafeID;
    delete org.street;
    delete org.city;
    delete org.province;
    delete org.postalCode;
    delete org.country;
    delete org.certificationStatus;
    delete org.certificationDate;
    delete org.type;
    delete org.ohsCertified;
    delete org.rtwCertified;
    return org;
  }
  setFieldsValue(data) {
    if (data.cor && data.cor.certificationDate) data.cor.certificationDate = moment(data.cor.certificationDate);
    this.setState({ worksafeID: data.worksafeID }, this.form.setFieldsValue({
      ...data, ...data.address, ...data.cor, worksafeID: data.worksafeID,
      street: (data.address?.street?.length > 0 ? data.address.street : ['']), cus: (data.cus || []).map(cu => (cu.id))
    }));
  };
  //Life cycle
  componentDidUpdate(prevProps) {
    const data = this.props.organization;
    if (prevProps.organization != data) this.setFieldsValue(data);
  }
  //
  async handleChangeWorksafeID(worksafeID) {
    const wsID = worksafeID.target.value.replace(/^0+/, '');
    this.setState({ worksafeID: wsID }, () => {
      this.form.setFieldsValue({ worksafeID: wsID });
    });
  }
  //UI
  render() {
    const cus = this.props.cus;
    const statusList = Globals.OrgStatusList;
    const typeList = Globals.OrgCORTypeList;
    const countryList = Globals.OrgCountryList;
    const worksafeTerm = this.props.worksafeTerm;
    return (
      <Layout.Content justify="right-align">
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={8} offset={1}>
              <Form.Item name="worksafeID" label={worksafeTerm} rules={[
                { required: true, message: `Please, type the company ${worksafeTerm}!` },
                { min: 4, message: `${worksafeTerm} must be between 4 and 255 characters` },
                { max: 255, message: `${worksafeTerm} must be between 4 and 255 characters` },
              ]}>
                <Input onChange={this.handleChangeWorksafeID.bind(this)} value={this.state.worksafeID} disabled={this.props.organization.worksafeID} />
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item name="status" label="Status" initialValue='Active'>
                <Select>
                  {statusList.map(option => (
                    <Select.Option key={option.key} value={option.key}>{option.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item name="outOfProvince" valuePropName="checked" label="Out of Province">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="name" label="Company Name" rules={[
                { required: true, message: 'Please, type the company name!' },
                { min: 4, message: 'Name must be between 4 and 255 characters' },
                { max: 255, message: 'Name must be between 4 and 255 characters' },
              ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item name="tradeName" label="Company Trade Name" >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={22} offset={1}>
              <Form.Item name="notes" label="Notes">
                <Input.TextArea size={3} showCount />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Address</Divider>
          <Row type='flex'>
            <Col span={5} offset={1}>
              <Form.List name="street">
                {(fields, { add, remove }) => (
                  <Form.Item label='Address'>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          style={{ width: 600 }}
                          {...restField}
                          name={name}
                          rules={[
                            { required: true, message: 'Please, type the address or remove the empty line!' },
                            { min: 4, message: 'Address must be between 4 and 255 characters' },
                            { max: 255, message: 'Address must be between 4 and 255 characters' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add address
                      </Button>
                    </Form.Item>
                  </Form.Item>
                )}
              </Form.List>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={6} offset={1}>
              <Form.Item name="postalCode" label="Postal Code">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item name="city" label="City">
                <Input />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name="province" label="Province">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="country" label="Country">
                <Select>
                  {countryList.map(option => (
                    <Select.Option key={option.key} value={option.key}>{option.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item name="phone" label="Phone">
                <PhoneInput />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Business</Divider>
          <Row type='flex'>
            <Col span={21} offset={1}>
              <Form.Item name="cus" label="Classification Units" >
                <Select showSearch mode="multiple" options={cus.map(cu => ({ label: `${cu.id} - ${cu.description}`, value: cu.id }))} optionFilterProp="label" />
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="companySize" label="Company Size" >
                <Select showSearch mode="single" options={Object.keys(Globals.CompanySize).map(size => ({ label: size, value: Globals.CompanySize[size] }))} optionFilterProp="label" />
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item name="sizePersonYears" label="Size Based on Person Years" >
                <Select showSearch mode="single" allowClear options={Object.keys(Globals.CompanySize).map(size => ({ label: size, value: Globals.CompanySize[size] }))} optionFilterProp="label" />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">COR</Divider>
          <Row type='flex'>
            <Col span={6} offset={1}>
              <Form.Item name="certificationStatus" label="Certification Status" >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item name="certificationDate" label="Certification Date" >
                <DatePicker style={{ width: '100%' }} format={`${Globals.DefaultDateFormat}`} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name="type" label="COR Program Size">
                <Select>
                  {typeList.map(option => (
                    <Select.Option key={option.key} value={option.key}>{option.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="ohsCertified" label="OHS Certified" >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10} offset={1}>
              <Form.Item name="rtwCertified" label="RTW Certified" >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    );
  }
}
