import React from "react";
import { Layout, Button, Row, Col, Form, Input } from 'antd';
import autoBind from 'react-autobind';
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
import CommonBoxLinks from '../commonComponents/CommonBoxLinks';
//
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";
import config from "@/config/config";
//
import "@/stylesheets/CommonReset.less";
//
export default class CommonRegistrationConfirmationResetView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    const email = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    this.state = { username: (email ? email : ''), isLoading: false };
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = `Resend Registration Confirmation - ${this.props.app.themeManager.theme.applicationName}`;
  }

  //Actions
  async handleResetRegistration() {
    const formData = await this.form.validateFields();
    this._resetRegistrationConfirmation(formData.username);
  }

  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    const editMode = true;
    return (
      <Layout.Content>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <Layout.Content className='resetContainer'>
          <Row type="flex" justify="center" align="middle">
            <Col className='resetBoxContainer' align='middle' justify="center">
              <div className='resetBox'>
                <Row type="flex" justify="center" align="middle">
                  <Col> <Logo app={this.props.app}/> </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                  <Col align="center">
                    <h5 className="resetBoxTitle">Resend your {this.props.app.themeManager.theme.applicationName}<br></br> registration confirmation email</h5>
                  </Col>
                </Row>
                <Form layout="vertical" {...Utils.propagateRef(this, 'form')} className='resetForm'>
                  <Row type="flex" justify="center" align="middle">
                    <Col span={22}>
                      <Form.Item
                        initialValue={this.state.username}
                        name="username"
                        label="Email"
                        rules={[
                          { required: true, message: 'Please, type your email!' },
                          { type: 'email', message: 'Please, type a valid email address!' },
                        ]}
                      >
                        <Input disabled={!editMode} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button block className='resetButton' type="primary" htmlType="submit" onClick={this.handleResetRegistration}> Resend </Button>
                </Form>
              </div>
            </Col>
          </Row>
          <CommonBoxLinks width={500} app={this.props.app}/>
        </Layout.Content>
      </Layout.Content>
    );
  }
  /* private APi */
  async _resetRegistrationConfirmation(username) {
    this.startLoading();
    const resetResp = await this.props.app.idm.api.registrationConfirmation.reset(username);
    if (resetResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Registration confirmation email was resend with success! Please, check your mailbox.");
      this.props.app.urlManager.preAuthorizationRedirect(config.ApplicationRoutes.registrationConfirmation, null, resetResp.body.regToken, username);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading();
    }
  }
}
