import { Button, Card, Col, Divider, Row, Modal, Switch, Form, Input, InputNumber, Popconfirm } from "antd";
import React from "react";
import autoBind from 'react-autobind';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Utils from "@/components/helpers/Utils";

export class AdminCancellationPoliciesForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isPolicyModalOpen: false,
      policies: [],
      currentEditingPolicy: null,
      editMode: false,
    };
  }

  handleCreateNewPolicy() {
    this.setState({
      isPolicyModalOpen: true,
      currentEditingPolicy: this._makeDefaultPolicyObject(),
      editMode: false,
    });
  }

  handleAddNewRule() {
    this.setState(prevState => ({
      ...prevState,
      currentEditingPolicy: {
        ...prevState.currentEditingPolicy,
        rules: prevState.currentEditingPolicy.rules.concat(this._makeDefaultPolicyRuleObject()),
      },
    }));
  }

  handleChangeChangeRule(ruleIndex, fieldName, value) {
    this.setState(prevState => ({
      ...prevState,
      currentEditingPolicy: {
        ...prevState.currentEditingPolicy,
        rules: prevState.currentEditingPolicy.rules.map((rule, index) => (
          index != ruleIndex
            ? rule
            : {
              ...rule,
              [fieldName]: value,
            }
        )),
      },
    }));
  }

  handleRemoveRule(ruleIndex) {
    this.setState(prevState => ({
      ...prevState,
      currentEditingPolicy: {
        ...prevState.currentEditingPolicy,
        rules: prevState.currentEditingPolicy.rules.filter((_, index) => index !== ruleIndex),
      },
    }));
  }

  async handleSavePolicy() {
    const {
      description,
      isBusinessDays,
      canBeCanceled,
      canBeRescheduled,
    } = await this.form.validateFields();

    const { editMode } = this.state;

    const policyToBeSaved = {
      id: this.state.currentEditingPolicy.id,
      description,
      cancelPolicy: {
        isBusinessDays: !!isBusinessDays,
        canBeCanceled: !!canBeCanceled,
        canBeRescheduled: !!canBeRescheduled,
        rules: this.state.currentEditingPolicy.rules,
      },
    };

    this.setState(prevState => ({
      ...prevState,
      currentEditingPolicy: null,
      isPolicyModalOpen: false,
      editMode: false,
      policies: (
        !editMode
          ? prevState.policies.concat(policyToBeSaved)
          : prevState.policies.map(policy => (
            policy.id === policyToBeSaved.id
              ? policyToBeSaved
              : policy
          ))
      ),
    }));

    this.form.setFieldsValue({
      description: '',
      isBusinessDays: false,
      canBeCanceled: false,
      canBeRescheduled: false,
    });
  }

  handleEditPolicy(policy) {
    this.setState({
      isPolicyModalOpen: true,
      editMode: true,
      currentEditingPolicy: {
        id: policy.id,
        rules: policy.cancelPolicy.rules,
      },
    }, () => {
      this.form.setFieldsValue({
        description: policy.description,
        isBusinessDays: policy.cancelPolicy.isBusinessDays,
        canBeCanceled: policy.cancelPolicy.canBeCanceled,
        canBeRescheduled: policy.cancelPolicy.canBeRescheduled,
      });
    });
  }

  handleDeletePolicy(deletingPolicy) {
    this.setState(prevState => ({
      ...prevState,
      policies: prevState.policies.filter(policy => policy.id != deletingPolicy.id),
    }));
  }

  handleClosePolicyModal() {
    this.form.setFieldsValue({
      description: '',
      isBusinessDays: false,
      canBeCanceled: false,
      canBeRescheduled: false,
    });

    this.setState({
      isPolicyModalOpen: false,
      currentEditingPolicy: null,
      editMode: false,
    });
  }

  getPolicies() {
    return this.state.policies;
  }

  setPolicies(policies) {
    if (policies) {
      this.setState({ policies });
    }
  }

  render() {
    const { policies } = this.state;

    return (
      <>
        <Divider orientation="left">Cancellation Policies</Divider>

        <Row type='flex' align="middle">
          <Col span={3} offset={1}>
            <Button
              danger
              type="dashed"
              style={{ width: '100%', height: 120 }}
              icon={<PlusCircleOutlined />}
              onClick={this.handleCreateNewPolicy}
            >
              Create new Policy
            </Button>
          </Col>

          <Col offset={1} span={17}>
            <Row guttter={32}>
              {policies.map(policy => (
                <Col span={8} key={policy.id}>
                  <Card
                    style={{ marginRight: 16, marginBottom: 16 }}
                    actions={[
                      <EditOutlined key="edit" onClick={this.handleEditPolicy.bind(this, policy)} />,
                      <Popconfirm
                        key="delete"
                        title="Are you sure you want to remove this cancelation policy? Sessions that use this policy will no longer be able to correctly charge for cancelations."
                        onConfirm={this.handleDeletePolicy.bind(this, policy)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    ]}
                  >
                    {policy.description}
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        {this._renderFormModal()}
      </>
    )
  }

  _renderFormModal() {
    const { currentEditingPolicy, editMode } = this.state;

    return (
      <Modal
        open={this.state.isPolicyModalOpen}
        title={editMode ? 'Edit Policy' : 'Create new Policy'}
        width={830}
        onCancel={this.handleClosePolicyModal}
        okText="Save"
        onOk={this.handleSavePolicy}
      >
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Description is required!' }]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col>
              <Form.Item
                name="isBusinessDays"
                label="Business days"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="canBeCanceled"
                label="Cancellation available"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="canBeRescheduled"
                label="Reschedule available"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">Rules</Divider>

          {currentEditingPolicy?.rules.map((rule, index) => (
            <Row
              gutter={32}
              style={{
                background: 'rgba(0,0,0,.03)',
                padding: 16,
                borderRadius: 4,
                margin: '0',
                marginBottom: 16,
                position: 'relative',
              }}
              key={index}
            >
              <Col span={5}>
                <Form.Item
                  label="Above days"
                  rules={[{ required: true, message: 'Above days is required!' }]}
                >
                  <InputNumber
                    value={rule.untilDaysBefore}
                    onChange={v => this.handleChangeChangeRule(index, 'untilDaysBefore', v)}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Refund"
                  rules={[{ required: true, message: 'Refund is required!' }]}
                >
                  <InputNumber
                    addonAfter={<span>%</span>}
                    max={100}
                    value={rule.percentageRefunded}
                    onChange={v => this.handleChangeChangeRule(index, 'percentageRefunded', v)}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Charge"
                  rules={[{ required: true, message: 'Charge is required!' }]}
                >
                  <InputNumber
                    value={rule.amountCharged}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={v => this.handleChangeChangeRule(index, 'amountCharged', v)}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Deduct Enrolment Payment"
                  rules={[{ required: true, message: 'Deduct Enrolment Payment is required!' }]}
                  valuePropName="checked"
                >
                  <Switch
                    checked={rule.deductEnrolmentPayment}
                    onChange={v => this.handleChangeChangeRule(index, 'deductEnrolmentPayment', v)}
                  />
                </Form.Item>
              </Col>

              {currentEditingPolicy?.rules.length > 1 && (
                <div style={{ position: 'absolute', right: -5, top: -5 }}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{ borderRadius: '50%' }}
                    onClick={this.handleRemoveRule.bind(this, index)}
                  />
                </div>
              )}
            </Row>
          ))}

          <Row justify="center" style={{ marginTop: 8 }}>
            <Col>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={this.handleAddNewRule}
              >
                Add new Rule
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }

  // Private factories
  _makeDefaultPolicyObject() {
    const id = `${Date.now()}`;

    return {
      id,
      rules: [this._makeDefaultPolicyRuleObject()],
    }
  }
  _makeDefaultPolicyRuleObject() {
    return {
      untilDaysBefore: 0,
      percentageRefunded: 0,
      amountCharged: 0,
      deductEnrolmentPayment: false,
    };
  }
}