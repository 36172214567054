import React from "react";
import autoBind from "react-autobind";
import { Link } from "react-router-dom"
import { Layout, PageHeader, Button, message, Menu, Breadcrumb } from "antd";
import { GrCertificate } from 'react-icons/gr';
//
import Utils from "@/components/helpers/Utils";
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
//
import CommonVaultsTable from "../commonComponents/CommonVaultsTable";
//
import config from "@/config/config";
import Globals from "@/config/Globals";
//
export default class AdminVaultsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, vaults: [] };
  }

  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    this._loadVaults();
  }

  //Vault actions
  handleVaultCreate() {
    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.config_vault,
      null,
      Globals.URL_NewIndentifier
    );
  }
  handleVaultEdit(tObj) {
    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.config_vault,
      null,
      tObj.id
    );
  }
  async handleVaultDelete(tObj) {
    //Double confirmation
    if (
      (await this.props.app.alertController.showPromptAlert(
        "Attention!!",
        `Do you really want to delete the Vault ${tObj.organizationName}? If so, please, type it's ID to confirm the deletion.`
      )) != tObj.id
    )
      return;
    if (
      !(await this.props.app.alertController.showQuestionAlert(
        "Deletion imminent!",
        `Ok, I\'m not 100% sure you want to do this, can you confirm once again the vault ${tObj.organizationName} with ID ${tObj.id} is the one you want to DELETE irreversibly?`
      ))
    )
      return;
    //
    this._deleteVault(tObj);
  }
  //UI
  render() {
    //
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader
          className="pageHeader"
          title="Vaults"
          extra={this._renderNavigationVaultsDetailsLevel()}
        />
        <Layout.Content>
          <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={this.handleVaultCreate} type="primary">
              Add Vault
            </Button>
          </div>
          <CommonVaultsTable app={this.props.app} isLoading={this.state.isLoading}
            onEdit={this.handleVaultEdit} onDelete={this.handleVaultDelete} vaults={this.state.vaults} />
        </Layout.Content>
      </Layout.Content>
    );
  }

  _renderNavigationVaultsDetailsLevel() {
    let option = null;
    option = {
      displayName: 'Custom Attributes |', link: this._parseUrl(config.ApplicationRoutes.custom_attributes), raw: config.ApplicationRoutes.custom_attributes,
      icon: <GrCertificate style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
    };
    //Render
    return (
      <Link to={option.link} style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ fontWeight: 'bold' }}> {option.icon}{option.displayName}</span>
      </Link>
    );
  }

  /* Private API operations */
  async _deleteVault(vault) {
    this.startLoading();
    if (!this._isMounted) return;
    try {
      const deleteResp = await this.props.app.ikFileService.vault.deleteVault(vault.id);
      message.success(`Vault ${vault.name} deleted successfully!`);
    } catch (error) {
      console.error("Error occurred while deleting:", error);
      message.error(`Error occurred while deleting: ${error.response.data.err}`);
    } finally {
      this.stopLoading();
    }
  }
  async _loadVaults() {
    this.startLoading();
    try {
      const resp = await this.props.app.ikFileService.vault.listVaults();
      this.setState({ vaults: resp.vaults, });
    } catch (error) {
      console.error("Error occurred while loading:", error);
      message.error(`Error occurred while loading: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
    this.stopLoading();
  }

  /* private helpers */
  _parseUrl(a1, a2, a3, a4) { return this.props.app.urlManager.parseUrl(a1, a2, a3, a4); }
}
