import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col, Divider } from 'antd';
//
import CustomComponent from '@/ui-components/CustomComponent';
//
import '@/stylesheets/AdminDashboardView.less';
//
export default class AdminDashboardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.options = this._getOptions();
  }
  //Lifecycle
  componentDidMount() {
    document.title = `${this.props.app.themeManager.theme.applicationName}`;
  }
  //Actions
  handleManageClick(option) { if (option.route) this.props.app.urlManager.pushPage(option.route); }
  //UI
  render() {
    return (
      <Layout.Content className="pageContent">
        <Layout.Content>
          {this.options.filter(o => o.hasPermission).map((group) => (
            <React.Fragment key={group.key}>
              <Row>
                <Col span={24}>
                  <Divider orientation="left">{group.name}</Divider>
                </Col>
              </Row>
              <Row>
                {group.options.filter(o => o.hasPermission).map((option) => (
                  <Col span={8} key={option.key} style={{ padding: '15px' }}>
                    <div className="settingsCard" onClick={this.handleManageClick.bind(this, option)} style={{opacity: (option.route ? 1 : .3)}}>
                      {<option.Icon style={{ fontSize: 30 }} />}
                      <div className="settingsCardInfo">
                        <h3>{option.name}</h3>
                        <span>{option.description}</span>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </React.Fragment>
          ))}
        </Layout.Content>
      </Layout.Content>
    )
  }
  /* private */
  _getOptions() {
    return this.props.app.appsManager.enabledApps.map((app, i) => {
      return {
        key: i, name: app.name, description: app.description, hasPermission: true,
        options: app.dashboardOptions || []
      };
    });
  }
}
