import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/helpers/Utils';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
//
import config from '@/config/config';
import Globals from '@/config/Globals'
//
import { Layout, Button, PageHeader, Table, Tooltip, Popconfirm} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
export default class RootAppsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {data: [], firstLoad: true, sortedInfo: null, isLoading: false};
  }

  loadResponse(response) {
    if (!this._isMounted) return;
    if (response.statusCode == 200 && response.body.apps) { //valid data state
      this.state.data = response.body.apps;
      this.props.app.sharedCache().setApps(response.body.apps); //keep cache updated
    }else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, response);
      this.state.data = [];
    }
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() {
    super.componentDidMount();
    if (this.state.firstLoad)  this.fetchData();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Applications`;
  }
  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    this.loadResponse((await this.props.app.idm.api.application.listAll()));
  }
  //Actions
  handleApplicationDelete(appObj) {
    const resp = this.props.app.alertController.showPromptAlert('Attention!', `Are you really sure you want to delete application ${appObj.name}? If you are sure, please type the application name.`);
    if (resp == appObj.name) this._deleteApplication(appObj);
    else this.props.app.alertController.showWarningAlert('Aborted!', 'Delete operation aborted!');
  }
  handleApplicationEdit(appObj) { 
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.application, null, appObj.id); 
    this.props.app.appsManager?.navigation?.appendValue('applicationID', appObj.id);
    this.props.app.appsManager?.navigation?.appendValue('applicationName', appObj.name);
  }
  handleApplicationCreate() { 
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.application, null, Globals.URL_NewIndentifier); 
    this.props.app.appsManager?.navigation?.appendValue('applicationID', Globals.URL_NewIndentifier);
    this.props.app.appsManager?.navigation?.appendValue('applicationName', 'New Application');
  }
  //Table support
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
     let { sortedInfo } = this.state;
     sortedInfo = sortedInfo || {};
     const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
     const props = { loading: this.state.isLoading, scroll: { x: 1100 }, size: 'middle', onChange: this.handleFilterChange, rowKey: 'id' };
     const columns = [
       { title: 'Name', dataIndex: 'name', key: 'name', width: 150,
         sorter: (a, b) => a.name.localeCompare(b.name),
         sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
       },
       { title: 'Description', dataIndex: 'description', key: 'description', width: 150,
         sorter: (a, b) => a.description.localeCompare(b.description),
         sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order
       },
       {
         title: 'Display Name', dataIndex: 'customDisplayName', key: 'customDisplayName', width: 150,
         sorter: (a, b) => (a.customDisplayName || '').localeCompare(b.customDisplayName || ''),
         sortOrder: sortedInfo.columnKey === 'customDisplayName' && sortedInfo.order,
         render: props => props || '-'
       },
       { title: 'MFA Enabled', width: 150, id: 'mfaEnabled', render: d => (d.mfaEnabled ? 'Yes' : 'No'), key: 'mfaEnabled',
         sorter: (a, b) => (a.mfaEnabled == b.mfaEnabled ? 0 : a.mfaEnabled ? -1 : 1),
         sortOrder: sortedInfo.columnKey === 'mfaEnabled' && sortedInfo.order
       },
       { title: 'CORS', width: 150, dataIndex: 'CORS', key: 'CORS',
         sorter: (a, b) => (JSON.stringify(a.CORS || {}).localeCompare(JSON.stringify(b.CORS || {}))),
         sortOrder: sortedInfo.columnKey === 'CORS' && sortedInfo.order,
         render: props => JSON.stringify(props)
       },
       {
         title: 'Email Template', width: 150, dataIndex: 'customEmailTemplate', key: 'customEmailTemplate',
         sorter: (a, b) => ((a.customEmailTemplate || '').localeCompare(b.customEmailTemplate || '')),
         sortOrder: sortedInfo.columnKey === 'customEmailTemplate' && sortedInfo.order,
         render: props => props || 'default'
       },
       { title: 'Updated By', width: 150, dataIndex: 'updatedBy', key: 'updatedBy',
         sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy),
         sortOrder: sortedInfo.columnKey === 'updatedBy' && sortedInfo.order,
       },
       { title: 'Updated On', width: 150, dataIndex: 'updatedOn', key: 'updatedOn',
         sorter: (a, b) => a.updatedOn - b.updatedOn,
         sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
         render: props => Utils.getDateAndTimeOnUIFormatByTimestamp(props)
       },
       { title: 'Actions', width: 180, key: 'Actions', fixed: 'right',
         render: props => {
           return (<span className='tableButtonContainer'>
                     <Tooltip placement="bottomLeft" title='Delete Application'>
                       <Popconfirm title={`Do you really want to delete application '${props.name}'?`} placement="bottomRight" onConfirm={this.handleApplicationDelete.bind(this, props)} okText="Yes" cancelText="No">
                         <Button variant="none" icon={<DeleteOutlined />} shape="circle" />
                       </Popconfirm>
                     </Tooltip>{' '}
                     <Tooltip placement="bottomLeft" title='Edit application'>
                       <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleApplicationEdit.bind(this, props)}/>
                     </Tooltip>
                   </span>);
         }
       }
     ];
     //render
     return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <PageHeader className='pageHeader' title="Applications"
          extra={[<Button key="1" onClick={this.handleApplicationCreate} type='primary'>Create application</Button>]}/>
        <Layout.Content>
          <Table dataSource={this.state.data} columns={columns} {...props} />
        </Layout.Content>
      </Layout.Content>
    );
  }

  async _deleteApplication(appObj) {
    this.startLoading();
    const delResp = await this.props.app.idm.api.application.delete(appObj.id);
    if (delResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Application deleted with succeed!");
      this.fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, delResp);
      this.stopLoading();
    }
  }
}
