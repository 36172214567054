import React from 'react';
import autoBind from 'react-autobind';
import { Button, Table, Tooltip, Popconfirm, Tag, Badge } from 'antd';
import { DeleteOutlined, EditOutlined, UndoOutlined, EyeOutlined } from '@ant-design/icons';
//
import config from "@/config/config";
import Globals from '@/config/Globals';
import Utils from '@/components/helpers/Utils';

export default class CommonVaultsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: {} };
  }

  // Actions
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  handleViewDockets(tObj) {
    this.props.app.urlManager.pushPage(
      config.ApplicationRoutes.dockets,
      null,
      tObj.id
    );
  }

  // UI
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        sorter: (a, b) => a.id.toLowerCase().localeCompare(b.id.toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
      },
      {
        title: 'Name',
        key: 'name',
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: props => (
          <span>
            {props.name}
            {props.isDeleted && (
              <Badge count="Deleted" style={{ backgroundColor: '#f5222d', marginLeft: 8 }} />
            )}
          </span>
        )
      },
      {
        title: 'Alias',
        key: 'alias',
        dataIndex: 'alias',
        sorter: (a, b) => a.alias.toLowerCase().localeCompare(b.alias.toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'alias' && sortedInfo.order
      },
      {
        title: 'Dockets',
        key: 'vaultNumDockets',
        dataIndex: 'vaultNumDockets',
        sorter: (a, b) => a.vaultNumDockets.toLowerCase().localeCompare(b.vaultNumDockets.toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'vaultNumDockets' && sortedInfo.order
      },
      {
        title: 'Documents',
        key: 'vaultNumDocs',
        dataIndex: 'vaultNumDocs',
        sorter: (a, b) => a.vaultNumDocs.toLowerCase().localeCompare(b.vaultNumDocs.toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'vaultNumDocs' && sortedInfo.order
      },
      {
        title: 'Size',
        key: 'vaultSize',
        dataIndex: 'vaultSize',
        sorter: (a, b) => a.vaultSize.toLowerCase().localeCompare(b.vaultSize.toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'vaultSize' && sortedInfo.order
      },
      {
        title: 'Created on',
        key: 'createdOn',
        width: '10%',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A'
      },
      {
        title: 'Actions',
        width: '10%',
        key: 'Actions',
        align: 'center',
        render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit Vault'>
              <Button
                variant="none"
                icon={<EditOutlined />}
                shape="circle"
                onClick={this.props.onEdit.bind(this, props)}
              />
            </Tooltip>
            <Tooltip placement="bottomLeft" title='View Dockets from Vault'>
              <Button
                variant="none"
                icon={<EyeOutlined />}
                shape="circle"
                style={{ marginLeft: 10 }}
                onClick={this.handleViewDockets.bind(this, props)}
              />
            </Tooltip>
            <Tooltip
              placement="bottomLeft"
              title={props.isDeleted ? 'Undo Delete' : 'Delete Vault'}
            >
              <Popconfirm
                title={props.isDeleted
                  ? `Do you want to undo the delete for '${props.id}-${props.name}'?`
                  : `Do you really want to delete vault unit '${props.id}-${props.name}'?`
                }
                placement="bottomRight"
                onConfirm={this.props.onDelete.bind(this, props)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  variant="none"
                  icon={props.isDeleted ? <UndoOutlined /> : <DeleteOutlined />}
                  shape="circle"
                  style={{ marginLeft: 10 }}
                  disabled={true}
                />
              </Popconfirm>
            </Tooltip>
          </span>
        )
      }
    ];

    const props = {
      rowKey: 'id',
      loading: this.props.isLoading,
      onChange: this.handleFilterChange,
      size: 'small',
      locale: { emptyText: 'No vaults found on current customer!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        position: ['bottomRight']
      }
    };

    return (
      <Table
        columns={columns}
        dataSource={this.props.vaults}
        {...props}
      />
    );
  }
}
