import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
import {
  Layout, PageHeader, Row, Col, Statistic, Button, Table, Form, Select, Dropdown, Menu, Popover, 
  Alert, Result, Badge, Descriptions, Spin, Divider, Typography
} from 'antd';
import { WarningOutlined, CloseCircleOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { GrDocumentPdf, GrDocumentTxt } from "react-icons/gr";
//
import WhiteBox from '../commonComponents/WhiteBox';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import Utils from '@/components/helpers/Utils';
import config from '@/config/config';
import Globals from "@/config/Globals";
//
import '@/stylesheets/org-app/AdminOrgImportView.less';
//
export default class AdminOrgImportView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.jobID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    //set navigation items details
    this.props.app.appsManager?.navigation?.appendValue('jobID', this.jobID);
    //
    this.state = {
      isLoading: false, isDownloading: false, data: null, //batch
    };
    //kept out of state for optmization
    this.importLogData = null;
    this.filteredCachedData = {};
  }
  //Life cycle
  componentDidMount() { this._fetch(); }
  //Actions
  handleDownloadLogs(i) { 
    if (i.key == 'debug') this._downloadLogs(); 
    else if (i.key == 'wande') this._downloadLogs(true); 
  }
  handleDownloadSource() { this._downloadFile(); }
  handleValidate() { this._startProc(true); }
  handleImport() { this._startProc(false); }
  //UI
  render() {
    const { data, isDownloading } = this.state;
    const isLoading = (data?.status == Globals.ImportJob_Statuses.VALIDATION_STARTING || data?.status == Globals.ImportJob_Statuses.VALIDATION_RUNNING || 
                       data?.status == Globals.ImportJob_Statuses.IMPORT_STARTING || data?.status == Globals.ImportJob_Statuses.IMPORT_RUNNING);
    const isAvailable = (data?.status == Globals.ImportJob_Statuses.AVAILABLE);
    const allowValidation = (isAvailable || data?.status == Globals.ImportJob_Statuses.VALIDATION_FAILED);
    const isValidating = (data?.status == Globals.ImportJob_Statuses.VALIDATION_STARTING || data?.status == Globals.ImportJob_Statuses.VALIDATION_RUNNING);
    // const isValidated = (data?.status == Globals.ImportJob_Statuses.VALIDATION_COMPLETED || data?.status == Globals.ImportJob_Statuses.VALIDATION_FAILED);
    const isValidatedSuccessfully = (data?.status == Globals.ImportJob_Statuses.VALIDATION_COMPLETED);
    const isImporting = (data?.status == Globals.ImportJob_Statuses.IMPORT_STARTING || data?.status == Globals.ImportJob_Statuses.IMPORT_RUNNING);
    const isImported = (data?.status == Globals.ImportJob_Statuses.IMPORT_COMPLETED || data?.status == Globals.ImportJob_Statuses.IMPORT_FAILED);
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading || this.state.isDownloading} isFixed />
        <PageHeader
          title="Companies Import"
          onBack={() => window.history.back()}
          extra={[
            <Button key="btn" disabled={isDownloading} loading={isDownloading} onClick={this.handleDownloadSource}>Download Source File</Button>,
            <Button key="btn2"  type={allowValidation ? 'primary' : 'secondary'} loading={isValidating}
              disabled={isLoading || isValidatedSuccessfully || !allowValidation} onClick={this.handleValidate}>Validate File</Button>,
            <Button key="btn3"  type={!isImported ? 'primary' : 'secondary'} loading={isImporting}
              disabled={!isValidatedSuccessfully || isLoading || isImported} onClick={this.handleImport}>Import File</Button>,
            ...((data?.executionLog || data?.validationLog) ? [ <Dropdown key='1' overlay={(
              <Menu onClick={this.handleDownloadLogs} items={[
                { label: <><GrDocumentTxt /> Debug Logs</>, key: 'debug' },
                { label: <><CloseCircleOutlined style={{ color: 'red' }} /> Warnings & Errors Logs</>, key: 'wande' }
              ]}/>
            )}> 
              <Button type="dashed" icon={<DownloadOutlined style={{ color: 'red' }} /> } > Download Logs </Button> 
            </Dropdown> ] : [])
          ]}/>
        <Layout.Content>
          {this._renderHeaderRow()}
          {!isAvailable && data && this._renderResultRow()}
          {this._renderErrorsRow()} 
          {this._renderLogsTableRow()}
        </Layout.Content>
      </Layout.Content>
    )
  }
  /* Private UI */
  _renderHeaderRow() {
    const { data } = this.state;
    return (
      <WhiteBox>
        <Row type='flex' justify='space-between'>
          <Col> <Statistic title="Job ID" value={!data ? '...' : `${data?.id?.substr(0, 10)}`} /> </Col>
          <Col> <Statistic title="File" value={!data ? '...' : data?.importFileName || ''} /> </Col>
          {this._renderStatus()}
          <Col> <Statistic title="Created on" value={!data ? '...' : Utils.getDateAndTimeOnUIFormatByTimestamp(data?.createdOn)} /> </Col>
          <Col> <Statistic title="Started On" value={!data ? '...' : Utils.getDateAndTimeOnUIFormatByTimestamp(data?.startDate)} /> </Col>
          <Col> <Statistic title="Ended On" value={!data ? '...' : Utils.getDateAndTimeOnUIFormatByTimestamp(data?.endDate)} /> </Col>
        </Row>
        <Row type='flex' justify='space-between'>
          <Col> <Statistic title="Description" value={!data ? '...' : data?.description || ''} /> </Col>
        </Row>
      </WhiteBox>
    );
  }
  _renderResultRow() {
    const { data } = this.state;
    const isError = (data?.status == Globals.ImportJob_Statuses.VALIDATION_FAILED || data?.status == Globals.ImportJob_Statuses.IMPORT_FAILED);
    const isValidation = (data?.status == Globals.ImportJob_Statuses.VALIDATION_STARTING || data?.status == Globals.ImportJob_Statuses.VALIDATION_RUNNING ||
                          data?.status == Globals.ImportJob_Statuses.VALIDATION_COMPLETED || data?.status == Globals.ImportJob_Statuses.VALIDATION_FAILED);
    const isRunning = (data?.status == Globals.ImportJob_Statuses.VALIDATION_STARTING || data?.status == Globals.ImportJob_Statuses.VALIDATION_RUNNING ||
                       data?.status == Globals.ImportJob_Statuses.IMPORT_STARTING || data?.status == Globals.ImportJob_Statuses.IMPORT_RUNNING);
    return (
      <WhiteBox>
        <Result status={isError ? 'error' : (isRunning ? 'info' : 'success')}
          title={
            isError ? (
              isValidation ? 
                <>An error occurred while validating your import file.<br></br>Please, review the errors below and try again or submit a new file.</> :
                <>An error occurred while importing your file.<br></br>Please, review the logs carefully and submit a new file if applicable.</>
            ) : (isRunning ? ( 
              isValidation ? 
                <>Validating your import file.<br></br>Please wait until validation is complete.</> :
                <>Importing your file to the system.<br></br>Please wait until the validation completes to see the import report.</>
              ) : 
              isValidation ? 
                <>Validation completed with success.<br></br>Please, review the summary and logs, to determine if the system recognized all your data!</> :
                <>Import completed!<br></br>Please, review the summary and logs.</>
            )}
          subTitle={
            isRunning ? <Spin/> : data?.summary ? <Row type='flex' justify='center' style={{marginTop: 20}}>
              <Divider/>
              <Col>
                <Descriptions title="Summary:" size='small' layout='vertical' bordered column={6}>
                  {data?.summary && <Descriptions.Item span={1} label={'# of validated rows'}>{data?.summary?.validated || 0}</Descriptions.Item>}
                  {data?.summary && <Descriptions.Item span={1} label={'# of warnings'}>{data?.summary?.warnings || 0}</Descriptions.Item>}
                  {data?.summary && <Descriptions.Item span={1} label={'# of errors'}>{data?.summary?.errors || 0}</Descriptions.Item>}
                  {data?.summary?.processed && <Descriptions.Item span={1} label={'# of processed rows'}>{data?.summary?.processed}</Descriptions.Item>}
                  {data?.summary?.updated && <Descriptions.Item span={1} label={'# of updates on companies'}>{data?.summary?.updated}</Descriptions.Item>}
                  {data?.summary?.created && <Descriptions.Item span={1} label={'# of created companies'}>{data?.summary?.created}</Descriptions.Item>}
                  {data?.summary?.failed && <Descriptions.Item span={1} label={'# of failed processed rows'}>{data?.summary?.failed}</Descriptions.Item>}
                </Descriptions>
              </Col>
            </Row> : 'Summary is not available, please, contact the support if believe this is an error.'
          }/>
      </WhiteBox>
    );
  }
  _renderErrorsRow() {
    const generalErrors = this._findGeneralErrors();
    if (!generalErrors || generalErrors.length <= 0) return <></>;
    return (
      <WhiteBox>
        <Row justify="center"> <Col><Typography.Title level={4}>Errors</Typography.Title></Col> </Row>
        <Row justify="center">
          <Col>{generalErrors.map((error) => (<Alert showIcon type="error" key={error.message} message={error.message}/>))}</Col>
        </Row>
      </WhiteBox>
    );
  }
  _renderLogsTableRow() {
    const dataEntries = this._findEntries();
    if ((!dataEntries || dataEntries.length <= 0)) return <></>;
    const columns = [
      {
        title: '#', dataIndex: 'index', key: 'index', width: 150,
        render: (index) => Number(index) + 2,
      },
      { title: 'Legal Name', key: 'legalName', render: (props) => props[Globals.ImportJob_SimpleV1Columns.EmployerLegalName] || '-' },
      { title: 'Trade Name', key: 'tradeName', render: (props) => props[Globals.ImportJob_SimpleV1Columns.EmployerTradeName] || '-' },
      { title: 'Industry CU', key: 'industryCU', render: (props) => props[Globals.ImportJob_SimpleV1Columns.IndustryCU] || '-' },
      {
        title: 'Status', key: 'Errors', align: 'right', width: 150,
        render: (record) => {
          const errors = this._findEntryErrors(record.index).map((err) => ({ ...err, type: 'error' }));
          const warnings = this._findEntryWarnings(record.index).map((err) => ({ ...err, type: 'warning' }));;
          const errorsAndWarnings = [...errors, ...warnings];
          if (!errorsAndWarnings.length) return (<CheckCircleOutlined style={{ color: 'green', fontSize: 22 }} />);
          //
          return (<Popover placement="leftTop" content={(errorsAndWarnings.map((item, index) => (
            <div key={index}>
              {item.type == 'error' && <><CloseCircleOutlined style={{ color: 'red', marginRight: 10 }} /> Error: {item.message}</>}
              {item.type == 'warning' && <><WarningOutlined style={{ color: '#FAAF21', marginRight: 10 }} /> Warning: {item.message}</>}
            </div>))
          )}>
            {errors.length > 0 && (<CloseCircleOutlined style={{ color: 'red', fontSize: 22 }}/>)}
            {warnings.length > 0 && (<WarningOutlined style={{ color: '#FAAF21', fontSize: 22, marginLeft: (errors.length > 0 ? 10 : 0)}}/>)}
          </Popover>);
        },
      },
    ];
    return (
      <WhiteBox>
        <Form layout="horizontal">
          <Row justify="center">
            <Col><Typography.Title level={4}>Entries</Typography.Title></Col>
          </Row>
        </Form>
        <Table columns={columns} dataSource={dataEntries} rowKey="index" pagination={{ pageSize: 50, showSizeChanger: false }} scroll={{ x: true }}/>
      </WhiteBox>
    )
  }
  _renderStatus() {
    const { data, isLoading } = this.state;
    const Statuses = Globals.ImportJob_Statuses;
    let val = null;
    /* data status */
    switch (data?.status) {
      case isLoading:
        val = <Statistic title="Status" value={'...'}/>
        break;
      case Statuses.AVAILABLE:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="success" text="Ready to Import" />}/>
        break;
      case Statuses.IMPORT_STARTING:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="processing" text='Preparing Import' />}/>
        break;
      case Statuses.VALIDATION_STARTING:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="processing" text='Preparing Validation' />}/>
        break;
      case Statuses.IMPORT_RUNNING:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="processing" text='Import Running' />}/>
        break;
      case Statuses.VALIDATION_RUNNING:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="processing" text='Validation Running' />}/>
        break;
      case Statuses.IMPORT_COMPLETED:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="success" text='Import Completed' />}/>
        break;
      case Statuses.VALIDATION_COMPLETED:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="success" text='Validation Completed' />}/>
        break;
      case Statuses.IMPORT_FAILED:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="error" text='Import Failed' />}/>
        break;
      case Statuses.VALIDATION_FAILED:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="error" text='Validation Failed' />}/>
        break;
      default:
        val = <Statistic title="Status" value=' ' suffix={<Badge status="error" text={data?.status}/>} />
        break;
    }
    return (<Col> {val} </Col>); 
  }

  /* Raw data helpers */
  _findGeneralErrors() { 
    if (this.filteredCachedData?.generalErrors) return this.filteredCachedData?.generalErrors;
    if (!this.filteredCachedData) this.filteredCachedData = {};
    this.filteredCachedData.generalErrors = this.importLogData?.filter((log) => (log.level == Globals.ImportJob_ProcessLogTypes.ERROR && (!log.rawdata || !log.rawdata.entryID))); 
    return this.filteredCachedData?.generalErrors;
  }
  _findEntries() { 
    if (this.filteredCachedData?.entries) return this.filteredCachedData?.entries;
    if (!this.filteredCachedData) this.filteredCachedData = {};
    this.filteredCachedData.entries = this.importLogData?.filter((log) => (log.level == Globals.ImportJob_ProcessLogTypes.DATA)).map((r) => r.rawdata); 
    return this.filteredCachedData?.entries;
  }
  _findEntryErrors(index) { return this.importLogData?.filter((log) => (log.level == Globals.ImportJob_ProcessLogTypes.ERROR && log.rawdata && (index == undefined || log.rawdata.entryID == index))); }
  _findEntryWarnings(index) { return this.importLogData?.filter((log) => (log.level == Globals.ImportJob_ProcessLogTypes.WARNING && log.rawdata && (index == undefined || log.rawdata.entryID == index))); }

  /* Auto refresh */
  _rescheduleAutoRefresh() {
    this._cleanTimer();
    //Setup timer
    this.autoRefresh = setTimeout(() => {
      console.debug('Auto refresh triggered!');
      this._cleanTimer();
      this._fetch();
    }, 10000);
  }
  _cleanTimer() {
    if (this.autoRefresh) clearTimeout(this.autoRefresh);
    this.autoRefresh = null;
  }
  /* API Calls */
  async _fetch() {
    this.startLoading();
    //request
    const resp = await this.props.app.organization.organizationsImportJob.getJob(this.jobID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body) {
      const data = resp.body;
      //
      const isAvailable = data?.status == Globals.ImportJob_Statuses.AVAILABLE;
      const isRunning = (data?.status == Globals.ImportJob_Statuses.VALIDATION_STARTING || data?.status == Globals.ImportJob_Statuses.VALIDATION_RUNNING || 
                         data?.status == Globals.ImportJob_Statuses.IMPORT_STARTING || data?.status == Globals.ImportJob_Statuses.IMPORT_RUNNING);
      const reportsAvailable = (data?.executionLog || data?.validationLog);
      //
      this.setState({ data, isLoading: !isAvailable && !isRunning && reportsAvailable }, () => {
        //Auto refresh will schedule if running
        if (isRunning) this._rescheduleAutoRefresh();
        //Will download raw logs if completed (not running and not available)
        if (!isAvailable && !isRunning && reportsAvailable) this._downloadRawLogs();
      });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading(true);
    }
  }
  async _startProc(isValidation) {
    this.startLoading();
    const jobObj = this.state.data;
    //request
    const resp = await this.props.app.organization.organizationsImportJob[isValidation ? 'startValidation' : 'startImport'](jobObj.id);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `${isValidation ? 'Validation' : 'Import'} process started with success!`);
      this._fetch();
    } else {
      this.props.app.alertController.showAPIErrorAlert(`Error while starting ${isValidation ? 'validation' : 'import'} process!`, resp);
      this.stopLoading(true);
    }
  }
  async _downloadFile() {
    this.setState({ isDownloading: true });
    const jobObj = this.state.data;
    //request
    const resp = await this.props.app.organization.organizationsImportJobFile.download(jobObj.importFileID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200) Utils.downloadBlob(resp.body, jobObj.importFileName);
    else this.props.app.alertController.showAPIErrorAlert('Error while downloading import file!', resp);
    this.setState({ isDownloading: false });
  }
  async _downloadLogs(onlyWande) {
    this.setState({ isDownloading: true });
    const jobObj = this.state.data;
    //request
    const resp = await this.props.app.organization.organizationsImportJob.getLogs(jobObj.id, (onlyWande ? 'WANDE' : 'ALL'));
    if (!this._isMounted) return;
    if (resp.statusCode == 200) {
      Utils.downloadBlob(resp.body, `${jobObj.importFileName}-${moment(jobObj.createdOn).format(Globals.DefaultDateFormat)}-${onlyWande ? 'logs' : 'debug-logs'}`, 'txt');
    } else {
      this.props.app.alertController.showAPIErrorAlert(`Error while downloading import logs file!`, resp);
    } this.setState({ isDownloading: false });
  }
  async _downloadRawLogs() {
    const jobObj = this.state.data;
    //request
    const resp = await this.props.app.organization.organizationsImportJob.getLogs(jobObj.id, 'ALL', true);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body) {
      /* cache data */
      this.importLogData = resp.body;
      this.filteredCachedData = {};
      this._findGeneralErrors();
      this._findEntries();
      //
      this.setState({ isLoading: false });
    } else this.props.app.alertController.showAPIErrorAlert(`Error while downloading raw import logs, preview will be unavailable!`, resp);
    this.stopLoading();
  }
}
