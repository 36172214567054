import React from "react";
import autoBind from 'react-autobind';
import { Card, Layout, Checkbox, Row, Alert, Button, Col, Switch, InputNumber, Select, Form, Input, Divider, Table, Popover, Tooltip, Popconfirm, message } from 'antd';
import MarkdownEditor from '@ikonintegration/react-rte';
import { EyeOutlined, QuestionCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import CommonRegistrationSectionTable from '../../commonComponents/CommonRegistrationSectionTable';
import CommonRegistrationFieldsTable from '../../commonComponents/CommonRegistrationFieldsTable';
import CommonRegistrationSectionDrawer from '../../commonComponents/Drawers/CommonRegistrationSectionDrawer';
import CommonInvitationReminderDrawer from '../../commonComponents/Drawers/CommonInvitationReminderDrawer';
import CommonRegistrationFieldDrawer from '../../commonComponents/Drawers/CommonRegistrationFieldDrawer';
import CommonRegistrationFormPreviewModal from '../../commonComponents/Modals/CommonRegistrationFormPreviewModal';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";

//props are: app, tenant, isNew
export default class AdminTenantForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      organizationRegText: MarkdownEditor.createEmptyValue(),
      licModPurchaseMessage: MarkdownEditor.createEmptyValue(),
      discountHelpText: MarkdownEditor.createEmptyValue(),
      enableCustomRegistration: false, customRegistration: { fields: {}, sections: [] },
      enableOrgMemberProfile: false, organizationMemberProfile: { fields: {}, sections: [] },
      warningInvitationExpiration: [],
    };
  }
  //Form methods
  async validateFields() {
    const d = await this.form.validateFields();
    if (!d) return;
    const { email, phoneNumber, organizationRegText, enableOrgMemberProfile, enableCustomRegistration, optionalSearchColumns, showEmployerInRevenueReport, showReplaceStudentAction, discountHelpText, showPreferredLanguageInSession, ...ds } = d;
    const regText = this.state.organizationRegText.toString('markdown');
    const licText = this.state.licModPurchaseMessage.toString('markdown');
    const discountText = this.state.discountHelpText.toString('markdown');

    return {
      ...ds,
      //non-optional booleans
      licModUseProdSuffixOnTx: !!d.licModUseProdSuffixOnTx,
      orgModDisabled: !!d.orgModDisabled,
      orgModEmployeeMode: !!d.orgModEmployeeMode,
      orgModAllowOrgSelfRegistration: !!d.orgModAllowOrgSelfRegistration,
      organizationRegText: regText?.length > 2 ? regText : '',
      licModPurchaseMessage: licText?.length > 2 ? licText : '',
      discountHelpText: discountText?.length > 2 ? discountText : '',
      //array values
      disabledEmails: d.disabledEmails || [],
      enabledUsersFilter: (ds.enabledUsersFilter ? ds.enabledUsersFilter : []),
      optionalUserSearchFilters: (ds.optionalUserSearchFilters ? ds.optionalUserSearchFilters : []),
      reportTypes: (ds.reportTypes ? ds.reportTypes : []),
      //org contact structure
      organizationContactDisplay: { email: d.email, phoneNumber: d.phoneNumber },
      //checkbox groups
      menuDisplayOptions: Globals.Tenant_UISidebarOptions.reduce((acc, v) => {
        if (ds.menuDisplayOptions) acc[v.value] = ds.menuDisplayOptions.indexOf(v.value) != -1;
        return acc;
      }, {}),
      settingsDisplayOptions: Globals.Tenant_UISettingsOptions.reduce((acc, v) => {
        if (ds.settingsDisplayOptions) acc[v.value] = ds.settingsDisplayOptions.indexOf(v.value) != -1;
        return acc;
      }, {
        optionalSearchColumns: optionalSearchColumns || [],
        showEmployerInRevenueReport,
        showReplaceStudentAction,
        showPreferredLanguageInSession,
      }),
      warningInvitationExpiration: this.state.warningInvitationExpiration,
      //custom registrations
      ...(this.state.enableCustomRegistration ? { customRegistration: this.state.customRegistration } : { customRegistration: null }),
      ...(this.state.enableOrgMemberProfile ? { organizationMemberProfile: this.state.organizationMemberProfile } : { organizationMemberProfile: null }),
      //preserve deprecated
      certNumberMaskFormat: this.props.tenant?.certNumberMaskFormat,
      certNumberMode: this.props.tenant?.certNumberMode,
      certNumberInitialNumber: this.props.tenant?.certNumberInitialNumber,
      courseCertNumberMaskFormat: this.props.tenant?.courseCertNumberMaskFormat,
      courseCertNumberMode: this.props.tenant?.courseCertNumberMode,
      courseCertNumberInitialNumber: this.props.tenant?.courseCertNumberInitialNumber,

    }
  }
  setFieldsValue(data) {
    this.setState({
      organizationRegText: data.organizationRegText ? MarkdownEditor.createValueFromString(data.organizationRegText, 'markdown') : MarkdownEditor.createEmptyValue(),
      licModPurchaseMessage: data.licModPurchaseMessage ? MarkdownEditor.createValueFromString(data.licModPurchaseMessage, 'markdown') : MarkdownEditor.createEmptyValue(),
      discountHelpText: data.discountHelpText ? MarkdownEditor.createValueFromString(data.discountHelpText, 'markdown') : MarkdownEditor.createEmptyValue(),
      enableCustomRegistration: !!data.customRegistration, customRegistration: data.customRegistration || { fields: {}, sections: [] },
      warningInvitationExpiration: data.warningInvitationExpiration || [],
      enableOrgMemberProfile: !!data.organizationMemberProfile, organizationMemberProfile: data.organizationMemberProfile || { fields: {}, sections: [] },
    }, () => {
      this.form.setFieldsValue({
        ...data, ...data.organizationContactDisplay,
        enableCustomRegistration: this.state.enableCustomRegistration, enableOrgMemberProfile: this.state.enableOrgMemberProfile,
        menuDisplayOptions: Object.keys(data.menuDisplayOptions || {}).filter((k) => !!data.menuDisplayOptions[k]),
        settingsDisplayOptions: Object.keys(data.settingsDisplayOptions || {}).filter((k) => !!data.settingsDisplayOptions[k]),
        optionalSearchColumns: this.props.tenant?.settingsDisplayOptions?.optionalSearchColumns || [],
        showEmployerInRevenueReport: this.props.tenant?.settingsDisplayOptions?.showEmployerInRevenueReport || false,
        showReplaceStudentAction: this.props.tenant?.settingsDisplayOptions?.showReplaceStudentAction || false,
        showPreferredLanguageInSession: this.props.tenant?.settingsDisplayOptions?.showPreferredLanguageInSession || false,
      });
    });
  }
  //Life cycle
  componentDidUpdate(prevProps) {
    const data = this.props.tenant;
    if (prevProps.tenant != data && data) this.setFieldsValue(data);
  }
  //Actions
  //registration section
  handleAddRegistrationSection() { this.regSectDrawer.show(); }
  handleEditRegistrationSection(section) { this.regSectDrawer.show(section, this.state.customRegistration?.sections?.indexOf(section)); }
  handleDeleteRegistrationSection(section) {
    const { sections, ...rest } = this.state.customRegistration;
    sections.splice(sections?.indexOf(section), 1);
    this.setState({ customRegistration: { ...rest || {}, sections } });
  }
  handleRegistrationSectionUpdated(section, sectionIdx) {
    const { sections, ...rest } = this.state.customRegistration;
    sections[sectionIdx] = section;
    this.setState({ customRegistration: { ...rest || {}, sections } });
  }
  handleRegistrationSectionCreated(section) {
    const { sections, ...rest } = this.state.customRegistration;
    sections.push(section);
    this.setState({ customRegistration: { ...rest || {}, sections } });
  }
  //invitation reminder
  handleAddInvitationReminder() { this.invitationReminder.show(); }
  handleEditInvitationReminder(warning) { this.invitationReminder.show(warning, this.state.warningInvitationExpiration.indexOf(warning)); }
  handleDeleteInvitationReminder(warning) {
    const v = this.state.warningInvitationExpiration;
    v.splice(v.indexOf(warning), 1);
    this.setState({ [warning]: v });
  }
  handleInvitationReminderUpdated(updatedWarning, index) {
    const existingWarningID = this.state.warningInvitationExpiration.findIndex(warning => warning.id === updatedWarning.id);

    if (existingWarningID !== -1) {
      message.error('This ID has already been sent.');
      return;
    }

    if (index !== -1) {
      const updatedWarnings = [...this.state.warningInvitationExpiration];
      updatedWarnings[index] = updatedWarning;
      this.setState({ warningInvitationExpiration: updatedWarnings });
    } else {
      this.setState(prevState => ({
        warningInvitationExpiration: [...prevState.warningInvitationExpiration, updatedWarning]
      }));
    }
  }

  //registration field
  handleAddRegistrationField() { this.regFieldDrawer.show(); }
  handleEditRegistrationField(field) { this.regFieldDrawer.show(field); }
  handleDeleteRegistrationField(field) {
    const { fields, ...rest } = this.state.customRegistration;
    delete fields[field.id];
    this.setState({ customRegistration: { ...(rest || {}), fields: { ...(fields || {}) } } });
  }
  handleRegistrationFieldUpdated(field) {
    const { fields, ...rest } = this.state.customRegistration;
    this.setState({ customRegistration: { ...(rest || {}), fields: { ...(fields || {}), [field.id]: field } } });
  }
  handleRegistrationFieldCreated(field) {
    const { fields, ...rest } = this.state.customRegistration;
    this.setState({ customRegistration: { ...(rest || {}), fields: { ...(fields || {}), [field.id]: field } } });
  }
  //registration preview
  handlePreviewRegistration() { this.regPreviewModal.show(); }
  //org member profile section
  handleAddOrgMemberProfileSection() { this.orgMemberProfileSectDrawer.show(); }
  handleEditOrgMemberProfileSection(section) { this.orgMemberProfileSectDrawer.show(section, this.state.organizationMemberProfile?.sections?.indexOf(section)); }
  handleDeleteOrgMemberProfileSection(section) {
    const { sections, ...rest } = this.state.organizationMemberProfile;
    sections.splice(sections?.indexOf(section), 1);
    this.setState({ organizationMemberProfile: { ...rest || {}, sections } });
  }
  handleOrgMemberProfileSectionUpdated(section, sectionIdx) {
    const { sections, ...rest } = this.state.organizationMemberProfile;
    sections[sectionIdx] = section;
    this.setState({ organizationMemberProfile: { ...(rest || {}), sections } });
  }
  handleOrgMemberProfileSectionCreated(section) {
    const { sections, ...rest } = this.state.organizationMemberProfile;
    this.setState({ organizationMemberProfile: { ...(rest || {}), sections: sections.concat([section]) } });
  }
  //org member profile field
  handleAddOrgMemberProfileField() { this.orgMemberProfileFieldDrawer.show(); }
  handleEditOrgMemberProfileField(field) { this.orgMemberProfileFieldDrawer.show(field); }
  handleDeleteOrgMemberProfileField(field) {
    const { fields, ...rest } = this.state.organizationMemberProfile;
    delete fields[field.id];
    this.setState({ organizationMemberProfile: { ...(rest || {}), fields: { ...(fields || {}) } } });
  }
  handleOrgMemberProfileFieldUpdated(field) {
    const { fields, ...rest } = this.state.organizationMemberProfile;
    this.setState({ organizationMemberProfile: { ...(rest || {}), fields: { ...(fields || {}), [field.id]: field } } });
  }
  handleOrgMemberProfileFieldCreated(field) {
    const { fields, ...rest } = this.state.organizationMemberProfile;
    this.setState({ organizationMemberProfile: { ...(rest || {}), fields: { ...(fields || {}), [field.id]: field } } });
  }
  //org member profile preview
  handlePreviewOrgMemberProfile() { this.orgMemberProfilePreviewModal.show(); }
  //custom form actions
  handleCheckChange(id) { this.setState({ [id]: !this.state[id] }); }
  //UI
  render() {
    const criticalChangeInfo = <>Attention: <strong>Change on these values might take a while to propagate throughout the system!</strong></>;
    return (
      <Layout.Content>
        {/* Modals and drawers -- registration */}
        <CommonRegistrationSectionDrawer app={this.props.app} onUpdate={this.handleRegistrationSectionUpdated} onCreate={this.handleRegistrationSectionCreated} {...Utils.propagateRef(this, 'regSectDrawer')} />
        <CommonRegistrationFieldDrawer app={this.props.app} onUpdate={this.handleRegistrationFieldUpdated} onCreate={this.handleRegistrationFieldCreated} {...Utils.propagateRef(this, 'regFieldDrawer')} />
        <CommonRegistrationFormPreviewModal app={this.props.app} customRegistration={this.state.customRegistration} {...Utils.propagateRef(this, 'regPreviewModal')} />
        {/* Modals and drawers -- org member profile */}
        <CommonInvitationReminderDrawer app={this.props.app} onUpdate={this.handleInvitationReminderUpdated} {...Utils.propagateRef(this, ('invitationReminder'))} />
        <CommonRegistrationSectionDrawer app={this.props.app} onUpdate={this.handleOrgMemberProfileSectionUpdated} onCreate={this.handleOrgMemberProfileSectionCreated} {...Utils.propagateRef(this, 'orgMemberProfileSectDrawer')} />
        <CommonRegistrationFieldDrawer app={this.props.app} onUpdate={this.handleOrgMemberProfileFieldUpdated} onCreate={this.handleOrgMemberProfileFieldCreated} {...Utils.propagateRef(this, 'orgMemberProfileFieldDrawer')} />
        <CommonRegistrationFormPreviewModal app={this.props.app} customRegistration={this.state.organizationMemberProfile} {...Utils.propagateRef(this, 'orgMemberProfilePreviewModal')} />
        {/* Form */}
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={10} offset={1}>
              <Form.Item name="id" label="ID (Unique Identifier)" rules={[
                { required: true, message: 'Please, type the tenant ID!' },
                { min: 4, message: 'Tenant ID must be between 4 and 255 characters' },
                { max: 255, message: 'Tenant ID must be between 4 and 255 characters' },
              ]}>
                <Input disabled={!this.props.isNew} />
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item name="customerID" initialValue={this.props.app.customerID} label="Customer ID (Tenant's owner)" rules={[
                { required: true, message: 'Please, type the customer ID!' },
              ]}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* Alert */}
          <Row type='flex' style={{ marginBottom: 10 }}>
            <Col span={22} offset={1}> <Alert message={criticalChangeInfo} type="warning" showIcon /> </Col>
          </Row>
          {/* Tenant Organization informations */}
          {this._renderTenantOrgInfoSubsection()}
          {/* IDM Roles and Credentials */}
          {this._renderIDMRolesSubsection()}
          {/* Submodules Credentials */}
          {this._renderSubmodulesCredentialsSubsection()}
          {/* Email configs */}
          {this._renderEmailConfigSubsection()}
          {/* License module configs */}
          {this._renderLicenseModuleSubsection()}
          {/* Organization module configs */}
          {this._renderOrganizationModuleSubsection()}
          {/* UI configs */}
          {this._renderUIConfigSubsection()}
          {/* COR configs */}
          {this._renderCORSubsection()}
          {/* Payment */}
          {this._renderPaymentsSubsection()}
          {/* Registration */}
          {this._renderRegistrationSubsection()}
        </Form>
      </Layout.Content>
    );
  }
  /* subsections */
  _renderTenantOrgInfoSubsection() {
    return (
      <>
        <Divider orientation="left">Tenant Info</Divider>
        <Row type='flex'>
          <Col span={8} offset={1}>
            <Form.Item name="organizationName" label="Organization Name" rules={[{ required: true, message: 'Tenant name is required!' }]} extra='This is the name which appears on the UI, emails and all sort of place identifying this tenant.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name="email" label="Contact Email" rules={[{ required: true, message: 'Tenant contact email is required!' }]} extra='This email is used on the support form, email footers and few other places.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name="mailerBillingRecipient" label="Billing Email" extra=''>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={8} offset={1}>
            <Form.Item name="phoneNumber" label="Contact Phone" rules={[{ required: true, message: 'Tenant phone email is required!' }]} extra='This phone on the email footers and few other places where the user can contact the support for some help.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item name="taxRate" label="Tax Rate" rules={[{ required: true, message: 'Tenant tax rate is required!' }]} extra='This is the tax rate used on the online purchases.'>
              <InputNumber min={0} max={1} precision={2} step={0.01} style={{ width: 200 }} />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item name="timezone" label="Timezone" rules={[{ required: true, message: 'Tenant timezone is required!' }]} extra='This timezone is used on server side operations to determine expiration, validation and user facing dates on emails.'>
              <Select> {Intl.supportedValuesOf('timeZone').map((opt) => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="organizationApplicationURL" label="Application URL" rules={[{ required: true, message: 'Tenant application URL is required!' }]} extra='Full tenant application URL used on emails for email. This value should include the protocol. Eg.: "http://tenant.example.com"'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="organizationApplicationLogo" label="Logo" rules={[{ required: true, message: 'Tenant logo is required!' }]} extra='This logo path used by emails and other resources.'>
              <Select> {Globals.Tenant_LogoPaths.map((opt) => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="refundPolicyURL" label="Refund and Cancellation Policy URL" extra='URL to a page or document describing the tenant refund and cancellation policy'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    )
  }
  _renderIDMRolesSubsection() {
    return (
      <>
        <Divider orientation="left">IDM Roles & Credentials</Divider>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="idmUserRoleID" label="User's role" rules={[{ required: true, message: 'User role is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="idmAdminRoleID" label="Admin's role" rules={[{ required: true, message: 'Admin role is required!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="idmKey" label="IDM LMS FE Key" rules={[{ required: true, message: 'LMS FE Key is required!' }]} extra='Key used to sign all calls made to IDM within the LMS FE.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="idmSecret" label="IDM LMS FE Token" rules={[{ required: true, message: 'LMS FE Secret is required!' }]} extra='Token used to sign all calls made to IDM within the LMS FE.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderSubmodulesCredentialsSubsection() {
    return (
      <>
        <Divider orientation="left">Submodules credentials</Divider>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="sharedModuleAuditingAPIKey" label="Auditing Module API Key" rules={[{ required: true, message: 'Auditing module API Key is required!' }]} extra='Auditing module API key utilized to send tenant scoped events to the auditing module.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="sharedModuleClassroomAPIKey" label="Classroom Module API Key" rules={[{ required: true, message: 'Classroom module API Key is is required!' }]} extra='Classroom module API key utilized to make privileged access into classroom module on the tenant scope.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderEmailConfigSubsection() {
    return (
      <>
        <Divider orientation="left">Email configuration</Divider>
        <Row type='flex'>
          <Col span={6} offset={1}>
            <Form.Item name="mailerSender" label="Default email sender" rules={[{ required: true, message: 'Tenant default email sender is required!' }]} extra='Default email sender used by API actions that send emails at tenant scope.'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name="mailerBCC" label="BCC List" extra="Comma separated list of addresses that will be BCC'd in every message sent from the tenant">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name="mailerAdminRecipient" label="Admin email recipient(s)" rules={[{ required: true, message: 'At least one admin email recipient must be included!' }]} extra='Admin email addresses to receive admin level notifications from the tenant events.'>
              <Select mode="tags" placeholder="email@example.com" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={6} offset={1}>
            <Form.Item name="mailerTemplates" label="Email templates group" rules={[{ required: true, message: 'Email template group is required!' }]} extra='Which email templates will this tenant attempt to use at first glance before falling back to default template of the each event (if no group specific template if specified for that event).'>
              <Select> {Object.keys(Globals.Tenant_MailTemplates).map((opt) => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item name="mailerSysAdminRecipient" label="SysAdmin email recipient(s)" rules={[{ required: true, message: 'At least one sysadmin email recipient must be included!' }]} extra='Sysadmin email addresses to receive sysadmin level notifications from the tenant events.'>
              <Select mode="tags" placeholder="email@example.com" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={1} offset={1}>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="disabledEmails" label="Disabled Email event(s)" extra='List of email event that are disabled at tenant-wide level.'>
              <Select mode='multiple' style={{ minWidth: 300 }} showSearch filterOption={(input, option) => {
                const provider = (Globals.Tenant_DisablableEmails || []).find((c) => option.value == c);
                return provider.toLowerCase().includes(input.toLowerCase());
              }}> {Globals.Tenant_DisablableEmails.map((opt) => <Select.Option key={opt} value={opt}>{opt}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderLicenseModuleSubsection() {
    return (
      <>
        <Divider orientation="left">License module integration configuration</Divider>
        <Row type='flex'>
          <Col span={5} offset={1}>
            <Form.Item name="licModUseProdSuffixOnTx" valuePropName="checked" label="Use product name as suffix on payment transactions?" extra='When checked, it will include the purchased product name on transaction description at the processor gateway (Braintree for example).'>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={5} offset={1}>
            <Form.Item name="licModVaultingEnabled" valuePropName="checked" label="Vaulting enabled?" extra='If any product from the current tenant has Braintree enabled and this option also checked, vaulting functionality will be enabled for this tenant.'>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="sharedModuleLicenseAPIKey" label="License Module API Key" rules={[{ required: true, message: 'License module API Key is is required!' }]} extra='License module API key utilized to make privileged access into license module on the tenant scope.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={5} offset={1}>
            <Form.Item name="licModPurchaseEnabled" valuePropName="checked" label="Purchase orders enabled" extra='When checked, it will enable to product order and shipping order management in the tenant.'>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={22} offset={1}>
            <Form.Item name="licModPurchaseMessage" valuePropName="none" label="Purchase orders explanatory text" extra='This message will be displayed to users in the enrolment/purchase flow explaining to the user that they can optionally purchase training material along with their license to enrol in a course.'>
              <MarkdownEditor toolbarConfig={Utils.markdownToolbarItems()} value={this.state.licModPurchaseMessage}
                onChange={licModPurchaseMessage => this.setState({ licModPurchaseMessage })} />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={6} offset={1}>
            <Form.Item
              name="purchaseWebhookSuccess"
              label={
                <>
                  Purchase completed Webhook URLs
                  <Popover
                    title="Purchase completed Webhook URLs"
                    content="List containing webhooks to be invoked when a purchase is successful."
                  >
                    <QuestionCircleFilled
                      style={{ fontSize: 18, color: "#bcbcbc", marginLeft: 10 }}
                    />
                  </Popover>
                </>
              }
            >
              <Select mode='tags' style={{ minWidth: 200 }}>
                {(this.props.tenant.purchaseWebhookSuccess || []).map(purchaseWebhookSuccess => (
                  <Select.Option key={purchaseWebhookSuccess?.id} value={purchaseWebhookSuccess?.id}>
                    {purchaseWebhookSuccess}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item
              name="purchaseWebhookFail"
              label={
                <>
                  Purchase failed Webhook URLs
                  <Popover
                    title="Purchase failed Webhook URLs"
                    content="List containing webhooks to be invoked when a purchase fails."
                  >
                    <QuestionCircleFilled
                      style={{ fontSize: 18, color: "#bcbcbc", marginLeft: 10 }}
                    />
                  </Popover>
                </>
              }
            >
              <Select mode='tags' style={{ minWidth: 200 }}>
                {(this.props.tenant.purchaseWebhookFail || []).map(purchaseWebhookFail => (
                  <Select.Option key={purchaseWebhookFail?.id} value={purchaseWebhookFail?.id}>
                    {purchaseWebhookFail}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item
              name="purchaseWebhookRefund"
              label={
                <>
                  Purchase refunded Webhook URLs
                  <Popover
                    title="Purchase refunded Webhook URLs"
                    content="List containing webhooks to be invoked when a purchase is refunded."
                  >
                    <QuestionCircleFilled
                      style={{ fontSize: 18, color: "#bcbcbc", marginLeft: 10 }}
                    />
                  </Popover>
                </>
              }
            >
              <Select mode='tags' style={{ minWidth: 200 }}>
                {(this.props.tenant.purchaseWebhookRefund || []).map(purchaseWebhookRefund => (
                  <Select.Option key={purchaseWebhookRefund?.id} value={purchaseWebhookRefund?.id}>
                    {purchaseWebhookRefund}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderOrganizationModuleSubsection() {
    const columns = [
      { title: 'ID', key: 'id', dataIndex: 'id' },
      { title: 'Template', key: 'template', dataIndex: 'template' },
      { title: 'Period (days)', key: 'period', dataIndex: 'period' },
      {
        title: 'Actions', width: 'auto', key: 'Actions', width: '15%',
        render: props => {
          return (<span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit'>
              <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleEditInvitationReminder.bind(this, props)} />
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Delete'>
              <Popconfirm placement="right" title={`Are you sure that you want to delete this warning?`}
                onConfirm={this.handleDeleteInvitationReminder.bind(this, props)} okText="Yes" cancelText="No" >
                <Button style={{ marginLeft: 5 }} variant="none" icon={<DeleteOutlined />} shape="circle" />
              </Popconfirm>
            </Tooltip>
          </span>);
        }
      },
    ];
    const props = { rowKey: (p) => JSON.stringify(p), locale: { emptyText: 'No reminders found!' }, pagination: false };
    return (
      <>
        <Divider orientation="left">Organization module integration configuration</Divider>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="orgModDisabled" valuePropName="checked" label="Organization module disabled?" extra='When checked, it will hide all organization module components from the UI (making it enabled but disabled for the users). Theoretically, you could have employment selection even with org module disabled.'>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="sharedModuleOrganizationAPIKey" label="Organization Module API Key" rules={[{ required: true, message: 'Organization module API Key is is required!' }]} extra='Organization module API key utilized to make privileged access into organization module on the tenant scope.'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="orgModEmployeeMode" valuePropName="checked" label="Employee Mode enabled?" extra='When enabled, user will organization relation at tenant level will be directly related to its employment. Otherwise user will be able to be part of multiple organization without any relation to its employment.'>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="orgModAllowOrgSelfRegistration" valuePropName="checked" label="Allow organization self-registration?" extra='When enabled, user will be able to create an organization and self-enroll into it. Otherwise user will need to select pre-existing organizations.'>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="organizationExpireInvitationAfter" rules={[{ required: true, message: 'Expiration in days is required!' }]} label="Organization Invitation Expiration in Days" extra='When invitations are allowed, this default expiration will be used on every invitation issued by this tenant.'>
              <InputNumber min={7} max={365} precision={0} step={1} style={{ width: 200 }} />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="orgMgrCanEnrolBySession" valuePropName="checked" label="Can Managers enrol multiple students to a session?" extra='When enabled, organization managers will have an extra menu option that will allow them to select a session and register multiple students from their organization in a single operation.'>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="orgModCCOrgAdminInInvitations" valuePropName="checked" label="Send Org Admins a copy of invitations sent to students?" extra='When enabled, org admins will receive a copy of invitations sent to students to join their organization. This option is only applicable to tenants where Employee Mode is disabled.'>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={10} offset={1}>
            <Form.Item name="orgModReplicateMembership" valuePropName="checked" label="Replicate membership to/from other tenants" extra="When enabled, app org membership changes will be replicated to/from all tenants with this option enabled. Only applicable when employee mode is disabled.">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={22} offset={1}>
            <Form.Item name="orgMgrCanEnrolBySession" valuePropName="checked" label={<>
              Invitation Reminders
              <Popover title='Invitation Reminders' placement="right" content="This setting allows you to configure when reminders will be sent to students regarding unaccepted invitations. Enter a unique ID, template and period (days before invitation expires) for each warning to be sent.">
                <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
              </Popover>
            </>}>
              <Row gutter={32}>
                <Col span={21}>
                  <Table className="adminGenericTable" columns={columns} dataSource={[...(this.state.warningInvitationExpiration || [])]} {...props} />
                </Col>
                <Col>
                  <Button type='primary' onClick={this.handleAddInvitationReminder.bind(this)}>Add Reminder</Button>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={22} offset={1}>
            <Card title="Organization Member Profile Form">
              <Card.Grid style={{ width: '100%' }} hoverable={false}>
                <Row type='flex' align='middle'>
                  <Col span={5} offset={1}>
                    <Form.Item name="enableOrgMemberProfile" valuePropName="checked" label="Enable organization member profile?">
                      <Switch checked={this.state.enableOrgMemberProfile} onChange={this.handleCheckChange.bind(this, 'enableOrgMemberProfile')} />
                    </Form.Item>
                  </Col>
                  <Col span={3} offset={12}>
                    <Button type='primary' disabled={!this.state.enableOrgMemberProfile} onClick={this.handlePreviewOrgMemberProfile}> <EyeOutlined /> Generate Organization Member Profile form preview</Button>
                  </Col>
                </Row>
              </Card.Grid>
              <Card.Grid style={{ width: '35%' }} hoverable={false}>
                <CommonRegistrationSectionTable app={this.props.app} onEdit={this.handleEditOrgMemberProfileSection}
                  onDelete={this.handleDeleteOrgMemberProfileSection} sections={this.state?.organizationMemberProfile?.sections}
                  onAdd={this.handleAddOrgMemberProfileSection} disabled={!this.state.enableOrgMemberProfile} />
              </Card.Grid>
              <Card.Grid style={{ width: '65%' }} hoverable={false}>
                <CommonRegistrationFieldsTable app={this.props.app} onEdit={this.handleEditOrgMemberProfileField}
                  onDelete={this.handleDeleteOrgMemberProfileField} fields={this.state?.organizationMemberProfile?.fields}
                  onAdd={this.handleAddOrgMemberProfileField} disabled={!this.state.enableOrgMemberProfile} />
              </Card.Grid>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
  _renderUIConfigSubsection() {
    return (
      <>
        <Divider orientation="left">UI Configuration</Divider>
        <Row type='flex'>
          <Col span={3} offset={1}>
            <Form.Item name="uiThemeKey" rules={[{ required: true, message: 'UI Theme is required!' }]} label="UI Theme" extra='The theme key used on the LMS FE.'>
              <Select style={{ minWidth: 300 }} showSearch filterOption={(input, option) => {
                const provider = (Globals.Tenant_Themes || []).find((c) => option.value == c.value);
                return provider.label.toLowerCase().includes(input.toLowerCase());
              }}> {Globals.Tenant_Themes.map((opt) => <Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item name="reportTypes" label="Enabled Report types" extra='Which reports are enabled for admins to use on this tenant.'>
              <Select mode='multiple' style={{ minWidth: 300 }} showSearch filterOption={(input, option) => {
                const provider = (Globals.Tenant_ReportTypes || []).find((c) => option.value == c.value);
                return provider.label.toLowerCase().includes(input.toLowerCase());
              }}> {Globals.Tenant_ReportTypes.map((opt) => <Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item name="enabledUsersFilter" label="Enabled Users statuses on search filters" extra='Users search filters (status filter) enabled for usage on this tenant.'>
              <Select mode='multiple' style={{ minWidth: 300 }} showSearch filterOption={(input, option) => {
                const provider = (Globals.Tenant_EnabledUsersFilter || []).find((c) => option.value == c.value);
                return provider.label.toLowerCase().includes(input.toLowerCase());
              }}> {Globals.Tenant_EnabledUsersFilter.map((opt) => <Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={8} offset={1}>
            <Form.Item name="menuDisplayOptions" label="Sidebar Displayed Menu Options" extra='What sidebar menu options should be displayed for this tenant.'>
              <Checkbox.Group options={Globals.Tenant_UISidebarOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={14} offset={1}>
            <Form.Item name="settingsDisplayOptions" label="Settings Displayed Options" extra='What settings options should be displayed for admins on this tenant.'>
              <Checkbox.Group options={Globals.Tenant_UISettingsOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={8} offset={1}>
            <Form.Item name="showEmployerInRevenueReport" valuePropName="checked" label="Employer In Revenue Report" extra='If true, 2 extra columns will be included in the revenue report showing employer name and CUs'>
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item name="showReplaceStudentAction" valuePropName="checked" label="Replace Student Action" extra='If true, admins will see a "replace" action in the student list of a session'>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={8} offset={1}>
            <Form.Item name="showPreferredLanguageInSession" valuePropName="checked" label="Show Preferred Language in session" extra='If true, student’s preferred language will be added to the students view of a session'>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={8} offset={1}>
            <Form.Item name="optionalUserSearchFilters" label="Optional User Search Filters" extra='Allow optional columns to be configured for each tenant in User Search Filters.'>
              <Select mode='multiple' style={{ minWidth: 300 }} showSearch filterOption={(input, option) => {
                const provider = (Globals.Tenant_OptionalUserSearchFilters || []).find((c) => option.value == c.value);
                return provider.label.toLowerCase().includes(input.toLowerCase());
              }}> {Globals.Tenant_OptionalUserSearchFilters.map((opt) => <Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item name="optionalSearchColumns" label="Optional Search Columns" extra='Allow optional columns to be configured for each tenant.'>
              <Select mode='multiple' style={{ minWidth: 300 }} showSearch filterOption={(input, option) => {
                const provider = (Globals.Tenant_OptionalSearchColumns || []).find((c) => option.value == c.value);
                return provider.label.toLowerCase().includes(input.toLowerCase());
              }}> {Globals.Tenant_OptionalSearchColumns.map((opt) => <Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>)} </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={14} offset={1}>
            <Form.Item name="discountHelpText" valuePropName="none" label="No cost purchase tooltip" extra='Help text to purchase page explain how student could receive the course for free.'>
              <MarkdownEditor toolbarConfig={Utils.markdownToolbarItems()} value={this.state.discountHelpText}
                onChange={discountHelpText => this.setState({ discountHelpText })} />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderCORSubsection() {
    return (
      <>
        <Divider orientation="left">COR</Divider>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="idmSuperAdminRoleID" label="Super admin's role">
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="idmReviewerRoleID" label="Reviewer's role">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={10} offset={1}>
            <Form.Item name="idmTrainingAdminRoleID" label="Training Admin's role">
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="idmAuditorRoleID" label="Auditor's role">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderPaymentsSubsection() {
    return (
      <>
        <Divider orientation="left">Payments</Divider>

        <Row type='flex'>
          <Col span={22} offset={1}>
            <Form.Item
              name="commissionPayType"
              rules={[{ required: true, message: 'Commission Payment Type is required!' }]}
              label="Commission Payment Type"
              extra="Payments using affiliate URL will be made when students first register into the platform and registering using an affiliate URL (SiteReadyBC). Referral payments will be made based on the organization selected by the student when enrolling into a session."
              initialValue={Globals.Tenant_Commission_Pay_Type.at(-1).value}
            >
              <Select style={{ width: '100%' }}>
                {Globals.Tenant_Commission_Pay_Type.map((opt) => (
                  <Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderRegistrationSubsection() {
    return (
      <>
        <Divider orientation="left">Registration</Divider>
        <Row type='flex'>
          <Col span={22} offset={1}>
            <Form.Item name="organizationRegText" valuePropName="none" label="Registration Acknowledgement Text" extra='Text used for the tenant registration confirmation modal. If not value is specified or blank, no registration confirmation acknowledgement step is present on the modal.'>
              <MarkdownEditor toolbarConfig={Utils.markdownToolbarItems()} value={this.state.organizationRegText}
                onChange={organizationRegText => this.setState({ organizationRegText })} />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={22} offset={1}>
            <Form.Item name="organizationRegCheckboxText" label="Registration Checkbox Text" extra='Text used for the "acknowledgement" checkbox on the tenant registration modal.'>
              <Input.TextArea size={3} showCount />
            </Form.Item>
          </Col>
        </Row>
        <Row type='flex'>
          <Col span={22} offset={1}>
            <Card title="Registration Form">
              <Card.Grid style={{ width: '100%' }} hoverable={false}>
                <Row type='flex' align='middle'>
                  <Col span={3} offset={1}>
                    <Form.Item name="enableCustomRegistration" valuePropName="checked" label="Enable custom registration?">
                      <Switch checked={this.state.enableCustomRegistration} onChange={this.handleCheckChange.bind(this, 'enableCustomRegistration')} />
                    </Form.Item>
                  </Col>
                  <Col span={3} offset={15}>
                    <Button type='primary' disabled={!this.state.enableCustomRegistration} onClick={this.handlePreviewRegistration}> <EyeOutlined /> Generate Registration form preview</Button>
                  </Col>
                </Row>
              </Card.Grid>
              <Card.Grid style={{ width: '35%' }} hoverable={false}>
                <CommonRegistrationSectionTable app={this.props.app} onEdit={this.handleEditRegistrationSection}
                  onDelete={this.handleDeleteRegistrationSection} sections={this.state?.customRegistration?.sections}
                  onAdd={this.handleAddRegistrationSection} disabled={!this.state.enableCustomRegistration} />
              </Card.Grid>
              <Card.Grid style={{ width: '65%' }} hoverable={false}>
                <CommonRegistrationFieldsTable app={this.props.app} onEdit={this.handleEditRegistrationField}
                  onDelete={this.handleDeleteRegistrationField} fields={this.state?.customRegistration?.fields}
                  onAdd={this.handleAddRegistrationField} disabled={!this.state.enableCustomRegistration} />
              </Card.Grid>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
