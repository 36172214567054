import React from "react";
import autoBind from 'react-autobind';
import { Row, Col, Button, Table, Tooltip, Popconfirm, Modal, Select, Form, message, Input, Layout, PageHeader, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";

const { Option } = Select;

export default class AdminVaultCustomAttributes extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sortedInfo: null,
      isModalVisible: false,
      editingCustomAttribute: null,
      form: null,
      data: [],
      isLoading: false,
    };
  }

  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    this._loadCustomAttributes();
  }
  // Table support
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  // Handle Modal
  showEditModal(attribute) {
    this.setState({ isModalVisible: true, editingCustomAttribute: attribute });
  }
  async handleModalOk() {
    const values = await this.state.form.validateFields();
    const { id, label, type, autoFill } = values;
    const customAttributeData = { id, label, type, autoFill };

    if (this.state.editingCustomAttribute) {
      await this._editCustomAttributes(this.state.editingCustomAttribute.id, customAttributeData);
    } else {
      await this._createCustomAttributes(customAttributeData);
    }

    this.setState({ isModalVisible: false, editingCustomAttribute: null });
  }

  handleModalCancel() {
    this.setState({ isModalVisible: false, editingCustomAttribute: null });
  }

  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <PageHeader className='pageHeader' title={'Custom Attributes'} onBack={() => window.history.back()}
          extra={<Button
            type="primary"
            onClick={() => this.showEditModal(null)}
          >
            Create Custom Attribute
          </Button>} />
        <Layout.Content>
          {this.renderCustomAttributesTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  //private UI
  renderCustomAttributesTable() {
    let { sortedInfo, isModalVisible, editingCustomAttribute } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      size: 'small',
      onChange: this.handleFilterChange,
      rowKey: 'id',
      loading: this.props.isLoading,
      locale: { emptyText: 'No custom attributes founds in this vault.' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: ['bottomRight'] }
    };

    const columns = [
      {
        title: 'ID', dataIndex: 'id', key: 'id', width: 150,
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      },
      {
        title: 'Label', dataIndex: 'label', key: 'label', width: 150,
        sorter: (a, b) => a.label.localeCompare(b.label),
        sortOrder: sortedInfo.columnKey === 'label' && sortedInfo.order,
      },
      {
        title: 'Type', dataIndex: 'type', key: 'type', width: 150,
        sorter: (a, b) => a.type.localeCompare(b.type),
        sortOrder: sortedInfo.columnKey === 'type' && sortedInfo.order,
      },
      {
        title: 'Autofill', dataIndex: 'autoFill', key: 'autoFill', width: 150,
        render: (text) => (
          <Switch checked={text} defaultChecked={false} />
        ),
      },
      {
        title: 'Actions',
        width: 80,
        key: 'Actions',
        render: (record) => {
          return (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title='Edit Custom Attribute'>
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={() => this.showEditModal(record)}
                />
              </Tooltip>
            </span>
          );
        },
      },
    ];

    return (
      <>
        <Row type="flex">
          <Col offset={1} span={22}>
            <Table dataSource={this.state.data} columns={columns} {...props} />
          </Col>
        </Row>
        <Modal
          title={editingCustomAttribute ? "Edit Custom Attribute" : "Create Custom Attribute"}
          visible={isModalVisible}
          onOk={() => this.handleModalOk()}
          onCancel={() => this.handleModalCancel()}
          destroyOnClose
        >
          <Form
            ref={(form) => (this.state.form = form)}
            initialValues={editingCustomAttribute || { label: '', type: '', autoFill: false }}
          >
            {!editingCustomAttribute && (
              <Form.Item name="id" label="ID" rules={[{ required: true, message: 'Please enter an ID' }]}>
                <Input placeholder="Enter id" />
              </Form.Item>
            )}
            <Form.Item name="label" label="Label" rules={[{ required: true, message: 'Please enter a label' }]}>
              <Input placeholder="Enter label" />
            </Form.Item>
            <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select a type' }]}>
              <Select>
                <Option value="NUMERIC">Numeric</Option>
                <Option value="TEXT">Text</Option>
                <Option value="DATE">Date</Option>
              </Select>
            </Form.Item>
            <Form.Item name="autoFill" label="Autofill" valuePropName="checked">
              <Switch defaultChecked={false} />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }

  /* Private API operations */
  async _loadCustomAttributes() {
    this.startLoading();
    try {
      const resp = await this.props.app.ikFileService.customAttributes.listCustomAttributes();
      this.setState({ data: resp.customAttributes, });
    } catch (error) {
      console.error("Error occurred while loading:", error);
      message.error(`Error occurred while loading: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
    this.stopLoading();
  }
  async _createCustomAttributes(attributeData) {
    this.startLoading();
    if (!this._isMounted) return;

    try {
      const createResp = await this.props.app.ikFileService.customAttribute.createCustomAttribute(attributeData.id, attributeData);
      message.success(`Custom attribute created successfully!`);
      this._loadCustomAttributes();
    } catch (error) {
      console.error("Error occurred while creating:", error);
      message.error(`Error occurred while creating: ${error.response.data.err}`);
    } finally {
      this.stopLoading();
    }
  }
  async _editCustomAttributes(attributeId, attributeData) {
    this.startLoading();
    if (!this._isMounted) return;
    try {
      const editResp = await this.props.app.ikFileService.customAttribute.updateCustomAttribute(attributeId, attributeData);
      message.success(`Custom attribute updated successfully!`);
      this._loadCustomAttributes();
    } catch (error) {
      console.error("Error occurred while updating:", error);
      message.error(`Error occurred while updating: ${error.response.data.err}`);
    } finally {
      this.stopLoading();
    }
  }
}
