import React from 'react';
import autoBind from 'react-autobind';
import { Table } from 'antd';
//
import Globals from '@/config/Globals'
//props are: app, isLoading, sortedInfo, applications, tenants
export default class CommonContactsApplicationsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  render() {
    let { sortedInfo } = this.props;
    sortedInfo = sortedInfo || {};
    const columns = [
      { title: 'Application', key: 'application.keyword', width: '15%', sorter: true,
        render: props => this.props.tenants.find(t => t.id === props.tenantID).name || 'N/A', },
      { title: 'Company', key: 'company.keyword', width: '15%', sorter: true,
        render: props => props.name || 'N/A', },
    ];
    const props = { rowKey: (r) => `${r.tenantID}:${r.orgID}`, loading: this.props.isLoading, onChange: this.props.onFilterChange,
                    locale: {emptyText: 'No applications found!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (<Table className="applicationsTable" columns={columns} dataSource={this.props.applications} {...props}/>);
  }
}
