export default class BaseApp {
  constructor(type, app) {
    this.appType = type;
    this.app = app;
  }
  //
  get isEnabled() { return false; }
  get name() { return ""; }
  get description() { return ""; }
  //routes
  get routes() { return []; }
  //dashboard tile options
  get dashboardOptions() { return null; }
  //navigation items
  getNavigationOption(path, values) { return null; }
  /* private helpers */
  _parseUrl(a1,a2,a3,a4) { return this.app.urlManager.parseUrl(a1,a2,a3,a4); }
  _openLink(a1, a2, a3, a4) { this.app.urlManager.pushPage(a1,a2,a3,a4); }
}