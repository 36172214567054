import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, Spin, message } from 'antd';
//
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import 'antd/dist/antd.css';
import CommonEmailSuppressionTable from '../commonComponents/CommonEmailSuppressionTable';
import CommonEmailSuppressionModal from '../commonComponents/Modals/CommonEmailSuppressionModal';
import CommonSimpleSearchBar from '../commonComponents/CommonSimpleSearchBar';
//

export default class AdminEmailSuppresionView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      suppressions: [],
      isLoading: false,
      searchTerm: "",
      selectedRowDetails: null,
    };
    this.handleOpenAddSuppressionModal = this.handleOpenAddSuppressionModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this._fetchSuppressionData();
  }

  async _fetchSuppressionData() {
    this.setState({ isLoading: true });
    try {
      const emails = await this.props.app.emailLog.suppressedAddress.listSuppressedAddress();
      this.setState({ suppressions: emails });
    } catch (error) {
      console.error("Error occurred while searching:", error);
      message.error(`Error occurred while searching: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // Event handler to close the modal
  handleModalClose() {
    this.setState({ isModalVisible: false });
  }

  /* Private methods */
  async handleSearch(term) {
    this.setState({ searchTerm: term });
  }

  async handleOpenAddSuppressionModal() {
    this.setState({ isModalVisible: true });
  }

  async handleDeleteSuppression(emailAddress) {
    this.setState({ isLoading: true });
    this._fetchSuppressionData();
    this.setState({ isLoading: false });
    try {
      const deleteResp = await this.props.app.emailLog.suppressedAddress.deleteSuppressedAddress(emailAddress);
      message.success("Suppression deleted with success!");
    } catch (error) {
      console.error("Error occurred while deleting:", error);
      message.error(`Error occurred while deleting: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async handleAddSuppression(data) {
    this.setState({ isLoading: true });

    const sendData = { ...data, startDate: new Date().getTime() }
    try {
      const postResp = await this.props.app.emailLog.suppressedAddress.listSuppressedAddress2(sendData);
      message.success("Suppression added with success!");
      this._fetchSuppressionData();
    } catch (error) {
      console.error("Error occurred while adding:", error);
      message.error(`Error occurred while adding: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
    this.handleModalClose()
  }

  // UI
  render() {
    const { isLoading, suppressions, isModalVisible, searchTerm } = this.state;
    //filter tenants on runtime
    const filteredSuppressions = (suppressions || []).filter((c) => {
      return searchTerm && searchTerm.length > 0 ?
        c.email.toLowerCase().includes(searchTerm) || (c.reason || '').toLowerCase().includes(searchTerm) :
        true;
    });

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={isLoading} />
        <PageHeader className='pageHeader' title="Email Suppression" extra={[
          <Button key="1" type='primary' onClick={() => this.handleOpenAddSuppressionModal()}>Add</Button>
        ]} />
        <CommonSimpleSearchBar isLoading={isLoading} onSearch={this.handleSearch}
          app={this.props.app} placeholder="Search by email or reason" />
        {isLoading ? <Spin spinning size="large" /> :
          <CommonEmailSuppressionTable
            suppressions={filteredSuppressions}
            isLoading={isLoading}
            onDelete={this.handleDeleteSuppression}
          />
        }
        <CommonEmailSuppressionModal
          onConfirm={this.handleAddSuppression}
          isVisible={isModalVisible}
          onCancel={this.handleModalClose}
        />
      </Layout.Content>
    );
  }
}

