import React from "react";
import autoBind from 'react-autobind';
import { Row, Col, Button, Table, message, Layout, PageHeader } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";


export default class AdminVaultDocketDoc extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sortedInfo: null,
      data: [],
      isLoading: false,
    };
  }

  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    this._loadDocketDocuments();
  }
  // Table support
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <PageHeader className='pageHeader' title={'Documents'} onBack={() => window.history.back()} />
        <Layout.Content>
          {this.renderDocketDocumentsTable()}
        </Layout.Content>
      </Layout.Content>
    );
  }

  //private UI
  renderDocketDocumentsTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};

    const props = {
      size: 'small',
      onChange: this.handleFilterChange,
      rowKey: 'id',
      loading: this.state.isLoading,
      locale: { emptyText: 'No document found in this docket.' },
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: ['bottomRight'] }
    };

    const columns = [
      {
        title: 'ID', dataIndex: 'id', key: 'id', width: 150,
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      },
      {
        title: 'Name', dataIndex: 'name', key: 'name', width: 150,
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      },
      {
        title: 'File', dataIndex: 'filename', key: 'filename', width: 150,
        sorter: (a, b) => a.filename.localeCompare(b.filename),
        sortOrder: sortedInfo.columnKey === 'filename' && sortedInfo.order,
      },
      {
        title: 'Size', dataIndex: 'filesize', key: 'filesize', width: 150,
        sorter: (a, b) => a.filesize.localeCompare(b.filesize),
        sortOrder: sortedInfo.columnKey === 'filesize' && sortedInfo.order,
      },
      {
        title: 'Type', dataIndex: 'filetype', key: 'filetype', width: 150,
        sorter: (a, b) => a.filetype.localeCompare(b.filetype),
        sortOrder: sortedInfo.columnKey === 'filetype' && sortedInfo.order,
      },
      {
        title: 'Tags', dataIndex: 'tags', key: 'tags', width: 150,
        sorter: (a, b) => a.tags.localeCompare(b.tags),
        sortOrder: sortedInfo.columnKey === 'tags' && sortedInfo.order,
        render: (tags) => tags ? tags.map((tag) => tag).join(',') : '-'
      },
      {
        title: 'Preview Available', dataIndex: 'previewStatus', key: 'previewStatus', width: 150,
        render: (previewStatus) => previewStatus === 'COMPLETED' ? 'Yes' : '-'
      },
      {
        title: 'Deleted', dataIndex: 'deletionDate', key: 'deletionDate', width: 150,
        sorter: (a, b) => a.deletionDate - b.deletionDate,
        sortOrder: sortedInfo.columnKey === 'deletionDate' && sortedInfo.order,
        render: deletionDate => (deletionDate && deletionDate !== 0) ? Utils.getDateOnUIFormatByTimestamp(deletionDate) : '-',
      },
    ];

    return (
      <>
        <Row type="flex">
          <Col offset={1} span={22}>
            <Table dataSource={this.state.data} columns={columns} {...props} />
          </Col>
        </Row>
      </>
    );
  }


  /* Private API operations */
  async _loadDocketDocuments() {
    this.startLoading();
    const vaultId = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const docketId = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);
    if (!vaultId || !docketId) return;
    try {
      const resp = await this.props.app.ikFileService.vault.getDocketDocuments(vaultId, docketId);
      this.setState({ data: resp.documents });
    } catch (error) {
      console.error("Error occurred while loading:", error);
      message.error(`Error occurred while loading: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
    this.stopLoading();
  }
}
