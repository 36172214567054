import React from "react";
import autoBind from 'react-autobind';
import { Modal, Button, Form } from 'antd';
import CommonEmailSuppressionForm from "../Forms/CommonEmailSuppressionForm";
//

export default class CommonEmailSuppressionModal extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false };
  }

  show() { 
    this.setState({ isVisible: true }); 
  }

  handleClose = () => {
    this.setState({ isVisible: false });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  // UI
  render() {
    return (
      <Modal 
      open={this.props.isVisible}
      title={'Add Email Suppression'}
      placement="right" 
      onCancel={this.handleClose}
      footer={[
        <Button type="primary" form="emailSuppressionForm" key="submit" htmlType="submit"
        loading={this.state.isLoading} > Ok </Button>,
        ]} 
      width={800}
      >
        <Form
          id="emailSuppressionForm"
          onFinish={this.props.onConfirm}
        >
          <CommonEmailSuppressionForm /> 
        </Form>
      </Modal>
    );
  }
}


