import React from "react";
import autoBind from 'react-autobind';
import { Layout, Button, PageHeader } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
//
import AdminCustomerForm from "./Forms/AdminCustomerForm";
//
export default class AdminCustomerView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: {}, isLoading: false };
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Customer Configuration`;
    this._fetchData();
  }

  //Actions handler
  async handleSubmit() {
    //validate and retrieve form data
    const formData = await this.form.validateFields();
    if (!formData) return;
    console.log(formData);
    //make operation
    this._saveCustomer(formData);
  }
  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        {/* Keeping support to possible 'new customer' operation */}
        <PageHeader className='pageHeader' onBack={() => window.history.back()} title={this.isNew ? 'New Customer' : `Customer - ${(this.state.data?.id || 'Loading...')}`}
          extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create Customer' : 'Save Customer'}</Button>]}/>
        <Layout.Content>
          <AdminCustomerForm isNew={this.isNew} app={this.props.app} customer={this.state.data} courseProviders={this.props.app.sharedCache().getConfigCourseProviders()} {...Utils.propagateRef(this, 'form')}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /*Private methods*/
  async _saveCustomer(data) {
    this.startLoading();
    data.id = this.props.app.customerID;
    const updateResp = await this.props.app.config.customer.updateCustomer(data);
    if (!this._isMounted) return;
    if (updateResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Customer updated with success!");
      this._fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
  async _fetchData() {
    this.startLoading();
    await this.props.app.sharedCache().loadConfigCourseProviders();
    await this._fetchCustomer();
  }
  async _fetchCustomer() {
    let resp = await this.props.app.config.customer.getCustomer(this.props.app.customerID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body.id) { //valid data state
      const data = resp.body;
      this.setState({ data, isLoading: false });
      //set navigation items details
      this.props.app.appsManager?.navigation?.appendValue('customerID', data.id);
    } else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ data: {}, isLoading: false });
    }
  }
}
