import React from "react";
import autoBind from 'react-autobind';
import moment from 'moment';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "../commonComponents/CommonLoadingView";
//
import CommonUserProfileForm from "./CommonUserProfileForm";
import CommonSetupSMSMFAModal from '@/views/commonComponents/CommonSetupSMSMFAModal';
//mini-apps components
import CommonUserRolesTable from '@/views/apps/idm/commonComponents/CommonUserRolesTable';
import CommonPartitionsTable from '@/views/apps/idm/commonComponents/CommonPartitionsTable';
import CommonEmployeeProgramsTable from '@/views/apps/organization/commonComponents/Table/CommonEmployeeProgramsTable';
//resources
import "@/stylesheets/CommonUserProfile.less";
//
import { Layout, Button, PageHeader, Tag } from 'antd';
import { MobileOutlined } from '@ant-design/icons';
//
export default class CommonUserProfileView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.additionalInputs = this.props.app.getRegistrationObject();
    this.state = {
      oldUser: {
        bypassConfirmation: true, linkingRoles: [config.IDMClientOptions.roles.IDM_USER, props.app.themeManager.theme.userRole].concat(
          props.app.configManager.launchConfig.tenant ? [props.app.configManager.launchConfig.tenant?.idmUserRoleID] : []
        )
      },
      firstLoad: true, isLoading: false,
    };
  }

  //life cycle
  async componentDidMount() {
    super.componentDidMount();
    //Check if new parameter set
    this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) == Globals.URL_NewIndentifier);
    document.title = `${this.props.app.themeManager.theme.applicationName} - ${(this.isNew ? 'New user' : 'User Profile')}`;
    //Load data if not new user
    if (this.state.firstLoad && !this.isNew) this.fetchData();
    else this.forceUpdate();
  }

  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    //Get user ID form URL, get user ID, and fetch user info
    let userID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    if (!userID) userID = this.props.app.idm.session.authorization.getUserID();
    if (!this.isNew) {
      const data = await this._getUser(userID);
      this.stopLoading(false);
      this.setState({ oldUser: { ...this.state.oldUser, ...data }, firstLoad: false });
    } else {
      this.stopLoading(false);
      this.setState({ firstLoad: false });
    }
  }

  //Actions handler
  handlePartitionDelete(partObj) { }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      if (this.isNew) this._createUser({ ...this.state.oldUser, ...formData });
      else this._saveUser({ ...this.state.oldUser, ...formData });
    }
  }
  handleMFAChange() {
    if (!this.state.oldUser.mfaEnabled) this.smsMFAModal.enableMFA(this.state.oldUser);
    else this._disableMFA();
  }
  handleRoleAdded(role) {
    const linkingRoles = this.state.oldUser.linkingRoles;
    linkingRoles.push(role);
    this.setState({ oldUser: { ...this.state.oldUser, linkingRoles } });
  }
  handleRoleDeleted(roleIdx) {
    const linkingRoles = this.state.oldUser.linkingRoles;
    linkingRoles.splice(roleIdx, 1);
    this.setState({ oldUser: { ...this.state.oldUser, linkingRoles } });
  }

  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    //Get tags by user type
    let tags = [];
    if (this.state.oldUser?.linkingRoles?.indexOf(config.IDMClientOptions.roles.ROOT) != -1) tags.push(<Tag color="red" key='root'>Root user</Tag>);
    if (this.state.oldUser?.linkingRoles?.indexOf(this.props.app.themeManager.theme.adminRole) != -1) tags.push(<Tag color="orange" key='admin'>Admin user</Tag>);
    if (this.isNew) tags.push(<Tag color="blue" key='new'>New user</Tag>);
    if (this.state.oldUser?.mfaEnabled) tags.push(<Tag key='mfa' icon={<MobileOutlined />}>MFA Enabled</Tag>);
    //
    const isCustomUser = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    //
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <CommonSetupSMSMFAModal app={this.props.app} onFetchData={this.fetchData} {...Utils.propagateRef(this, 'smsMFAModal')} />
        <PageHeader className='pageHeader' title="Profile" tags={tags}
          {...(isCustomUser ? { onBack: () => window.history.back() } : {})}
          extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>
            {this.isNew ? 'Create user' : 'Save user'}</Button>]} />
        <Layout.Content>
          <CommonUserProfileForm editMode={true} isNew={this.isNew} app={this.props.app} handleSubmit={this.handleSubmit}
            data={this.state.oldUser} additionalInputs={this.additionalInputs} {...Utils.propagateRef(this, 'form')} onMFAToggle={this.handleMFAChange} />
          {(this.props.app.isRoot() || this.props.app.isAdmin() || this.props.app.isOrgAdmin() || this.props.app.isOrgSubAdmin()) &&
            <CommonEmployeeProgramsTable app={this.props.app} isLoading={this.props.isLoading}
              memberships={this.state.oldUser?.programs?.memberships} tenants={this.state.oldUser?.programs?.tenants} />
          }
          {this.props.app.isRoot() &&
            <CommonUserRolesTable editMode={true} roles={this.state.oldUser.linkingRoles} isLoading={isLoading} app={this.props.app}
              onRoleAdded={this.handleRoleAdded} onRoleDeleted={this.handleRoleDeleted} />
          }
          {this.props.app.isRoot() &&
            <CommonPartitionsTable editMode={true} data={this.state.partitionData} isLoading={isLoading}
              handleDelete={this.handlePartitionDelete} />
          }
          {/* Ugly fix for ending space, please improve me */}
          <br />
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* private API */
  async _saveUser(data) {
    //Build user
    let obj = await this._getIDMUserObject(data);
    obj.id = this.state.oldUser.id;
    console.debug('update', obj);
    //Check for email change confirmation
    if (this.state.oldUser && this.state.oldUser.email != obj.email) {
      const confirmation = await this.props.app.alertController.showPromptAlert('Attention!', `User email will be updated to "${obj.email}". Are you sure you want to update it? (type the new email address to confirm)`);
      if (confirmation != obj.email) return;
    }
    //Start loading
    this.startLoading();

    //Send update request
    const updateResp = await this.props.app.idm.api.user.update(obj);
    if (updateResp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
      return;
    }

    //Update employeement if field is specified
    const isRoot = this.state.oldUser?.linkingRoles?.indexOf(config.IDMClientOptions.roles.ROOT) != -1;
    if (Object.values(this.additionalInputs.fields).find((f) => f.type == Globals.RegOrgEmploymentSelectionType) && !isRoot) {
      let employmentResp = (await this.props.app.organization.employee.upsertEmployee(data?.selectedOrg?.id || '', obj.id, {
        email: this.state.oldUser.email /* user old email, as if a chnage happens, we receive reflection after validation :p */,
        firstName: obj.firstName, lastName: obj.lastName, hashedWorksafeID: data?.selectedOrg?.hashedWorksafeID || ''
      }));
      if (employmentResp.statusCode != 200 && employmentResp.statusCode != 204) {
        this.props.app.alertController.showAPIErrorAlert(null, employmentResp);
        this.stopLoading();
        return;
      }
      //save on program user as well
      data.employerID = data?.selectedOrg?.id;
    } else if (Object.values(this.additionalInputs.fields).find((f) => f.type == Globals.RegOrgEmploymentSelectionType) && isRoot) {
      //Failsafe call to make employment is deleted from root users if this came to the case of another app adding it via API
      try { (await this.props.app.organization.employee.deleteEmployee(data?.selectedOrg?.id || '', obj.id)); }
      catch { }
    }

    //Update tenant
    if (this.props.app.tenantID && this.state.oldUser?.tenantUser) {
      const programUser = this._getProgramUserObject(data, this.state.oldUser?.tenantUser);
      let programResp = (await this.props.app.api.user.update(programUser));
      if (programResp.statusCode != 200) {
        this.props.app.alertController.showAPIErrorAlert(null, programResp);
        this.stopLoading();
        return;
      }
    }

    //Check if should send to confirmation page (update error done before)
    if (updateResp.body && updateResp.body.emailChangeConfirmationToken) {
      this.props.app.alertController.showSuccessAlert("", `Email updated with success. Please, confirm the new email by entering the code you just received!`);
      await this.props.app.idm.authenticator.logout();
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.registrationConfirmation, null, updateResp.body.emailChangeConfirmationToken, obj.email); //push to main page
    } else {
      this.props.app.alertController.showSuccessAlert("", `User updated with success!`);
      this.fetchData();
    }
  }
  async _createUser(data) {
    this.startLoading();
    let obj = await this._getIDMUserObject(data, true);
    console.debug('create', obj);
    //
    const resp = await this.props.app.idm.api.registration.register(obj);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User created with success!`);
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.users);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _disableMFA() {
    this.startLoading();
    console.debug('Disabling MFA');
    const resp = await this.props.app.idm.api.user.disableSMSMFA(this.state.oldUser.id);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `MFA was disabled with success!`);
      this.fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _getUser(userID) {
    const promises = [];
    //get user and check for failure
    let userObj = null;
    promises.push(new Promise(async (resolve) => {
      userObj = (await this.props.app.idm.api.user.read(userID));
      if (userObj.statusCode == 200) {
        userObj = userObj.body;
        if (!userObj.sendEmailAs) userObj.sendEmailAs = ''; //enforce empty string on send email as removal
        //set navigation items details
        this.props.app.appsManager?.navigation?.appendValue('userID', userObj.id);
        this.props.app.appsManager?.navigation?.appendValue('userName', `${userObj.firstName} ${userObj.lastName}`);
        resolve(true);
      } else {
        this.props.app.alertController.showAPIErrorAlert(null, userObj);
        resolve(false);
      }
    }));

    //Load tenant user
    let tenantUser = null;
    if (this.props.app.tenantID) {
      promises.push(new Promise(async (resolve) => {
        let tenantUserResp = (await this.props.app.api.user.getByID(userID));
        if (tenantUserResp.statusCode == 200) tenantUser = tenantUserResp.body;
        resolve(true);
      }));
    }

    //Load employment info
    let employementInfo = null;
    if (Object.values(this.additionalInputs.fields).find((f) => f.type == Globals.RegOrgEmploymentSelectionType)) {
      promises.push(new Promise(async (resolve) => {
        let employmentResp = (await this.props.app.organization.employee.getEmployeeByEmployeeID(userID));
        if (employmentResp.statusCode == 200) employementInfo = employmentResp.body;
        resolve(true);
      }));
    }

    //Load programs info
    let programsInfo = null;
    if (this.props.app.isRoot() || this.props.app.isAdmin() || this.props.app.isOrgAdmin() || this.props.app.isOrgSubAdmin()) {
      promises.push(new Promise(async (resolve) => {
        let membershipResp = (await this.props.app.organization.employee.describeEmployeeMembershipsByEmployeeID(userID));
        if (membershipResp.statusCode == 200) programsInfo = membershipResp.body;
        resolve(true);
      }));
    }

    //get partitions data
    const wantedParts = this._getAvailablePartitions(true);
    let partData = null;
    if (wantedParts.length > 0) {
      promises.push(new Promise(async (resolve) => {
        partData = await this.props.app.idm.api.userPartition.readSome(wantedParts, userID);
        partData = partData?.body?.parts || [];
        resolve(true);
      }));
    }

    //Resolve alll, continue if all true
    const resolveAll = await Promise.all(promises);
    if (!resolveAll || resolveAll.find((r) => !r)) return;
    //Return parsed info
    return this._parseUserResponses(userObj, partData, employementInfo, programsInfo, tenantUser);
  }
  /* private - remote data read helpers */
  _parseUserResponses(userObj, partData, employementInfo, programsInfo, tenantUser) {
    //
    try {
      //Buildup form data
      const formData = {};
      for (let fieldKey of Object.keys(this.additionalInputs.fields)) {
        const field = this.additionalInputs.fields[fieldKey];
        if (field.partitionID && field.partitionID.length > 0) {
          const part = partData.find(part => (part.partID == field.partitionID));
          formData[field.id] = Utils.getNestedObject(part ? part.value : null, field.id);
          if (formData[field.id] && field.type == 'date') formData[field.id] = moment(formData[field.id]);
        }
        if (field.saveOnUser && !formData[field.id]) {
          formData[field.id] = Utils.getNestedObject(userObj, field.id);
          if (formData[field.id] && field.type == 'date') formData[field.id] = moment(formData[field.id]);
        }
        if (!formData[field.id]) { formData[field.id] = ''; /* unknown source */ }
        if (field.type == 'boolean' || field.type == 'switch') formData[field.id] = !!formData[field.id];
      }
      //Set form data
      return { ...formData, ...userObj, partData, employementInfo, programs: programsInfo, tenantUser };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  _getAvailablePartitions(noSanitize) {
    let parts = [];
    const additionalInputs = this.additionalInputs.fields;
    for (let input of Object.values(additionalInputs)) {
      const partID = (noSanitize ? input.partitionID : input.partitionID.replace(/\./g, '_'));
      if (parts.indexOf(partID) == -1) parts.push(partID);
    } return parts;
  }
  /* private - remote data write helper */
  async _getIDMUserObject(data, isCreate) {
    /* ------------- Partition data --------------- */
    //Get all unique partitions in parallel
    const { fields } = this.additionalInputs;
    const uniqueValidParts = Object.keys(fields).filter((f) => fields[f].partitionID && fields[f].partitionID.length > 0).reduce((acc, curr) => {
      if (acc.indexOf(fields[curr].partitionID) == -1) acc.push(fields[curr].partitionID);
      return acc;
    }, []);
    let partsPool = [];
    if (this.props.app.isAdmin()) {
      let userID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
      if (!userID) userID = this.props.app.idm.session.authorization.getUserID();
      partsPool = await this.props.app.idm.api.userPartition.readSome(uniqueValidParts, userID);
      partsPool = partsPool?.body?.parts || [];
    } else {
      partsPool = await Promise.all(uniqueValidParts.map((p) => this.props.app.idm.session.getPartitionByID(p, true)));
    }
    //Buildup part data
    const partData = {};
    //For each unique part
    for (let partitionID of uniqueValidParts) {
      //Get old value
      let partition = partsPool.find((p) => p && p.partID == partitionID);
      if (!partition || !partition.partID) partition = { partID: partitionID };
      //Get fields that needs to be saved on this partition
      const fieldsData = {};
      for (let fieldKey of Object.keys(fields)) {
        const field = fields[fieldKey];
        if (field.partitionID && field.partitionID.length > 0 && field.partitionID == partition.partID) {
          if (data[field.id] && field.type == 'date') {
            Utils.setNestedObject(fieldsData, field.id, moment(data[field.id]).format(field.format));
          } else {
            Utils.setNestedObject(fieldsData, field.id, data[field.id]);
          }
        }
      }
      //Build part from old values + overrides
      partData[partition.partID] = { value: { ...partition.value || {}, ...fieldsData } };
    }

    /* --------------- IDM user -------------------- */
    const userObj = {};
    //If has impersonate callback url, set to registration object
    if (this.props.app.idm.impersonate.getCallbackURL()) userObj.redirectURL = this.props.app.idm.impersonate.getCallbackURL();
    //Default fields
    userObj.email = data.email;
    userObj.firstName = data.firstName;
    userObj.lastName = data.lastName;
    userObj.sendEmailAs = data.sendEmailAs;
    userObj.notes = data.notes;
    userObj.externalID = data.externalID;
    userObj.externalID2 = data.externalID2;
    // userObj.phoneNumber = null;//data.mobilePhone;
    userObj.linkingRoles = this.state.oldUser.linkingRoles;
    if (data.password) userObj.password = data.password;
    userObj.parts = partData;
    //enforce cert name replication
    if (userObj.parts['ca.bccsa.professional']) userObj.parts['ca.bccsa.professional'].value.certNameOnCertificate = data.certNameOnCertificate;
    if (isCreate) userObj.bypassConfirmation = this.state.oldUser.bypassConfirmation;
    //
    return userObj;
  }
  _getProgramUserObject(data, existingUser) {
    //Get fields that needs to be saved on the user object partition
    const { fields } = this.additionalInputs;
    const fieldsData = { employerID: data.employerID };
    for (let fieldKey of Object.keys(fields)) {
      const field = fields[fieldKey];
      if (field.saveOnUser) {
        if (data[field.id] && field.type == 'date') {
          Utils.setNestedObject(fieldsData, field.id, moment(data[field.id]).format(field.format));
        } else {
          Utils.setNestedObject(fieldsData, field.id, data[field.id]);
        }
      }
    }
    const userValue = {
      ...existingUser,
      firstName: data.firstName, lastName: data.lastName, email: data.email,
      ...(this.props.app.isAdmin() ? { certNameOnCertificate: data.certNameOnCertificate } : {}),
      ...fieldsData /* dynamic data */
    };
    return userValue;
  }
}
