import React from "react";
import autoBind from 'react-autobind';
import { Layout, Button, PageHeader, Table, Tooltip, Popconfirm } from 'antd';
import {
  DisconnectOutlined, IssuesCloseOutlined, ToolOutlined, UnlockOutlined, EditOutlined, MessageOutlined
} from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
//
import config from '@/config/config';
import Globals from '@/config/Globals'
//
import CommonSearchBar from '../commonComponents/CommonSearchBar';
import CommonSetPasswordModal from '@/views/commonComponents/CommonSetPasswordModal';
//
export default class CommonUsersSearchView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      data: [], total: 0,
      sortedInfo: null, firstLoad: true, isLoading: false,
      ...this._getInitialState()
    }
  }
  async componentDidMount() {
    super.componentDidMount();
    if (this.state.firstLoad) this.fetchData();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Users Search`;
  }
  //API
  async fetchData(force) {
    if (!force && this.state.isLoading) return;
    if (!this._isMounted) return;
    //
    this.startLoading();
    this._setSearchQueryParams();
    //request
    const resp = await this.props.app.idm.api.user.searchUsersByTerm(
      this.state.searchObj.term, this._getSearchFilter(),
    );
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.users) {
      const users = resp.body.users.map(user => user._source);
      this.setState({ data: users, total: resp.body.total, firstLoad: false, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ data: [], total: 0, isLoading: false });
    }
  }
  //Actions
  //Table core functions actions
  handleFilterChange(pagination, filters, sortedInfo) {
    this.setState({ sortedInfo }, () => this.fetchData());
  }
  handlePagination(currentPage) {
    this.setState({ currentPage }, () => this.fetchData());
  }
  //Table User Actions
  handleUserPasswordReset(userObj) { this._resetUsersPassword(userObj); }
  handleUserConfirm(userObj) { this._confirmUser(userObj); }
  handleUserEnable(userObj) {
    if (userObj.isEnabled) this._disableUser(userObj);
    else this._enableUser(userObj);
  }
  handleUserEdit(userObj) {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.user, null, userObj.id);
    this.props.app.appsManager?.navigation?.appendValue('userID', userObj.id);
    this.props.app.appsManager?.navigation?.appendValue('userName', `${userObj.firstName} ${userObj.lastName}`);
  }
  handleUserPasswordSet(userObj) { this.changePassModal.handleShow(userObj.id); }
  //Search
  handleSearch(term) {
    this.setState({ searchObj: { term }, sortedInfo: null, currentPage: 1 }, () => this.fetchData());
  }
  //Other
  handleUserCreate() {
    this.props.app.urlManager.pushPage(config.ApplicationRoutes.user, null, Globals.URL_NewIndentifier);
    this.props.app.appsManager?.navigation?.appendValue('userID', Globals.URL_NewIndentifier);
    this.props.app.appsManager?.navigation?.appendValue('userName', `New User`);
  }

  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    const columns = [
      {
        title: 'Name',
        key: 'fullName.keyword',
        width: 150,
        dataIndex: 'fullName',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'fullName.keyword' && sortedInfo.order,
        render: (text, record) => (
          <span style={{ color: record.isEnabled ? 'black' : 'red', alignItems: 'center' }}>
            {text}
            {record.notes && record.notes.length > 0 && (
              <>
                {' '}
                <Tooltip title={record.notes} placement="top">
                  <MessageOutlined style={{ fontSize: '16px', verticalAlign: 'middle' }} />
                </Tooltip>
              </>
            )}
          </span>
        ),
      },
      {
        title: 'Email',
        width: 200,
        dataIndex: 'email',
        key: 'email.keyword',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'email.keyword' && sortedInfo.order,
        render: (text, record) => (
          <span style={{ color: record.isEnabled ? 'black' : 'red' }}>{text}</span>
        ),
      },
      {
        title: 'Enabled',
        width: 150,
        key: 'isEnabled',
        render: (text, record) => (
          <span style={{ color: record.isEnabled ? 'black' : 'red' }}>{record.isEnabled ? 'Yes' : 'No'}</span>
        ),
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'isEnabled' && sortedInfo.order,
      },
      {
        title: 'MFA Enabled',
        width: 150,
        dataIndex: 'mfaEnabled',
        key: 'mfaEnabled',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'mfaEnabled' && sortedInfo.order,
        render: (text, record) => (
          <span style={{ color: record.isEnabled ? 'black' : 'red' }}>{text ? 'Yes' : 'No'}</span>
        ),
      },
      {
        title: 'Locked',
        width: 150,
        dataIndex: 'lockedUntil',
        key: 'lockedUntil',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'lockedUntil' && sortedInfo.order,
        render: (text, record) => (
          <span style={{ color: record.isEnabled ? 'black' : 'red' }}>{text > Date.now() ? 'Yes' : 'No'}</span>
        ),
      },
      {
        title: 'Confirmed',
        width: 150,
        dataIndex: 'confirmationDate',
        key: 'confirmationDate',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'confirmationDate' && sortedInfo.order,
        render: (text, record) => (
          <span style={{ color: record.isEnabled ? 'black' : 'red' }}>{text ? 'Yes' : 'No'}</span>
        ),
      },
      {
        title: 'Last login',
        width: 150,
        dataIndex: 'lastLogin',
        key: 'lastLogin',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'lastLogin' && sortedInfo.order,
        render: (text, record) => (
          <span style={{ color: record.isEnabled ? 'black' : 'red' }}>
            {Utils.getDateAndTimeOnUIFormatByTimestamp(text)}
          </span>
        ),
      },
      {
        title: 'Actions',
        width: 200,
        key: 'Actions',
        fixed: 'right',
        render: props => {
          return (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title={(!props.isEnabled ? 'Enable user' : 'Disable user')}>
                <Popconfirm title={`Do you really want to ${props.isEnabled ? "disable" : "enable"} user '${props.firstName + ' ' + props.lastName}'?`} placement="bottomRight" onConfirm={this.handleUserEnable.bind(this, props)} okText="Yes" cancelText="No">
                  <Button variant="none" icon={<DisconnectOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>{' '}
              <Tooltip placement="bottomLeft" title='Invalidate password'>
                <Popconfirm title={`Do you really want to invalidate user '${props.firstName + ' ' + props.lastName}' password ?`} placement="bottomRight" onConfirm={this.handleUserPasswordReset.bind(this, props)} okText="Yes" cancelText="No">
                  <Button variant="none" icon={<IssuesCloseOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>{' '}
              <Tooltip placement="bottomLeft" title='Set password'>
                <Popconfirm title={`Do you really want to set user '${props.firstName + ' ' + props.lastName}' a new password ?`} placement="bottomRight" onConfirm={this.handleUserPasswordSet.bind(this, props)} okText="Yes" cancelText="No">
                  <Button variant="none" icon={<ToolOutlined />} shape="circle" />
                </Popconfirm>
              </Tooltip>{' '}
              {!props.confirmationDate && <>
                <Tooltip placement="bottomLeft" title='Confirm user'>
                  <Popconfirm title={`Do you really want to confirm user '${props.firstName + ' ' + props.lastName}' ?`} placement="bottomRight" onConfirm={this.handleUserConfirm.bind(this, props)} okText="Yes" cancelText="No">
                    <Button variant="none" icon={<UnlockOutlined />} shape="circle" />
                  </Popconfirm>
                </Tooltip>{' '}
              </>}
              <Tooltip placement="bottomLeft" title='Edit user'>
                <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.handleUserEdit.bind(this, props)} />
              </Tooltip>
            </span>
          );
        },
      }
    ];



    const props = {
      rowKey: 'id', loading: this.state.isLoading, onChange: this.handleFilterChange,
      locale: { emptyText: (this.state.firstLoad ? 'Search users' : (this.state.isLoading ? 'Loading...' : 'No users found!')) },
      scroll: { x: 1500 }, sortDirections: ['ascend', 'descend', 'ascend'],
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, showSizeChanger: false, hideOnSinglePage: true, position: 'bottom',
        total: this.state.total, onChange: this.handlePagination, current: this.state.currentPage
      }
    };
    //render
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <CommonSetPasswordModal ref={f => (this.changePassModal = f)} app={this.props.app} />
        <PageHeader className='pageHeader' title="Users"
          extra={[<Button key="1" onClick={this.handleUserCreate} type='primary'>Create user</Button>]} />
        <Layout.Content>
          <CommonSearchBar alwaysEnabled defaultValue={this.state.searchObj.term} handleSearch={this.handleSearch} />
          <Table dataSource={this.state.data} columns={columns} {...props} />
        </Layout.Content>
      </Layout.Content>
    );
  }

  /* API Calls */
  async _disableUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.idm.api.user.disable(userObj.id);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User '${userObj.firstName + ' ' + userObj.lastName}' disabled with succeed!`);
      this.fetchData(true);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _enableUser(userObj) {
    this.startLoading();
    const resp = await this.props.app.idm.api.user.enable(userObj.id);
    if (resp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User '${userObj.firstName + ' ' + userObj.lastName}' enabled with succeed!`);
      this.fetchData(true);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _resetUsersPassword(userObj) {
    this.startLoading();
    //
    const resetResp = await this.props.app.idm.api.password.reset(userObj.email);
    if (resetResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User '${userObj.firstName + ' ' + userObj.lastName}' password reset with succeed!`);
      this.fetchData(true);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading();
    }
  }
  async _confirmUser(userObj) {
    this.startLoading();
    //
    const resetResp = await this.props.app.idm.api.registrationConfirmation.confirmAsAdmin(userObj.email);
    if (resetResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `User '${userObj.firstName + ' ' + userObj.lastName}' registration confirmation succeed!`);
      this.fetchData(true);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resetResp);
      this.stopLoading();
    }
  }

  //Filters and URL support
  _getInitialState() {
    const search = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SearchTerm) || null;
    const currentPage = parseInt(this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortField) || null;
    const order = this.props.app.urlManager.getQueryParam(Globals.URLQueryParam_SortOrder) || null;
    return {
      searchObj: { term: search || null },
      currentPage, sortedInfo: { columnKey, order },
      total: 0
    };
  }

  _setSearchQueryParams() {
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SearchTerm, (this.state.searchObj.term || null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : null));
    this.props.app.urlManager.updateQueryStringParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }

  _getSearchFilter() {
    const filters = this.state.filters;
    //page
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    //sort
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    return { from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'), ...filters };
  }
}
