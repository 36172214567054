import React from "react";
import { Switch } from "react-router-dom";
import CustomRoute from "./ui-components/CustomRoute";

//Common Auth views
//import ErrorView from "./views/ErrorView";
import CommonLoginView from "./views/authSubviews/CommonLoginView";
import CommonRegistrationView from './views/authSubviews/CommonRegistrationView';
import CommonRegistrationConfirmationView from './views/authSubviews/CommonRegistrationConfirmationView';
import CommonRegistrationConfirmationResetView from './views/authSubviews/CommonRegistrationConfirmationResetView';
import CommonResetPasswordRequestView from './views/authSubviews/CommonResetPasswordRequestView';
import CommonResetPasswordConfirmationView from './views/authSubviews/CommonResetPasswordConfirmationView';
import CommonNewPasswordSetup from './views/authSubviews/CommonNewPasswordSetup';
import CommonAuthErrorView from './views/authSubviews/CommonAuthErrorView';
import CommonRedirectView from './views/authSubviews/CommonRedirectView';
import CommonSMSMFARequestView from './views/authSubviews/CommonSMSMFARequestView';
//Common
import CommonUserProfileView from './views/commonSubviews/CommonUserProfileView';
//User
import UserDashboardView from "./views/userSubviews/UserDashboardView";
//Admin
import AdminDashboardView from "./views/adminSubviews/AdminDashboardView";
// Instructor
import InstructorCalendarView from './views/instructorSubviews/InstructorCalendarView';
import InstructorStudentsView from './views/instructorSubviews/InstructorStudentsView';
import InstructorEditSessionAttendanceView from './views/instructorSubviews/InstructorEditSessionAttendanceView';
//
import config from "./config/config";

export default class AppRoutes extends React.Component {
  render() {
    return this._renderPrivilegedRoutes();
  }
  _renderPrivilegedRoutes() {
    //not logged? - default landing page
    if (!this.props.appRef.props.app.idm.isLogged() || this.props.appRef.props.app.onGoingBehaviour.isAuthenticating) {
      return (
        <Switch>
          <CustomRoute path={config.ApplicationRoutes.login} exact component={CommonLoginView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.register} exact component={CommonRegistrationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.registrationConfirmationReset} exact component={CommonRegistrationConfirmationResetView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.registrationConfirmation} exact component={CommonRegistrationConfirmationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.registrationConfirmationMail} exact component={CommonRegistrationConfirmationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.resetPassword} exact component={CommonResetPasswordRequestView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.resetPasswordRaw} exact component={CommonResetPasswordRequestView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.resetPasswordConfirmation} exact component={CommonResetPasswordConfirmationView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.setupPassword} exact component={CommonNewPasswordSetup} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.authError} exact component={CommonAuthErrorView} appRef={this.props.appRef}/>
          <CustomRoute path={config.ApplicationRoutes.smsMFA} exact component={CommonSMSMFARequestView} appRef={this.props.appRef} />
          <CustomRoute component={CommonLoginView} appRef={this.props.appRef}/>
        </Switch>
      );
    }
    //Logged but being redirected
    if (this.props.appRef.props.app.idm.isLogged() && this.props.appRef.props.app.onGoingBehaviour.redirectMode) {
      return (
        <Switch>
          <CustomRoute component={CommonRedirectView} appRef={this.props.appRef}/>
        </Switch>
      );
    }
    //Logged as admin
    if (this.props.appRef.props.app.isRoot() || this.props.appRef.props.app.isAdmin()) {
      const routes = this.props.appRef.props.app.appsManager.renderExpandedMiniAppsRoutes();
      routes.push(<CustomRoute key={config.ApplicationRoutes.profile} path={config.ApplicationRoutes.profile} exact component={CommonUserProfileView} appRef={this.props.appRef} />);
      routes.push(<CustomRoute key={config.ApplicationRoutes.homepage} path={config.ApplicationRoutes.homepage} component={AdminDashboardView} appRef={this.props.appRef} />);
      return ( <Switch> {routes} </Switch> );
    }
    //Logged as instructor
    if (this.props.appRef.props.app.isInstructor()) {
      const routes = this.props.appRef.props.app.appsManager.renderExpandedMiniAppsRoutes();
      routes.push(<CustomRoute key={config.ApplicationRoutes.instructor_calendar} path={config.ApplicationRoutes.instructor_calendar} component={InstructorCalendarView} appRef={this.props.appRef} />);
      routes.push(<CustomRoute key={config.ApplicationRoutes.instructor_students} path={config.ApplicationRoutes.instructor_students} component={InstructorStudentsView} appRef={this.props.appRef} />);
      routes.push(<CustomRoute key={config.ApplicationRoutes.instructor_edit_session_attendance} path={config.ApplicationRoutes.instructor_edit_session_attendance} component={InstructorEditSessionAttendanceView} appRef={this.props.appRef} />);
      routes.push(<CustomRoute key={config.ApplicationRoutes.profile} path={config.ApplicationRoutes.profile} exact component={CommonUserProfileView} appRef={this.props.appRef} />);
      routes.push(<CustomRoute key={config.ApplicationRoutes.homepage} path={config.ApplicationRoutes.homepage} component={InstructorCalendarView} appRef={this.props.appRef} />);
      return ( <Switch> {routes} </Switch> );
    }
    //Logged as user
    if (this.props.appRef.props.app.isUser()) {
      const routes = this.props.appRef.props.app.appsManager.renderExpandedMiniAppsRoutes();
      routes.push(<CustomRoute key={config.ApplicationRoutes.profile} path={config.ApplicationRoutes.profile} exact component={CommonUserProfileView} appRef={this.props.appRef}/>);
      routes.push(<CustomRoute key={config.ApplicationRoutes.homepage} path={config.ApplicationRoutes.homepage} component={UserDashboardView} appRef={this.props.appRef}/>);
      return ( <Switch> {routes} </Switch> );
    }
    //Unknown state
    console.log("No routes to be served :/")
    return (<Switch></Switch>);
  }
}
