import React from 'react';
import autoBind from 'react-autobind';
import { Dropdown, Menu, Button, Table, Tooltip } from 'antd';
//icons
import { EllipsisOutlined, EditOutlined, MessageOutlined } from '@ant-design/icons';
import { FaUsers, FaRegAddressCard, FaUserShield } from 'react-icons/fa';
import { GrMapLocation, GrCertificate } from 'react-icons/gr';
//
import Globals from '@/config/Globals'
import Utils from '@/components/helpers/Utils'
//props are: app, isLoading, total, currentPage, sortedInfo, orgs, onPageChange, onFilterChange, onOrgEdit, onRowAction
export default class CommonOrganizationsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  render() {
    let { sortedInfo } = this.props;
    sortedInfo = sortedInfo || {};
    const worksafeTerm = this.props.worksafeTerm;
    const columns = [
      {
        title: worksafeTerm, key: 'worksafeID.keyword', width: '10%', sorter: true,
        sortOrder: sortedInfo.columnKey === 'worksafeID.keyword' && sortedInfo.order,
        render: (props) => (<StyledSpan status={props.status}>{props.worksafeID || 'N/A'}</StyledSpan>),
      },
      {
        title: 'Name', key: 'name.keyword', dataIndex: 'name', width: '20%',
        sorter: true, sortOrder: sortedInfo.columnKey === 'name.keyword' && sortedInfo.order,
        render: (text, record) => (
          <span style={{ alignItems: 'center', color: record.status !== "Active" ? 'red' : '' }}>
            {text}
            {record.notes && record.notes.length > 0 && (
              <>
                {' '}
                <Tooltip title={record.notes} placement="top">
                  <MessageOutlined style={{ fontSize: '16px', verticalAlign: 'middle' }} />
                </Tooltip>
              </>
            )}
          </span>
        ),
      },
      {
        title: 'Trade Name', key: 'tradeName.keyword', width: '20%', sorter: true,
        sortOrder: sortedInfo.columnKey === 'tradeName.keyword' && sortedInfo.order,
        render: (props) => (<StyledSpan status={props.status}>{props.tradeName || 'N/A'}</StyledSpan>),
      },
      {
        title: 'City', key: 'address.city.keyword', width: '10%', sorter: true,
        sortOrder: sortedInfo.columnKey === 'address.city.keyword' && sortedInfo.order,
        render: (props) => (<StyledSpan status={props.status}>{props.address?.city || 'N/A'}</StyledSpan>),
      },
      {
        title: 'CUs', key: 'cus', width: '10%',
        sorter: false,
        render: (org) =>  (<StyledSpan status={org.status}>{org.cus ? org.cus.map((cu) => cu.id).join(',') : 'N/A'}</StyledSpan>)
      },
      {
        title: 'Created on', key: 'createdOn', width: '10%', sorter: true,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order,
        render: (props) => (<StyledSpan status={props.status}>{props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A'}</StyledSpan>),
      },
      {
        title: 'Actions', width: '10%', key: 'Actions', align: 'center',
        render: props => (
          <>
            <Button style={{ color: props.status !== "Active" ? 'red' : '' }} onClick={(e) => this.props.onRowAction(props, 'organization')}>
              <EditOutlined />
            </Button>
            <Dropdown key={props.id} overlay={<Menu onClick={(e) => this.props.onRowAction(props, e.key)} items={[
              { label: 'Employees', key: 'employees', icon: <FaUsers /> },
              { label: 'Contacts', key: 'contacts', icon: <FaRegAddressCard /> },
              { label: 'Managers', key: 'managers', icon: <FaUserShield /> },
              { label: 'Locations', key: 'locations', icon: <GrMapLocation /> },
              { label: 'Programs', key: 'programs', icon: <GrCertificate /> }
            ]} />} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
              <Button style={{ color: props.status !== "Active" ? 'red' : '' }}><EllipsisOutlined /></Button>
            </Dropdown>
          </>
        ),
      }
    ];
    const props = {
      rowKey: 'id', loading: this.props.isLoading, onChange: this.props.onFilterChange,
      locale: { emptyText: 'No companies found!' }, sortDirections: ['ascend', 'descend', 'ascend'],
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: ['bottomRight'],
        total: this.props.total, onChange: this.props.onPageChange, current: this.props.currentPage,
        showSizeChanger: false, showTotal: (total, range) => <div style={{ marginRight: 20 }}>{`${range[0]}-${range[1]} of ${total} companies`}</div>
      },
    };
    return (<Table className="organizationsTable" columns={columns} dataSource={this.props.orgs} {...props} />);
  }
}

const StyledSpan = (props) => {
  return (
    <span style={{ color: props.status !== "Active" ? 'red' : '' }}>{props.children}</span>
  );
}