import React from "react";
import autoBind from 'react-autobind';
//
import { Divider, Row, Col, Button, Table, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";

//props: isLoading, data, handleEdit, handleDelete
export default class CommonRolesTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }
  //Table support
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = { 
      size: 'middle', onChange: this.handleFilterChange, rowKey: 'id', loading: this.props.isLoading,
      locale: {emptyText: 'No roles found on this application.'},
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] },
    };
    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name', width: 150,
        sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order
      },
      { title: 'Description', dataIndex: 'description', key: 'description', width: 150,
        sorter: (a, b) => (a.description || '').localeCompare(b.description || ''),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order
      },
      { title: 'MFA Required', width: 150, dataIndex: 'mfaRequired',
        render: d => (d ? 'Yes' : 'No'), key: 'mfaRequired',
        sorter: (a, b) => (a == b ? 0 : a ? -1 : 1),
        sortOrder: sortedInfo.columnKey === 'mfaRequired' && sortedInfo.order
      },
      { title: 'Administrator Role', width: 150, dataIndex: 'superRole',
        render: d => (d ? 'Yes' : 'No'), key: 'superRole',
        sorter: (a, b) => (a == b ? 0 : a ? -1 : 1),
        sortOrder: sortedInfo.columnKey === 'superRole' && sortedInfo.order
      },
      { title: 'Registration Enabled', width: 150, dataIndex: 'registrationEnabled',
        render: d => (d ? 'Yes' : 'No'), key: 'registrationEnabled',
        sorter: (a, b) => (a == b ? 0 : a ? -1 : 1),
        sortOrder: sortedInfo.columnKey === 'registrationEnabled' && sortedInfo.order
      },
      { title: 'Updated On', width: 150, dataIndex: 'updatedOn', key: 'updatedOn',
        sorter: (a, b) => a - b,
        sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
        render: props => Utils.getDateAndTimeOnUIFormatByTimestamp(props)
      },
      { title: 'Actions', width: 80, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Delete Role'>
                      <Popconfirm title={`Do you really want to delete role '${props.name}'?`} placement="bottomRight" onConfirm={this.props.handleDelete.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>{' '}
                    <Tooltip placement="bottomLeft" title='Edit Role'>
                      <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.props.handleEdit.bind(this, props)}/>
                    </Tooltip>
                  </span>);
        }
      }
    ];
    return (
      <>
        <Divider orientation="left">Roles</Divider>
        <Row type='flex' justify='end' style={{marginBottom: 20}}>
          <Col offset={1} span={2}>
            <Button key="1" onClick={this.props.handleCreate} type='primary'>Create role</Button>
          </Col>
        </Row>
        <Row type='flex'>
          <Col offset={1} span={22}>
            <Table dataSource={this.props.data} columns={columns} {...props} />
          </Col>
        </Row>
      </>
   );
  }
}
