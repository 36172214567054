import React from "react";
import autoBind from 'react-autobind';
import { Drawer, Row, Col, Button, message } from 'antd';
//
import Utils from '@/components/helpers/Utils';
//
import CommonRegistrationFieldForm from '../Forms/CommonRegistrationFieldForm';

//props are: app, onUpdate, onCreate
export default class CommonRegistrationFieldDrawer extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, field: null };
  }
  //Public
  show(field) { this.setState({ isVisible: true, field }); }
  //Private actions
  handleClose() {
    if (this.form) this.form?.form?.resetFields();
    this.setState({ isVisible: false, field: null });
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (!formData) return;
    if (this.state.field) this.props.onUpdate(formData)
    else this.props.onCreate(formData);
    this.handleClose();
  }

  //UI
  render() {
    return (
      <Drawer open={this.state.isVisible} title={`${this.state.field ? 'Edit' : 'Create'} Field`}
              placement="right" onClose={this.handleClose} width={800}>
        <Row type='flex' justify='end'>
          <Col> <Button type="primary" onClick={this.handleSubmit}>{this.state.field ? 'Save' : 'Create'}</Button> </Col>
        </Row>
        <CommonRegistrationFieldForm {...Utils.propagateRef(this, 'form')} field={this.state.field} isNew={!this.state.field}/>
      </Drawer>
    );
  }
}
