import React from "react";
import autoBind from 'react-autobind';
import { Layout, Button, PageHeader, message } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
//
import CommonVaultForm from "../commonComponents/Forms/CommonVaultForm";
import CommonVaultPermissionsTable from "../commonComponents/CommonVaultPermissionsTable";
//
export default class AdminVaultView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: {}, isLoading: false };
    this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) == Globals.URL_NewIndentifier);
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Vault`;
    //Check for id
    if (!this.isNew) this._fetchData();
  }

  //Actions handler
  async handleSubmit() {
    //validate and retrieve form data
    const formData = await this.form.validateFields();
    if (!formData) return;
    //make operation
    if (this.isNew) this._addVault(formData);
    else this._saveVault(formData);
  }
  async handleEditVaultRole(roleID, accessLevel) {
    this._editVaultRole(roleID, accessLevel);
  }
  async handleAddVaultRole(roleID, accessLevel) {
    this._addVaultRole(roleID, accessLevel);
  }
  async handleDeleteVaultRole(roleID) {
    this._deleteVaultRole(roleID);
  }

  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <PageHeader className='pageHeader' onBack={() => window.history.back()} title={this.isNew ? 'New Vault' : `Vault - ${(this.state.data?.name || 'Loading...')}`}
          extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create Vault' : 'Save Vault'}</Button>]} />
        <Layout.Content>
          <CommonVaultForm isEdit={!this.isNew} app={this.props.app} data={this.state.data} {...Utils.propagateRef(this, 'form')} />
          {!this.isNew &&
            <CommonVaultPermissionsTable app={this.props.app} isLoading={isLoading} data={this.state.data.vaultRoles} handleUpdatePermission={this.handleEditVaultRole} handleAddPermission={this.handleAddVaultRole} handleDelete={this.handleDeleteVaultRole} />}
        </Layout.Content>
      </Layout.Content>
    );
  }

  /*Private methods*/
  async _addVault(data) {
    this.startLoading();
    try {
      const addResp = await this.props.app.ikFileService.vault.createVault("new", data);
      message.success(`Vault added successfully!`);
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.config_vaults);
    } catch (error) {
      console.error("Error occurred while adding:", error);
      message.error(`Error occurred while adding: ${error.response.data.err}`);
    } finally {
      this.stopLoading();
    }
  }
  async _saveVault(data) {
    this.startLoading();
    const tID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    if (!tID) return;
    if (!this._isMounted) return;
    try {
      const updateResp = await this.props.app.ikFileService.vault.updateVault(tID, data);
      message.success(`Vault updated successfully!`);
    } catch (error) {
      console.error("Error occurred while saving:", error);
      message.error(`Error occurred while saving: ${error.response.data.err}`);
    } finally {
      this.stopLoading();
    }
  }
  async _fetchData() {
    this.startLoading();
    const tID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    if (!tID) return;
    if (!this._isMounted) return;

    try {
      const resp = await this.props.app.ikFileService.vault.getVault({ vaultId: tID, expanded: true });
      this.setState({ data: resp });
    } catch (error) {
      console.error("Error occurred while loading data:", error);
      message.error(`Error occurred while loading data: ${error.response.data.err}`);
      this.setState({ data: {} });
    } finally {
      this.stopLoading();
    }
  }
  async _editVaultRole(roleID, accessLevel) {
    this.startLoading();
    const tID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    if (!tID) return;
    const payload = {
      accessLevel: accessLevel
    };

    if (!this._isMounted) return;
    try {
      const updateResp = await this.props.app.ikFileService.vault.updateVaultRole(tID, roleID, payload);
      message.success(`Role updated successfully!`);
      this._fetchData();
    } catch (error) {
      console.error("Error occurred while updating:", error);
      message.error(`Error occurred while updating: ${error.response.data.err}`);
    } finally {
      this.stopLoading();
    }
  }
  async _addVaultRole(roleID, accessLevel) {
    this.startLoading();
    const payload = { accessLevel: accessLevel };
    const tID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    if (!tID) return;
    if (!this._isMounted) return;
    try {
      const addResp = await this.props.app.ikFileService.vault.createVaultRole(tID, roleID, payload);
      message.success(`Permission added successfully!`);
    } catch (error) {
      console.error("Error occurred while adding:", error);
      message.error(`Error occurred while adding: ${error.response.data.err}`);
    } finally {
      this.stopLoading();
    }
  }

  async _deleteVaultRole(roleID) {
    this.startLoading();
    const tID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    if (!tID) return;
    if (!this._isMounted) return;
    try {
      const deleteResp = await this.props.app.ikFileService.vault.deleteVaultRole(tID, roleID.vaultRoleID);
      message.success(`Permission deleted successfully!`);
      this._fetchData();
    } catch (error) {
      console.error("Error occurred while deleting:", error);
      message.error(`Error occurred while deleting: ${error.response.data.err}`);
      this.setState({ data: {} });
    } finally {
      this.stopLoading();
    }
  }
}
