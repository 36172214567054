import React from 'react';
import autoBind from 'react-autobind';
import { Table, Divider, Tooltip, Col, Row } from 'antd';
//
import Globals from '@/config/Globals'
import Utils from '@/components/helpers/Utils'
//props are: app, isLoading, memberships, tenants
export default class CommonEmployeeProgramsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: {} };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      { title: 'Program', key: 'program', sorter: false, render: props => this._getTenantNameByID(props.tenantID) },
      { title: 'Company', key: 'name', sorter: false, render: props => props.name },
      { title: 'Account', key: 'worksafeID', sorter: false, render: props => props.worksafeID },
      { title: 'Role', key: 'role', sorter: false, render: props => props.type },
      { title: 'Confirmed', key: 'confirmed', sorter: false,
        render: props => {
          if (!props.confirmedOn) {
            if (props.type == Globals.OrganizationApp_ProgramsMembersTypes.MANAGER) return 'Yes'; //managers dont have confirmed date, we assume already confirmed
            return 'No';
          } else return (<Tooltip placement="bottomLeft" title={`Confirmed on ${Utils.getDateAndTimeOnUIFormatByTimestamp(props.confirmedOn)}`}>Yes</Tooltip>)
        }
      }
    ];
    const props = { rowKey: (r) => `${r.tenantID}:${r.type}`, loading: this.props.isLoading, onChange: this.handleFilterChange, size: 'small',
                    locale: {emptyText: 'User not found into any program.'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (
      <>
        <Divider orientation="left">Programs</Divider>
        <Row type='flex'>
          <Col offset={1} span={22}>
            <Table className="userMembershipsTable" columns={columns} dataSource={this.props.memberships} {...props}/>
          </Col>
        </Row>
      </>
    );
  }
  _getTenantNameByID(tenantID) { return (this.props.tenants || []).find((t) => t.id == tenantID)?.name || tenantID; }
}