import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button } from 'antd';
import scrollIntoView from 'scroll-into-view';
import { DownloadOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import PersistentSearch from '@/components/helpers/PersistentSearch';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonStudentsSearchBar from '../commonComponents/CommonStudentsSearchBar';
import CommonStudentsTable from '../commonComponents/CommonStudentsTable';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminStudentsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.persistentSearch = new PersistentSearch();
    this.state = {
      isLoading: false, companies: null,
      employees: [], employeesTotal: 0,
      ...this._getInitialState(),
    };
  }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  //Actions
  handleSearch(term) { this.setState({ searchTerm: term, currentPage: 1, sortedInfo: null }, this.fetchData); }
  handleSearchChange(value) {
    if (!value) this.setState({ searchTerm: null, currentPage: 1, sortedInfo: null }, this.fetchData);
  }
    //Employee actions
  handleEmployeeEdit(employeeObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.user, null, employeeObj.id); }
  handleStudentEmployerSet(updateObj) { this._setEmployer(updateObj); }
  handleExportXLSX() { this._exportStudents(); }
    //Table internal actions
  handleTablePageChange(currentPage) { this.setState({ currentPage }, this.fetchData); }
  handleTableChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }, this.fetchData); }

  //UI
  render() {
    const { isInstructorView } = this.props;

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className='pageHeader' title="Students" extra={[
            isInstructorView ? null : (
              <Button key="1" type="primary" icon={<DownloadOutlined />} onClick={this.handleExportXLSX}
              disabled={this.state.employees?.length < 1} style={{ marginLeft: 20, marginRight: -4 }}> Export to XLSX </Button>
            )
          ]}/>
          <Layout.Content>
            <CommonStudentsSearchBar persistentSearch={this.persistentSearch} isLoading={this.state.isLoading}
                onSearch={this.handleSearch} {...Utils.propagateRef(this, 'searchBar')} app={this.props.app}/>
            <CommonStudentsTable app={this.props.app} isLoading={this.state.isLoading} total={this.state.employeesTotal}
                currentPage={this.state.currentPage} employees={this.state.employees} sortedInfo={this.state.sortedInfo}
                onPageChange={this.handleTablePageChange} onFilterChange={this.handleTableChange} companies={this.state.companies}
                onEmployeeEdit={this.handleEmployeeEdit} onEmployerSet={this.handleStudentEmployerSet} hideActions={isInstructorView}/>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* private */
  _getInitialState() {
    const currentPage = parseInt(this.persistentSearch.getParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.persistentSearch.getParam(Globals.URLQueryParam_SortField) || 'name.keyword';
    const order = this.persistentSearch.getParam(Globals.URLQueryParam_SortOrder) || 'ascend';
    return { currentPage, sortedInfo: { columnKey, order } };
  }
  _reloadURLParams() {
    this.persistentSearch.setParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.persistentSearch.setParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : null));
    this.persistentSearch.setParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }
  _getSearchFilter() {
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    const searchBarObjs = this.searchBar ? this.searchBar.getSearchFilters() : {};
    return { from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'), ...searchBarObjs };
  }
  
  /* remote data */
  async fetchData() {
    if (this.state.isLoading) return;
    this.startLoading();
    //request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const resp = await this.props.app.organization.employee.searchOrganizationsEmployeesByTerm(searchTerm, this._getSearchFilter());
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.employees) {
      const employees = resp.body.employees;
      const orgIDs = employees.map(o => o.orgID).filter((o) => !!o);
      this.setState({ employees, employeesTotal: resp.body.total, isLoading: false, companies: (orgIDs?.length > 0 ? null : []) }, () => {
        if (orgIDs?.length > 0) this._loadCompanies(orgIDs);
      });
      scrollIntoView(document.getElementById('root'), { time: 500, align: { top: 0 } });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
    this._reloadURLParams();
  }
  async _setEmployer(updateObj) {
    this.startLoading();
    const resp = await this.props.app.organization.employee.upsertEmployee(updateObj.org.id, updateObj.user.id, 
      { hashedWorksafeID: updateObj.org.hashedWorksafeID, email: updateObj.user.email, firstName: updateObj.user.firstName, lastName: updateObj.user.lastName });
    if (resp.statusCode == 200 || resp.statusCode == 204) this.props.app.alertController.showSuccessAlert("", "Employer updated with success!");
    else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.stopLoading();
  }
  async _loadCompanies(orgIDs) {
    let orgsResp = await this.props.app.organization.organization.searchOrganizationsByIDs({ orgIDs });
    if (orgsResp.statusCode == 200 && orgsResp.body?.orgs) {
      this.setState({ companies: orgsResp.body.orgs });
    } else this.props.app.alertController.showAPIErrorAlert(null, orgsResp);
  }
  async _exportStudents() {
    this.startLoading();
    //request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const resp = await this.props.app.organization.employee.exportEmployeesSearch(searchTerm, this._getSearchFilter());
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body) Utils.downloadBlob(resp.body, 'students', 'xlsx');
    else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.stopLoading();
  }
}
