import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/helpers/Utils';
//
import { Layout, Row, Col, Form, Input, Checkbox } from 'antd';
//
export default class RootAppForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    const data = this.props.data;
    if (data && data !== prevProps.data) {
      this.form.setFieldsValue(data);
    }
  }
  validateFields() {
    return this.form.validateFields();
  }

  render() {
    const editMode = this.props.editMode;

    return (
      <Layout.Content justify="right-align">
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row gutter={12}>
            <Col span={6} offset={2}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: 'Please, type acces token description!' },
                ]}
              >
                <Input.TextArea disabled={!editMode} onChange={this.props.onChange} />
              </Form.Item>
            </Col>
            <Col span={6} offset={2}>
              <Form.Item name="enabled" label="Enabled" valuePropName="checked">
                <Checkbox onChange={this.props.onChange} />
              </Form.Item>
            </Col>
          </Row><Row gutter={12}>
            <Col span={6} offset={2}>
              <Form.Item name="tokenKey" label="Token key">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6} offset={2}>
              <Form.Item name="tokenSecret" label="Token secret">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row><Row gutter={12}>
            <Col span={6} offset={2}>
              <Form.Item name="createdOn" label="Created On">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6} offset={2}>
              <Form.Item name="updatedOn" label="Updated On">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row><Row gutter={12}>
            <Col span={6} offset={2}>
              <Form.Item name="updatedBy" label="Updated By">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    );
  }


  //Form actions
  handleValidation(e) { return this._validateForm(e); }

  //Validate
  _validateForm(event) {
    if (!this.form) return false;
    const formLength = this.form.length;
    for (let i = 0; i < formLength; i++) {
      const elem = this.form[i];
      if ((elem.type == "checkbox" && elem.required && elem.checked == undefined) ||
        (elem.type != "checkbox" && elem.required && !elem.value) ||
        !elem.checkValidity()) { return false; }
    } return true;
  }
}
