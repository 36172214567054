export default {
  applicationName: "IHSA",
  privacyURL: "https://www.ihsa.ca/privacypolicy.aspx", //If specified it will appear on registration and any other pre- authorization box for the user. -- Privacy and Help URLs are required in order to make this visible.
  helpURL: null, //If specified it will appear on registration and any other pre - authorization box for the user. -- Privacy and Help URLs are required in order to make this visible.
  termsURL: "https://www.ihsa.ca/privacypolicy.aspx", //If specified it will appear on registration and any other pre - registration box for the user. -- Privacy and Terms URLs are required in order to make this visible.
  hidePhoneNumberField: true,
  signupEnabled: true,
  //alert
  // alert: {
  //   type: 'warning',
  //   content: 'Our systems will be undergoing maintenance on Oct 29 from 3:00 PM to 07:00 PM PST. During this time, all applications will be out of service.'
  // }
};
