import React from "react";
import autoBind from 'react-autobind';
import { Button, Row, Col, Divider, Popover, Typography, Form, Input } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ReactPasswordStrength from "react-password-strength";
//
import Utils from '@/components/helpers/Utils';
//
import CommonDynamicForm from "@/views/commonComponents/CommonDynamicForm";
//
const VALIDATION_RULES = {
  LOWER: 0,
  UPPER: 1,
  NUMBER: 2,
  MATCH_EMAIL: 3,
  MIN_CHARS: 4,
  PASS_MATCH: 5
};
//props are: onSignup, onLogin, app
export default class CommonRegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isDesktop: window.innerWidth > 890, selectedOrg: null };
  }

  componentDidMount() {
    const formDefaults = {}; let numDefaults = 0;
    for (const field in this.props.additionalInputs.fields) {
      if (Object.prototype.hasOwnProperty.call(this.props.additionalInputs.fields, field)) {
        const element = this.props.additionalInputs.fields[field];
        if (element.defaultValue) {
          if (element.type === 'boolean') {
            formDefaults[field] = !!element.defaultValue;
          } else {
            formDefaults[field] = element.defaultValue;
          }
          numDefaults++;
        }
      }
    }
    if (numDefaults > 0) this.form.setFieldsValue(formDefaults);
  }

  //Actions
  async handleSignup() {
    const formData = await this.form.validateFields();
    if (formData) this.props.onSignup({ ...this.state, ...formData });
  }
  handlePasswordInputChange(fieldName) { return (event) => { this.setState({ [fieldName]: event.password }); }; }
  handleEmailChange(field, event) { this.setState({ [field]: event.target.value }); }
  handleOrgChange(orgObj) { this.setState({ selectedOrg: orgObj }); }

  //UI
  render() {
    const faillingRules = this._calculateFaillingRules();

    const isFormInvalid = (
      (faillingRules && faillingRules.length > 0)
      || (!this.state.password || !this.state.password_verification)
      || (this.props.enableEmailConfirmation && this.state.email !== this.state.emailConfirmation)
    );

    return (
      <Form layout="vertical" className='registerForm' {...Utils.propagateRef(this, 'form')}>
        {this._renderBasicInformation(faillingRules)}
        <CommonDynamicForm app={this.props.app} selectedOrg={this.state.selectedOrg} isDesktop={this.state.isDesktop}
          additionalInputs={this.props.additionalInputs} onOrgChange={this.handleOrgChange} />
        {this.props.app.idm.impersonate.isImpersonatingRole() &&
          <a className={"alreadyHaveAccountLink " + (this.props.app.themeManager.theme.branding.privacyURL && this.props.app.themeManager.theme.branding.privacyURL ? "withTerms" : "")}
            onClick={this.props.onLogin}>Already have a {this.props.app.themeManager.theme.applicationName} account?</a>
        }
        <Button
          block disabled={isFormInvalid}
          className='registerButton' type="primary" htmlType="submit" onClick={this.handleSignup}> Sign Up </Button>
        {this._renderTermsInfo()}
      </Form>
    );
  }

  /* private renders */
  _renderBasicInformation(faillingRules) {
    const editMode = true;
    const { isDesktop } = this.state;
    const colSpan = isDesktop ? 10 : 24;
    const passwordRulesPassing = !!(faillingRules && faillingRules.length == 0);
    const PasswordRulesIconComponent = passwordRulesPassing ? CheckCircleOutlined : CloseCircleOutlined;
    return (
      <>
        <Row type="flex" justify="center" align="middle">
          <Col span={colSpan}>
            <Form.Item name="firstName" label="First Name" rules={[
              { required: true, message: 'Please, type your first name!' },
              { min: 2, max: 255, message: 'First name must be between 2 and 255 characters' },
            ]}>
              <Input disabled={!editMode} />
            </Form.Item>
          </Col>

          <Col span={colSpan} offset={isDesktop ? 2 : 0}>
            <Form.Item name="lastName" label="Last Name" rules={[
              { required: true, message: 'Please, type your last name!' },
              { min: 2, max: 255, message: 'Last name must be between 2 and 255 characters' },
            ]}>
              <Input disabled={!editMode} />
            </Form.Item>
          </Col>
        </Row>

        <Row
          type="flex"
          align="middle"
          justify={
            this.props.enableEmailConfirmation
              ? (isDesktop ? 'center' : 'start')
              : 'start'
          }
        >
          <Col
            {...(
              this.props.enableEmailConfirmation
                ? { span: isDesktop ? 10 : 23 }
                : { span: colSpan, offset: isDesktop ? 1 : 0 }
            )}
          >
            <Form.Item name="email" label="Email" rules={[
              { required: true, message: 'Please, type your email!' },
              { min: 2, max: 255, message: 'Email name must be between 2 and 255 characters' },
              { type: 'email', message: 'Invalid email format' }
            ]}>
              <Input disabled={!editMode} onChange={this.handleEmailChange.bind(this, 'email')} />
            </Form.Item>
          </Col>

          {this.props.enableEmailConfirmation && (
            <Col span={colSpan} offset={isDesktop ? 2 : 0}>
              <Form.Item
                name="emailConfirmation"
                label="Email confirmation"
                rules={[
                  { required: true, message: 'Please, type your email!' },
                  { min: 2, max: 255, message: 'Email name must be between 2 and 255 characters' },
                  { type: 'email', message: 'Invalid email format' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('email') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two emails that you entered do not match!'));
                    },
                  }),
                ]}>
                <Input
                  disabled={!editMode}
                  onChange={this.handleEmailChange.bind(this, 'emailConfirmation')}
                  onPaste={e => { e.preventDefault(); }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row type="flex" justify={isDesktop ? 'center' : 'start'} align="middle">
          <Col span={isDesktop ? 10 : 23}>
            <Form.Item label="Password">
              <ReactPasswordStrength enabledVisibityToogle minLength={5} minScore={2}
                scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                changeCallback={this.handlePasswordInputChange('password')}
                inputProps={{
                  name: 'password', autoComplete: "off", className: "form-control",
                  required: true, disabled: !editMode, 'data-dd-privacy': 'mask',
                }} />
              <Popover className='passwordTooltip' getPopupContainer={triggerNode => triggerNode.parentNode}
                open={faillingRules && faillingRules.length > 0} content={
                  <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '300px' }}>
                    <Typography.Title level={4}>Password rules:</Typography.Title>
                    <ul>
                      <li>Have at least one lower case character {this._renderRuleStatus(faillingRules, VALIDATION_RULES.LOWER)}</li>
                      <li>Have at least one capital letter {this._renderRuleStatus(faillingRules, VALIDATION_RULES.UPPER)}</li>
                      <li>Have at least one number {this._renderRuleStatus(faillingRules, VALIDATION_RULES.NUMBER)}</li>
                      <li>Not be the same as the account email {this._renderRuleStatus(faillingRules, VALIDATION_RULES.MATCH_EMAIL)}</li>
                      <li>Be at least 8 characters long {this._renderRuleStatus(faillingRules, VALIDATION_RULES.MIN_CHARS)}</li>
                      <li>Passwords must match {this._renderRuleStatus(faillingRules, VALIDATION_RULES.PASS_MATCH)}</li>
                    </ul>
                  </div>
                }>
                {faillingRules && <PasswordRulesIconComponent className={'passwordTooltipIcon ' + (passwordRulesPassing ? 'rulePass' : 'ruleFail')} />}
              </Popover>
            </Form.Item>

          </Col>
          <Col span={colSpan} offset={isDesktop ? 2 : 0}>
            <Form.Item label="Password verification">
              <ReactPasswordStrength enabledVisibityToogle minLength={5} minScore={2}
                scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                changeCallback={this.handlePasswordInputChange('password_verification')}
                inputProps={{
                  name: 'password_verification', autoComplete: "off", className: "form-control",
                  required: true, disabled: !editMode, 'data-dd-privacy': 'mask',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderRuleStatus(faillingRules, ruleType) {
    if (faillingRules && faillingRules.indexOf(ruleType) != -1) return (<CloseCircleOutlined className="ruleFail" />);
    else if (faillingRules) return (<CheckCircleOutlined className="rulePass" />);
    else return (<></>);
  }
  _renderTermsInfo() {
    if (this.props.app.themeManager.theme.branding.privacyURL && this.props.app.themeManager.theme.branding.termsURL) {
      return (
        <>
          <Divider></Divider>
          <Row type="flex" align="center" className="termsRow">
            <Col><h6 className="termsRowLabel">
              I agree that I have read and understand the <a href={this.props.app.themeManager.theme.branding.termsURL} target="_blank">{this.props.app.themeManager.theme.applicationName} Website Terms of use</a> and <a target="_blank" href={this.props.app.themeManager.theme.branding.privacyURL}>Privacy Policy</a> and agree to be bound by both</h6>
            </Col>
          </Row>
        </>
      )
    } else return (<></>);
  }

  /* private */
  _calculateFaillingRules() {
    if (!this.state.password && !this.state.password_verification) return null;
    const faillingRules = [];
    const pass = this.state.password;
    const passC = this.state.password_verification;
    const email = this.state.email;

    //Check lower
    if (!(/[a-z]/.test(pass))) faillingRules.push(VALIDATION_RULES.LOWER);
    //Check upper
    if (!(/[A-Z]/.test(pass))) faillingRules.push(VALIDATION_RULES.UPPER);
    //Check number
    if (!(/[0-9]/.test(pass))) faillingRules.push(VALIDATION_RULES.NUMBER);
    //Check matching email
    if (pass && email && pass.indexOf(email) != -1) faillingRules.push(VALIDATION_RULES.MATCH_EMAIL);
    //Check min chars
    if (pass && pass.length < 8) faillingRules.push(VALIDATION_RULES.MIN_CHARS);
    //Check confirmation
    if (pass && passC && pass != passC) faillingRules.push(VALIDATION_RULES.PASS_MATCH);
    return faillingRules;
  }
}
