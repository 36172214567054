import React from "react";
import autoBind from 'react-autobind';
import { Select, Divider, Button, Row, Col, Table, Tooltip, Form} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";

//props are: editMode, roles, isLoading, app, onRoleAdded, onRoleDeleted
export default class CommonUserRolesTable extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
      this.state = { selectedRole: null, sortedInfo: null};
    }
    //Actions
    handleRoleAdd(e) {
      e.preventDefault();
      if (!this.state.selectedRole) return;
      //get selected object
      const role = this.props.app.sharedCache().findRoleByID(this.state.selectedRole);
      for (let roleID of this.props.roles) {
        if (roleID == role.cognitoName) {
          this.props.app.alertController.showErrorAlert("Error!", 'Role already associated with user');
          return;
        }
      }
      //Add into
      this.props.onRoleAdded(role.cognitoName);
    }
    handleRoleDelete(_roleID) {
      let idx = -1;
      for (let roleID of this.props.roles) if (roleID == _roleID) idx = this.props.roles.indexOf(roleID);
      if (idx != -1) this.props.onRoleDeleted(idx);
    }
    handleRoleChanged(role) {  this.setState({ selectedRole: role }); }
      //Filter support
    handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
    //UI
    render() {
      const editMode = this.props.editMode;
      const roles = this.props.app.sharedCache().getRoles();
      const data = this.props.roles?.map(item => { return {item} }) || [];
      //Table info
      let { sortedInfo } = this.state;
      sortedInfo = sortedInfo || {};
      //
      const props = { 
        size: 'middle', onChange: this.handleFilterChange, rowKey: 'item', loading: this.props.isLoading,
        locale: {emptyText: 'User does not have any roles.'},
        pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }
      };
      const columns = [
        { title: 'Role Name', key: 'roleName', width: 150, dataIndex: 'item',
          sorter: (a, b) => (a.roleName || '').localeCompare(b.roleName || ''),
          sortOrder: sortedInfo.columnKey === 'roleName' && sortedInfo.order,
          render: (d) => {
            let role = this.props.app.sharedCache().findRoleByCognitoName(d);
            return (role ? role.name : 'UNKNOWN');
          }
        },
        { title: 'Role App', key: 'appName', width: 150, dataIndex: 'item',
          sorter: (a, b) => (a.appName || '').localeCompare(b.appName || ''),
          sortOrder: sortedInfo.columnKey === 'appName' && sortedInfo.order,
          render: (d) => {
            let role = this.props.app.sharedCache().findRoleByCognitoName(d);
            let app = this.props.app.sharedCache().findAppByID((role ? role.appID : ''));
            return (app ? app.name : 'UNKNOWN');
          }
        },
        { title: 'Actions', width: 180, key: 'Actions', dataIndex: 'item',
          render: props => {
            return (<span className='tableButtonContainer'>
                      <Tooltip placement="bottomLeft" title='Remove role'>
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle" onClick={this.handleRoleDelete.bind(this, props)}/>
                      </Tooltip>
                    </span>);
          }
        }
      ];
      //render
      return (
        <>
          <Divider orientation="left">Roles</Divider>
          <Form {...Utils.propagateRef(this, 'form')}>
            <Row type='flex' justify='end'>
              <Col span={6}>
                <Form.Item name="selectedRole" label="Role" style={{marginLeft: 10, width: 500}}>
                  <Select showSearch value={this.state.selectedRole} onChange={this.handleRoleChanged.bind(this)} disabled={!editMode}>
                    {roles.map((opt) => (<Select.Option key={opt.id} value={opt.id}>{`${opt.name} - ${this.props.app.sharedCache().findAppByID(opt.appID).name}`}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2} offset={1}>
                <Button key="1" disabled={!this.state.selectedRole} onClick={this.handleRoleAdd} type='primary'>Add role</Button>
              </Col>
            </Row>
          </Form>
          <Row type='flex'>
            <Col offset={1} span={22}>
              <Table dataSource={data} columns={columns} {...props} />
            </Col>
          </Row>
        </>
     );
    }


    //Form actions
    handleValidation(e) { return this._validateForm(e); }

    //Validate
    _validateForm(event) {
      if (!this.form) return false;
      const formLength = this.form.length;
      for (let i = 0; i < formLength; i++) {
        const elem = this.form[i];
        if ((elem.type == "checkbox" && elem.required && elem.checked == undefined) ||
            (elem.type != "checkbox" && elem.required && !elem.value) ||
            !elem.checkValidity()) { return false; }
      } return true;
    }
}
