import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import RootAccessTokenForm from "./RootAccessTokenForm";
//resources
import "@/stylesheets/CommonRegistration.less";
//
import { Layout, Button, PageHeader } from 'antd';
//
export default class RootAccessTokenView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: [], firstLoad: true, isLoading: false };
    this.isNew = false;
  }
  loadResponse(tokenResp) {
    console.log(tokenResp)
    if (!this._isMounted) return;
    if (tokenResp.statusCode == 200) { //valid data state
      this.setState({ data: tokenResp.body });

      //set navigation items details
      this.props.app.appsManager?.navigation?.appendValue('accessTokenID', this.state.data.id);
      this.props.app.appsManager?.navigation?.appendValue('accessTokenName', this.state.data.name);
    } else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, tokenResp);
      this.setState({ data: [] });
    }
    this.setState({ firstLoad: false });
    this.stopLoading(false);
  }
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Access Token details`;
    if (this.state.firstLoad) {
      //Check for id
      this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this) == Globals.URL_NewIndentifier);
      if (!this.isNew) this.fetchData();
      else this.setState({ firstLoad: false });
    }
  }
  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const tokenID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);
    this.loadResponse((await this.props.app.idm.api.accessToken.read(tokenID, appID)));
    this.stopLoading();
  }
  //Actions handler
  handleChange(event) { Utils.defaultFormChangeHandler(event, this); }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      if (this.isNew) this._addAccessToken();
      else this._saveAccessToken();
    }
  }
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <PageHeader className='pageHeader' onBack={() => window.history.back()} title={this.isNew ? 'New Access Token' : `Access Token - ${(this.state.data.id ? this.state.data.id : 'Loading...')}`}
          extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create Access Token' : 'Save Access Token'}</Button>]} />
        <Layout.Content>
          <RootAccessTokenForm onChange={this.handleChange} editMode={true} isNew={this.isNew} data={this.state.data} {...Utils.propagateRef(this, 'form')} />
        </Layout.Content>
      </Layout.Content>
    );
  }



  /*Private methods*/
  async _addAccessToken() {
    this.startLoading();
    let obj = Object.assign({}, this.state.data);
    obj.enabled = !!obj.enabled;
    const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    //
    const addResp = await this.props.app.idm.api.accessToken.create(obj, appID);
    if (addResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Access token created with success!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.application, null, appID);
      this.props.app.appsManager?.navigation?.appendValue('applicationID', appID);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, addResp);
      this.stopLoading();
    }
  }
  async _saveAccessToken() {
    this.startLoading();
    let obj = Object.assign({}, this.state.data);
    const updateResp = await this.props.app.idm.api.accessToken.update(obj);
    if (updateResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Access token updated with succeed!");
      const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.application, null, appID);
      this.props.app.appsManager?.navigation?.appendValue('applicationID', appID);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
}
