import React from 'react';
import autoBind from 'react-autobind';
import { Button, Table, Tooltip } from 'antd';
import moment from "moment";
//icons
import { EyeOutlined } from '@ant-design/icons';
//
import Globals from '@/config/Globals'

export default class CommonEmailLogTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }

  handleViewDetails(row) {
    this.props.onViewDetails(row);
  }
  
  //Table support
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      { title: 'Date', dataIndex: 'timestamp', key: 'timestamp',
        sortOrder: sortedInfo.columnKey === 'timestamp' && sortedInfo.order, 
        sorter: (a, b) => a.timestamp - b.timestamp,
        render: timestamp => moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
      },
      { title: 'Event Type', dataIndex: 'eventType', key: 'eventType',
        sortOrder: sortedInfo.columnKey === 'eventType' && sortedInfo.order,
        sorter: (a, b) => a.eventType.localeCompare(b.eventType)
      },
      { title: 'Recipient', dataIndex: 'email', key: 'email',
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        sorter: (a, b) => a.email.localeCompare(b.email)
      },
      { title: 'Subject', dataIndex: 'subject', key: 'subject',
        sortOrder: sortedInfo.columnKey === 'subject' && sortedInfo.order,
        sorter: (a, b) => a.subject.localeCompare(b.subject)
      },
      { title: 'Application', key: 'applicationID',
        render: props => props?.applicationID ? props?.applicationID : '-',
        sorter: (a, b) => {
          const appA = a.applicationID || '';
          const appB = b.applicationID || '';
          return appA.localeCompare(appB);
        },
        sortOrder: sortedInfo.columnKey === 'applicationID' && sortedInfo.order,
      },
      { title: 'Actions', width: '10%', key: 'Actions', align: 'center',
        render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title="View Details">
              <Button
                variant="none"
                icon={<EyeOutlined />}
                shape="circle"
                onClick={() => this.handleViewDetails(props)}
              />
            </Tooltip>
          </span>
        ),
      }
    ];
    const props = { rowKey: 'messageId', loading: this.props.isLoading, onChange: this.handleFilterChange,
                    locale: {emptyText: 'No email logs found!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, showSizeChanger: false, position: ['bottomCenter'] },};
    return (<Table columns={columns} dataSource={this.props.logs} {...props}/>);
  }
}
