import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/helpers/Utils';
//
import { Layout, Row, Col, Form, Input } from 'antd';
//props are: classificationUnit, isNew
export default class AdminClassificationUnitForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Form methods
  async validateFields() { return await this.form.validateFields(); }
  setFieldsValue(data) { this.form.setFieldsValue(data); }
  //Life cycle
  componentDidUpdate() {
    const data = this.props.classificationUnit;
    if (data) this.setFieldsValue(data);
  }
  //UI
  render() {
    return(
      <Layout.Content justify="right-align">
        <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
          <Row type='flex'>
            <Col span={22} offset={1}>
              <Form.Item name="id" label="ID (Unique Identifier)" rules={[
                  { required: true, message: 'Please, type the classification unit ID!' },
                  { min: 4, message: 'Classification Unit ID must be between 4 and 255 characters' },
                  { max: 255, message: 'Classification Unit ID must be between 4 and 255 characters' },
                ]}> 
                  <Input disabled={!this.props.isNew}/> 
                </Form.Item>
            </Col>
          </Row><Row type='flex'>
            <Col span={22} offset={1}>
              <Form.Item name="description" label="Description" rules={[ { required: true, message: 'Classification unit description is required!' } ]}>
                <Input.TextArea size={5} showCount/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    );
  }
}
