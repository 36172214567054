import React from 'react';
import autoBind from 'react-autobind';
import { Button, Table, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//
import Globals from '@/config/Globals'
import Utils from '@/components/helpers/Utils'
//props are: app, isLoading, onDelete, onEdit, tenants
export default class CommonTenantsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: {} };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      { title: 'ID', key: 'id', dataIndex: 'id', sorter: true,
        sorter: (a, b) => a.id.toLowerCase().localeCompare(b.id.toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order },
      { title: 'Name', key: 'name', sorter: true,
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order, 
        sorter: (a, b) => a.organizationName.toLowerCase().localeCompare(b.organizationName.toLowerCase()),
        render: props => props.organizationName && props.organizationName.length > 0 ? props.organizationName : 'N/A' 
      },
      { title: 'Customer', key: 'customer', sorter: true,
        sortOrder: sortedInfo.columnKey === 'customer' && sortedInfo.order, 
        sorter: (a, b) => a.customerID.toLowerCase().localeCompare(b.customerID.toLowerCase()),
        render: props => props.customerID
      },
      { title: 'Created on', key: 'createdOn', width: '10%', sorter: true,
        sortOrder: sortedInfo.columnKey === 'createdOn' && sortedInfo.order, 
        sorter: (a, b) => a.createdOn - b.createdOn,
        render: props => props.createdOn ? Utils.getDateOnUIFormatByTimestamp(props.createdOn) : 'N/A',
      },
      { title: 'Actions', width: '10%', key: 'Actions', align: 'center',
         render: props => (
          <span className='tableButtonContainer'>
            <Tooltip placement="bottomLeft" title='Edit Tenant'>
              <Button variant="none" icon={<EditOutlined />} shape="circle" onClick={this.props.onEdit.bind(this, props)}/>
            </Tooltip>
            <Tooltip placement="bottomLeft" title='Delete Tenant'>
              <Popconfirm title={`Do you really want to delete tenant unit '${props.id}-${props.organizationName}'?`} placement="bottomRight" onConfirm={this.props.onDelete.bind(this, props)} okText="Yes" cancelText="No">
                <Button variant="none" icon={<DeleteOutlined />} shape="circle" style={{marginLeft: 10}}/>
              </Popconfirm>
            </Tooltip>
          </span>
        ),
      }
    ];
    const props = { rowKey: 'id', loading: this.props.isLoading, onChange: this.handleFilterChange, size: 'small',
                    locale: {emptyText: 'No tenants found on current customer!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (<Table className="tenantsTable" columns={columns} dataSource={this.props.tenants} {...props}/>);
  }
}