import React from 'react';
import autoBind from 'react-autobind';
import * as ExcelJS from 'exceljs';
import { Layout, PageHeader, Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from "moment";
//
import Utils from "@/components/helpers/Utils";
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
import CommonEmailLogTable from '../commonComponents/CommonEmailLogTable';
import CommonEmailLogSearchBar from '../commonComponents/CommonEmailLogSearchBar';
import CommonEmailMessageModal from '../commonComponents/Modals/CommonEmailMessageModal';
//

export default class AdminEmailLogView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      logs: [],
      isLoading: false,
      searchObj: { eventType: ["Delivery"], dateFrom: 0, dateTo: 0, email: "", tz: "" },
      selectedRowDetails: null,
    };
    this._handleSearch = this._handleSearch.bind(this);
    this.handleViewDetails = this.handleViewDetails.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this._fetchCustomerData();
  }

  async _fetchCustomerData() {
    const customerID = this.props.app.sharedCache().auth?.customerID;
    const customer = await this.props.app.config.customer.getCustomer(customerID);
    if (customer.statusCode === 200 && customer.body.timezone) {
      this.setState(prevState => ({
        searchObj: {
          ...prevState.searchObj,
          tz: customer.body.timezone
        }
      }));
    }
  }

  // Event handler to update searchObj
  _updateSearchObj(searchObjUpdates) {
    this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        ...searchObjUpdates
      }
    }));
  }

  // Event handler to close the modal
  handleModalClose() {
    this.setState({ isModalVisible: false });
  }

  // UI
  render() {
    const { isLoading, logs, searchObj, isModalVisible, selectedRowDetails } = this.state;

    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={isLoading} />
        <PageHeader className='pageHeader' title="Email Logs" extra={[
          <>
            <Button key="1" type='primary' onClick={() => this._handleSearch(searchObj)}>Search</Button>
            <Button key="2" type="dashed" icon={<DownloadOutlined />} onClick={this.generateReportXLSX} disabled={this.state.logs < 1}> Export to XLSX</Button>
          </>
        ]} />
        <CommonEmailLogSearchBar
          onSearch={this._search}
          onUpdateSearchObj={this._updateSearchObj}
          searchObj={searchObj}
        />
        <CommonEmailLogTable
          logs={logs}
          isLoading={isLoading}
          onViewDetails={this.handleViewDetails}
        />
        <CommonEmailMessageModal
          isVisible={isModalVisible}
          details={selectedRowDetails}
          onCancel={this.handleModalClose}
        />
      </Layout.Content>
    );
  }

  /* Private methods */
  async _handleSearch(searchObj) {
    this.setState({ isLoading: true });

    let updatedSearchObj = { ...searchObj };

    if (searchObj.dateFrom) {
      updatedSearchObj.dateFrom = moment(searchObj.dateFrom).startOf('day').valueOf();
    }
    if (searchObj.dateTo) {
      updatedSearchObj.dateTo = moment(searchObj.dateTo).endOf('day').valueOf();
    }

    await this._search(updatedSearchObj);

    this.setState({ isLoading: false });
  }

  async _search(searchObj) {
    this.setState({ isLoading: true });
    try {
      const searchResp = await this.props.app.emailLog.messageEvents.createSearch(searchObj);
      this.setState({ logs: searchResp.messageEvents });
    } catch (error) {
      console.error("Error occurred while searching:", error);
      message.error(`Error occurred while searching: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async handleViewDetails(row) {
    this.setState({ isLoading: true });
    try {
      const { id, email, eventType, timestamp } = row;
      const messageDetails = await this.props.app.emailLog.messageEvent.getMessageEvent(id, email, eventType, timestamp);
      this.setState({ isModalVisible: true, selectedRowDetails: messageDetails });
    } catch (error) {
      console.error("Error occurred while fetching message details:", error);
      message.error(`Error occurred while fetching message details: ${error.response.data.err}`);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async generateReportXLSX() {
    if (this.state.logs < 1) {
      return;
    }

    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');

    let columns = [
      'Date',
      'Event type',
      'Recipient',
      'Subject',
      'Application',
    ];

    // Generate XLSX header
    ws.addRow(columns);

    // Generate XLSX rows
    this.state.logs.forEach((log) => {
      let row = [
        moment(log.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        log.eventType,
        log.email,
        log.subject,
        log.applicationID,
      ];

      ws.addRow(row);
    });

    const buffer = await wb.xlsx.writeBuffer();

    Utils.downloadArrayBuffer(buffer, `EmailLog`, 'xlsx');
  }
}

