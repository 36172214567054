import React from "react";
import { Breadcrumb, Menu } from "antd";
import { Link } from "react-router-dom";
import { matchPath } from "react-router";
import autoBind from "react-autobind";
//Icons
import { FaUsersCog } from "react-icons/fa";
import { IoIosApps, IoIosMail } from "react-icons/io";
import { MdSecurity } from "react-icons/md";
//Components
import CustomRoute from "@/ui-components/CustomRoute";
import BaseApp from "@/components/subcomponents/AppsManager/BaseApp";
import Globals from "@/config/Globals";
//App Routes
import AdminEmailLogView from "./../config/adminSubviews/AdminEmailLogView";
import CommonUserProfileView from "@/views/commonSubviews/CommonUserProfileView";
import CommonUsersSearchView from "./commonSubviews/CommonUsersSearchView";
import RootAppsView from "./rootSubviews/RootAppsView";
import RootAppView from "./rootSubviews/RootAppView";
import RootRoleView from "./rootSubviews/RootRoleView";
import RootAccessTokenView from "./rootSubviews/RootAccessTokenView";
import RootACLsView from "./rootSubviews/RootACLsView";
import RootACLView from "./rootSubviews/RootACLView";
//Config
import config from "@/config/config";
import AdminEmailSuppressionView from "../config/adminSubviews/AdminEmailSuppressionView";
//
export default class IDMApp extends BaseApp {
  constructor(app) {
    super(Globals.MiniApps.IDM, app);
    autoBind(this);
  }
  /* Overrides */
  get isEnabled() {
    return this.app.isRoot() || this.app.isAdmin();
  }
  get name() {
    return "Users Management";
  }
  get description() {
    return "Internal user management and related.";
  }
  get routes() {
    //Logged as root
    if (this.app.isRoot()) {
      return [
        <CustomRoute key={config.ApplicationRoutes.applications} path={config.ApplicationRoutes.applications} exact component={RootAppsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.accessToken} path={config.ApplicationRoutes.accessToken} exact component={RootAccessTokenView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.role} path={config.ApplicationRoutes.role} exact component={RootRoleView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.application} path={config.ApplicationRoutes.application} exact component={RootAppView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.user} path={config.ApplicationRoutes.user} exact component={CommonUserProfileView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.users} path={config.ApplicationRoutes.users} exact component={CommonUsersSearchView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.partitionACLs} path={config.ApplicationRoutes.partitionACLs} exact component={RootACLsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.partitionACL} path={config.ApplicationRoutes.partitionACL} exact component={RootACLView} appRef={this.app} />,
      ];
    }
    //Logged as admin
    if (this.app.isAdmin()) {
      return [
        <CustomRoute key={config.ApplicationRoutes.user} path={config.ApplicationRoutes.user} exact component={CommonUserProfileView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.users} path={config.ApplicationRoutes.users} exact component={CommonUsersSearchView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.users} path={config.ApplicationRoutes.users} exact component={CommonUsersSearchView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.config_emails} path={config.ApplicationRoutes.config_emails} exact component={AdminEmailLogView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.config_email_suppression} path={config.ApplicationRoutes.config_email_suppression} exact component={AdminEmailSuppressionView} appRef={this.app} />,
      ];
    }
    return [];
  }
  get dashboardOptions() {
    if (this.app.isRoot() || this.app.isAdmin()) {
      return [
        {
          key: 1,
          name: "Applications",
          Icon: IoIosApps,
          description: "Manage IDM Applications",
          route: config.ApplicationRoutes.applications,
          hasPermission: this.app.isRoot(),
        },
        {
          key: 2,
          name: "Partitions ACLs",
          Icon: MdSecurity,
          description: "Manage IDM Data layer ACLs",
          route: config.ApplicationRoutes.partitionACLs,
          hasPermission: this.app.isRoot(),
        },
        {
          key: 3,
          name: "Users",
          Icon: FaUsersCog,
          description: "Manage Users",
          route: config.ApplicationRoutes.users,
          hasPermission: true,
        },
        {
          key: 4,
          name: "Email log",
          Icon: IoIosMail,
          description: "Search emails sent from the platform",
          route: config.ApplicationRoutes.config_emails,
          hasPermission: true,
        },
        {
          key: 5,
          name: "Email Suppression",
          Icon: IoIosMail,
          description: "Manages the list of email addresses with delivery supressed",
          route: config.ApplicationRoutes.config_email_suppression,
          hasPermission: true /* TBD */,
        },
      ];
    }
    return null;
  }
  //navigation items
  getNavigationOption(path, values) {
    //reusabe crumbs
    const AppBC = (
      <Breadcrumb.Item key="appBC" onClick={this._openLink.bind(this, config.ApplicationRoutes.application, null, values.applicationID)}>
        <Link to={this._parseUrl(config.ApplicationRoutes.application, null, values.applicationID)}> {values.applicationName || "Application"} </Link>
      </Breadcrumb.Item>
    );
    //
    if (matchPath(path, config.ApplicationRoutes.accessToken)) {
      return [
        this._renderNavigationMainLevel(0),
        AppBC,
        <Breadcrumb.Item key="acBC" onClick={this._openLink.bind(this, config.ApplicationRoutes.accessToken, null, values.applicationID, values.accessTokenID)}>
          <Link to={this._parseUrl(config.ApplicationRoutes.accessToken, null, values.applicationID, values.accessTokenID)}> {values.accessTokenName || "Access Token"} </Link>
        </Breadcrumb.Item>,
      ];
    } else if (matchPath(path, config.ApplicationRoutes.role)) {
      return [
        this._renderNavigationMainLevel(0),
        AppBC,
        <Breadcrumb.Item key="roleBC" onClick={this._openLink.bind(this, config.ApplicationRoutes.role, null, values.applicationID, values.roleID)}>
          <Link to={this._parseUrl(config.ApplicationRoutes.role, null, values.applicationID, values.roleID)}> {values.roleName || "Role"} </Link>
        </Breadcrumb.Item>,
      ];
    } else if (matchPath(path, config.ApplicationRoutes.user)) {
      return [
        this._renderNavigationMainLevel(1),
        <Breadcrumb.Item key="userBC" onClick={this._openLink.bind(this, config.ApplicationRoutes.user, null, values.userID)}>
          <Link to={this._parseUrl(config.ApplicationRoutes.user, null, values.userID)}> {values.userName || "User"} </Link>
        </Breadcrumb.Item>,
      ];
    } else if (matchPath(path, config.ApplicationRoutes.applications)) return [this._renderNavigationMainLevel(0)];
    else if (matchPath(path, config.ApplicationRoutes.application)) return [this._renderNavigationMainLevel(0), AppBC];
    else if (matchPath(path, config.ApplicationRoutes.users)) return [this._renderNavigationMainLevel(1)];
    else if (matchPath(path, config.ApplicationRoutes.partitionACLs)) return [this._renderNavigationMainLevel(2)];
    else if (matchPath(path, config.ApplicationRoutes.config_emails)) return [this._renderNavigationMainLevel(3)];
    else if (matchPath(path, config.ApplicationRoutes.config_email_suppression)) return [this._renderNavigationMainLevel(4)];
    else if (matchPath(path, config.ApplicationRoutes.partitionACL)) {
      return [
        this._renderNavigationMainLevel(2),
        <Breadcrumb.Item key="aclBC" onClick={this._openLink.bind(this, config.ApplicationRoutes.partitionACL, null, values.aclID)}>
          <Link to={this._parseUrl(config.ApplicationRoutes.partitionACL, null, values.aclID)}> {values.aclName || "Partition ACL"} </Link>
        </Breadcrumb.Item>,
      ];
    }
    return null;
  }

  //Private UI
  _renderNavigationMainLevel(type) {
    //Check for selected third level option
    let option = null;
    if (type == 0 /* app */) {
      option = {
        displayName: "Applications",
        link: this._parseUrl(config.ApplicationRoutes.applications, null, null),
        raw: config.ApplicationRoutes.applications,
        icon: <IoIosApps style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    } else if (type == 1 /* users */) {
      option = {
        displayName: "Users",
        link: this._parseUrl(config.ApplicationRoutes.users, null, null),
        raw: config.ApplicationRoutes.users,
        icon: <FaUsersCog style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    } else if (type == 2 /* acls */) {
      option = {
        displayName: "Partitions ACLs",
        link: this._parseUrl(config.ApplicationRoutes.partitionACLs, null, null),
        raw: config.ApplicationRoutes.partitionACLs,
        icon: <MdSecurity style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    } else if (type == 3 /* email logs */) {
      option = {
        displayName: "Email Logs",
        link: this._parseUrl(config.ApplicationRoutes.config_emails),
        raw: config.ApplicationRoutes.config_emails,
        icon: <IoIosMail style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    } else if (type == 4 /* email suppression */) {
      option = {
        displayName: "Email Suppression",
        link: this._parseUrl(config.ApplicationRoutes.config_email_suppression),
        raw: config.ApplicationRoutes.config_email_suppression,
        icon: <IoIosMail style={{ marginRight: 10, bottom: -3, position: "relative" }} />,
      };
    }
    //should be handled above, but it's here to support new options without showing wrong information
    if (!option) return <></>;
    //Render
    const items = [];
    if (this.app.isRoot())
      items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.applications, null, null)}> Applications </Link>, key: config.ApplicationRoutes.applications, icon: <IoIosApps /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.users, null, null)}> Users </Link>, key: config.ApplicationRoutes.users, icon: <FaUsersCog /> });
    if (this.app.isRoot())
      items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.partitionACLs, null, null)}> Partition ACLs </Link>, key: config.ApplicationRoutes.partitionACLs, icon: <MdSecurity /> });
    const menu = <Menu items={items} />;
    return (
      <Breadcrumb.Item key="idmMainBC" overlay={menu} onClick={this._openLink.bind(this, option.raw, null, null)}>
        {" "}
        <Link to={option.link}>
          {option.icon}
          {option.displayName}
        </Link>{" "}
      </Breadcrumb.Item>
    );
  }
}
