import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button, message } from 'antd';
import scrollIntoView from 'scroll-into-view';
import { DownloadOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import PersistentSearch from '@/components/helpers/PersistentSearch';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonContactsSearchBar from '../commonComponents/CommonContactsSearchBar';
import CommonContactsTable from '../commonComponents/Table/CommonContactsTable';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminContactsView extends CustomComponent {
    constructor(props) {
      super(props);
      autoBind(this);
      this.persistentSearch = new PersistentSearch();
      this.state = {
        isLoading: false,
        contacts: [], contactsTotal: 0,
        ...this._getInitialState(),
      };
    }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    // may need to add something when company selector component is added
    this.fetchData();
  }

  //Actions
  handleSearch(term) { this.setState({ searchTerm: term, currentPage: 1, sortedInfo: null }, this.fetchData); }
  handleSearchChange(value) {
    if (!value) this.setState({ searchTerm: null, currentPage: 1, sortedInfo: null }, this.fetchData);
  }
  handleExportXLSX() { this._exportSearch(); }
  //Contact actions
  handleContactCreate() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_contact, null, Globals.URL_NewIndentifier); }
  handleContactEdit(contactObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.org_contact, null, contactObj.id); }
  handleContactDelete(contactObj) { this._deleteContact(contactObj.id); }
  //Table internal actions
  handleTablePageChange(currentPage) { this.setState({ currentPage }, this.fetchData); }
  handleTableChange(pagination, filters, sortedInfo) { this.setState({ sortedInfo }, this.fetchData); }

  //UI
  render() {
    return (
    <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className='pageHeader' title="Contacts"
              extra={[
                <Button key="1" onClick={this.handleContactCreate} type='primary'>Create Contact</Button>,
                <Button key="2" type="primary" icon={<DownloadOutlined />} onClick={this.handleExportXLSX}
                  disabled={this.state.contacts.length < 1} style={{ marginLeft: 20, marginRight: -4 }}> Export to XLSX </Button>
              ]}/>
          <Layout.Content>
            <CommonContactsSearchBar persistentSearch={this.persistentSearch} isLoading={this.state.isLoading}
                onSearch={this.handleSearch} {...Utils.propagateRef(this, 'searchBar')} app={this.props.app}/>
            <CommonContactsTable app={this.props.app} isLoading={this.state.isLoading} total={this.state.contactsTotal}
                currentPage={this.state.currentPage} contacts={this.state.contacts} sortedInfo={this.state.sortedInfo}
                onPageChange={this.handleTablePageChange} onFilterChange={this.handleTableChange}
                onContactEdit={this.handleContactEdit}
                onContactDelete={this.handleContactDelete}/>
          </Layout.Content>
      </Layout.Content>
    );
  }

  /* private */
  _getInitialState() {
    const currentPage = parseInt(this.persistentSearch.getParam(Globals.URLQueryParam_Page) || 1);
    const columnKey = this.persistentSearch.getParam(Globals.URLQueryParam_SortField) || 'firstName.keyword';
    const order = this.persistentSearch.getParam(Globals.URLQueryParam_SortOrder) || 'ascend';
    return { currentPage, sortedInfo: { columnKey, order } };
  }
  _reloadURLParams() {
    this.persistentSearch.setParam(Globals.URLQueryParam_Page, this.state.currentPage);
    this.persistentSearch.setParam(Globals.URLQueryParam_SortField, (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.columnKey : null));
    this.persistentSearch.setParam(Globals.URLQueryParam_SortOrder, (this.state.sortedInfo ? this.state.sortedInfo.order : null));
  }

  _getSearchFilter() {
    const from = (Globals.Table_PagingItemsPerPage * (this.state.currentPage - 1));
    const sortField = (this.state.sortedInfo && this.state.sortedInfo.order && this.state.sortedInfo.columnKey ? this.state.sortedInfo.columnKey : null);
    const sortOrder = (this.state.sortedInfo && this.state.sortedInfo.order ? this.state.sortedInfo.order : null);
    const searchBarObjs = this.searchBar ? this.searchBar.getSearchFilters() : {};
    return { from, sortField, sortOrder: (sortOrder == 'descend' ? 'desc' : 'asc'), ...searchBarObjs };
  }
  
  /* private - API */
  async fetchData() {
    if (this.state.isLoading) return;
    this.startLoading();
    //request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const resp = await this.props.app.organization.contact.searchContactsByTerm(searchTerm, this._getSearchFilter());
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body && resp.body.contacts) {
      const contacts = resp.body.contacts;
      this.setState({ contacts, contactsTotal: resp.body.total, isLoading: false });
      scrollIntoView(document.getElementById('root'), { time: 500, align: { top: 0 } });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
    this._reloadURLParams();
  }
  async _deleteContact(contactID) {
    if (!this._isMounted) return; //Important, check if is mounted

    this.startLoading();

    const resp = await this.props.app.organization.contact.deleteContact(contactID);

    if (resp.statusCode == 200) {
      message.success('Contact successfully removed, you may need to refresh your search');
      await Utils.shortRandSleep(500);
      await this.fetchData();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
  async _exportSearch() {
    this.startLoading();
    //request
    const searchTerm = this.searchBar ? this.searchBar.getSearchTerm() : '';
    const resp = await this.props.app.organization.contact.exportContactsSearch(searchTerm, this._getSearchFilter());
    if (!this._isMounted) return; //Important, check if is mounted
    if (resp.statusCode == 200 && resp.body) Utils.downloadBlob(resp.body, 'contacts', 'xlsx');
    else this.props.app.alertController.showAPIErrorAlert(null, resp);
    this.stopLoading();
  }
}  

