import React from 'react';
import autoBind from 'react-autobind';
import { Dropdown, Menu, Button, Table, Tooltip, Popconfirm } from 'antd';
//icons
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { FaUsers, FaRegAddressCard, FaUserShield } from 'react-icons/fa';
import { GrMapLocation, GrCertificate} from 'react-icons/gr';
//
import Globals from '@/config/Globals'
import Utils from '@/components/helpers/Utils'
//props are: app, isLoading, total, currentPage, sortedInfo, contacts, onPageChange, onFilterChange, onContactEdit, onContactDelete, onRowAction
export default class CommonContactsTable extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
    }
    render() {
      let { sortedInfo } = this.props;
      sortedInfo = sortedInfo || {};
      const columns = [
        { title: 'First Name', key: 'firstName.keyword', width: '15%', sorter: true,
          render: props => props.firstName || 'N/A', 
          sortOrder: sortedInfo.columnKey === 'firstName.keyword' && sortedInfo.order,},
        { title: 'Last Name', key: 'lastName.keyword', width: '15%', sorter: true,
          render: props => props.lastName || 'N/A', 
          sortOrder: sortedInfo.columnKey === 'props.lastName' && sortedInfo.order,},
        { title: 'Email', key: 'email.keyword', width: '10%', sorter: true,
          render: props => props.email || 'N/A', 
          sortOrder: sortedInfo.columnKey === 'props.email' && sortedInfo.order,},
        { title: 'Phone', key: 'phone.keyword', width: '10%', sorter: true,
          render: props => props.phone ? ( (props.phone[0].label || '') + ': ' + props.phone[0].number || '') : 'N/A', 
          sortOrder: sortedInfo.columnKey === 'phone.keyword' && sortedInfo.order,},
        { title: 'Last update', key: 'updatedOn', width: '10%', sorter: true,
          render: props => props.updatedOn ? Utils.getDateOnUIFormatByTimestamp(props.updatedOn) : 'N/A',
          sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
        },
        { title: 'Actions', width: '10%', key: 'Actions', align: 'center',
           render: props => (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.props.onContactEdit.bind(this,props)}
                />
              </Tooltip>
              {' '}
              <Tooltip placement="bottomLeft" title={`Delete ${props.firstName ? props.firstName : ''} ${props.lastName ? props.lastName : ''}`}>
              <Popconfirm
                placement="top"
                title={`Are you sure that you want to delete the contact ${props.firstName ? props.firstName : ''} ${props.lastName ? props.lastName : ''} ?`}
                onConfirm={this.props.onContactDelete.bind(this, props)}
                okText="Yes"
                cancelText="No"
              >
              <Button
                variant="none"
                icon={<DeleteOutlined />}
                shape="circle"
              />
              </Popconfirm>
            </Tooltip>
          </span>
        ),
        }
      ];
      const props = { rowKey: 'id', loading: this.props.isLoading, onChange: this.props.onFilterChange,
                      locale: {emptyText: 'No contacts found!'},
                      pagination: {
                        pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ],
                        total: this.props.total, onChange: this.props.onPageChange, current: this.props.currentPage,
                        showSizeChanger: false, showTotal: (total, range) => <div style={{marginRight: 20}}>{`${range[0]}-${range[1]} of ${total} contacts`}</div>
                      }};
      return (<Table className="contactsTable" columns={columns} dataSource={this.props.contacts} {...props}/>);
    }
  }