import React from "react";
import { Divider, Badge, Tag, Button, Col, Row, Form, Input, Popover, Typography, TextArea } from 'antd';
import autoBind from 'react-autobind';
//
import config from "@/config/config";
import Utils from '@/components/helpers/Utils';
//
import CommonDynamicForm from "@/views/commonComponents/CommonDynamicForm";
import PhoneInput from "@/ui-components/PhoneInput";
//
export default class CommonUserProfileForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isDesktop: window.innerWidth > 890, selectedOrg: null };
  }
  //Life cycle
  async componentDidUpdate(prevProps) {
    if (prevProps.data != this.props.data) {
      console.log('*-*- will set fields value:', this.props.data);
      this.form.setFieldsValue({ ...this.props.data, certNameOnCertificate: this.props.data?.tenantUser?.certNameOnCertificate });
      this.setState({ selectedOrg: this.props.data?.employementInfo?.org });
    }
  }

  //Actions
  handleEmailChange(email) { this.setState({ email }); }
  handleOrgChange(orgObj) { this.setState({ selectedOrg: orgObj }); }
  //Public
  async validateFields() {
    const d = await this.form.validateFields();
    if (!d) { return; }
    return { ...d, selectedOrg: this.state.selectedOrg };
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        {this._renderBasicInformation()}
        {this._renderAdditionalInformation()}
        <CommonDynamicForm app={this.props.app} selectedOrg={this.state.selectedOrg} isDesktop={this.state.isDesktop}
          additionalInputs={this.props.additionalInputs} onOrgChange={this.handleOrgChange}
          isRootUser={(this.props.data?.linkingRoles?.indexOf(config.IDMClientOptions.roles.ROOT) != -1)} />
      </Form>
    );
  }

  /* private render */
  _renderBasicInformation() {
    const editMode = this.props.editMode;
    const { isDesktop } = this.state;
    const offset = isDesktop ? 1 : 0;
    const colSpan = isDesktop ? 11 : 24;
    return (
      <>
        <Row type="flex" justify="start" align="middle">
          <Col span={colSpan} offset={offset}>
            <Form.Item name="firstName" label="First Name" rules={[
              { required: true, message: 'Please, type your first name!' },
              { min: 2, max: 255, message: 'First name must be between 2 and 255 characters' },
            ]}>
              <Input disabled={!editMode || (this.props.app.configManager?.launchConfig?.tenant?.customer?.preventUserFromUpdatingNames && !this.props.app.isAdmin())} />
            </Form.Item>
          </Col>
          <Col span={colSpan} offset={offset}>
            <Form.Item name="lastName" label="Last Name" rules={[
              { required: true, message: 'Please, type your last name!' },
              { min: 2, max: 255, message: 'Last name must be between 2 and 255 characters' },
            ]}>
              <Input disabled={!editMode || (this.props.app.configManager?.launchConfig?.tenant?.customer?.preventUserFromUpdatingNames && !this.props.app.isAdmin())} />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={colSpan} offset={offset}>
            {this._wrapIntoEmailPopover(
              <Form.Item name="email" label="Email" rules={[
                { required: true, message: 'Please, type your email!' },
                { min: 2, max: 255, message: 'Email name must be between 2 and 255 characters' },
                { type: 'email', message: 'Invalid email format' }
              ]}>
                <Input disabled={!editMode} onChange={this.handleEmailChange}
                  suffix={(this.props.data?.id ? (this.props.data?.confirmationDate > 0 ? <Tag color='green'>Confirmed</Tag> : <Tag color='red'>Unconfirmed</Tag>) : <></>)} />
              </Form.Item>
            )}
          </Col>
          <Col span={colSpan} offset={offset}>
            {this.props.app.isAdmin() && (<Form.Item name="sendEmailAs" label="Send Email As" rules={[
              { required: false, message: 'Please, type your email!' },
              { min: 2, max: 255, message: 'Email name must be between 2 and 255 characters' },
              { type: 'email', message: 'Invalid email format' }
            ]}>
              <Input disabled={!editMode} />
            </Form.Item>
            )}
          </Col>
          <Col span={colSpan} offset={offset}>
            {this.props.app.isAdmin() && (<Form.Item name="notes" label="Notes" ><Input.TextArea disabled={!editMode} /></Form.Item>)}
          </Col>
        </Row >
        <Row type="flex" justify="start" align="middle">
          <Col span={colSpan} offset={offset}>
            {this.props.app.isAdmin() && (<Form.Item name="externalID" label="External ID" ><Input disabled={!editMode} /></Form.Item>)}
          </Col>
          <Col span={colSpan} offset={offset}>
            {this.props.app.isAdmin() && (<Form.Item name="externalID2" label="2nd External ID" ><Input disabled={!editMode} /></Form.Item>)}
          </Col>
        </Row>
        {
          this.props.app.tenantID && this.props.data?.tenantUser?.id && <>
            <Divider orientation="left">{this.props.app.configManager?.launchConfig?.tenant?.organizationName} Specific</Divider>
            <Row type="flex" justify="start" align="middle">
              <Col span={colSpan} offset={offset}>
                <Form.Item name="certNameOnCertificate" label={'Name on Certificate'}>
                  <Input disabled={!this.props.app.isAdmin() || !editMode} allowClear />
                </Form.Item>
              </Col>
            </Row>
          </>
        }
      </>
    );
  }
  _renderAdditionalInformation() {
    const editMode = this.props.editMode;
    const { isDesktop } = this.state;
    const offset = isDesktop ? 1 : 0;
    const colSpan = isDesktop ? 23 : 24;
    const isRoot = (this.props.data?.linkingRoles?.indexOf(config.IDMClientOptions.roles.ROOT) != -1);
    return (
      <>
        <Divider orientation="left">Other</Divider>
        <Row type="flex" justify="start" align="middle" style={{ marginTop: '20px' }}>
          {isRoot && <Col offset={offset} span={colSpan}>
            <Typography.Text strong style={{ marginRight: '5px' }}>Multi-Factor Authentication:</Typography.Text>
            <Button disabled={!editMode} type="link" onClick={this.props.onMFAToggle}>
              <Tag> {this.props.data?.mfaEnabled ? 'Enabled' : 'Disabled'} <Badge status={this.props.data?.mfaEnabled ? 'success' : 'error'} /> </Tag>
            </Button>
          </Col>}
          {this.props.data?.mfaEnabled && this.props.app.themeManager.theme.branding.hidePhoneNumberField == false &&
            <Col span={colSpan} offset={offset}>
              {this._wrapIntoPhonePopover(<Form.Item name="phoneNumber" label="Phone Number" rules={[
                { required: false },
                { pattern: /^[\d\-\(\) +]+$/, message: 'Invalid phone number! Only numbers, spaces, hyphens and parentheses' },
              ]}>
                <PhoneInput disabled={!editMode || this.props.data?.mfaEnabled}
                  suffix={(this.props.data?.phoneNumber ? (this.props.data?.phoneConfirmationDate > 0 ? <Tag color='green'>Confirmed</Tag> : <Tag color='red'>Unconfirmed</Tag>) : <></>)} />
              </Form.Item>)}
            </Col>
          }
        </Row>
        {(!(!this.props.data?.federation || this.props.data?.federation?.length <= 0)) && <Row type="flex" justify="start" align="middle" style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Typography.Text strong style={{ marginRight: '15px' }}>This account is linked with the following provider(s): </Typography.Text> {this.props.data?.federation?.map((f, i) => <Tag key={i}>{f}</Tag>)}
        </Row>}
      </>
    );
  }
  /* popovers */
  _wrapIntoPhonePopover(toWrap) {
    if (!this.props.data?.mfaEnabled) return (<>{toWrap}</>);
    else return (<Popover trigger="focus" className='phoneDisabledTooltip' getPopupContainer={triggerNode => triggerNode.parentNode}
      content={
        <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '250px' }}>
          <Typography.Title level={5}>If you want to change tour phone number, please, disable the MFA and chage it.</Typography.Title>
        </div>
      }
    >{toWrap}</Popover>);
  }
  _wrapIntoEmailPopover(toWrap) {
    if (this.props.isNew || this.props.app.isAdmin()) return (<>{toWrap}</>);
    else return (<Popover trigger="focus" className='emailChangeTooltip' getPopupContainer={triggerNode => triggerNode.parentNode}
      content={
        <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
          <Typography.Title level={4}>Important notes for email change:</Typography.Title>
          <ul>
            <li>In order to take effect you <u>will be logged out</u> after changing the email.</li>
            <li>If wrong email is typed, email change can lock you out of your account.</li>
            <li>You will be asked to confirm the new email by entering a code sent on the new email.</li>
          </ul>
        </div>
      }
    >{toWrap}</Popover>);
  }
}
