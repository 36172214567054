//Polyfills
import "./polyfills";
// Log rocket
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
//
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
//Components
import App from "./app/App";
import config from "./app/config/config";
//
import 'antd/dist/antd.less';
import './app/stylesheets/index.less';

if (!process.env.REACT_APP_OFFLINE) {
  //Log rocket
  console.log('LogRocket App ID:', config.LogRocketOptions.applicationId);
  LogRocket.init(config.LogRocketOptions.applicationId);
  setupLogRocketReact(LogRocket);
}
//Render router with App
ReactDOM.render(
  <Router>
    <GoogleReCaptchaProvider reCaptchaKey={config.ApplicationRecaptchaKey}>
      <App/>
    </GoogleReCaptchaProvider>
  </Router>,
  document.getElementById("root")
);
