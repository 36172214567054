import React from 'react';
import autoBind from 'react-autobind';
import { Dropdown, Menu, Button, Table, Tooltip, Popconfirm } from 'antd';
//icons
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { FaUsers, FaRegAddressCard, FaUserShield } from 'react-icons/fa';
import { GrMapLocation, GrCertificate} from 'react-icons/gr';
//
import Globals from '@/config/Globals'
import Utils from '@/components/helpers/Utils'
//props are: app, isLoading, total, currentPage, sortedInfo, employees, onPageChange, onFilterChange, onEmployeeEdit, onEmployeeDelete, onRowAction
export default class CommonOrganizationEmployeesTable extends React.Component {
    constructor(props) {
      super(props);
      autoBind(this);
    }
    render() {
      let { sortedInfo } = this.props;
      sortedInfo = sortedInfo || {};
      const columns = [
        { title: 'Name', key: 'name', width: '30%', sorter: true,
          sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
          render: props => props.firstName+' '+props.lastName || 'N/A', },
        { title: 'Email', key: 'email.keyword', width: '10%', sorter: true,
          sortOrder: sortedInfo.columnKey === 'email.keyword' && sortedInfo.order,
          render: props => props.email || 'N/A', },
        { title: 'Updated on', key: 'updatedOn', width: '10%', sorter: true,
          sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order, 
          sorter: (a, b) => a.updatedOn - b.updatedOn,
          render: props => props.updatedOn ? Utils.getDateOnUIFormatByTimestamp(props.updatedOn) : 'N/A',
        },
        { title: 'Actions', width: '10%', key: 'Actions', align: 'center',
           render: props => (
            <span className='tableButtonContainer'>
              <Tooltip placement="bottomLeft" title="Edit">
                <Button
                  variant="none"
                  icon={<EditOutlined />}
                  shape="circle"
                  onClick={this.props.onEmployeeEdit.bind(this,props)}
                />
              </Tooltip>
            </span>
        ),
        }
      ];
      const props = { rowKey: 'id', loading: this.props.isLoading, onChange: this.props.onFilterChange,
                      locale: {emptyText: 'No employees found!'},
                      pagination: {
                        pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ],
                        total: this.props.total, onChange: this.props.onPageChange, current: this.props.currentPage,
                        showSizeChanger: false, showTotal: (total, range) => <div style={{marginRight: 20}}>{`${range[0]}-${range[1]} of ${total} employees`}</div>
                      }};
      return (<Table className="employeesTable" columns={columns} dataSource={this.props.employees} {...props}/>);
    }
  }