import React from "react";
import autoBind from 'react-autobind';
import { Layout, Button, PageHeader } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
//
import AdminTenantForm from "./Forms/AdminTenantForm";
//
export default class AdminTenantView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: {}, isLoading: false };
    this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this) == Globals.URL_NewIndentifier);
  }
  //Life cycle
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Tenant`;
    //Check for id
    if (!this.isNew) this._fetchData();
  }

  //Actions handler
  async handleSubmit() {
    //validate and retrieve form data
    const formData = await this.form.validateFields();
    if (!formData) return;
    //make operation
    if (this.isNew) this._addTenant(formData);
    else this._saveTenant(formData);
  }
  //UI
  render() {
    const isLoading = this.state.isLoading;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true}/>
        <PageHeader className='pageHeader' onBack={() => window.history.back()} title={this.isNew ? 'New Tenant' : `Tenant - ${(this.state.data?.organizationName || 'Loading...')}`}
          extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create Tenant' : 'Save Tenant'}</Button>]}/>
        <Layout.Content>
          <AdminTenantForm isNew={this.isNew} app={this.props.app} tenant={this.state.data} {...Utils.propagateRef(this, 'form')}/>
        </Layout.Content>
      </Layout.Content>
    );
  }

  /*Private methods*/
  async _addTenant(data) {
    this.startLoading();
    const addResp = await this.props.app.config.tenant.createTenant(data);
    if (!this._isMounted) return;
    if (addResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Tenant created with success!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.config_tenant, null, encodeURIComponent(data.id));
      this.props.app.appsManager?.navigation?.appendValue('tenantID', data.id);
      this.props.app.appsManager?.navigation?.appendValue('tenantName', data.organizationName);
      this._fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, addResp);
      this.stopLoading();
    }
  }
  async _saveTenant(data) {
    this.startLoading();
    const updateResp = await this.props.app.config.tenant.updateTenant(data);
    if (!this._isMounted) return;
    if (updateResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Tenant updated with success!");
      this._fetchData();
    }else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
  async _fetchData() {
    this.startLoading();
    const tID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const resp = await this.props.app.config.tenant.getTenant(tID);
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body.id) { //valid data state
      const data = resp.body;
      this.setState({ data, isLoading: false });
      //set navigation items details
      this.props.app.appsManager?.navigation?.appendValue('tenantID', data.id);
      this.props.app.appsManager?.navigation?.appendValue('tenantName', data.organizationName);
    }else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ data: {}, isLoading: false });
    }
  }
}
