import React from "react"
import { Layout, Menu, Alert, Row, Col, Typography } from 'antd';
import Logo from "../Logo";
//
import "@/stylesheets/AppHeader.less"
export default class AppHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  /* UI */
  render() {
    return (
    <Layout.Header className='appHeader'>
      <Row type="flex" justify='space-between' align='middle'>
        <Col> <Logo isHeading={true} app={this.props.app}/> </Col>
        <Col>
          <Typography.Title className="headerSubtitle" level={4}>{`${this.props.app.themeManager.theme.applicationName} Students Portal`}</Typography.Title>
        </Col>
      </Row>
    </Layout.Header>);
  }
}