import React from "react";
import autoBind from 'react-autobind';
import { Layout, Row, Col, Divider } from 'antd';
//
import CustomComponent from '@/ui-components/CustomComponent';
//
import '@/stylesheets/AdminDashboardView.less';
import AdminStudentsView from "../apps/organization/adminSubviews/AdminStudentsView";
//
export default class InstructorStudentsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    document.title = `${this.props.app.themeManager.theme.applicationName} - Students`;
  }

  render() {
    return (
      <AdminStudentsView {...this.props} isInstructorView />
    )
  }
}
