import React from "react";
import { Layout, Row, Col, Typography } from 'antd';
import autoBind from 'react-autobind';
import { GridLoader } from 'react-spinners';
//
import Logo from '../commonComponents/Logo';
import CustomComponent from "@/ui-components/CustomComponent";
//resources
import "@/stylesheets/CommonLogin.less";
//
import config from "@/config/config";
//
export default class CommonLoginView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
  }
  componentDidMount() {
    super.componentDidMount();
    document.title = `Redirecting - ${this.props.app.themeManager.theme.applicationName}`;
  }

  //UI
  render() {
    let isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    return (
      <Layout.Content>
        <Layout.Content className='authorizationContainer'>
          <Row type="flex" justify="center" align="middle" >
            <Col span={6} className='authorizationBoxContainer' align='middle' justify="center">
              <div className='authorizationBox'>
                <Row><Col align="center"> <Logo app={this.props.app}/> </Col></Row>
                <Row>
                  <Col align="center">
                    <Typography.Title level={4}>Please, wait while you are being redirected...</Typography.Title>
                  </Col>
                </Row>
                <Row>
                  <Col align="center">
                    <GridLoader sizeUnit="px" size={20} color='#454545'
                      loading={true} />
                  </Col>
                </Row>

              </div>
            </Col>
          </Row>
        </Layout.Content>
      </Layout.Content>
    );
  }
}
