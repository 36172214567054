export default {
  "sections": [
    {
      "name": "Address and Contact",
      "beforeRow": 3
    }
  ],
  "fields": {
    "dateOfBirth": {
      "row": 1,
      "orderInRow": 0,
      "widthPercentage": 20,
      "id": "dateOfBirth",
      "label": "Date of Birth",
      "type": "date",
      "format": "YYYY-MM-DD",
      "partitionID": "ca.bccsa.personal",
      "saveOnUser": false,
      "required": true,
      "placeholder": "yyyy-mm-dd",
      "allowFutureValue": false
    },
    "orgID": {
      "row": 1,
      "orderInRow": 2,
      "widthPercentage": 25,
      "id": 'orgID',
      "label": 'Company',
      "type": 'employmentSelection',
      "required": false
    },
    "title": {
      "row": 1,
      "orderInRow": 3,
      "widthPercentage": 20,
      "id": 'title',
      "label": 'Title',
      "type": 'text',
      "required": false,
      "saveOnUser": false,
      "partitionID": "ca.bccsa.professional"
    },
    "certifiedUserOptOut": {
      "row": 2,
      "orderInRow": 1,
      "widthPercentage": 100,
      "id": 'certifiedUserOptOut',
      "label": 'Exclude from web site searches',
      "extra": 'Enabling this option excludes the user from appearing on searches looking for certified people',
      "type": 'switch',
      "required": false,
      "saveOnUser": true,
      "partitionID": "ca.bccsa.professional"
    },
    "address.unit": {
      "row": 3,
      "orderInRow": 0,
      "id": "address.unit",
      "label": "Apt/Unit",
      "type": "text",
      "partitionID": "ca.bccsa.personal",
      "saveOnUser": false,
      "required": false
    },
    "addressStreetZero": {
      "row": 3,
      "orderInRow": 1,
      "id": "address.street.0",
      "label": "Street Address",
      "type": "text",
      "partitionID": "ca.bccsa.personal",
      "saveOnUser": false,
      "required": true
    },
    "addressStreetOne": {
      "row": 3,
      "orderInRow": 2,
      "id": "address.street.1",
      "label": "Street Address 2",
      "type": "text",
      "partitionID": "ca.bccsa.personal",
      "saveOnUser": false,
      "required": false
    },
    "addressCity": {
      "row": 4,
      "orderInRow": 0,
      "id": "address.city",
      "label": "City",
      "type": "text",
      "partitionID": "ca.bccsa.personal",
      "saveOnUser": false,
      "required": true
    },
    "addressProvince": {
      "row": 4,
      "orderInRow": 1,
      "id": "address.province",
      "label": "Province",
      "type": "text",
      "partitionID": "ca.bccsa.personal",
      "required": true,
      "saveOnUser": false
    },
    "addressCountry": {
      "row": 4,
      "orderInRow": 2,
      "id": "address.country",
      "label": "Country",
      "type": "text",
      "partitionID": "ca.bccsa.personal",
      "saveOnUser": false,
      "required": true
    },
    "addressPostalCode": {
      "row": 5,
      "orderInRow": 0,
      "id": "address.postalCode",
      "label": "Postal Code",
      "type": "text",
      "partitionID": "ca.bccsa.personal",
      "saveOnUser": false,
      "required": true
    },
    "phoneNumber": {
      "row": 5,
      "orderInRow": 1,
      "id": "phoneNumber",
      "label": "Mobile Number",
      "customProps": {},
      "type": "tel",
      "partitionID": "ca.bccsa.personal",
      "required": true,
      "saveOnUser": true
    },
    "addressPhone": {
      "row": 5,
      "orderInRow": 2,
      "id": "address.phone",
      "label": "Other Number",
      "customProps": {},
      "type": "tel",
      "partitionID": "ca.bccsa.personal",
      "required": false,
      "saveOnUser": false
    },
  }
}
/* saving this for later
    "certifiedUserOptOut": {
      "row": 2,
      "orderInRow": 1,
      "widthPercentage": 100,
      "id": 'certifiedUserOptOut',
      "label": 'Exclude from web site searches',
      "extra": 'Enabling this option excludes the user from appearing on searches looking for certified people',
      "type": 'switch',
      "required": false,
      "saveOnUser": true,
      "partitionID": "ca.bccsa.professional"
    },
*/