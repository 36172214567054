import Globals from '../../../config/Globals';
import autoBind from 'react-autobind';
//
export default class LaunchConfig {
  constructor(optionalOverride = {}) {
    this.customerID = this._fallbackWhenUndefined(optionalOverride.customerID, null);
    this.tenantID = this._fallbackWhenUndefined(optionalOverride.tenantID, null);
    this.tenant = this._fallbackWhenUndefined(optionalOverride.tenant, null);
  }
  _fallbackWhenUndefined(value, fallback) {
    if (value === undefined) return fallback;
    return value;
  }
}
