import React from "react";
import autoBind from 'react-autobind';
//
import { Descriptions, Layout, Divider, Row, Col, Button, Table, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
//
import Utils from '@/components/helpers/Utils';
import Globals from "@/config/Globals";

//props: isLoading, data, handleDelete
export default class CommonPartitionsTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: null };
  }
  //Table support
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const props = {
      size: 'medium', onChange: this.handleFilterChange, rowKey: 'partID', loading: this.props.isLoading,
      locale: {emptyText: 'Not partition data found for this user.'},
      pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] },
      expandedRowRender:(record) => {
        return (
          <Layout className='partitionDetailedView'>
            <Descriptions title="Partition data:" bordered size='small' layout='vertical'>
              {Object.keys(record).map(_key => {
                if (typeof record[_key] === 'object') return (<Descriptions.Item key={_key} label={_key}><pre>{JSON.stringify(record[_key], null, 2)}</pre></Descriptions.Item>);
                else return (<Descriptions.Item key={_key} label={Utils.camelizeString(_key)}>{(record[_key])}</Descriptions.Item>);
              })}
            </Descriptions>
          </Layout>
        )
      }
    };
    const columns = [
      { title: 'Partition', dataIndex: 'partID', key: 'partID', width: 150,
        sorter: (a, b) => a.partID.localeCompare(b.partID),
        sortOrder: sortedInfo.columnKey === 'partID' && sortedInfo.order
      },
      { title: 'Updated On', width: 150, dataIndex: 'updatedOn', key: 'updatedOn',
        sorter: (a, b) => a - b,
        sortOrder: sortedInfo.columnKey === 'updatedOn' && sortedInfo.order,
        render: props => Utils.getDateAndTimeOnUIFormatByTimestamp(props)
      },
      { title: 'Actions', width: 80, key: 'Actions',
        render: props => {
          return (<span className='tableButtonContainer'>
                    <Tooltip placement="bottomLeft" title='Delete Partition'>
                      <Popconfirm title={`Do you really want to delete partition '${props.partID}' data?`} placement="bottomRight" onConfirm={this.props.handleDelete.bind(this, props)} okText="Yes" cancelText="No">
                        <Button variant="none" icon={<DeleteOutlined />} shape="circle"/>
                      </Popconfirm>
                    </Tooltip>
                  </span>);
        }
      }
    ];
    return (
      <>
        <Divider orientation="left">User partitions</Divider>
        <Row type='flex'>
          <Col offset={1} span={22}>
            <Table dataSource={this.props.data} columns={columns} {...props} />
          </Col>
        </Row>
      </>
   );
  }
}
