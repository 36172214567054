import React from 'react';
import autoBind from 'react-autobind';
import { Table, Spin, Tooltip } from 'antd';
//
import Utils from '@/components/helpers/Utils'
import Globals from '@/config/Globals'
//props are: app, isLoading, members, tenants
export default class CommonOrganizationMembersTable extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { sortedInfo: {} };
  }
  //Actions
  handleFilterChange(pagination, filters, sorter) { this.setState({ sortedInfo: sorter }); }
  //UI
  render() {
    let { sortedInfo } = this.state;
    const columns = [
      { title: 'Name', key: 'name', sorter: true,
        sorter: (a, b) => (`${a.firstName} ${a.lastName}`).toLowerCase().localeCompare((`${b.firstName} ${b.lastName}`).toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
        render: props => (props.firstName ? `${props.firstName} ${props.lastName}` : <>Loading.. <Spin size="small"/></>)
      },
      { title: 'Email', key: 'email', sorter: true,
        sorter: (a, b) => (a.email || '').toLowerCase().localeCompare((b.email || '').toLowerCase()),
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        render: props => (props.email)
      },
      { title: 'Programs', key: 'programs', sorter: true,
        sorter: (a, b) => ((a.programs || []).map(p => this._getTenantNameByID(p.tenantID)).join(', ')).toLowerCase().localeCompare(((b.programs || []).map(p => this._getTenantNameByID(p.tenantID)).join(', '))),
        sortOrder: sortedInfo.columnKey === 'programs' && sortedInfo.order,
        render: props => [... new Set((props.programs || []).map(p => this._getTenantNameByID(p.tenantID)))].join(', ')
      },
    ];
    const props = { rowKey: 'email', loading: this.props.isLoading, onChange: this.handleFilterChange, size: 'small',
                    locale: {emptyText: 'No members found into this company yet!'},
                    pagination: { pageSize: Globals.Table_PagingItemsPerPage, hideOnSinglePage: true, position: [ 'bottomRight' ] },
                  expandable: { expandedRowRender: this._subTableRender }};
    return (<Table className="orgMembersTable" columns={columns} dataSource={this.props.members} {...props}/>);
  }
  /* Private renders */
  _subTableRender(record) {
    const columns = [
      { title: 'Program', key: 'program', sorter: false, render: props => this._getTenantNameByID(props.tenantID) },
      { title: 'Role', key: 'role', sorter: false, render: props => props.role },
      { title: 'Confirmed', key: 'confirmed', sorter: false,
        render: props => {
          if (!props.confirmedOn) {
            if (props.role == Globals.OrganizationApp_ProgramsMembersTypes.MANAGER) return 'Yes'; //managers dont have confirmed date, we assume already confirmed
            return 'No';
          } else return (<Tooltip placement="bottomLeft" title={`Confirmed on ${Utils.getDateAndTimeOnUIFormatByTimestamp(props.confirmedOn)}`}>Yes</Tooltip>)
        }
      }
    ];
    const props = { rowKey: (r) => `${r.tenantID}:${r.role}`, size: 'small', locale: {emptyText: 'Member is not participant of any program!'},
                    pagination: { pageSize: 100, hideOnSinglePage: true, position: [ 'bottomRight' ] }};
    return (<Table className="orgMemberAppsTable" columns={columns} dataSource={record.programs || []} {...props}/>);
  }
  _getTenantNameByID(tenantID) { return (this.props.tenants || []).find((t) => t.id == tenantID)?.name || tenantID; }
}