import React from 'react';
import { Breadcrumb, Menu } from 'antd';
import { Link } from "react-router-dom"
import { matchPath } from "react-router";
import autoBind from 'react-autobind';
//Icons
import { FaTags, FaRegAddressCard, FaUserGraduate, FaUsers, FaUserShield } from 'react-icons/fa';
import { IoMdBusiness } from 'react-icons/io';
import { TbFileImport } from 'react-icons/tb';
import { GrMapLocation, GrCertificate } from 'react-icons/gr';
//Components
import CustomRoute from "@/ui-components/CustomRoute";
import BaseApp from "@/components/subcomponents/AppsManager/BaseApp";
import Globals from "@/config/Globals";
//App Routes
import AdminOrganizationsView from './adminSubviews/AdminOrganizationsView';
import AdminContactsView from './adminSubviews/AdminContactsView';
import AdminStudentsView from './adminSubviews/AdminStudentsView';
import AdminClassificationUnitsView from './adminSubviews/AdminClassificationUnitsView';
import AdminClassificationUnitView from './adminSubviews/AdminClassificationUnitView';
import AdminOrganizationView from './adminSubviews/AdminOrganizationView';
import AdminOrganizationProgramsView from './adminSubviews/AdminOrganizationProgramsView';
import AdminOrganizationEmployeesView from './adminSubviews/AdminOrganizationEmployeesView';
import AdminOrganizationContactsView from './adminSubviews/AdminOrganizationContactsView';
import AdminOrganizationLocationsView from './adminSubviews/AdminOrganizationLocationsView';
import AdminOrganizationManagersView from './adminSubviews/AdminOrganizationManagersView';
import AdminOrgImportsView from './adminSubviews/AdminOrgImportsView';
import AdminOrgImportView from './adminSubviews/AdminOrgImportView';
import AdminContactView from './adminSubviews/AdminContactView';
import AdminVaultCustomAttributes from '../config/adminSubviews/AdminVaultCustomAttributes';
import AdminVaultDockets from '../config/adminSubviews/AdminVaultDockets';
import AdminVaultDocketDoc from '../config/adminSubviews/AdminVaultDocketDoc';
//Config
import config from "@/config/config";
//
export default class OrgApp extends BaseApp {
  constructor(app) {
    super(Globals.MiniApps.ORG, app);
    autoBind(this);
  }
  /* Overrides */
  get isEnabled() { return this.app.isRoot() || this.app.isOrgAdmin() || this.app.isOrgSubAdmin(); }
  get name() { return "Company Management"; }
  get description() { return "Companies, students, contacts and import administration."; }
  get routes() {
    if (this.app.isRoot() || this.app.isOrgAdmin() || this.app.isOrgSubAdmin()) {
      return [
        <CustomRoute key={config.ApplicationRoutes.org_orgs_search} path={config.ApplicationRoutes.org_orgs_search} exact component={AdminOrganizationsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_fileImports} path={config.ApplicationRoutes.org_fileImports} exact component={AdminOrgImportsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_fileImport_job} path={config.ApplicationRoutes.org_fileImport_job} exact component={AdminOrgImportView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_orgs_cus} path={config.ApplicationRoutes.org_orgs_cus} exact component={AdminClassificationUnitsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_orgs_contacts} path={config.ApplicationRoutes.org_orgs_contacts} exact component={AdminContactsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_orgs_students} path={config.ApplicationRoutes.org_orgs_students} exact component={AdminStudentsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_cu_cu} path={config.ApplicationRoutes.org_cu_cu} exact component={AdminClassificationUnitView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_organization} path={config.ApplicationRoutes.org_organization} exact component={AdminOrganizationView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_programs} path={config.ApplicationRoutes.org_programs} exact component={AdminOrganizationProgramsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_employees} path={config.ApplicationRoutes.org_employees} exact component={AdminOrganizationEmployeesView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_contacts} path={config.ApplicationRoutes.org_contacts} exact component={AdminOrganizationContactsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_locations} path={config.ApplicationRoutes.org_locations} exact component={AdminOrganizationLocationsView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_managers} path={config.ApplicationRoutes.org_managers} exact component={AdminOrganizationManagersView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.org_contact} path={config.ApplicationRoutes.org_contact} exact component={AdminContactView} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.custom_attributes} path={config.ApplicationRoutes.custom_attributes} exact component={AdminVaultCustomAttributes} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.dockets} path={config.ApplicationRoutes.dockets} exact component={AdminVaultDockets} appRef={this.app} />,
        <CustomRoute key={config.ApplicationRoutes.docketDoc} path={config.ApplicationRoutes.docketDoc} exact component={AdminVaultDocketDoc} appRef={this.app} />,
      ];
    }
    return [];
  }
  get dashboardOptions() {
    if (this.app.isRoot() || this.app.isOrgAdmin() || this.app.isOrgSubAdmin()) {
      return [
        {
          key: 'org_1', name: 'Companies', Icon: IoMdBusiness,
          description: 'Manage Companies',
          route: config.ApplicationRoutes.org_orgs_search,
          hasPermission: true /* TBD */,
        },
        {
          key: 'org_2', name: 'File Import', Icon: TbFileImport,
          description: 'Import files and manage companies imports',
          route: config.ApplicationRoutes.org_fileImports,
          hasPermission: true /* TBD */,
        },
        {
          key: 'org_3', name: 'Contacts', Icon: FaRegAddressCard,
          description: 'Manage contacts related to the companies',
          route: config.ApplicationRoutes.org_orgs_contacts,
          hasPermission: true /* TBD */,
        },
        {
          key: 'org_4', name: 'Students', Icon: FaUserGraduate,
          description: 'Manage students',
          route: config.ApplicationRoutes.org_orgs_students,
          hasPermission: true /* TBD */,
        },
        {
          key: 'org_5', name: 'Classification Units', Icon: FaTags,
          description: 'Manage classification units related to the companies',
          route: config.ApplicationRoutes.org_orgs_cus,
          hasPermission: true /* TBD */,
        },
      ];
    } return null;
  }
  //navigation items
  getNavigationOption(path, values) {
    //Top level
    if (matchPath(path, config.ApplicationRoutes.org_orgs_search)) return [this._renderNavigationMainLevel(0)];
    else if (matchPath(path, config.ApplicationRoutes.org_fileImports)) return [this._renderNavigationMainLevel(1)];
    else if (matchPath(path, config.ApplicationRoutes.org_orgs_contacts)) return [this._renderNavigationMainLevel(2)];
    else if (matchPath(path, config.ApplicationRoutes.org_orgs_students)) return [this._renderNavigationMainLevel(3)];
    else if (matchPath(path, config.ApplicationRoutes.org_orgs_cus)) return [this._renderNavigationMainLevel(4)];
    //CU details
    else if (matchPath(path, config.ApplicationRoutes.org_cu_cu)) {
      return [this._renderNavigationMainLevel(4),
      <Breadcrumb.Item key='org_cuBC' onClick={this._openLink.bind(this, config.ApplicationRoutes.org_cu_cu, null, values.cuID)}>
        <Link to={this._parseUrl(config.ApplicationRoutes.org_cu_cu, null, values.cuID)}> {values.cuID || 'Classification Unit'} </Link>
      </Breadcrumb.Item>];
    }
    //Import details
    else if (matchPath(path, config.ApplicationRoutes.org_fileImport_job)) {
      return [this._renderNavigationMainLevel(1),
      <Breadcrumb.Item key='org_fileImportBC' onClick={this._openLink.bind(this, config.ApplicationRoutes.org_fileImport_job, null, values.jobID)}>
        <Link to={this._parseUrl(config.ApplicationRoutes.org_fileImport_job, null, values.jobID)}> {'File Import'} </Link>
      </Breadcrumb.Item>];
    }
    //Org details
    //reusabe crumbs
    const OrgBC = <Breadcrumb.Item key='org_org_detailBC' onClick={this._openLink.bind(this, config.ApplicationRoutes.org_organization, { n: values.orgName }, values.orgID)}>
      <Link to={this._parseUrl(config.ApplicationRoutes.org_organization, { n: values.orgName }, values.orgID)}> {values.orgName || 'Company'} </Link>
    </Breadcrumb.Item>;
    if (!values.orgID) return null;
    if (matchPath(path, config.ApplicationRoutes.org_employees)) return [this._renderNavigationMainLevel(0), OrgBC, this._renderNavigationOrganizationDetailsLevel(0, values)];
    else if (matchPath(path, config.ApplicationRoutes.org_contacts)) return [this._renderNavigationMainLevel(0), OrgBC, this._renderNavigationOrganizationDetailsLevel(1, values)];
    else if (matchPath(path, config.ApplicationRoutes.org_managers)) return [this._renderNavigationMainLevel(0), OrgBC, this._renderNavigationOrganizationDetailsLevel(2, values)];
    else if (matchPath(path, config.ApplicationRoutes.org_locations)) return [this._renderNavigationMainLevel(0), OrgBC, this._renderNavigationOrganizationDetailsLevel(3, values)];
    else if (matchPath(path, config.ApplicationRoutes.org_programs)) return [this._renderNavigationMainLevel(0), OrgBC, this._renderNavigationOrganizationDetailsLevel(4, values)];
    else if (matchPath(path, config.ApplicationRoutes.org_organization)) return [this._renderNavigationMainLevel(0), OrgBC];
    return null;
  }

  //Private UI
  _renderNavigationMainLevel(type) {
    //Check for selected third level option
    let option = null;
    if (type == 0 /* companies */) {
      option = {
        displayName: 'Companies', link: this._parseUrl(config.ApplicationRoutes.org_orgs_search, null, null), raw: config.ApplicationRoutes.org_orgs_search,
        icon: <IoMdBusiness style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    } else if (type == 1 /* imports */) {
      option = {
        displayName: 'File Imports', link: this._parseUrl(config.ApplicationRoutes.org_fileImports, null, null), raw: config.ApplicationRoutes.org_fileImports,
        icon: <TbFileImport style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    } else if (type == 2 /* contacts */) {
      option = {
        displayName: 'Contacts', link: this._parseUrl(config.ApplicationRoutes.org_orgs_contacts, null, null), raw: config.ApplicationRoutes.org_orgs_contacts,
        icon: <FaRegAddressCard style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    } else if (type == 3 /* students */) {
      option = {
        displayName: 'Students', link: this._parseUrl(config.ApplicationRoutes.org_orgs_students, null, null), raw: config.ApplicationRoutes.org_orgs_students,
        icon: <FaUserGraduate style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    } else if (type == 4 /* classification units */) {
      option = {
        displayName: 'Classification Units', link: this._parseUrl(config.ApplicationRoutes.org_orgs_cus, null, null), raw: config.ApplicationRoutes.org_orgs_cus,
        icon: <FaTags style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    }
    //should be handled above, but it's here to support new options without showing wrong information
    if (!option) return (<></>);
    //Render
    const items = [];
    if (true /*TODO: check condition based on TBD ACL */) items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_orgs_search, null, null)}> Companies </Link>, key: config.ApplicationRoutes.org_orgs_search, icon: <IoMdBusiness /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_fileImports, null, null)}> File Imports </Link>, key: config.ApplicationRoutes.org_fileImports, icon: <TbFileImport /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_orgs_contacts, null, null)}> Contacts </Link>, key: config.ApplicationRoutes.org_orgs_contacts, icon: <FaRegAddressCard /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_orgs_students, null, null)}> Students </Link>, key: config.ApplicationRoutes.org_orgs_students, icon: <FaUserGraduate /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_orgs_cus, null, null)}> Classification Units </Link>, key: config.ApplicationRoutes.org_orgs_cus, icon: <FaTags /> });
    const menu = (<Menu items={items} />);
    return (<Breadcrumb.Item key='org_mainBC' overlay={menu} onClick={this._openLink.bind(this, option.raw, null, null)}> <Link to={option.link}>{option.icon}{option.displayName}</Link> </Breadcrumb.Item>);
  }
  _renderNavigationOrganizationDetailsLevel(type, values) {
    const { orgID, orgName } = values;
    const orgParams = { n: orgName };
    //Check for selected third level option
    let option = null;
    if (type == 0 /* Employees */) {
      option = {
        displayName: 'Employees', link: this._parseUrl(config.ApplicationRoutes.org_employees, orgParams, orgID), raw: config.ApplicationRoutes.org_employees,
        icon: <FaUsers style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    } else if (type == 1 /* Contacts */) {
      option = {
        displayName: 'Contacts', link: this._parseUrl(config.ApplicationRoutes.org_contacts, orgParams, orgID), raw: config.ApplicationRoutes.org_contacts,
        icon: <FaRegAddressCard style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    } else if (type == 2 /* Managers */) {
      option = {
        displayName: 'Managers', link: this._parseUrl(config.ApplicationRoutes.org_managers, orgParams, orgID), raw: config.ApplicationRoutes.org_managers,
        icon: <FaUserShield style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    } else if (type == 3 /* Locations */) {
      option = {
        displayName: 'Locations', link: this._parseUrl(config.ApplicationRoutes.org_locations, orgParams, orgID), raw: config.ApplicationRoutes.org_locations,
        icon: <GrMapLocation style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    } else if (type == 4 /* Programs */) {
      option = {
        displayName: 'Programs', link: this._parseUrl(config.ApplicationRoutes.org_programs, orgParams, orgID), raw: config.ApplicationRoutes.org_programs,
        icon: <GrCertificate style={{ marginRight: 10, bottom: -3, position: 'relative' }} />
      };
    }
    //should be handled above, but it's here to support new options without showing wrong information
    if (!option) return (<></>);
    //Render
    const items = [];
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_employees, orgParams, orgID)}> Employees </Link>, key: config.ApplicationRoutes.org_employees, icon: <FaUsers /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_contacts, orgParams, orgID)}> Contacts </Link>, key: config.ApplicationRoutes.org_contacts, icon: <FaRegAddressCard /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_managers, orgParams, orgID)}> Managers </Link>, key: config.ApplicationRoutes.org_managers, icon: <FaUserShield /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_locations, orgParams, orgID)}> Locations </Link>, key: config.ApplicationRoutes.org_locations, icon: <GrMapLocation /> });
    items.push({ label: <Link to={this._parseUrl(config.ApplicationRoutes.org_programs, orgParams, orgID)}> Programs </Link>, key: config.ApplicationRoutes.org_programs, icon: <GrCertificate /> });
    const menu = (<Menu items={items} />);
    return (<Breadcrumb.Item key='org_org_detailsBC' overlay={menu} onClick={this._openLink.bind(this, option.raw, orgParams, orgID)}> <Link to={option.link}>{option.icon}{option.displayName}</Link> </Breadcrumb.Item>);
  }
}