import React from 'react';
import autoBind from 'react-autobind';
import { Layout, PageHeader, Button } from 'antd';
//
import Utils from '@/components/helpers/Utils';
import CustomComponent from '@/ui-components/CustomComponent';
import CommonLoadingView from '@/views/commonComponents/CommonLoadingView';
//
import CommonSimpleSearchBar from '../commonComponents/CommonSimpleSearchBar';
import CommonTenantsTable from '../commonComponents/CommonTenantsTable';
//
import config from '@/config/config';
import Globals from '@/config/Globals';
//
export default class AdminTenantsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false, tenants: [], searchTerm: '' };
  }
  
  //Life-cycle
  async componentDidMount() {
    super.componentDidMount();
    this._loadTenants();
  }
    //Search
  handleSearch(searchTerm) { this.setState({ searchTerm }); }
    //Tenant actions
  handleTenantCreate() { this.props.app.urlManager.pushPage(config.ApplicationRoutes.config_tenant, null, Globals.URL_NewIndentifier); }
  handleTenantEdit(tObj) { this.props.app.urlManager.pushPage(config.ApplicationRoutes.config_tenant, null, tObj.id); }
  async handleTenantDelete(tObj) { 
    //Double confirmation
    if ((await this.props.app.alertController.showPromptAlert('Attention!!', `Do you really want to delete the Tenant ${tObj.organizationName}? If so, please, type it's ID to confirm the deletion.`)) != tObj.id) return;
    if (!(await this.props.app.alertController.showQuestionAlert('Deletion imminent!', `Ok, I\'m not 100% sure you want to do this, can you confirm once again the tenant ${tObj.organizationName} with ID ${tObj.id} is the one you want to DELETE irreversibly?`))) return;
    //
    this._deleteTenant(tObj); 
  }
  //UI
  render() {
    const { searchTerm } = this.state;
    //filter tenants on runtime
    const tenants = (this.state.tenants || []).filter((c) => {
      return searchTerm && searchTerm.length > 0 ? 
        c.id.toLowerCase().includes(searchTerm) || (c.organizationName || '').toLowerCase().includes(searchTerm) :
        true;
    });
    //
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
          <PageHeader className='pageHeader' title="Tenants"
              extra={[<Button key="1" onClick={this.handleTenantCreate} type='primary'>Create Tenant</Button>]}/>
          <Layout.Content>
            <CommonSimpleSearchBar isLoading={this.state.isLoading} onSearch={this.handleSearch} 
                {...Utils.propagateRef(this, 'searchBar')} app={this.props.app} placeholder="Search by tenant name or ID"/>
            <CommonTenantsTable app={this.props.app} isLoading={this.state.isLoading}
                onEdit={this.handleTenantEdit} onDelete={this.handleTenantDelete} tenants={tenants} />
          </Layout.Content>
      </Layout.Content>
    );
  }
  /* Private API operations */
  async _deleteTenant(tenant) {
    this.startLoading();
    const deleteResp = await this.props.app.config.tenant.deleteTenant(tenant.id);
    if (!this._isMounted) return;
    if (deleteResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", `Tenant '${tenant.organizationName}' delete with success!`);
      this._loadTenants();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, deleteResp);
      this.stopLoading();
    }
  }
  async _loadTenants() {
    this.startLoading();
    const resp = await this.props.app.config.tenant.getAllTenants();
    if (!this._isMounted) return;
    if (resp.statusCode == 200 && resp.body && resp.body.tenants) this.setState({tenants: resp.body.tenants, isLoading: false});
    else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.stopLoading();
    }
  }
}
