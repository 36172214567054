import React from "react";
import autoBind from 'react-autobind';
//
import Utils from '@/components/helpers/Utils';
import config from "@/config/config";
import Globals from "@/config/Globals";
//
import CustomComponent from "@/ui-components/CustomComponent";
import CommonLoadingView from "@/views/commonComponents/CommonLoadingView";
import RootRoleForm from "./RootRoleForm";
//resources
import "@/stylesheets/CommonRegistration.less";
//
import { Layout, Button, PageHeader } from 'antd';
//
export default class RootRoleView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: [], firstLoad: true, isLoading: false };
    this.isNew = false;
  }
  loadResponse(roleResp) {
    if (!this._isMounted) return;
    if (roleResp.statusCode == 200) { //valid data state
      this.setState({ data: roleResp.body });
      //set navigation items details
      this.props.app.appsManager?.navigation?.appendValue('roleID', this.state.data.id);
      this.props.app.appsManager?.navigation?.appendValue('roleName', this.state.data.name);
    } else { //non-valid data state
      this.props.app.alertController.showAPIErrorAlert(null, roleResp);
      this.setState({ data: [] });
    }
    this.setState({ firstLoad: false });
    this.stopLoading(false);
  }
  async componentDidMount() {
    super.componentDidMount();
    document.title = `${this.props.app.themeManager.theme.applicationName} - Role details`;
    if (this.state.firstLoad) {
      //Check for id
      this.isNew = (this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this) == Globals.URL_NewIndentifier);
      if (!this.isNew) this.fetchData();
      else this.setState({ firstLoad: false });
    }
  }
  //API
  async fetchData() {
    this.startLoading();
    if (!this._isMounted) return;
    const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    const roleID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID2_Placeholder, this);
    this.loadResponse((await this.props.app.idm.api.role.read(roleID, appID)));
    this.stopLoading();
  }
  //Actions handler
  handleChange(event) {
    //set readonly to false if disabling superRole
    if (event.target.id == 'superRole' && event.target.checked == false) {
      this.form.setFieldsValue({ readOnly: false });
      this.state.data.readOnly = false;
    }
    Utils.defaultFormChangeHandler(event, this);
  }
  async handleSubmit() {
    const formData = await this.form.validateFields();
    if (formData) {
      if (this.isNew) this._addRole();
      else this._saveRole();
    }
  }
  //UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.onGoingBehaviour.isAuthenticating;
    return (
      <Layout.Content className='pageContent'>
        <CommonLoadingView isLoading={isLoading} isFixed={true} />
        <PageHeader className='pageHeader' onBack={() => window.history.back()} title={this.isNew ? 'New Role' : `Role - ${(this.state.data.name ? this.state.data.name : 'Loading...')}`}
          extra={[<Button key="1" onClick={this.handleSubmit} type='primary'>{this.isNew ? 'Create Role' : 'Save Role'}</Button>]} />
        <Layout.Content>
          <RootRoleForm onChange={this.handleChange} editMode={true} isNew={this.isNew} data={this.state.data} {...Utils.propagateRef(this, 'form')} />
        </Layout.Content>
      </Layout.Content>
    );
  }

  /*Private methods*/
  async _addRole() {
    this.startLoading();
    let obj = Object.assign({}, this.state.data);
    // checkboxes
    obj.mfaRequired = !!obj.mfaRequired;
    obj.registrationEnabled = !!obj.registrationEnabled;
    obj.readOnly = !!obj.readOnly;
    obj.superRole = !!obj.superRole;
    obj.allowFederation = !!obj.allowFederation;
    //
    const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
    //
    const addResp = await this.props.app.idm.api.role.create(obj, appID);
    if (addResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Role created with success!");
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.application, null, appID);
      this.props.app.appsManager?.navigation?.appendValue('applicationID', appID);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, addResp);
      this.stopLoading();
    }
  }
  async _saveRole() {
    this.startLoading();
    let obj = Object.assign({}, this.state.data);
    // checkboxes
    obj.mfaRequired = !!obj.mfaRequired;
    obj.registrationEnabled = !!obj.registrationEnabled;
    obj.readOnly = !!obj.readOnly;
    obj.superRole = !!obj.superRole;
    obj.allowFederation = !!obj.allowFederation;
    //
    const updateResp = await this.props.app.idm.api.role.update(obj);
    if (updateResp.statusCode == 200) {
      this.props.app.alertController.showSuccessAlert("", "Role updated with succeed!");
      const appID = this.props.app.urlManager.getPathParam(Globals.URL_Path_ID_Placeholder, this);
      this.props.app.urlManager.pushPage(config.ApplicationRoutes.application, null, appID);
      this.props.app.appsManager?.navigation?.appendValue('applicationID', appID);
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, updateResp);
      this.stopLoading();
    }
  }
}
