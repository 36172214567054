import React from 'react';
import autoBind from 'react-autobind';
//
import { Button } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
//props are: app
export default class BackToTenantButton extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Actions
  handleSendToTenant() {
    const tenant = this.props.app.configManager.launchConfig?.tenant;
    if (tenant) this.props.app.urlManager.openPage(tenant.organizationApplicationURL);
  }
  //UI
  render() {
    const tenant = this.props.app.configManager.launchConfig?.tenant;
    return (
      <Button type="link" className="backTenantButton" icon={<CaretLeftOutlined />} onClick={this.handleSendToTenant}>
        {tenant?.organizationName}
      </Button>
    );
  }
}
